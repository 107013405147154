import React, {Fragment, useState} from "react";
import {Col, Container, Media, Row} from "reactstrap";
import {Link} from "react-router-dom";


const PermissionError = () => {
        return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        {/*<Media body className="img-100" src={} alt="" />*/}
                        {/*<div className="error-heading">*/}
                            <h1 className="display-2 primary">403</h1>
                        {/*</div>*/}
                        <Col md="8 offset-md-2">
                            <p className="sub-content">{"You do not have permission to access this page"}</p>
                        </Col>
                        {/*<Link to={`${process.env.PUBLIC_URL}/dashboard/default/`}><Button color="info-gradien" size='lg'>{BACK_TO_HOME_PAGE}</Button></Link>*/}
                    </Container>
                </div>
            </div>
         </Fragment>
    )
}

export default PermissionError