import React, {Fragment, useEffect, useRef, useState} from "react";
import {Row, Col, FormGroup, Label, Media} from "reactstrap";
import {FormCheck} from "react-bootstrap";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import { getTagSettingData } from "../../store/productTagSettingSlice";
import Switch from "react-switch";


const BinLocationTypeForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;
    const [settingData, setSettingData] = useState([]);
    const [isTag,setIsTag]=useState(getValues('is_tag'));
    const dispatch = useDispatch();
    const [file, setFile] = useState(getValues('image'));
    const inputFile = useRef(null);

    const imageClick = (event)=>{
        event.preventDefault();
        inputFile.current.click();
    };
    const handleImageClick = (event)=>{
        setFile(URL.createObjectURL(event.target.files[0]));
        setValue('image_file',event.target.files[0]);        
    };
    return (
        <Fragment>
                <input name="imageURL" type="hidden" value={getValues('image_path')} {...register('imageURL')}/>
            
            <FormGroup>
                <Label className="col-form-label">Title</Label>
                <input name="title" type="text" {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.title?.message}</div>
            </FormGroup>
        
            <FormGroup>
                <Label className="col-form-label">Capacity</Label>
                <input name="capacity" type="number" {...register('capacity')} className={`form-control ${errors.capacity ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.capacity?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="col-form-label">Width</Label>
                <input name="width" type="number" {...register('width')} className={`form-control ${errors.width ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.width?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="col-form-label">Length</Label>
                <input name="length" type="number" {...register('length')} className={`form-control ${errors.length ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.length?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="col-form-label">Height</Label>
                <input name="height" type="number" {...register('height')} className={`form-control ${errors.height ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.height?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="col-form-label">Image </Label>                         
                <div className="col-auto">
                    <Media onClick={imageClick} style={{width:'120px'}} className="" alt="user-image" src={file?file:require("../../../../assets/images/default-medium-product.jpeg")}   />
                    <input type='file' id='file' ref={inputFile} onChange={handleImageClick} style={{display: 'none'}}/>
                </div>
            </FormGroup>
                
        </Fragment>
    );
};

export default BinLocationTypeForm;