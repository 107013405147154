import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { salesOrderResponseToTable } from "../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import salesOrderReducer from "../store";
import {
  getSalesOrders,
  getSalesOrder,
  selectSalesOrder,
} from "../store/salesOrderSlice";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../../share-components/ui/preLoader";
import SweetAlert from "sweetalert2";
import { DateRangePicker } from "rsuite";

const { afterToday } = DateRangePicker;

const PopoverBasicItem = (props) => {
  const { id, btntext, Popoverbody } = props;
  const [popover, setPopover] = useState(false);
  const Toggle = () => setPopover(!popover);
  return (
    <>
      <p
        style={{ cursor: "pointer" }}
        className="example-popover"
        id={"Popover-" + id}
      >
        {btntext} <i className="fa fa-info-circle"></i>
      </p>
      <Popover
        placement="top"
        isOpen={popover}
        target={"Popover-" + id}
        toggle={Toggle}
        trigger="hover"
      >
        <PopoverBody>{Popoverbody}</PopoverBody>
      </Popover>
    </>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const SalesOrder = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [loading, isSetLoading] = useState(true);
  const salesOrders = useSelector(selectSalesOrder);
  console.log("S : " + salesOrders);
  let history = useHistory();
  const sellerState = useSelector(({ seller }) => seller.seller);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  // const salesOrderData = useSelector(selectSeller);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;

  const saleOrderState = useSelector(({ order }) => order.salesOrder);

  const dataMenu = [
    {
      type: 0,
      text: "Sales Order",
    },
  ];

  const tableColumns = [
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      center: false,
    },
    {
      name: "Amount",
      selector: (row) => row.total,
      sortable: true,
      center: false,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
              <a
                onClick={() => {
                  onView(row);
                }}
              >
                <i
                  className="fa fa-eye"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(51, 153, 255)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
          {userState.user &&
            (userState.user.permission.indexOf("Update Seller") !== -1 ? (
              <a
                onClick={() => {
                  onEdit(row);
                }}
              >
                <i
                  className="fa fa-pencil"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
          {userState.user &&
            (userState.user.permission.indexOf("Update Seller") !== -1 ? (
              <a
                onClick={() => {
                  onInvoiceEdit(row);
                }}
              >
                <i
                  className="fa fa-file-text-o"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(255, 187, 51)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [page, brand, search]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
    };
    console.log("Data:", data);
    dispatch(getSalesOrders(data)).then((res) => {
      console.log("API Response:", res);
      console.log('Data:', data); 
      if (!res.error) isSetLoading(false);
    });
  };
  const onInvoiceEdit = (row) => {
    history.push("sale/" + row.id + "/billing/invoice");
  };
  const onEdit = (row) => {
    history.push("sale/" + row.id + "/edit");
  };
  const onView = (row) => {
    history.push("sale/" + row.id + "/view");
  };
  const onAdd = () => {
    history.push("sale/create");
  };
  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Sales Order" data={dataMenu} />
          <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
              <div className="feature-products">
                {currentUser.permission.includes("Create Seller") ? (
                  <Card>
                    {userState.user &&
                      (userState.user.permission.indexOf("Add Seller") !==
                      -1 ? (
                        <Row>
                          <Col sm="12">
                            <div className="float-sm-right p-3">
                              <a
                                onClick={onAdd}
                                className="btn btn-sm btn-primary"
                              >
                                {" "}
                                <i className="fa fa-plus"></i> Add Sales Order
                              </a>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      ))}
                  </Card>
                ) : (
                  ""
                )}
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <DatePicker
                            dateRange={dateRange}
                            dateRangeChange={(update) => {
                              setDateRange(update);
                            }}
                            disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col sm="2">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Type"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setBrand(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        sellerState.settings
                          ? sellerState.settings.brands
                          : null
                      }
                    />
                  </Col>

                  <Col sm="2">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Status"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setBrand(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        sellerState.settings
                          ? sellerState.settings.brands
                          : null
                      }
                    />
                  </Col>

                  <Col sm="2">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Customer"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setBrand(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        sellerState.settings
                          ? sellerState.settings.brands
                          : null
                      }
                    />
                  </Col>

                  <Col sm="3">
                    <Form>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          placeholder="Search by Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={salesOrderResponseToTable(salesOrders)}
                        onEdit={
                          currentUser.permission.includes("Update Seller")
                            ? onEdit
                            : ""
                        }
                        onView={
                          currentUser.permission.includes("View Seller")
                            ? onView
                            : ""
                        }
                        noAction
                        paginationServer
                        pagination
                        // paginationTotalRows={sellerState.sellerTableRow}
                        paginationTotalRows={saleOrderState.noOfTableRows}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        paginationPerPage={10}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([
  {
    salesOrders: salesOrderReducer,
  },
])(SalesOrder);
