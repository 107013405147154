import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductStyleApi, deleteProductStyleApi,
    getProductStyleApi,
    updateProductStyleApi
} from "../../../services/manage-product/setting/productStyleService";
import {toast} from "react-toastify";


export const getAllProductStyles = createAsyncThunk('productStyle/getProductStyle', async (filters, {dispatch}) => {
    const response = await getProductStyleApi(filters);
    return response.response;
});

export const addProductStyle = createAsyncThunk('productStyle/addProductStyle', async (productStyle, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductStyleApi(productStyle);
        const data = await response.response;
        dispatch(getAllProductStyles());
        toast.success("Add product style successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductStyle = createAsyncThunk('productStyle/updateProductStyle', async (productStyle,{dispatch, getState})=>{
    try {
        const response = await updateProductStyleApi(productStyle, productStyle.id);
        const data = await response.response;
        dispatch(getAllProductStyles());
        toast.success("Update product style successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductStyle = createAsyncThunk('productStyle/deleteProductStyle', async (id,{dispatch, getState})=>{
    try {
        const response = await deleteProductStyleApi(id);
        const data = await response.response;
        dispatch(getAllProductStyles());
        toast.success("Delete product style successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productStyleAdapter = createEntityAdapter({});

export const {
    selectAll: selectProductStyle,
    selectById: selectProductStyleById
} = productStyleAdapter.getSelectors(
    state => state.product.productStyle
);

const productStyleSlice = createSlice({
    name: 'productStyle',
    initialState: productStyleAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productStyle',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductStyleSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productStyle',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },

    extraReducers: {
        [getAllProductStyles.fulfilled]: productStyleAdapter.setAll
    }
});

export const {
    selectProductStyleSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productStyleSlice.actions;

export default productStyleSlice.reducer;