import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AgentForm from "./agentForm";
import withReducer from "../../../store/withReducer";
import userReducer from "../store";
import {
    addAgent,
    closeEditDialog,
    deleteAgent,
    getAgents,
    getAllSellers,
    openEditDialog,
    openNewDialog,
    selectAgent,
    updateAgent
} from "../store/agentSlice";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapAgentResponseToTable, mapSearchAutoSuggestion} from "../../../utils/mapper";
import Select from "react-select";
import {sellerAutoSuggestionApi} from "../../../services/manage-user/agent";
import '../user-setting.css'
import PreLoader from "../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    email: '',
    commission_percentage: '',
    mobile_number: '',
    seller: '',
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  true;
    });
});

const schema = yup.object().shape({
    name: yup.string().required('You must enter a role name'),
    email: yup.string().email().required('You must enter a email'),
    commission_percentage: yup.number().required('You must enter a number').typeError('You must enter a number'),
    mobile_number: yup.string().required('You must enter valid phone number').customPhoneValidation('You must enter valid phone number'),
});

const Agent = () => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);
    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [mobile, setMobile] = useState([]);
    const [seller, setSeller] = useState([]);
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const agent = useSelector(selectAgent);

    useEffect(() => {
        const data = {
            name,
            email,
            mobile,
            seller
        };
        dispatch(getAgents({...data})).then(res => {
            setLoading(false);
        });
    }, [dispatch, name, email, mobile, seller]);

    useEffect(() => {
        dispatch(getAllSellers()).then(res => {
            setOptions(res.payload);
        });
    }, [dispatch]);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const tableColumns = [
        {
            name: 'Agent Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            left: true,
        },
        {
            name: 'Commission (%)',
            selector: row => row.commission_percentage,
            sortable: true,
            right: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile_number,
            sortable: true,
            left: true,
        },
    ];


    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addAgent({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateAgent({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    }

    const closeDialog = () => {
        setValue('seller', {});
        dispatch(closeEditDialog());
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
        setValue('seller', row.seller);
    }

    const onDelete = (row) => {
        dispatch(deleteAgent(row.id))
    }

    const promiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
            }, 1000);
        });

    const {errors} = formState;
    return (
        <Fragment>

            <Container fluid={true}>
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb parent="Manage Agents" title="Agents"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Agent') ?
                                    <Card>
                                        <Row>
                                            <Col sm="12">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Agent</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" xl="3">

                                    <Form>
                                        <FormGroup>
                                            {/*<Label>Search By Name</Label>*/}
                                            <input onChange={(e) => setName(e.target.value)} name="name"
                                                   type="text" className="form-control"
                                                   placeholder="Search By Name"/>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col sm="12" xl="3">
                                    <Form>
                                        <FormGroup>
                                            {/*<Label>Search By Email</Label>*/}
                                            <input onChange={(e) => setEmail(e.target.value)} name="name"
                                                   type="text" className="form-control"
                                                   placeholder="Search By Email"/>
                                        </FormGroup>

                                    </Form>
                                </Col>
                                <Col sm="12" xl="3">
                                    <Form>
                                        <FormGroup>
                                            {/*<Label>Search By Mobile</Label>*/}
                                            <input onChange={(e) => setMobile(e.target.value)} name="name"
                                                   type="text" className="form-control"
                                                   placeholder="Search By Mobile"/>
                                        </FormGroup>

                                    </Form>
                                </Col>
                                <Col sm="12" xl="3">

                                    <Select getOptionLabel={(option) => `${option.name}`}
                                            className="select2-filter border"
                                            getOptionValue={(option) => option.id}
                                            placeholder={<div>Search By Seller</div>}
                                            onChange={(e) => setSeller(e?e.id:null)}
                                            options={options}
                                            isClearable
                                            cacheOptions
                                            defaultOptions
                                            styles={customStyles}


                                    />


                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-5">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={mapAgentResponseToTable(agent)}
                                                    pagination
                                                    onEdit={(currentUser.permission).includes('Update Agent') ? onEdit:''}
                                                    onDelete={(currentUser.permission).includes('Delete Agent') ? onDelete:''}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>


                        </div>
                    </div>
                </>
                }
            </Container>
            <ModalFormSubmit
                size="lg"
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Agent'
                selector='staff'
                dialog={({user}) => user.agent.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <AgentForm control={control} errors={errors} register={register} sellers={options} trigger={trigger}
                           options={options} setOptions={setOptions} getValues={getValues} setValue={setValue}
                           promiseOptions={promiseOptions}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{agent: userReducer}])(Agent)