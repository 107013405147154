import API from "../utils/api";


export const getSellerReportDataApi = async (data) => {
  const response = await API.get('report/sales-report',{
      params: data,
  });
  return {response};
};

export const getBackInStockReportApi = async (data) => {
    const response = await API.get('report/back-in-stock-report',
        {
            params: data,
        });
    return {response};
}

export const getSelectedBackInRequestData = async (id) => {
    const response = await API.get(`report/get-selected-back-in-stock/${id}`);
    return {response};
};

export const getExchangeOrderReportApi = async (data) => {
    const response = await API.get(`order/get-all-exchange-orders`, {params:data});
    return {response};
}

export const offerItemReportApi = async (data) => {
    const response = await API.get('report/offer-report', {params:data});
    return {response};
}