import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import { addTagSettingApi, deleteTagSettingApi, getAllTagsSettingDataApi, getTagsSettingDataApi, retrieveTagSettingApi, updateTagSettingApi } from "../../../services/manage-product/setting/productTypeTagService";

export const getTagSettingData = createAsyncThunk('productTagSetting/getTagData', async (filters, {dispatch}) => {
    const response = await getTagsSettingDataApi(filters);
    return response.response;
});

export const getAllTagSetting = createAsyncThunk('productTagSetting/getAllTagData', async (filters, {dispatch}) => {
    const response = await getAllTagsSettingDataApi(filters);
    return response.response;
});

export const editTagSetting = createAsyncThunk('productTagSetting/edit', async (id) => {
	const response = await retrieveTagSettingApi(id);
	return response.response;
});

export const addTagSetting = createAsyncThunk('addTagSetting', async (item, {dispatch, getState}) => {
    try {
        // const formData = new FormData();

        // formData.append('name',item.name);
        // formData.append('tags',item.tags);
        // formData.append('is_tag',item.is_tag);
       
        const response = await addTagSettingApi(item);
        const data = await response.response;
        dispatch(getAllTagSetting());
        if (response.response.errors) {
            toast.error("The title has already been taken!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            toast.success("Create tag setting successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});



export const updateTagSetting = createAsyncThunk('updateTagSetting', async (productType, {
    dispatch,
    getStatus
}) => {
    try {
        const response = await updateTagSettingApi(productType, productType.id);
        const data = await response.response;
        dispatch(getAllTagSetting());
        toast.success("Update tag setting successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteTagSetting = createAsyncThunk('tagSetting/delete', async (id, {
    dispatch,
    getStatus
}) => {
    try {
        const response = await deleteTagSettingApi(id);
        const data = await response.response;
        dispatch(getAllTagSetting());
        toast.success("Deleted tag Setting successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productTagSettingAdapter = createEntityAdapter({});

export const {
    selectAll: selectProductTagSetting,
    selectById: selectProductTagSettingById
} = productTagSettingAdapter.getSelectors(
    state => state.product.productTagSetting
);

const productTagSettingSlice = createSlice({
    name: 'productTagSetting',
    initialState: productTagSettingAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productTagSetting',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        productTagSetting:null,
    }),
    reducers: {
        selectProductStyleSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'productTagSetting',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },

        setTagSettingData: (state, action) => {
            state.productTagSetting = action.payload
        },
    },

    extraReducers: {
        [getAllTagSetting.fulfilled]: productTagSettingAdapter.setAll
    }
});

export const {
    selectProductStyleSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog,
    setTagSettingData
} = productTagSettingSlice.actions;

export default productTagSettingSlice.reducer;