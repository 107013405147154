import React, {useState} from "react";
import {useDispatch} from "react-redux";
import ScrollArea from 'react-scrollbar';
import {Col, FormGroup, Row} from "reactstrap";
import Select from "react-select";
import {setOrderFilter} from "../store/orderSlice";
import Switch from "react-switch";


export default function OrderFilter(props) {
    const dispatch = useDispatch();
    const {orderState, clearSelectAll, customStyles,paymentTypeOptions,currentUser, setPaymentType, setCountry, setFilterValues, setOrderType, setShippingType,setRemoteIsland,remoteIsland, orderTypeOptions, unallocatedShipping, setUnallocatedShipping, setPartner} = props;

    document.addEventListener("mousedown", (event) => {
        const concernedElement = document.querySelector(".customizer-contain");
        if(concernedElement) {
            if (!concernedElement.contains(event.target)) {
                document.querySelector(".customizer-contain").classList.remove('open');
            }
        }
    });

    const [checked, setChecked] = useState(remoteIsland);
    const [checkedUnallocatedShipping, setCheckedUnallocatedShipping] = useState(unallocatedShipping);



    const handleChange = nextChecked => {
        dispatch(setOrderFilter({
            ...orderState.filter,
            remote_island:nextChecked
        }));
        setRemoteIsland(nextChecked)
        clearSelectAll();
        setChecked(nextChecked);
    }

    const unallocatedShippingHandleChange = nextChecked => {
        dispatch(setOrderFilter({
            ...orderState.filter,
            unallocated_shipping:nextChecked
        }));
        setUnallocatedShipping(nextChecked)
        clearSelectAll();
        setCheckedUnallocatedShipping(nextChecked);
    }

    const unallocatedSellerHandleChange = nextChecked => {
        dispatch(setOrderFilter({
            ...orderState.filter,
            unallocated_seller:nextChecked
        }));
    }
    return (
        <>
            <div style={{height: '1000'}}>
                <ScrollArea
                    horizontal={false}
                    vertical={true}
                >
                    <Row>
                        <Col md={12} className="mt-3">

                            <Select
                                getOptionLabel={(option) => option.name}
                                    className="select2-filter"
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                    value={orderState.filter.payment_type}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => {
                                        dispatch(setOrderFilter({
                                            ...orderState.filter,
                                            payment_type:e
                                        }));
                                       
                                        clearSelectAll();

                                    }}
                                    isClearable={true}
                                    placeholder="Payment type"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={paymentTypeOptions}/>
                        </Col>
                        <Col md={12} className="mt-4">
                            <Select
                                getOptionLabel={(option) => option.name}
                                className="select2-filter"
                                menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                value={orderState.filter.country}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    dispatch(setOrderFilter({
                                        ...orderState.filter,
                                        country:e
                                    }));
                                    
                                    clearSelectAll();

                                }}
                                isClearable={true}
                                placeholder="Country"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={orderState.settings.countries}/>
                        </Col>
                        {!(currentUser.modules).includes('is-hide-cg') &&    <>
                        <Col md={12} className="mt-4">
                            <Select
                                getOptionLabel={(option) => option.name}
                                className="select2-filter"
                                menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                value={orderState.filter.shipping_type}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    dispatch(setOrderFilter({
                                        ...orderState.filter,
                                        shipping_type:e
                                    }));
                                    
                                    clearSelectAll();

                                }}
                                isClearable={true}
                                placeholder="Shipping Types"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={orderState.settings.shippingType}/>
                        </Col>
                        <Col md={12} className="mt-4">
                            <Select
                                getOptionLabel={(option) => option.name}
                                className="select2-filter"
                                menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                value={orderState.filter.order_type}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    dispatch(setOrderFilter({
                                        ...orderState.filter,
                                        order_type:e
                                    }));
                                   
                                    clearSelectAll();

                                }}
                                isClearable={true}
                                placeholder="Order Types"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={orderTypeOptions}/>
                        </Col>
                        <Col md={12} className="mt-4">
                            <Select
                                getOptionLabel={(option) => option.name}
                                className="select2-filter"
                                menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                value={orderState.filter.partner}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                    dispatch(setOrderFilter({
                                        ...orderState.filter,
                                        partner:e
                                    }));
                                    
                                    clearSelectAll();

                                }}
                                isClearable={true}
                                placeholder="Partners"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={orderState.settings.partner}
                                />
                        </Col>
                        <Col md={12} className="mt-4">
                            <label style={{marginTop: 8, marginLeft: 4}}>Remote Island</label>
                            <FormGroup style={{marginTop: 1, marginLeft: 4}}>
                            <Switch
                                checked={orderState.filter.remote_island}
                                onChange={handleChange}
                                className="react-switch"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />
                            </FormGroup>
                        </Col>
                        <Col md={12} className="mt-2">
                            <label style={{marginTop: 8, marginLeft: 4}}>Unallocated Shipping Orders</label>
                            <FormGroup style={{marginTop: 1, marginLeft: 4}}>
                            <Switch
                                checked={orderState.filter.unallocated_shipping}
                                onChange={unallocatedShippingHandleChange}
                                className="react-switch"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />
                            </FormGroup>
                        </Col>

                        <Col md={12} className="mt-2">
                            <label style={{marginTop: 8, marginLeft: 4}}>Unallocated Seller</label>
                            <FormGroup style={{marginTop: 1, marginLeft: 4}}>
                            <Switch
                                checked={orderState.filter.unallocated_seller}
                                onChange={unallocatedSellerHandleChange}
                                className="react-switch"
                                uncheckedIcon={false}
                                checkedIcon={false}
                            />
                            </FormGroup>
                        </Col>
                            </>}
                       

                    </Row>
                </ScrollArea>
            </div>
        </>
    )
}