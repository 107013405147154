import API from "../../../utils/api";


export const getProductSizesApi = async (filters) => {
    const response = await API.get('product-size/product-size', { params: filters });
    return { response };
};

export const addProductSizeApi = async location => {
    const response = await API.post('product-size/product-size', location);
    return { response };
}

export const updateProductSizeApi = async (location, id) => {
    const response = await API.put(`product-size/product-size/${id}`, location);
    return { response };
};

export const deleteProductSizeApi = async (id) => {
    const response = await API.delete(`product-size/product-size/${id}`);
    return { response };
};