import React, { Fragment, useEffect } from 'react'
import {Button} from "reactstrap";
import {useDispatch} from "react-redux";
import ViewProductInfo from './viewProductInfo';
import {openProductInfoDialogs, getProductInfo, setProductInfo} from "../../../store/prodcutSlice";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";


function ProductInfo(props) {
    const {id} = useParams();
    const {productStates} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if(productStates.ProductDialogs.props.isOpen) {
            dispatch(getProductInfo(id)).then(res => {
                if (!res.error) {
                    dispatch(setProductInfo( res.payload));
                }
            });
        }
    },[dispatch,productStates.ProductDialogs.props]);

    
  return (

    <Fragment>
      
        <Button style={{marginBottom:"30px"}} className="font-weight-bold float-right" color="primary" type="button"
            onClick={() => {dispatch(openProductInfoDialogs())}} >
            Products Info
        </Button>
 
      <ViewProductInfo
        title='Draft Products'
        selector='product'
        dialog={({product}) => product.product.ProductDialogs}
        productStates={productStates}
        />
        
    </Fragment>
  )
}

export default ProductInfo