import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {crateGroupReturnApi, getGroupReturnApi, getGroupReturnDetailApi} from '../../../services/order';
import {addProductHolidayOfferApi} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";

export const getGroupReturn=createAsyncThunk('order/getGroupReturn',async(data, {dispatch})=>{
    const response=await getGroupReturnApi(data);
    dispatch(setGroupReturnData(response.response.count))
    return response.response.data;
});

export const getGroupReturnDetails=createAsyncThunk('order/getGroupReturnDetail',async(data, {dispatch})=>{
    const response=await getGroupReturnDetailApi(data.id, data.params);
    return response.response;
});
export const createGroupReturn = createAsyncThunk('order/group-return', async (product, {dispatch, getState}) => {
    try {
        const response = await crateGroupReturnApi(product);
        const data = await response.response;
        toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});
const groupReturnAdapter = createEntityAdapter({});
export const {selectAll: selectGroupReturn, selectById: selectGroupReturnById} = groupReturnAdapter.getSelectors(
    state => state.order.groupReturn
);

const groupReturnSlice = createSlice({
    name: 'groupReturn',
    initialState: groupReturnAdapter.getInitialState({
        groupReturnTableRow: 0, 
    }),
    reducers: {
        setGroupReturnData: (state, action) => {
            state.groupReturnTableRow = action.payload;
        },
    },
    extraReducers: {
        [getGroupReturn.fulfilled]: groupReturnAdapter.setAll,
    }
});

export const {setGroupReturnData} = groupReturnSlice.actions;

export default groupReturnSlice.reducer;