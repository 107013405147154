import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import { getPendingCancelApi, getPendingCancelDetailsApi, getPendingCancelStatusApi } from "../../../services/order";
import {toast} from "react-toastify";

export const getPendingCancel=createAsyncThunk('order/getPendingCancel',async(data, {dispatch})=>{
    const response=await getPendingCancelApi(data);
    dispatch(setPendingCancelData(response.response.count))
    return response.response.data;
});


export const getPendingCancelDetails=createAsyncThunk('order/getPendingCancelDetails',async(id, {dispatch})=>{
    const response=await getPendingCancelDetailsApi(id);
    return response.response;
});

export const getPendingCancelStatus=createAsyncThunk('order/getPendingCancelStatus',async(data, {dispatch})=>{
    const response=await getPendingCancelStatusApi(data.id,data.status);
    return response.response;
});

const cancelOrderAdapter = createEntityAdapter({});
export const {selectAll: selectcancelOrder, selectById: selectcancelOrderById} = cancelOrderAdapter.getSelectors(
    state => state.order.cancelOrder
);

const cancelOrderSlice = createSlice({
    name: 'cancelOrder',
    initialState: cancelOrderAdapter.getInitialState({
        cancelOrderTableRow: 0, 
        pendingCancelData:{
            orderData:[],
            itemData:[]
        },
        pendingCancelDialogProps:{
            props: {
                isOpen: false
            },
        }
    }),
    reducers: {
        setPendingCancelData: (state, action) => {
            state.cancelOrderTableRow = action.payload;
        },
        setPendingCancelViewData: (state, action) => {
            state.pendingCancelData = action.payload;
        },
        setPendingCancelModal: (state, action) => {
            state.pendingCancelDialogProps.props.isOpen = action.payload
        },
       
    },
    extraReducers: {
        [getPendingCancel.fulfilled]: cancelOrderAdapter.setAll,
    }
});

export const {setPendingCancelData, setPendingCancelModal, setPendingCancelViewData} = cancelOrderSlice.actions;

export default cancelOrderSlice.reducer;