import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useRef, useState} from "react";
import Select from "react-select";
import ImageUpload from 'image-upload-react';
import {SellerImageUploader} from "./sellerImageUploader";

export const UploadImage = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues}=props;
    const [loaderIcon,setLoaderIcon]=useState(false);
    return (
        <Fragment>
            <Row>
                {
                    sellerState.settings.imgTypes?
                        sellerState.settings.imgTypes.map((type,index) => (
                            <Col key={index} md={3}>
                                <FormGroup>
                                    <Label className="form-label">{type.name}</Label>
                                    <SellerImageUploader setLoaderIcon={setLoaderIcon} sellerId={sellerState.id} imgGcp={type.gcpPath?type.gcpPath:null} imgSrc={type.path?type.path:null} typeName={type.name} typeId={type.id} />
                                </FormGroup>
                            </Col>
                        )):""
                }

            </Row>
            {loaderIcon?
            <Col className="col-md-2 offset-md-10 mt-4">
                <div style={{height:0}} className="loader-box mt-0 mb-0">
                    <div className="loader-3"></div>
                </div>
            </Col>:""}
        </Fragment>
    );
};