import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Label, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapSellerRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {

    getTrackingItem
    , setOrderData, setReturnOrderData, setTrackingData, showOrder
} from "../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import orderReducer from "../store";
import {TrackOrder} from "./section/trackOrder";
import {ShippingInformation} from "./section/shippingInformation";
import {BillingInformation} from "./section/billingInformation";
import {Item} from "./section/item";
import {showSeller} from "../../seller/store/sellerSlice";


const ViewOrder = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const authData =  useSelector(({authReducer}) => authReducer);
    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "View Order"
        }
    ];

    useEffect(() => {
        const data = {
            id,
            params: {seller:authData.defaultSeller?.value}
            
        }

        dispatch(showOrder(data)).then(res => {
            if (!res.error) {
                dispatch(setReturnOrderData(res.payload));
            }
        });
    }, [dispatch,authData.defaultSeller]);

    const retrieveTrackingDetails = (id)=>{
        dispatch(getTrackingItem(id)).then(res => {
            if (!res.error) {
                dispatch(setTrackingData(res.payload));
            }
        });
    }

    return (
        <Fragment>
            <Breadcrumb title="Orders" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm={3}>
                        <TrackOrder orderState={orderState}/>
                        <ShippingInformation colSize={12} orderState={orderState}/>
                        <BillingInformation colSize={12} orderState={orderState}/>
                    </Col>
                    <Col sm={9}>
                        <Item retrieveTrackingDetails={retrieveTrackingDetails} orderId={id} orderState={orderState} authData={authData}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(ViewOrder);