import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {offerItemReportApi} from "../../../../services/report";


export const getVerifyOfferItemList = createAsyncThunk('report/offer-item-report', async (data, {dispatch})=>{
    const response = await offerItemReportApi(data);
    dispatch(setTableRowCount(response.response.count))
    return response.response.data;
});

const offerItemAdapter = createEntityAdapter({});

export const {selectAll: selectOfferItemReport, selectById: selectOfferItemReportById} = offerItemAdapter.getSelectors(
    state => state.report.offerItemReport
);

const offerItemReportSlice = createSlice({
    name: 'offerItemReport',
    initialState: offerItemAdapter.getInitialState({
        id: null,
        searchText: '',
        offerItemReportTableRow:0,
    }),
    reducers : {
        setTableRowCount: (state, action) => {
            state.offerItemReportTableRow = action.payload;
        }
    },
    extraReducers: {
        [getVerifyOfferItemList.fulfilled] : offerItemAdapter.setAll
    }
});

export const {setTableRowCount} = offerItemReportSlice.actions;

export default offerItemReportSlice.reducer;