import React, {Fragment, useEffect, useRef, useState} from "react";

import JqxTable from "../../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import {findIndexes, numberWithCommasWithoutPounds} from "../../../../../../utils/common";



const SaleOrderProductInvoiceJqx = (props) => {
    const {setValue, trigger, getValues, orderedProducts} = props;
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    const [orderData, setOrderData] = useState([]);

    // useEffect(() => {
    //     setOrderData(getValues('ordered_products'));
    // }, [getValues('ordered_products')])
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'id',  type: 'number'},
        {name: 'order_quantity', type: 'number'},
        {name: 'product', type: 'array'},
        {name: 'pending_quantity', type: 'number'},
        {name: 'quantity', type: 'number'},
        {name: 'rate', type: 'number'},
        {name: 'total_tax', type: 'number'},
        {name: 'total_price', type: 'number'},
        {name: 'gross_price', type: 'number'},
        {name: 'sales_order_line_item_id', type: 'number'},
        {name: 'is_vat', type: 'boolean'},
        {name: 'is_selected', type: 'boolean'},




    ];

    const columns = [
        {datafield: 'sales_order_line_item_id', hidden: true},
        {datafield: 'is_vat', hidden: true},
        {datafield: 'tax_amount', hidden: true},
        {
            text: 'Product',
            datafield: 'product',
            editable: false,
            columntype: 'text',
            width: '45.25%',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues('ordered_products')[row].product;
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{productDetails.name}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.option}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
        {
            text: 'Quantity',
            datafield: 'order_quantity',
            editable: false,
            columntype: 'text',
            width: '15%',
            cellsalign: 'right',
            align: 'right',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                // console.log(row,columnfield,);
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                       <div className="row mt-2">
                            <div className="col-12">
                                <div className="col-md-12 p-1">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>order Qty</p>
                                        </div>
                                        <div className="col-8">
                                            <p  className="text-right" >{rowdata.order_quantity}</p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>Pending Qty</p>
                                        </div>
                                        <div className="col-8">
                                            <p  className="text-right" >{rowdata.pending_quantity}</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                );
                return html;
            },

        },
        {
            text: 'Invoice Qty',
            datafield: 'quantity',
            editable: true,
            columntype: 'text',
            width: '9%',
            cellsalign: 'right',
            align: 'right',
            cellclassname: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                 return 'editable-column';
            },
            validation: function (cell, value) {
                const stock = gridRef.getcellvalue(cell.row, 'pending_quantity');
                if (Number(stock) < Number(value)) {
                    toast.error("should be less the order Quantity", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "should be less the order Quantity"}
                } else {
                    return true;

                }
            },


        },
        {
            text: 'Rate',
            datafield: 'rate',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
             cellsformat: 'c2',

        },
        {
            text: 'Amount',
            datafield: 'total_price',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
            cellsformat: 'c2',

        },
        {
            text: 'Tax',
            datafield: 'total_tax',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
            cellsformat: 'c2',

        },
        {
            text: 'Total',
            datafield: 'gross_price',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
            cellsformat: 'c2',

        },
    ];
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };
    const onDelete = (data) => {
        setDataToHookForm();
    }

    const cellEdit = event => {
        const rowArgs = event.args;
        if (rowArgs.datafield == "quantity") {
            const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, 'rate');
            const isTax = gridRef.getcellvalue(rowArgs.rowindex, 'is_vat');
            setTotalAmount(rowArgs.rowindex,rowArgs.value,singlePrice,isTax)
        }
        console.log(rowArgs.datafield);
        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };

    const setTotalAmount=(index,quantity,singlePrice,isVat)=>{
        const totalPrice=quantity*singlePrice;
        const taxAmount=isVat?totalPrice*0.2:0;
        gridRef.setcellvalue(index, "total_price", totalPrice);
        gridRef.setcellvalue(index, "total_tax", taxAmount);
        gridRef.setcellvalue(index, "gross_price", taxAmount+totalPrice);
    }
    const setDataToHookForm = () => {
        let orderData = [];

        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);

        }

        setValue('ordered_products', orderData);
        trigger('ordered_products');

    }


    const handelRowSelect=(event)=>{
         const orderProducts=getValues('ordered_products');
         const  orderData=[];
        const rowIndexes = gridRef.getselectedrowindexes();
        orderProducts.map(function (item, index) {
            const isSelected=rowIndexes.includes(index);
            const quantity=isSelected?(item.quantity?item.quantity:item.pending_quantity):0;
            const subTotal=item.rate*quantity;
            const taxAmount=item.is_vat?subTotal*0.2:0;
            console.log(rowIndexes,index);
              orderData.push({
                  ...item,
                  quantity:quantity,
                  total_price:subTotal,
                  total_tax:taxAmount,
                  gross_price:taxAmount +subTotal,
                  is_selected:isSelected
              })
            // gridRef.setcellvalue(index, "invoice_quantity", quantity);
            // setTotalAmount(index,quantity,item.rate,item.is_vat);
        });
        setValue('ordered_products', orderData);
        trigger('ordered_products');
    }


    return (
        <Fragment>

            <div style={{width: '100%'}}>
                {getValues('ordered_products').length > 0 && <JqxTable
                    deleteAll
                    rowsheight={75}
                    datafields={datafields}
                    columns={columns}
                    data={getValues('ordered_products')}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    autoheight={getValues('ordered_products').length<7}
                    scrollerbar
                    height="500"
                    onCellendedit={cellEdit}
                    disableCreateRow
                    onDelete={onDelete}
                    onRowselect={handelRowSelect}
                    onRowunselect={handelRowSelect}
                    selectionmode={'checkbox'}
                    isDelete={false}
                    selectedrowindexes={findIndexes(getValues('ordered_products'),item => item.is_selected)}
                />}
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderedProducts) == JSON.stringify(nextProps.orderedProducts);
}

export default SaleOrderProductInvoiceJqx;