import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
downloadLookBookApi,
    addProductLookBookApi, checkLookBookTitleApi, getLookBookApi, getCurrencyRateApi
} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";
import {getUsers} from "../../manage-user/store/userSlice";
import {filerValues} from "../product/edit/content/settings/map";

export const addProductLookBook = createAsyncThunk('addProductLookBook', async (product, {dispatch, getState}) => {
    try {
        const formData = new FormData();
        formData.append(
            'myFile',
            product.image
        );

        formData.append('title',product.title);
        formData.append('sub_title',product.sub_title);
        formData.append('heading',product.heading);
        formData.append('email',product.email);
        formData.append('contact_name',product.name);
        formData.append('product_ids',product.product_ids);
        formData.append('template',product.template);
        formData.append('dispatch_date',product.dispatch_date);
        formData.append('rrp_price',product.rrp_price);

        const response = await addProductLookBookApi(formData);
        const data = await response.response;
        if (response.response.errors) {
            toast.error("The title has already been taken!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            toast.success("Create look book successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const checkTitle = createAsyncThunk('product/lookBookTitle', async (data) => {
    const response = await checkLookBookTitleApi(data);
    return response.response;
});

export const productLookBook = createAsyncThunk('productLookBook', async (product, {dispatch, getState}) => {    
    const response = await downloadLookBookApi(product);
    return response.response;
});

export const getCurrencyRate = createAsyncThunk('look-book/getCurrencyRate', async (data, {dispatch, getState}) => {    
    const response = await getCurrencyRateApi(data.price, data.code);
    return response.response;
});

export const getLookBooks = createAsyncThunk('look-book/getLookBooks', async (data, {dispatch}) => {
    const response = await getLookBookApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

const lookBookAdapter = createEntityAdapter({});

export const {selectAll: selectLookBook, selectById: selectLookBookById} = lookBookAdapter.getSelectors(
    state => state.product.lookBook
);

const lookBookSlice = createSlice({
    name: 'lookBook',
    initialState: lookBookAdapter.getInitialState({
        searchText: '',
        tableRow:0,
        addLookBookDialog:{
            component: 'Look Book',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        filter: filerValues(),
        pagination: {
            count: 1,
            total: 1,
            page_total: 1
        },
        lookBook: null,
    }),
    reducers: {
        setLookBookPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLookBookData: (state, action) => {
            state.lookBook = action.payload
        },
        openLookBookDialog: (state, action) => {
            state.addLookBookDialog = {
                component: 'Look Book',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeLookBookDialog: (state, action) => {
            state.addLookBookDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setTableRowCount: (state, action) => {
            state.tableRow = action.payload;
        },
    },
    extraReducers: {
        [getLookBooks.fulfilled]: lookBookAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {
    
    setLookBookPagination,
    setLookBookData,setTableRowCount,
    openLookBookDialog,
    closeLookBookDialog
} =
    lookBookSlice.actions;

export default lookBookSlice.reducer;