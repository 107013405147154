import {Button, Card, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useRef, useState} from "react";
import Select from "react-select";
import ImageUpload from 'image-upload-react';
import {SellerImageUploader} from "./sellerImageUploader";
import DropZone from "../../../../share-components/upload/dropzone";
import {addBannerImage, deleteBannerImage, setSellerBanners} from "../../store/sellerSlice";
import {useDispatch} from "react-redux";
import SweetAlert from "sweetalert2";

export const UploadBannerImage = (props) => {
    const {sellerState,setPhotoIndex,photoIndex,trigger,setValue,getValues}=props;
    const dispatch = useDispatch();
    const [imagePreloader, setImageLoader] = useState(false);
    const onChangeFile = e => {
        setImageLoader(true);
        dispatch(addBannerImage({id:sellerState.id,image:e})).then(res => {
            if (!res.error) {
                dispatch(setSellerBanners(res.payload.data));
            }
            setImageLoader(false);
        });
    }
    const deleteImage = (item) => {

        SweetAlert.fire({
            title: 'Are you sure want to delete this banner?',
            text: "Once confirmed, banner will be delete completely from to the system",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setImageLoader(true);
                    dispatch(deleteBannerImage({id: sellerState.id, banner_image_id: item.id})).then(res => {
                        if (!res.error) {
                            dispatch(setSellerBanners(res.payload.data));
                        }
                        setImageLoader(false);
                    });

                }
            });

    }
    return (
        <Fragment>
            <Row>
                {sellerState.sellerBanners? sellerState.sellerBanners.map((item, i) =>
                    <Col md={3} key={i}>
                        <Card>
                            <div className="product-box border">
                                <div className="product-img">
                                    <img style={{height:'150px',width:'15vw',objectFit:'cover'}} src={item.path} alt="" />
                                    <div className="product-hover">
                                        <ul>
                                            <li onClick={() => setPhotoIndex({...photoIndex, index: i, isOpen: true})} className={imagePreloader?"d-none":""} >
                                                <Button type="button" color="default" >
                                                    <i className="icon-eye"></i>
                                                </Button>
                                            </li>
                                            <li onClick={() => deleteImage(item)} className={imagePreloader?"d-none":""}>
                                                {/*onClick={() => addWishList(create)} >*/}
                                                <Button type="button" color="default" data-al={item.id}  >
                                                    <i className="icon-close"></i>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ) : ''}
                <Col md={3}>
                    <Card>
                        <div className="product-box">
                            <div className="product-img pt-3 pb-3">
                                {imagePreloader&& <div className="loader-box">
                                    <div className="loader-2"></div>
                                </div>}
                                {!imagePreloader&& <DropZone onChangeFile={onChangeFile}></DropZone>}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};