import API from "../../../utils/api";


export const getProductSeasonsApi = async (filters) => {

    const response = await API.get(`settings/season`, { params: filters });
    return { response };
};

export const addProductSeasonApi = async (location) => {
    const response = await API.post(`settings/season`, location);
    return { response };
};

export const updateProductSeasonApi = async (location,id) => {
    const response = await API.put(`settings/season/${id}`, location);
    return { response };
}

export const deleteProductSeasonApi = async (id) => {
    const response = await API.delete(`settings/season/${id}`);
    return { response };
}