import {Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";

export const HeadingLinks = (props) => {
    const {sellerState,errors,register,trigger,setValue,getValues}=props;

    const onChangeReturnCancellationPolicy = (evt) => {
        const newContent = evt.editor.getData();
        setValue('returnCancellationPolicy',newContent);
    }
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Return & Cancellation Policy Heading</Label>
                        <input name="returnCancellationPolicyHeading" type="text" className={`form-control ${errors.returnCancellationPolicyHeading ? 'is-invalid' : ''}`} placeholder="" {...register('returnCancellationPolicyHeading')}  />
                        <div className="invalid-feedback">{errors.returnCancellationPolicyHeading?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Return & Cancellation Policy</Label>

                        <CKEditors
                            activeclassName="p10"
                            content={getValues('returnCancellationPolicy')}
                            {...register('returnCancellationPolicy')}
                            events={{
                                "change": onChangeReturnCancellationPolicy
                            }}
                        />
                        <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.returnCancellationPolicy?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <div className="table-responsive">
                        <Table hover>
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">{"#"}</th>
                                <th scope="col">{"Heading"}</th>
                                <th scope="col">{"Link"}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">{"Seller Profile"}</th>
                                <td>
                                    <input name="sellerProfileHeading" type="text" className={`form-control ${errors.sellerProfileHeading ? 'is-invalid' : ''}`} {...register('sellerProfileHeading')}  />
                                    <div className="invalid-feedback">{errors.sellerProfileHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="sellerProfileLink" type="text" className={`form-control ${errors.sellerProfileLink ? 'is-invalid' : ''}`} {...register('sellerProfileLink')}  />
                                    <div className="invalid-feedback">{errors.sellerProfileLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Seller Shop"}</th>
                                <td>
                                    <input name="sellerShopHeading" type="text" className={`form-control ${errors.sellerShopHeading ? 'is-invalid' : ''}`} {...register('sellerShopHeading')}  />
                                    <div className="invalid-feedback">{errors.sellerShopHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="sellerShopLink" type="text" className={`form-control ${errors.sellerShopLink ? 'is-invalid' : ''}`} {...register('sellerShopLink')}  />
                                    <div className="invalid-feedback">{errors.sellerShopLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Brand Size Chart"}</th>
                                <td>
                                    <input name="brandSizeChartHeading" type="text" className={`form-control ${errors.brandSizeChartHeading ? 'is-invalid' : ''}`} {...register('brandSizeChartHeading')}  />
                                    <div className="invalid-feedback">{errors.brandSizeChartHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="brandSizeChartLink" type="text" className={`form-control ${errors.brandSizeChartLink ? 'is-invalid' : ''}`} {...register('brandSizeChartLink')}  />
                                    <div className="invalid-feedback">{errors.brandSizeChartLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Size Chart"}</th>
                                <td>
                                    <input name="sizeChartHeading" type="text" className={`form-control ${errors.sizeChartHeading ? 'is-invalid' : ''}`} {...register('sizeChartHeading')}  />
                                    <div className="invalid-feedback">{errors.sizeChartHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="sizeChartLink" type="text" className={`form-control ${errors.sizeChartLink ? 'is-invalid' : ''}`} {...register('sizeChartLink')}  />
                                    <div className="invalid-feedback">{errors.sizeChartLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Promotion"}</th>
                                <td>
                                    <input name="promotionHeading" type="text" className={`form-control ${errors.promotionHeading ? 'is-invalid' : ''}`} {...register('promotionHeading')}  />
                                    <div className="invalid-feedback">{errors.promotionHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="promotionLink" type="text" className={`form-control ${errors.promotionLink ? 'is-invalid' : ''}`} {...register('promotionLink')}  />
                                    <div className="invalid-feedback">{errors.promotionLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Trade Show"}</th>
                                <td>
                                    <input name="tradeShowHeading" type="text" className={`form-control ${errors.tradeShowHeading ? 'is-invalid' : ''}`} {...register('tradeShowHeading')}  />
                                    <div className="invalid-feedback">{errors.tradeShowHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="tradeShowLink" type="text" className={`form-control ${errors.tradeShowLink ? 'is-invalid' : ''}`} {...register('tradeShowLink')}  />
                                    <div className="invalid-feedback">{errors.tradeShowLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Designed & Made In"}</th>
                                <td>
                                    <input name="designedMadeInHeading" type="text" className={`form-control ${errors.designedMadeInHeading ? 'is-invalid' : ''}`} {...register('designedMadeInHeading')}  />
                                    <div className="invalid-feedback">{errors.designedMadeInHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="designedMadeInLink" type="text" className={`form-control ${errors.designedMadeInLink ? 'is-invalid' : ''}`} {...register('designedMadeInLink')}  />
                                    <div className="invalid-feedback">{errors.designedMadeInLink?.message}</div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">{"Shipping Validity"}</th>
                                <td>
                                    <input name="shippingValidityHeading" type="text" className={`form-control ${errors.shippingValidityHeading ? 'is-invalid' : ''}`} {...register('shippingValidityHeading')}  />
                                    <div className="invalid-feedback">{errors.shippingValidityHeading?.message}</div>
                                </td>
                                <td>
                                    <input name="shippingValidityLink" type="text" className={`form-control ${errors.shippingValidityLink ? 'is-invalid' : ''}`} {...register('shippingValidityLink')}  />
                                    <div className="invalid-feedback">{errors.shippingValidityLink?.message}</div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>

            </Row>
        </Fragment>
    );
};