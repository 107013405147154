import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Container, Draggable } from "react-smooth-dnd";
import {arrayMoveImmutable} from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@mui/material/IconButton';
import { editPartner, updatePartnerOrder } from '../store/partnerSlice';
import { Badge, Col } from 'reactstrap';
import Switch from "react-switch";
import ReactPaginate from "react-paginate";

function shortableTable(props) {
	const {partnerState, onEdit, onActiveDeactivate, setIsActive, isActive, onView}=props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
	const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
	const [items, setItems] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [partner,setPartner] = useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
	const [page, setPage] = useState(0);
	// const currentUser = useSelector(({ auth }) => auth.user.data.user);
    // eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		setItems(partnerState.partnerData.data);
	}, [partnerState.partnerData]);

	const onDrop = ({ removedIndex, addedIndex }) => {
		const updatedArray = arrayMoveImmutable(items, removedIndex, addedIndex);
		setItems(updatedArray);
		const formattedArray=updatedArray.map(res => {
			return res.id;
		});
		dispatch(updatePartnerOrder({fields:formattedArray}));
	};

    const handleChange = (nextChecked) => {
        console.log(nextChecked)
        setIsActive(nextChecked);
    };

	const pageChange = (event) => {
        setPage(event.selected + 1)
    }

	return (
		<>
			<List>
				<Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop} style={{backgroundColor:'#f1f1f1'}}>
					{items && items.map(({ id, name, email, phone_number, status, order }) => (
						<Draggable key={id}>
							<ListItem
							>
								<ListItemIcon className="drag-handle">
									<DragHandleIcon />
								</ListItemIcon>
								<ListItemText primary={name} />
								<ListItemText primary={email} />
								<ListItemText primary={phone_number} />
								<ListItemText>
                                    {/* <div className="float-right">         */}
                                        <Badge color={status == 1 ? 'success' : 'warning'} key={id}> {status == 1 ? 'Active' : 'In-Active'} </Badge>
                                    {/* </div> */}
                                </ListItemText>
								<ListItemSecondaryAction style={{paddingRight:'48px'}}>
									<IconButton onClick={()=>onEdit(id)} edge="end" aria-label="delete">
										<EditIcon/>
									</IconButton>
                                    <IconButton onClick={()=>onView(id)} edge="end" aria-label="delete">
										<VisibilityIcon/>
									</IconButton>
  								</ListItemSecondaryAction>
                                <ListItemSecondaryAction>
                                <Switch uncheckedIcon={false} checkedIcon={false} 
                                        onChange={(e) => {
                                            setIsActive(e);
                                            onActiveDeactivate({id:id, status:e});
                                        }} checked={status} className="react-switch" height={20}
                                        width={35} />
                                </ListItemSecondaryAction>
							</ListItem>
						</Draggable>
					))}
				</Container>
			</List>

			<Col md={12}>
				<div style={{float: "right", marginTop: 10}}>
					<div>
						<ReactPaginate
							className="pagination"
							activeClassName="pagination-active"
							breakLabel="..."
							nextLabel=">"
							onPageChange={pageChange}
							pageRangeDisplayed={5}
							pageCount={partnerState.partnerData.count}
							forcePage={0}
							previousLabel="<"
							renderOnZeroPageCount={null}
						/>
					</div>
				</div>
			</Col>
		</>
	);
}

export default shortableTable;
