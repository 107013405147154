import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import PreLoader from "../../../share-components/ui/preLoader";
import LogActivity from "../../log";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import fulfillmentReducer from "../store";
import {
  getAllFulfillments,
  selectFulfillment,
} from "../store/saleOrderFulfillmentSlice";
import { fulfillmentResponseToTable } from "../../../utils/mapper";
import withReducer from "../../../store/withReducer";

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Fulfillments",
    link: "",
  },
];

const Fulfillment = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  let history = useHistory();
  const [loading, isSetLoading] = useState(true);
  const fulfillments = useSelector(selectFulfillment);
  const saleOrderFulfillmentState = useSelector(
    ({ order }) => order.salesOrderFulfilment
  );
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const [tableLoading, isSetTableLoading] = useState(false);

  const tableColumns = [
    {
      name: "Fulfillment ID",
      selector: (row) => row.fulfillment_no,
      sortable: true,
      center: false,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
      center: false,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      center: false,
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Delivery Partner",
      selector: (row) => row.delivery_partner,
      sortable: true,
      center: false,
    },
    {
      name: "Tracking ID",
      selector: (row) => row.tracking_id,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
              <a
                onClick={() => {
                  onView(row);
                }}
              >
                <i
                  className="fa fa-eye"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(51, 153, 255)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
          {/* {userState.user &&
            (userState.user.permission.indexOf("Update Seller") !== -1 ? (
              <a
                onClick={() => {
                  onEdit(row);
                }}
              >
                <i
                  className="fa fa-pencil"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))} */}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [page, brand, search]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
    };
    dispatch(getAllFulfillments(data)).then((res) => {
      console.log(res);
      if (!res.error) isSetLoading(false);
    });
  };

  const onView = (row) => {
    history.push(
      "sale/" + row.sales_order_id + "/billing/fulfillment/" + row.id
    );
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Fulfillments" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="6">
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Type Name"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        zIndex: 10,
                        right: 0,
                      }}
                    >
                      {/* <LogActivity log="Sales Order Fulfillment"></LogActivity> */}
                    </div>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={fulfillmentResponseToTable(fulfillments)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderFulfillmentState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([
  {
    fulfillment: fulfillmentReducer,
  },
])(Fulfillment);
