import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapSearchAutoSuggestion, mapStyleResponseToTable} from "../../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import {
    addProductStyle,
    closeEditDialog,
    deleteProductStyle,
    getAllProductStyles,
    openEditDialog,
    openNewDialog,
    selectProductStyle,
    updateProductStyle
} from "../../store/productStyleSlice";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProductStyleForm from "./productStyleForm";
import {categoryAutoSuggestionApi} from "../../../../services/manage-product/setting/productCategoryService";
import AsyncSelect from "react-select/async";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    tag_name: '',
    category: '',
    publish_at: ''
};

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
});

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Style',
        link: ''
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const ProductStyle = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [tagName, setTagName] = useState([]);
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const productStyle = useSelector(selectProductStyle);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const tableColumns = [
        {
            name: 'Style Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Tag Name',
            selector: row => row.tag_name,
            sortable: true,
            left: true,
        },
        {
            name: 'Product sub category',
            selector: row => row.category_text,
            sortable: true,
            left: true,
        },

    ];

    useEffect(() => {
        const data = {
            name, tagName, category
        };
        dispatch(getAllProductStyles({...data})).then(res => {
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name, tagName, category]);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    const categoryPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                is_sub_categories:1
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(categoryAutoSuggestionApi(data)))
            });
        });

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        let categories = [];
        console.log(data.category);
        (data.category).map((item, i) => {
            categories.push(item.value);
        });
        data.category=categories;
        if (dialog.type === 'new') {
            dispatch(addProductStyle({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductStyle({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        }
    }

    const {errors} = formState;

    const onEdit = (row) => {
        dispatch(openEditDialog(row))
    }

    const onDelete = (row) => {
        dispatch(deleteProductStyle(row.id))
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb data={dataMenu} title="Product Style"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">
                                {(currentUser.permission).includes('Create Product Style') ?
                                <Card>
                                    <Row>
                                        <Col sm="6">
                                            <Nav tabs className="border-tab">

                                            </Nav>
                                        </Col>
                                        <Col sm="6">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={() => {
                                                       dispatch(openNewDialog())
                                                   }}
                                                > <i className="fa fa-plus"></i> Add Product Style</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                    :''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setName(e.target.value)}
                                            name="name"
                                            type="text" className="form-control border"
                                            placeholder="Search By Style Name"/>
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xl="4" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setTagName(e.target.value)}
                                            name="name"
                                            type="text" className="form-control border"
                                            placeholder="Search By Tag Name"/>
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xl="4" sm="12">
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    placeholder="select category"
                                    loadOptions={categoryPromiseOption}
                                    styles={customStyles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onChange={(e) => {
                                        setCategory(e?e.value:null);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapStyleResponseToTable(productStyle)}
                                pagination
                                onEdit={(currentUser.permission).includes('Update Product Style') ? onEdit:''}
                                onDelete={(currentUser.permission).includes('Delete Product Style') ? onDelete:''}
                            />
                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
                </>}
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Style'
                selector='product'
                dialog={({product}) => product.productStyle.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <ProductStyleForm
                    control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                    categoryPromiseOption={categoryPromiseOption} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    )
        ;
}

export default withReducer([{style: productReducer}])(ProductStyle)