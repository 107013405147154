import React, {Fragment, useEffect, useState} from "react";
import {Row, Col, FormGroup, Label} from "reactstrap";
import {FormCheck} from "react-bootstrap";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import { getTagSettingData } from "../../store/productTagSettingSlice";
import Switch from "react-switch";


const ProductTypeTagForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;
    const [settingData, setSettingData] = useState([]);
    const [isTag,setIsTag]=useState(getValues('is_tag'));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTagSettingData()).then(res=>{
            if (!res.error)
            setSettingData(res.payload.type_tags);
        });

    }, [dispatch]);
    console.log(getValues('is_tag'))
    const handleChange = (nextChecked) => {
        console.log(nextChecked)
        setIsTag(nextChecked);
        setValue('is_tag',nextChecked)
    };

    return (
        <Fragment>
            
            <FormGroup>
                <Label className="col-form-label">Type Tag</Label>
                <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.name?.message}</div>
            </FormGroup>
        
            <FormGroup>
                <Label className="col-form-label">Product Tags</Label>
                <Select getOptionLabel={(option) => option.name}
                        // menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({...base, zIndex: 100})}}
                        isMulti
                        defaultValue={getValues('tags')}
                        getOptionValue={(option) => option.id} {...register("tags")}
                        onChange={(e) => {
                            setValue('tags', e);
                            console.log(e);
                            trigger('tags');
                        }} className={` ${errors.tags ? 'is-invalid' : ''}`}
                        options={settingData}/>

                <div className="invalid-feedback">{errors.tags?.message}</div>
            </FormGroup>

            <FormGroup>
                <Label className="form-label">Is Tag?</Label>
                <div className="form-control pl-0 border-0">
                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChange} checked={isTag} className="react-switch" />
                </div>
                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isTag?.message}</div>
            </FormGroup>
                
        </Fragment>
    );
};

export default ProductTypeTagForm;