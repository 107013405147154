import React, { Fragment, useState, useSelector,useEffect } from 'react'
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import {useDispatch} from "react-redux";
import Select from "react-select";
import ImageUpload from "image-upload-react";

function AdditionalForm(props) {
    
    const dispatch = useDispatch();
    const { errors, control, register,setValue,trigger,roles,getValues, productStates } = props;
    const [selectedImageLogo,setSelectedImageLogo]=useState(getValues('swatch_image'));
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const handleDeleteLogoImage = () => {
        setValue('swatch_image', null);
        setValue('swatch_image_url', null);
        // setSelectedImageLogo(null);
        trigger();
        const input = document.getElementById("hiddenFileInput2");
        input.value = "true";
    }

  return (
    <Fragment>
        <Container fluid={true}>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label className="form-label">Description</Label>
                        <textarea {...register('description')} className='form-control' rows={5}></textarea>
                    </FormGroup>
                    <div className="invalid-feedback" style={{display:"block"}}>{errors.description?.message}</div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <input name="logoImageDeleted" type="hidden" id="hiddenFileInput2"  />
                    <FormGroup>
                        <Label className="form-label">Swatch Image</Label>
                        <span className="mt-3 mr-2 ml-1" id="TooltipExample2">
                            <i aria-hidden="true" className="fa fa-exclamation-circle"></i>
                        </span>
                        <Tooltip
                            placement= 'right'
                            isOpen={tooltipOpen1}
                            target="TooltipExample2"
                            toggle={toggle1}
                            style={{ fontSize: '12px',maxWidth: '900px' }}
                        >
                            please upload logo image less than 2mb and with width:120px, height:120px
                        </Tooltip>
                        <div className="text-black-50">{errors.swatch_image?.message}</div>
                        <div className="text-black-50">{errors.swatch_image_url?.message}</div>
                        <ImageUpload
                            handleImageSelect={e => {
                                setValue('swatch_image',e.target.files[0]);
                                setValue('swatch_image_url',(URL.createObjectURL(e.target.files[0])));
                                setSelectedImageLogo(URL.createObjectURL(e.target.files[0]));
                            }}
                            imageSrc={getValues('swatch_image_url')}
                            setImageSrc={setSelectedImageLogo}
                            style={{
                                width: 'auto',
                                height: '85px',
                                marginTop:0,
                                backgroundColor:'#fff'
                            }}
                            deleteIcon={
                                <span className="mt-3 mr-2"  onClick={handleDeleteLogoImage}>
                                    <i aria-hidden="true" className="fa fa-times-circle fa-2x"></i>
                                </span>
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Detail and Care</Label>
                        <textarea {...register('details_care')} className='form-control' rows={3}></textarea>
                    </FormGroup>
                    <div className="invalid-feedback" style={{display:"block"}}>{errors.details_care?.message}</div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Label className="form-label">Color Facets</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Color"
                        type="select"
                        className={` ${errors.colour ? 'is-invalid' : ''}`}
                        name="colour"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('colour')}
                        options={productStates.additionalDetailsSettings.colours}
                        onChange={(e) => {
                            setValue('colour',e);
                            trigger('colour');
                        }}

                    />
                    <div className="invalid-feedback">{errors.colour?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <Label className="form-label">Fabrics</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Fabric"
                        type="select"
                        name="fabric"
                        className={` ${errors.fabric ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={getValues('fabric')}
                        isClearable
                        options={productStates.additionalDetailsSettings.fabrics}
                        onChange={(e) => {
                            setValue('fabric',e);
                            trigger('fabric');
                        }}

                    />
                    <div className="invalid-feedback">{errors.fabric?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <Label className="form-label">Neckline</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Neckline"
                        type="select"
                        name="neckline"
                        className={` ${errors.neckline ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('neckline')}
                        options={productStates.additionalDetailsSettings.necklines}
                        onChange={(e) => {
                            setValue('neckline',e);
                            trigger('neckline');
                        }}

                    />
                    <div className="invalid-feedback">{errors.neckline?.message}</div>
                    </FormGroup>
                </Col>

            </Row>
            <Row>
            <Col md={4}>
                    <Label className="form-label">Pattern</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Pattern"
                        type="select"
                        name="pattern"
                        className={` ${errors.pattern ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('pattern')}
                        options={productStates.additionalDetailsSettings.patterns}
                        onChange={(e) => {
                            setValue('pattern',e);
                            trigger('pattern');
                        }}

                    />
                    <div className="invalid-feedback">{errors.pattern?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <Label className="form-label">Sleeve length</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Sleeve length"
                        type="select"
                        name="sleeve_length"
                        className={` ${errors.sleeve_length ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('sleeve_length')}
                        options={productStates.additionalDetailsSettings.sleeve_lengths}
                        onChange={(e) => {
                            setValue('sleeve_length',e);
                            trigger('sleeve_length');
                        }}

                    />
                    <div className="invalid-feedback">{errors.sleeve_length?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <Label className="form-label">Women Style Dress</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Style"
                        type="select"
                        name="style"
                        className={` ${errors.style ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('style')}
                        options={productStates.additionalDetailsSettings.women_styles}
                        onChange={(e) => {
                            setValue('style',e);
                            trigger('style');
                        }}

                    />
                    <div className="invalid-feedback">{errors.style?.message}</div>
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md={4}>
                    <Label className="form-label">Women Clothing Occasion</Label>
                    <FormGroup>
                    <Select
                        placeholder="Select Occasion"
                        type="select"
                        name="occasion"
                        className={` ${errors.occasion ? 'is-invalid' : ''}`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        value={getValues('occasion')}
                        options={productStates.additionalDetailsSettings.occasions}
                        onChange={(e) => {
                            setValue('occasion',e);
                            trigger('occasion');
                        }}

                    />
                    <div className="invalid-feedback">{errors.occasion?.message}</div>
                    </FormGroup>
                </Col>
            </Row>

        </Container>
    </Fragment>
  )
}

export default AdditionalForm
