export const sizeMapper = response => {
    return response.map(res => {
        return {
            id: res.id,
            size: res.id,
            name: res.name,
            ratio:0,
        };
    });
};


export const filerValues =(sellerValue)=>{
    return {
        gridColumnNo: 3,
        pageSize: 12,
        name: "",
        currentPage: 0,
        brand:"",
        status:0,
        sort:"id-desc",
        seller:sellerValue,
        trigger:false,
        category:"",
        subCategory:"",
        tags:"",
        tag_type:"",
        stock:"",
        price:"",
        null_check:"",
        season:"",
        country:"",
        commodity:"",
        styles:"",
        compositions:"",
        variant_type:"",
        colour:"",
        offer_type:"",
        holiday_mode_date_range:'',
        product_group:''
    };
}

export const moreFilterFields=()=>{
    return [
        {name:"Variant Type",key:'variant_type',type:1},
        {name:"Category",key:'category',type:1},
        {name:"Sub Category",key:'subCategory',type:1},
        {name:"Styles",key:'styles',type:2},
        {name:"Tag Type",key:'tag_type',type:1},
        {name:"Tags",key:'tags',type:2},
        {name:"Season",key:'season',type:1},
        {name:"Country",key:'country',type:1},
        {name:"Compositions",key:'compositions',type:2},
        {name:"Commodity",key:'commodity',type:1},
        {name:"Colour",key:'colour',type:1},
        {name:"Stock",key:'stock',type:3},
        {name:"Price",key:'price',type: 3},
        {name:"Empty Field",key:'null_check',type:1},
        {name:"Sale",key:'offer_type',type:1},
        {name:"Holiday Mode",key:'holiday_mode_date_range',type:4},
        {name:"Group Product",key:'product_group',type:1},

    ];
}

export const getFilterValues=(type,value)=>{
    if(type == 1){
        return " is " +value.name;
    }else if(type ==2){
        return  " are "+arrayToStringWithComma(value).name
    }
    else if(type ==3){
        return  " is "+ value.min + " to "+value.max
    }else if(type == 4){
        return  "range "+ value.startDate + " to "+value.endDate
    } else if (type == 5) {
        return "Remote Island" + value.value ? " Yes" : "No"
    } else if (type == 6) {
        return "Unallocated Shipping" + value.value ? " Yes" : "No"
    }
}
export const arrayToStringWithComma=(value)=>{
    let id = '';
    let name = '';
    // eslint-disable-next-line array-callback-return
    value.map(k => {
        id += `${k.id},`;
        name += `${k.name},`;

    });
    return  {
        id:id.replace(/,\s*$/, ""),
        name:name.replace(/,\s*$/, "")
    };
}

export const buyerFilters =()=>{
    return {
        pageSize: 10,
        name: "",
        currentPage: 1,
        email: "",
        mobile: "",
        country: "",
        type: "",
        customerRepresentative: "",
    }
}

export const orderFilters = (sellerValue) => {
    return {
        pageSize: 10,
        currentPage: 1,
        country: "",
        payment_type: "",
        // fulfillmentStatus: "",
        financialStatus:"",
        paymentStatus: "",
        shippingType: "",
        orderType: "",
        remote_island: false,
        unallocated_shipping:false,
        partner: "",
        status:"",
        shipping_type:"",
        deliveryPartners:"",
        seller:sellerValue,
        order_type:"",
        name:"",
        unallocated_seller:false,
    }
}

export const draftOrderFilters = (sellerValue) => {
    return {
        pageSize: 10,
        currentPage: 1,
        seller:sellerValue,
        brand:"",
        customer:"",
    }
}

export const returnOrderFilters = (sellerValue) => {
    return {
        pageSize: 10,
        currentPage: 1,
        seller:sellerValue,
        brands:"",
        reason:"",
        exchangeType:"",
        refundStatus:"",
        customerReason:"",
        order:"",
    }
}

export const pendingReturnFilters = (sellerValue) => {
    return {
        pageSize: 10,
        currentPage: 1,
        seller:sellerValue,
        search:"",
        brand:"",
        type:"",
    }
}

export const moreOrderFilterFields= () => {
    return [
        {name:'Payment Type', key: 'payment_type', type:1},
        {name:'Country', key: 'country', type:1},
        {name:'Shipping Type', key: 'shipping_type', type:1},
        {name:'Order Type', key: 'order_type', type:1},
        {name:'Remote Island', key: 'remote_island', type:5},
        {name:'Unallocated Shipping', key: 'unallocated_shipping', type:5},
        {name:'Partner', key: 'partner', type:1},
        {name:'unallocated seller', key:'unallocated_seller', type:5}
    ]
}