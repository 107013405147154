/* eslint import/no-extraneous-dependencies: off*/
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as history from 'history'
import {getDefaultSeller} from "../../utils/common";


// And away you go!



export const setUserDataPassportAuth = userData => async dispatch => {
    const user = {
        role: ['authorizeUser'],
        from: 'passport',
        data: {
            user: userData,
            displayName: userData.name,
            photoURL: userData.google_image,
            email: userData.email,
        },
        defaultSeller:getDefaultSeller()
    };

    // const history = createBrowserHistory();

    dispatch(setUser(user));
 //  const hisotrys= history.createBrowserHistory();
 //    history.location.state = {
 //        redirectUrl: '/login' // for example 'apps/academy'
 //    };
};
export const setUserData = user => async (dispatch, getState) => {
    /*
        You can redirect the logged-in user to a specific route depending on his role
         */
   let t= history.createBrowserHistory();
   // const location = t.location;

// Listen for changes to the current location.
//     const unlisten = history.listen((location, action) => {
//         // location is an object like window.location
//         console.log(action, location.pathname, location.state);
//     });
//
//
//     t.push('/', { some: 'state' })
//     unlisten();

    /*
    Set User Settings
     */




};

export const logoutUser = () => async (dispatch, getState) => {


    localStorage.removeItem('token');
    document.title="Marketplace Portal";

    return dispatch(userLoggedOut());
};


const initialState = {
    role: '', // guest
    data: {
        displayName: '',
        photoURL: '',
        email: '',
        user:{notifications:[]}
    }
};

const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => action.payload,
        userLoggedOut: (state, action) => initialState,
        setUserDetails: (state, action) => {
            state.data.displayName=action.payload.name;
            state.data.email=action.payload.email;
        },
        setUserPhoto: (state, action) => {
            state.data.photoURL=action.payload.google_image;
        },
        setNotification: (state, action) => {
            state.data.user.notifications=action.payload;
        },
        setDefaultSeller:(state,action)=>{
            state.defaultSeller=action.payload;
        }
    },
    extraReducers: {}
});

export const { setDefaultSeller,setUser, userLoggedOut,setUserDetails,setUserPhoto,setNotification } = userSlice.actions;

export default userSlice.reducer;