import React, {Fragment, useState} from 'react';
import {Bell} from "react-feather";
import {CheckAllNotification, Notification} from "../../constant";
import Pusher from "pusher-js";
import {host} from "../../utils/api";
import {useDispatch} from "react-redux";
import {setNotification} from "../../redux/auth/userSlice";
import {notificationMarkAsRead} from "../../services/auth";
import {useHistory} from "react-router-dom";
import "../../components/notification/notofication.scss"
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import {MdPerson} from "react-icons";

function DesktopNotification(props) {

    const {currentUser} = props;
    const dispatch = useDispatch();
    let history = useHistory();
    const [notificationReset, setNotificationReset] = useState(false)
    const [notificationDropDown, setNotificationDropDown] = useState(false)
    Pusher.logToConsole = true;
    const token = localStorage.getItem('token');
    var pusher = new Pusher('40a16e808dc166ccd733', {
        cluster: 'eu',
        auth: {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        },
        authEndpoint: host + 'broadcasting/auth',
    });
    const domainJost = window.location.hostname == "localhost" ? 'mp.localhost' : `${window.location.hostname}`;
    var channel = pusher.subscribe('private-users-' + currentUser.user.id + '-' + domainJost);
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', function (data) {
        let notifcations = [];
        notifcations = [
            ...currentUser.user.notifications,
            data
        ];
        dispatch(setNotification(notifcations))
    });


    async function markAsRead(id, url) {
        await notificationMarkAsRead(id).then(res => {
            if (!res.errors) {
                let array = currentUser.user.notifications;
                const newArray = array.filter(item => item.id !== id)
                dispatch(setNotification(newArray))
            }
        });
        url ? history.push('/' + url) : history.push('/dashboard')
    }

    function openAllNotifications() {
        history.push('/view-all-notifications')
    }

    return (
        <Fragment>
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}>
                <Bell/><span
                className="badge badge-pill badge-secondary">{(currentUser.user.notifications).length}</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
                <li>
                    <h6 className="f-18 mb-0">{Notification}</h6>
                </li>
                {currentUser.user.notifications.slice(0,7).map((item, index) =>
                    <li className="notification-list" key={index}>

                        <a className="f-12" onClick={(e) => {
                            markAsRead(item.id, '').then()
                        }} href="#"><p>
                            <ListGroup
                                style={{
                                    height: '5.5rem',
                                    width: '103%'
                                }}
                            >
                                {/*<ListGroupItem>*/}
                                {/*    <Row className="mb-2">*/}
                                {/*        <Col md={12} sm={12}>*/}
                                {/*            <span style={{fontWeight: "normal", color: "black", fontSize: "medium"}} className="pull-left text-black">{item.message}</span>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}

                                {/*    <Row>*/}
                                {/*        <Col md={6} sm={6}>*/}
                                {/*                <span style={{color: '#4f4950'}} className="pull-left">Nayana darshana silva</span>*/}
                                {/*        </Col>*/}
                                {/*        <Col md={6} sm={6}>*/}
                                {/*            <span style={{color: '#071633'}} className="pull-left">{item.time}</span>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</ListGroupItem>*/}

                            </ListGroup>
                        </p>
                        </a>
                    </li>
                )}

                <li>
                    <button onClick={() => openAllNotifications()}
                            className="btn btn-primary mt-2">{CheckAllNotification}</button>
                </li>
            </ul>
        </Fragment>
    );
}

export default DesktopNotification;