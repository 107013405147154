import React, {useEffect, useState} from 'react';
import {setProductFilter} from "../../components/manage-product/store/prodcutSlice";
import InputRange from "react-input-range";
import {Col, Input, Row} from "reactstrap";


function CustomInputRange(props) {
    const {stateValue, clearRange, rangeComplete, title, maxRange} = props;
    const [rangeValue, setRangeValue] = useState(stateValue ? stateValue : {max: 0, min: 0});
    useEffect(() => {
        setRangeValue(stateValue ? stateValue : {max: 0, min: 0});
        setMin(stateValue ? stateValue.min : 0);
        setMax(stateValue ? stateValue.max : 0);
    }, [stateValue])
    const [min, setMin] = useState(stateValue ? stateValue.min : 0);
    const [max, setMax] = useState(stateValue ? stateValue.max : 0);
    const [timer, setTimer] = useState();

    const rangeInputValueChanged = (maxParams, minParams) => {
        if (maxParams >= minParams) {
            rangeComplete({max: Number(maxParams), min: Number(minParams)});
            setRangeValue({max: Number(maxParams), min: Number(minParams)})

        }
    }

    return (
        <>
            <h6 className="f-w-600">{title} {stateValue &&
                <a className="a-text-color pull-right" title="remove stock filter" onClick={() => {
                    clearRange();
                    setMax(0);
                    setMin(0);
                    setRangeValue({max: 0, min: 0})
                }}><i className="fa fa-refresh"></i></a>}</h6>
            <Row className="mb-2">
                <Col md={{size: 4, offset: 2}}>
                    <Input
                        className="form-control"
                        style={{height: 25}}
                        type="number"
                        value={min}
                        placeholder="Min"
                        onChange={(e) => {
                            setMin(e.target.value);
                            clearTimeout(timer)

                            const newTimer = setTimeout(() => {
                                let inputValue = e.target.value;
                                if (max >= e.target.value || !e.target.value || max == 0) {
                                    inputValue = Number(e.target.value);
                                } else {
                                    inputValue = max;
                                }
                                setMin(inputValue)
                                rangeInputValueChanged(max, inputValue);
                            }, 500)

                            setTimer(newTimer)

                        }}

                    />
                </Col>
                <Col md={4}>
                    <Input
                        className="form-control"
                        style={{height: 25}}
                        type="number"
                        value={max}
                        placeholder="Max"
                        onChange={(e) => {
                            setMax(e.target.value);
                            clearTimeout(timer)

                            const newTimer = setTimeout(() => {
                                let inputValue = e.target.value;
                                if (min <= e.target.value || !e.target.value) {
                                    inputValue = Number(e.target.value);
                                } else {
                                    inputValue = min;
                                }
                                setMax(inputValue);
                                rangeInputValueChanged(inputValue, min);
                            }, 500)

                            setTimer(newTimer)

                        }}

                    />

                </Col>
            </Row>
            <div className="p-2">
                <InputRange
                    maxValue={maxRange >= max ? maxRange : Number(max)}
                    minValue={0}
                    step={5}
                    allowSameValues
                    value={rangeValue}
                    onChange={(e) => {
                        setRangeValue(e);


                    }}
                    onChangeComplete={(e) => {
                        setMax(e.max);
                        setMin(e.min);
                        rangeComplete(e);
                    }}

                />
            </div>
        </>
    );
}

export default CustomInputRange;