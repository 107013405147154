import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { binSearchAutoSuggestion, mapReturnRestockResponseToTable } from "../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";

import {
    addReturnItems, getOrderSettings,
    setOrderData, setSettings, returnRestock, getReturnRestockList, setReturnData, setReturnOrderFilter
} from "../store/orderSlice";
import { autoSuggestionBin } from "../../manage-product/store/binLocationSlice";

import { useHistory, useParams } from "react-router-dom";

import orderReducer from "../store";
import JqxGrid, { IGridProps, jqx } from '../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import JqxTable from "../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import { forEach } from "react-bootstrap/ElementChildren";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import * as ReactDOM from "react-dom";
import CustomTagsInput from "../../../share-components/ui/customTagsInput";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import './style.scss';
import AsyncSelect from "react-select/async";
import { binAutoSuggestionApi } from "../../../services/manage-product/productService";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { boolean } from "yup";

const { afterToday } = DateRangePicker;

function ReturnRestoke() {
    const dispatch = useDispatch();
    let history = useHistory();

    const [loading, isSetLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [timer, setTimer] = useState(null);
    const orderState = useSelector(({order}) => order.order);
    const [checkBoxData, setCheckBox] = useState({penatly: []});
    const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);
    const [binErrors, setBinErrors] = useState([]);
    const [binQtyIsError, setBinQtyIsError] = useState(false);
    const [binNameIsError, setBinNameIsError] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [binLocationDefaultOption, setBinLocationDefaultOption] = useState([]);    
    const authData =  useSelector(({authReducer}) => authReducer);
	const [bins, setBins] = useState([]);
    const [binLocations, setBinLocations] = useState([]);
    const [restockCheckBoxData, setRestockCheckBoxData] = useState([]);

    const getData = () => {
        const filters = orderState.filters;

        if(authData.defaultSeller && JSON.stringify(filters) != JSON.stringify({...orderState.filters, seller: authData.defaultSeller})) return;

        dispatch(getReturnRestockList({
            page: filters.currentPage,
            pageSize: filters.pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            seller: filters.seller ? filters.seller.value : '',
            order: filters.order,
            sku: filters.sku
        })).then(res => {
            dispatch(setReturnData(res.payload));
        })
    }

    useEffect(() => {
        getData();
    }, [orderState.filters.currentPage, orderState.filters.pageSize, startDate, endDate,
        orderState.filters.order, orderState.filters.sku, orderState.filters.binLocation, 
        orderState.filters.seller
    ]);   

    useEffect(()=>{
        if (authData.defaultSeller?.value != orderState.filters.seller?.value) {
            dispatch(setReturnOrderFilter({
                ...orderState.filters,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
        }
    },[authData.defaultSeller])

    useEffect(() => {    
        const errLists = [];
        setBinQtyIsError(false);
        setBinNameIsError(false);

        selectedRows.forEach((item) => {
            if(item.binLocations.length > 0){
                let binQty = item.binLocations.reduce((accumulator, currentObject) => {
                        return accumulator + currentObject.qty;
                }, 0); // Initialize the accumulator with 0
    
                binQty = Number(binQty);
                const itemBin = item.binLocations.find((bin) => bin.qty && (bin.name === undefined || bin.name === null || bin.name === ''));

                if(binQty <= 0 || binQty > item.quantity) {
                    errLists.push({ orderName: item.orderName, sku: item.sku, quantity: item.quantity, binQty: binQty });
                    setBinQtyIsError(true);
                }
                else if(itemBin !== undefined) {                
                    errLists.push({ orderName: item.orderName, sku: item.sku, quantity: item.quantity, binQty: binQty });
                }
    
                    if(itemBin !== undefined) {                
                        setBinNameIsError(true);
                    }
            }
        });

        // remove the checked box for Complete Restock if the selectedRow is unchecked
        setRestockCheckBoxData(prevData => {
            let updatedArray = [...prevData];  // Create a copy of the array
            updatedArray.map((id) => {
                let isExist = selectedRows.some((selectedRow) => selectedRow.id === id);
                if(!isExist){
                    
                    let packIndex = updatedArray.indexOf(id);
                    if (packIndex !== -1) {
                        updatedArray.splice(packIndex, 1);
                    }
                } 
            });
            return updatedArray;
        });

        setBinErrors(errLists);

        if(selectedRows.length > 0){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }

    }, [selectedRows]);

    useEffect(() => {
        if(orderState.returnData){
            setReturnData(mapReturnRestockResponseToTable(orderState.returnData, restockCheckBoxData, selectedRows));
        }
    }, [restockCheckBoxData]);

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Return Restock"
        }
    ];

	const handleSearchForBin = (query) => {
		dispatch(autoSuggestionBin(query)).then((res) => {
			if (!res.error) {
				setBins(res.payload);
			}
		});
	};

	const handleOnBinChange = (binData, rowId, binIndex) => {
		const selectedName = binData.length > 0 ? binData[0].name : '';
        
        const newData = [...selectedRows];
        const rowIndex = newData.findIndex((row) => row.id === rowId);

        if (rowIndex !== -1) {
            const binLocations = [...newData[rowIndex]['binLocations']];
            const item = {...binLocations[binIndex]};
            
            item.name = selectedName;
            if(item.qty === undefined) item.qty = 0;
            binLocations[binIndex] = item;
            newData[rowIndex]['binLocations'] = binLocations;
            setSelectedRows(newData);
        }
	}

    const filterBy = () => true;

    function binLocationRow(row) {
        let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id);
        let binLocations = row.binLocations;

        return binLocations.map((binLocation, index) => (
            <div className="row mb-2" key={row.id + "_" + index}>
                <div className="col-md pr-0">
                    <AsyncTypeahead
                        id={`bin-location-${row.id}-${index}`}
                        disabled={!!binLocation.id || !isEnable}
                        defaultInputValue={binLocation.name}
                        filterBy={filterBy}
                        classname="mb-2"
                        size="sm"
                        minLength={1}
                        labelKey="name"
                        placeholder="Search for Bin..."
                        onChange={(selected) => {
                            handleOnBinChange(selected, row.id, index);
                        }}
                        onSearch={handleSearchForBin}
                        options={bins}
                        renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.name}</span>
                            </>
                        )}
                    />

                </div>
                <div className="col-md">                    
                    <input
                        type="number" 
                        className="form-control form-control-sm"
                        onChange={(e) => handleBinQtyEdit(e, row.id, index, 'qty')}
                        value={binLocation.qty}
                        disabled={!isEnable}
                    />
                </div>
                <div className="col-md-1 pl-0 pt-2">
                    {!binLocation.id && <i className="fa fa-minus-circle text-danger" onClick={() => handleRemoveBinLocation(row.id, index)}></i>}
                </div>
            </div>
        ));
    }

    const handleAddBinLocation = (row) => {
        const returnData = [...orderState.returnData];        
        let dataIndex = returnData.findIndex(x => x.id == row.id);
        let item = {...returnData[dataIndex]};
        let binLocations = [...item.bin_locations, { 'id': null, 'name': '', 'available': 0, 'qty': 0 }];
        item.bin_locations = binLocations;
        returnData[dataIndex] = item;        
        dispatch(setReturnData(returnData));
    };

    const handleRemoveBinLocation = (rowId, index) => {
        const returnData = [...orderState.returnData];
        let dataIndex = returnData.findIndex(x => x.id == rowId);
        let item = {...returnData[dataIndex]};
        let binLocations = [...item.bin_locations];
        binLocations.splice(index, 1);
        item.bin_locations = binLocations;
        returnData[dataIndex] = item;        
        dispatch(setReturnData(returnData));

        const newData = [...selectedRows];
        const rowIndex = newData.findIndex((row) => row.id === rowId);
        const binLocationsAtRow = [...newData[rowIndex]['binLocations']];
        binLocationsAtRow.splice(index, 1);
        newData[rowIndex]['binLocations'] = binLocationsAtRow;
        setSelectedRows(newData);
    };

    const conditionalRowStyles = [

        {
            when: row => row.restock_history,
            style: row => ({
                backgroundColor: '#fff3cd',
            }),
        },    
    ];

    const tableColumns = [
        {
            name: 'Order Number',
            selector: row => row.orderName,
            cell: (row) => {
                return (row.restock_history && row.restock_history.length > 0) 
                ? <PopoverBasicItem id={row.id} btntext={row.orderName} Popoverbody={row.restock_history} Popoverheader={"Restock History"} />
                : row.orderName;
            },
            sortable: false,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: false,
            center: false,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: false,
            center: true,
        },
        {
            name: 'Complete Restock?',
            selector: row => row.completeRestock,
            cell: (row) => <CheckboxCell row={row} />,
            sortable: false,
            center: true,
        },
        {
            name: 'Bin Location & Quantity',
            selector: row => row.binLocation,
            cell: (row) => {
                let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id);
                return (
                    <div className="my-2">
                        {binLocationRow(row)}

                        <div className="row">
                            <div className="col-md-11">                                
                                <button type="button" 
                                    className="btn btn-primary btn-sm btn-block" 
                                    onClick={() => handleAddBinLocation(row)}
                                    disabled={!isEnable}>Add More</button>
                            </div>
                        </div>
                    </div>
                );
            },
            sortable: false,
            center: true,
            width:"30%",
            style: {
                display: "block"
            }
        },
    ];

    const CheckboxCell = ({ row }) => {
        let isEnable = selectedRows.some((selectedRow) => selectedRow.id === row.id);
        return (
            <input 
            type={"checkbox"}
            disabled={!isEnable}
            checked={row.completeRestock} 
            onChange={(e) => {
                handleCompleteRestockCheckboxChange(e, row);
            }} 
            />
        );
    };

    const handleCompleteRestockCheckboxChange = (event, row) => {
        let rowId = row.id;

        const isChecked = event.target.checked;
        
        setRestockCheckBoxData(prevData => {
            let updatedArray = [...prevData];  // Create a copy of the array
    
            if (isChecked) {
                updatedArray.push(rowId);
            } else {
                let packIndex = updatedArray.indexOf(rowId);
                if (packIndex !== -1) {
                    updatedArray.splice(packIndex, 1);
                }
            }
            return updatedArray;
        });

        // Create a new array based on the current state
        const newData = selectedRows.map((prevRow) => {
            if(prevRow.id === rowId){ 
                let updatedItem = prevRow;
                updatedItem['completeRestock'] = isChecked;
                return {...prevRow, updatedItem }
            }else{
                return prevRow;
            }
        });

        // Update the state with the new array
        setSelectedRows(newData);
    };

    const pageChange = (e) => { 
        dispatch(setReturnOrderFilter({...orderState.filters, currentPage:e}))
    }

    const pagination = (e) => {
        dispatch(setReturnOrderFilter({...orderState.filters, currentPage: 1, pageSize:e}))
    }

    const handleRowSelected = (state) => {
		setSelectedRows(state.selectedRows);
	};

    const submitData = () => {       
        let isValid = true;
        let isValidQty = true;
        let isValidName = true;

        for(const item of selectedRows) {
            const binQty = item.binLocations.reduce((accumulator, currentObject) => {
                return accumulator + currentObject.qty;
            }, 0); // Initialize the accumulator with 0

            if(binQty <= 0 || binQty > item.quantity) {
                isValid = false;
                isValidQty = false;
            }

            const itemBin = item.binLocations.find((bin) => bin.name === undefined || bin.name === null || bin.name === '');
            if(itemBin !== undefined) {
                isValid = false;
                isValidName = false;
            }

            if(!isValid) break;
        }

        if(!isValid) {     
            let msg = "";
            if(!isValidQty) msg = "The quantity you entered, must be greater than zero and less than the requested return quantity. ";
            if(!isValidName) msg += "Bin number is required.";
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        setButtonDisabled(true);
       
        dispatch(returnRestock(selectedRows)).then(response => {
            setToggleCleared(!toggleCleared);
            setSelectedRows([]);
            getData();
            setButtonDisabled(false);
        });
    }

    const handleBinQtyEdit = (e, rowId, binIndex, field) => {
        const newData = [...selectedRows];
        const rowIndex = newData.findIndex((row) => row.id === rowId);

        if (rowIndex !== -1) {
            const binLocations = [...newData[rowIndex]['binLocations']];
            const item = {...binLocations[binIndex]};
    
            if (field === 'name') {
                item.name = e.target.value;
            }
    
            if (field === 'qty') {
                const inputValue = e.target.value.trim();
                item.qty = inputValue === '' ? 0 : Number(inputValue);
            }
    
            binLocations[binIndex] = item;
            newData[rowIndex]['binLocations'] = binLocations;
            setSelectedRows(newData);
        }
        
        const returnData = [...orderState.returnData];
        const dataIndex = returnData.findIndex(x => x.id === rowId);
    
        if (dataIndex !== -1) {
            const item = {...returnData[dataIndex]};
            const binLocations = [...item.bin_locations];
    
            const binItem = {...binLocations[binIndex]};
            const inputValue = e.target.value.trim();
            binItem.qty = inputValue === '' ? '' : Number(inputValue);
            binLocations[binIndex] = binItem;
            item.bin_locations = binLocations;
            returnData[dataIndex] = item;
            dispatch(setReturnData(returnData));
        }
    };
    

    const PopoverBasicItem = (props) => {
        const {id,btntext,Popoverbody, Popoverheader} = props;
        const [popover, setPopover] = useState(false)
        const Toggle = () => setPopover(!popover);
        return (
            <>
                <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
                <Popover
                    placement="top"
                    isOpen={popover}
                    target={"Popover-" + id}
                    toggle={Toggle}
                    trigger="hover"
                >
                    {(Popoverheader)
                        ? <PopoverHeader className="restock-history-header" tag={"div"}>{Popoverheader}</PopoverHeader>
                        : <></>
                    }
                    <PopoverBody>
                        {renderHistory(Popoverbody)}
                    </PopoverBody>
                </Popover>
            </>
        );
    };

    const renderHistory = (history) => {
        if (!history || history.length === 0) {
          return null; // or any message or placeholder you want to display when the data is empty
        }
      
        return (
          <table width={450} className="restock-history">
            <thead>
              <tr>
                <th>Restocked By</th>
                <th>Restocked Quantity</th>
                <th>Restock At</th>
              </tr>
            </thead>
            <tbody>
              {history.map((item, index) => (
                <tr key={index}>
                  <td>{item.restock_by}</td>
                  <td>
                    {Array.isArray(item.restocked_qty) ? (
                        item.restocked_qty.map((qty, i) => (
                        <div key={i}>{`${qty.bin_number} (${qty.quantity})`}</div>
                        ))
                    ) : (
                        <div>{item.restocked_qty}</div>
                    )}
                    </td>
                  <td>{item.restock_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      };

    return (
        <Fragment>
            {/* {(currentUser.modules).includes('return-restock') ? <> */}
            
            <Breadcrumb title="Returns Restock" data={dataMenu} />

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col md=''>
                                        <div style={{ marginTop: 7 }} className="dashboard-datepicker">
                                            <DatePicker
                                                dateRange={dateRange}
                                                dateRangeChange={(date) => {
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[TodayDate, sevenDays]}
                                                disabledDate={afterToday()}
                                            />
                                        </div>
                                    </Col>

                                    <Col md=''>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup style={{ marginTop: 7 }} className="dashboard-datepicker">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={orderState.filters.order}
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                        
                                                        const newTimer = setTimeout(()=>{
                                                            dispatch(setReturnOrderFilter({
                                                                ...orderState.filters,
                                                                order:e.target.value,
                                                                currentPage: 1,
                                                            }));
                                                        }, 500);

                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Order Number"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>

                                    <Col md=''>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup style={{ marginTop: 7 }} className="dashboard-datepicker">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={orderState.filters.sku}
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                        
                                                        const newTimer = setTimeout(()=>{
                                                            dispatch(setReturnOrderFilter({
                                                                ...orderState.filters,
                                                                sku:e.target.value,
                                                                currentPage: 1,
                                                            }));
                                                        }, 500);

                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="SKU"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>

                                    {/* <Col md='' className="">
                                        <div className='float-sm-right pt-3 pb-3 pr-3'>
                                            <a className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                    //    dispatch(openDialog())
                                                }}
                                            > <i className="fa fa-download"></i> Action</a>
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <h5 className="mb-2">Items from Order </h5>
                                <div>
                                    {binErrors.length > 0 && <Alert color="danger">
                                        <h6 className="alert-heading mb-1">
                                            {binQtyIsError === true && <span className="mr-2">The quantity you entered, must be greater than zero and less than the requested return quantity.<br /></span>}
                                            {binNameIsError === true && <span>Bin number is required.<br /></span>}
                                        </h6>
                                    
                                        <ul className="bin-error-list">
                                        {binErrors.map((error, index) => (
                                            <li key={index} className="pb-1">
                                                <strong>Order No:</strong> <span>{error.orderName}</span> 
                                                <strong className="pl-2">SKU:</strong> <span>{error.sku}</span>  
                                                <strong className="pl-2">Return Quantity:</strong> <span>{error.quantity}</span> 
                                                <strong className="pl-2">Bin Quantity:</strong> <span>{error.binQty}</span> 
                                            </li>
                                        ))}
                                        </ul>
                                    </Alert>
                                    }

                                    <div className="return-restock-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            conditionalRowStyles={conditionalRowStyles}
                                            gridData={orderState.returnData ? mapReturnRestockResponseToTable(orderState.returnData, restockCheckBoxData, selectedRows) : ""}
                                            noAction
                                            selectableRows={true}
                                            onSelectedRowsChange={handleRowSelected}
			                                clearSelectedRows={toggleCleared}
                                            paginationServer
                                            paginationTotalRows={orderState.orderTableRow}
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            onChangeRowsPerPage={pagination}
                                            onChangePage={pageChange}
                                        />
                                    </div>

                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            {(selectedRows.length == 0) 
                                            ?
                                                <Button disabled={buttonDisabled} onClick={submitData} className="btn btn-primary btn-block">Save</Button>
                                            :
                                                <ButtonLoader disabled={buttonDisabled} onClick={submitData} btntext="Save" className="btn btn-primary btn-block" />
                                            }
                                            
                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
            {/* </>
            :
            <>
            <Container>
                <p></p>
            </Container> */}
            {/* </>} */}
        </Fragment>
    )
}

export default ReturnRestoke