import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import {useHistory, useParams} from "react-router-dom";
import AsyncSelect from "react-select/async";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import orderReducer from "../store";
import {sellerAutoSuggestionApi, sellerBrandSuggestionApi} from "../../../services/manage-product/productService";
import {
    getCheckoutData,
    getCheckouts,
    selectCheckouts,
    setCheckoutData,
    setCheckoutModal
} from "../store/checkoutSlice";
import CheckoutCart from "./content/checkoutCart";
import {setProductFilter} from "../../manage-product/store/prodcutSlice";
import moment from "moment/moment";
import Select from "react-select";
import { getBrands } from "../../manage-product/store/inventorySlice";
import { DateRangePicker } from 'rsuite';


const { afterToday } = DateRangePicker;
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Checkout = (props) => {


    const dispatch = useDispatch();
    const [page,setPage]=useState(1);

    const orderState = useSelector(({order}) => order.checkout);
    const sellerState = useSelector(({seller}) => seller.seller);
    const authData =  useSelector(({authReducer}) => authReducer);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [brands, setBrands] = useState([]);
    const [order,setOrder]=useState(null);
    const [loading, isSetLoading] = useState(false);
    const [tableLoading, isSetTableLoading] = useState(false);
    const TodayDate=new Date();
    const last30Days=new Date().setDate(TodayDate.getDate()-90);
    const [dateRange, setDateRange] = useState([ last30Days,TodayDate]);
    // const [seller, setSeller] = useState("");
    const [seller, setSeller] = useState(authData.defaultSeller);
    const [brand, setBrand] = useState("");
    const [startDate, endDate] = dateRange;

    useEffect(()=>{
        setSeller(authData.defaultSeller)
    },[authData.defaultSeller])

    let history = useHistory();




    const dataMenu = [
        {
            type: 0,
            text: "Abandon Checkout"
        }
    ];
    const tableColumns = [
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            center: false,
        }, {
            name: 'Checkout Date',
            selector: row => row.check_out_date,
            sortable: true,
            center: true
        },
        {
            name: 'Total Price',
            selector: row => row.price,
            sortable: true,
            right: true,
        },
        {
            name: '',
            selector: row => <div><a><i className="fa fa fa-shopping-cart" onClick={()=>{
                viewCheckoutData(row.id);
            }
            }></i></a></div>,

            visible: true,
            sortable: true,
            center: true,
        }
    ];
    const typePromiseOption = (inputValue,seller) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                seller_id: seller
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)))
            });
        });
    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data ={
                value: inputValue
            }
            setTimeout(() =>{
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

        useEffect(() => { 
            const data = {
                value: '',
                // seller_id: seller
                seller_id: seller && seller.value ? seller.value : '',
            }
            dispatch(getBrands(data)).then(res=>{
                setBrands(res.payload);
            });
        },[dispatch, seller])


    useEffect(() => {
        getData();
    }, [page,seller,brand,startDate,endDate]);

    const getData = () =>{
        if((startDate && endDate)  ) {
            const data = {
                page: page,
                pageSize: 10,
                // seller_id: seller,
                seller_id: seller && seller.value ? seller.value : '',
                brand_id: brand,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),

            }
            isSetTableLoading(true);
            dispatch(getCheckouts(data)).then(res => {
                if (!res.error) {
                    // dispatch(setCommissionData(res.payload))
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

    const viewCheckoutData=(id)=>{
        dispatch(getCheckoutData(id)).then(res=>{
            if(!res.error){
                 dispatch(setCheckoutModal(true));
                 dispatch(setCheckoutData(res.payload))
            }

        });
    }
    const checkouts = useSelector(selectCheckouts);
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb title="Abandon Checkouts" data={dataMenu}/>
                    <Container fluid={true} className="product-wrapper">
                        <Row>
                            <Col sm="12">
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col sm={userState.user.role.name==="Seller"?6:4}>
                                        <div className="dashboard-datepicker">
                                            <DatePicker 
                                                dateRange={dateRange} 
                                                dateRangeChange={(date)=>{
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[TodayDate, last30Days]}
                                                disabledDate={afterToday()}
                                            />
                                        </div>
                                    </Col>
                                    {userState.user.role.name!=="Seller" && <Col sm="4">
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            defaultOptions
                                            styles={customStyles}
                                            placeholder="Select Seller"
                                            loadOptions={sellerPromiseOption}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            value={seller}
                                            onChange={(e) => {
                                                // setSeller(e?.value);
                                                setSeller(e);
                                            }}
                                        />
                                    </Col>}
                                    <Col sm={userState.user.role.name==="Seller"?6:4}>
                                        <Select         
                                            className="basic-single"
                                            isClearable
                                            cacheOptions
                                            styles={customStyles} placeholder="Select Brand" 
                                            getOptionLabel ={(option)=>option.name}
                                            getOptionValue ={(option)=>option.id} 
                                            onChange={(e)=>{
                                                setBrand(e?.value);
                                            }} 
                                            options={brands} 
                                        />
                                        {/* <AsyncSelect
                                            cacheOptions={true}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable="true"
                                            defaultOptions
                                            placeholder="select Brand"
                                            loadOptions={(e)=>{
                                                return   typePromiseOption(e,seller)
                                            }}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            onChange={(e) => {
                                                setBrand(e?.value);

                                            }}

                                        /> */}

                                    </Col>

                                </Row>
                            </div>
                        </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <CommonDataTable
                                                headerColumns={tableColumns}
                                                gridData={checkouts}
                                                noAction
                                                progressPending={tableLoading}
                                                paginationServer
                                                paginationTotalRows={orderState.checkoutTableRow}
                                                paginationPerPage={10}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                onChangePage={page => setPage(page)}
                                            />

                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <CheckoutCart></CheckoutCart>
                    </Container>

                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(Checkout);