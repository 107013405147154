import API from "../../../utils/api";


export const getProductTagApi = async (filters) => {

    const response = await API.get(`settings/tag`, { params: filters });
    return { response };
};

export const addProductTagApi = async (location) => {
    const response = await API.post(`settings/tag`, location);
    return { response };
};

export const updateProductTagApi = async (location,id) => {
    const response = await API.put(`settings/tag/${id}`, location);
    return { response };
}

export const deleteProductTagApi = async (id) => {
    const response = await API.delete(`settings/tag/${id}`);
    return { response };
}