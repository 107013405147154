import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import withReducer from "../../../../store/withReducer";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PreLoader from "../../../../share-components/ui/preLoader";
import SaleOrderProduct from "./content/saleOrderProduct";
import SaleOrderDiscussion from "./content/saleOrderDiscussion";
import draftOrderReducer from "../../store";
import {
  salesOrderStatus,
  numberWithCommasWithoutPounds,
} from "../../../../utils/common";
import { orderCommodities } from "../setting/calculation";
import { useHistory } from "react-router-dom";
import {
  showSalesOrder,
  updateSalesOrderStatus,
  downloadSalesOrderPickingTicketPdf,
} from "../../store/salesOrderSlice";

const defaultValues = {
  id: "",
  ordered_products: [],
  date: "",
  tags: [],
  notes: "",
  discount: "",
  shipping: "",
  tax: [],
  is_tax: false,
  is_paid: false,
  customer: "",
  shopify_draft_order_id: "",
  shippingAddress: "",
  billingAddress: "",
};

export const defaultAddressValues = {
  company: "",
  type: "",
  country: "",
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  phone: "",
  province: "",
  order_no: "",
};

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Sales Order View",
  },
];

const ViewSalesOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { reset, setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [okButtonsEnabled, setOkButtonsEnabled] = useState(true);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [discussionSection, setDiscussionSection] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [statusName, setStatusName] = useState("");
  const authData = useSelector(({ authReducer }) => authReducer);
  let history = useHistory();

  const processPDFDownload = (name) => {
    const orderId = getValues("id");
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${res.data ? res.payload.order_no : "-"}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleOkToGoClick = () => {
    const newStatusId = 1;
    const orderId = getValues("id");
    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        if (!res.error) {
          console.log("handle : ", res);
          setButtonsEnabled(true);
          setOkButtonsEnabled(false);
          setStatusDropdownVisible(true);
          setDiscussionSection(true);
          setStatusName(res.payload.name || "");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const handleStatusChange = (newStatusId) => {
    const orderId = getValues("id");

    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        console.log("statys : " + res.payload.id);
        setStatusName(res.payload.name || "");
        setDiscussionSection(res.payload.id == 1);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket");
  };

  const createInvoice = () => {
    history.push("billing/invoice");
  };

  const createFulfillment = () => {
    history.push("billing/invoice/fulfillment");
  };

  const editSalesOrder = () => {
    history.push("edit");
  };

  useEffect(() => {
    if (id) {
      dispatch(showSalesOrder(id)).then((res) => {
        console.log("Test 1" + getValues("id"));
        if (!res.error) {
          reset(res.payload);
          setLoading(false);
          setOkButtonsEnabled(res.payload.status?.id == 0);
          setButtonsEnabled(res.payload.status?.id > 0);
          setStatusDropdownVisible(res.payload.status?.id > 0);
          setDiscussionSection(res.payload.status?.id == 1);
          setStatusName(res.payload.status?.name || "");
        }
      });
    } else {
      setLoading(false);
      setButtonsEnabled(false);
    }
  }, [dispatch]);

  const dropdownStatusOptions = salesOrderStatus.map((status) => (
    <DropdownItem key={status.id} onClick={() => handleStatusChange(status.id)}>
      {status.name}
    </DropdownItem>
  ));

  return (
    <Fragment>
      <Breadcrumb
        title={
          <>
            Sales Order
            {getValues("order_no") ? <> - {getValues("order_no")}</> : ""}
            {getValues("status") ? (
              <>
                <br />
                <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                  Status:{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {statusName}
                  </span>
                </span>
              </>
            ) : (
              ""
            )}
          </>
        }
        data={dataMenu}
      />

      <Container fluid={true}>
        <div className="d-flex justify-content-between mb-2 px-2">
          <>
            {okButtonsEnabled && (
              <Button
                color="primary"
                type="button"
                className="btn btn-sm btn-primary"
                style={{ height: 38 }}
                onClick={handleOkToGoClick}
              >
                OK To Go
              </Button>
            )}

            {statusDropdownVisible && (
              <Dropdown
                isOpen={dropdownStatusOpen}
                toggle={toggleStatusDropdown}
              >
                <DropdownToggle
                  color="info"
                  className="btn btn-sm btn-info"
                  style={{ height: 38, marginRight: 8 }}
                >
                  Status <i className="fa fa-caret-down" aria-hidden="true"></i>
                </DropdownToggle>
                <DropdownMenu>{dropdownStatusOptions}</DropdownMenu>
              </Dropdown>
            )}
          </>

          <div className="d-flex">
            {buttonsEnabled && (
              <>
                <Button
                  color="info"
                  type="button"
                  className="btn btn-sm btn-info"
                  style={{ height: 38, marginRight: 8 }}
                  onClick={createInvoice}
                >
                  Create Invoice
                </Button>

                <Button
                  color="info"
                  type="button"
                  className="btn btn-sm btn-info"
                  style={{ height: 38, marginRight: 8 }}
                  onClick={createFulfillment}
                >
                  Create Fulfillment
                </Button>

                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    color="warning"
                    className="btn btn-sm btn-warning"
                    style={{ height: 38, marginRight: 8 }}
                  >
                    Download{" "}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={downloadPicking}>
                      Picking Ticket
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            )}

            <Button
              color="primary"
              type="button"
              className="btn btn-sm btn-primary"
              style={{ height: 38, marginRight: 8 }}
              onClick={editSalesOrder}
            >
              Edit
            </Button>

            <Button
              color="primary"
              type="button"
              className="btn btn-sm btn-primary"
              style={{ height: 38 }}
            >
              Cancel Order
            </Button>
          </div>
        </div>

        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <Row className="mt-3">
            <Col sm="8">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Label
                        style={{
                          fontWeight: "bolder",
                          fontSize: "16px",
                          paddingBottom: "10px",
                        }}
                      >
                        Products
                      </Label>

                      <Row>
                        <Col md={12}>
                          {getValues("ordered_products").length > 0 && (
                            <SaleOrderProduct
                              setValue={setValue}
                              getValues={getValues}
                              trigger={trigger}
                              orderedProducts={getValues("ordered_products")}
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Payment
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Total amount
                                </td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("total_price")}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Discount</td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("discount")?.value}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Sub Total
                                </td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("total_price")}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Duty</td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("duty")?.value}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Shipment</td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("shipping")?.price}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Tax</td>
                                <td>----------------</td>
                                <td align="right">
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("tax")?.rate_name}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  New Total
                                </td>
                                <td>----------------</td>
                                <td align="right">
                                  {/* {getValues("currency")?.label + " "} */}
                                  {getValues("currency")?.value === 1
                                    ? "€"
                                    : "$"}
                                  {getValues("total_price")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Order Details
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Created Date :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("date")}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Customer :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("customer")?.label}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Customer PO No :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("customer_purchase_order_no")}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Factory PO No :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("factory_purchase_order_no")}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Memo :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("memo")}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6">
                          <label style={{ fontWeight: "bolder" }}>
                            Currency :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("currency")?.label}
                            </label>
                          </label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Shipping Address
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label style={{ fontWeight: "bolder" }}>
                            Company Name :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("shippingAddress")?.company}
                            </label>
                          </label>

                          <label style={{ fontWeight: "bolder" }}>
                            Address :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("shippingAddress")?.address1}
                            </label>
                          </label>

                          <label style={{ fontWeight: "bolder" }}>
                            Contact No. :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("shippingAddress")?.phone}
                            </label>
                          </label>

                          <label style={{ fontWeight: "bolder" }}>
                            Billing Address :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("billingAddress")?.address1}
                            </label>
                          </label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Commodity Information
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {/* <label style={{ fontWeight: "bolder" }}>
                            Knitting :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("shippingAddress")?.company}
                            </label>
                          </label> */}
                          {orderCommodities(getValues("ordered_products")).map(
                            (item, i) => (
                              <p key={i}>
                                {item.commodity} {item.quantity} cost{" "}
                                {item.amount}
                              </p>
                            )
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm="12">
              {discussionSection && (
                <Card>
                  <CardBody
                    style={{ paddingTop: "24px", paddingBottom: "24px" }}
                  >
                    <Row>
                      <Col sm="6">
                        <Label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "16px",
                            paddingBottom: "10px",
                          }}
                        >
                          Discussion
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <SaleOrderDiscussion
                          salesOrderId={getValues("id")}
                          salesOrderComments={getValues("comments")}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([
  {
    order: draftOrderReducer,
  },
])(ViewSalesOrder);
