import API from "../../../utils/api";


export const getSizeMatrixConfigurationsApi = async (filters) => {
    const response = await API.get('size-matrix-configuration/size-matrix-configuration', {params: filters});
    return {response};
};

export const addSizeMatrixConfigurationsApi = async (location) => {
    const response = await API.post('size-matrix-configuration/size-matrix-configuration', location);
    return {response};
}

export const updateSizeMatrixConfigurationsApi = async (location, id) => {
    const response = await API.put(`size-matrix-configuration/size-matrix-configuration/${id}`, location);
    return {response};
}


export const deleteSizeMatrixConfigurationsApi = async (id) => {
    const response = await API.delete(`size-matrix-configuration/size-matrix-configuration/${id}`);
    return {response};
}

export const sizeMatrixAutoSuggestionApi = async (text) => {
    const response = await API.post(`size-matrix-configuration/size-matrix-auto-search`, text);
    return {response};
}

export const getSelectedSizeMatrixOrderApi = async (id) => {
    const response = await API.post(`size-matrix-configuration/get-selected-size-matrix-order/${id}`);
    return {response}
}

export const setSizeOrderApi = async (data,id) => {
    const response = await API.post(`size-matrix-configuration/set-size-format-order/${id}`, data);
    return {response}
};