import {injectReducer} from "./index";
import React, { Component }  from 'react';


const withReducer = items => WrappedComponent => {
    items.forEach((item) => {
        injectReducer(Object.keys(item)[0], item[Object.keys(item)[0]]);
    });
    return props => <WrappedComponent {...props} />;
};


export default withReducer;
