import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getReadNotificationsApi, getUnreadNotificationsApi} from "../../../services/manage-user/user";
import {toast} from "react-toastify";


export const getReadNotifications = createAsyncThunk('user/get-read-notifications', async (params, {dispatch}) => {
    try {
        const response = await getReadNotificationsApi(params);
        dispatch(setReadNotificationPaginationData(response.response.pagination));
        return await response.response.data;
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});
export const getUnreadNotifications = createAsyncThunk('user/get-unread-notifications', async (params, {dispatch}) => {
    try {
        const response = await getUnreadNotificationsApi(params);
        dispatch(setUnreadNotificationPaginationData(response.response.pagination));
        return await response.response.data;
    } catch (e) {
        toast.error("Failed to process, contact admin", {
            position: toast.POSITION.TOP_RIGHT,
        });
        console.log(e);
        return Promise.reject(e);
    }
});

const notificationAdapter = createEntityAdapter({});

export const {selectAll: selectNotifications, selectById: selectByNotificationId} = notificationAdapter.getSelectors(
    state => state.notification.notification
);

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: notificationAdapter.getInitialState({
        searchText: '',
        pageCount: 0,
        unreadPageCount: 0,
    }),
    reducers: {
        setReadNotificationPaginationData: (state, action) => {
            state.pageCount = action.payload.count
        },
        setUnreadNotificationPaginationData: (state, action) => {
            state.unreadPageCount = action.payload.count
        }
    },
    extraReducers: {
        [getReadNotifications.fulfilled]: notificationAdapter.setAll,
    }
});

export const {setReadNotificationPaginationData, setUnreadNotificationPaginationData} = notificationSlice.actions;

export default notificationSlice.reducer;