import API from "../../utils/api";

export const getSalesOrderReturnReasonsApi = async (filters) => {
  const response = await API.get("order/sales-order/return/get-data", {
    params: filters,
  });
  return { response };
};

export const addSalesOrderReturnApi = async (data) => {
  const response = await API.post("order/sales-order/return", data);
  return { response };
};

export const getSalesOrderPendingReturnApi = async (filters) => {
  const response = await API.get("order/sales-order/return", {
    params: filters,
  });
  return { response };
};

export const getSalesOrderReturnItemsApi = async (filters) => {
  const response = await API.get("order/sales-order/return-item", {
    params: filters,
  });
  return { response };
};

export const editSalesOrderPendingReturnApi = async (id) => {
  const response = await API.get(`order/sales-order/return/${id}/edit`);
  return { response };
};

export const updateSalesOrderPendingReturnApi = async (data, id) => {
  const response = await API.put(`order/sales-order/return/${id}/update`, data);
  return { response };
};

export const salesOrderRefundApi = async (id, data) => {
  const response = await API.get(`order/sales-order/return/${id}/show`, {
    params: data,
  });
  return { response };
};

export const sendReturnInvoiceItemApi = async (data) => {
  const response = await API.post("order/sales-order/return-item/update", data);
  return { response };
};
