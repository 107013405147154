import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Media, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap'

import {connect, useDispatch} from "react-redux";


import UserLog from "../user-profile/content/userLog";
import withReducer from "../../../store/withReducer";
import userReducer from "../store";


const ActivityLog = (props) => {
    const dataMenu = [
        {
            type: 0,
            text: "Activity Log"
        }
    ];

    const dispatch = useDispatch();


    return (
        <Fragment>
            <Breadcrumb title="Activity Log" data={dataMenu}/>
            <Container fluid={true}>
                <UserLog modal_view={false} />
            </Container>
        </Fragment>
    );

};

export default withReducer([{user: userReducer}])(ActivityLog)


