import {TabContent, TabPane} from "reactstrap";
import {CompanyProfile} from "./companyProfile";
import React from "react";
import {BusinessDetails} from "./businessDetails";
import {ContactDetails} from "./contactDetails";
import {AdditionalDetails} from "./additionalDetails";
import {ExtraDetails} from "./extraDetails";


export const UpdateBuyerLeadForm = (props) => {
    const {VerticleTab,errors,register,buyerState,isPremium,setIsPremium,isTaxChargeable,setIsTaxChargeable,trigger,setValue,getValues,sellerSinceDate,setSellerSinceDate, buyerLeadState,setVerticleTab,formData, buyer}=props;

    return (
        <TabContent activeTab={VerticleTab}>
            <TabPane className="fade show" tabId="1">
                <CompanyProfile {...props}/>
            </TabPane>
            <TabPane tabId="2">
                <BusinessDetails {...props}/>
            </TabPane>
            <TabPane tabId="3">
                <ContactDetails {...props}/>
            </TabPane>
            <TabPane tabId="4">
                <AdditionalDetails {...props}/>
            </TabPane>
            <TabPane tabId="5">
                <ExtraDetails {...props}/>
            </TabPane>
        </TabContent>
    );
}