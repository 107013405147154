import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {CardBody, Col, Container, Form, Label, Row} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import {Accordion, ButtonGroup, Card, useAccordionButton} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewShipping,
    addShippingZone,
    getAllSellers,
    getCountryList,
    getCurrentSellerId,
    getSelectedShippingZoneData,
    setShippingRates,
    updateShippingZone
} from "../store/shippingZoneSlice";
import CountryList from "./countryList";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import "../../../assets/scss/components/accordian.scss";
import RateJqx from "./rateJqx";
import Select from "react-select";
import ButtonLoader from "../../../share-components/ui/buttonLoader";

export const defaultValues = {
    zone_name: '',
    radio1: '',
    seller: '',
}

export const schema = yup.object().shape({
    zone_name: yup.string().required('Name is required field'),
});

const dataMenu = [
    {
        type: 0,
        text: 'Seller',
        link: '/seller'
    },
    {
        type: 1,
        text: 'Shipping Zone',
        link: '/seller/shipping-zone'
    },
    {
        type: 2,
        text: 'Add Shipping Zone',
        link: ''
    },
];

function CustomToggle({children, eventKey}) {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        setIsOpen(!isOpen)
    );

    return (
        <button
            type="button"
            className="btn btn-primary-outline pull-right"
            onClick={decoratedOnClick}
        >
            {isOpen ? <i className="fa fa-chevron-circle-up"></i> : <i className="fa fa-chevron-circle-down"></i>}
        </button>
    );
}

const AddShippingZone = () => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const formRef = useRef();
    const {handleSubmit, formState, reset, register, setValue, getValues, trigger, control} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const [dataList, setDataList] = useState([]);

    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "countryList", // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    let gridRef = useRef(null);
    console.log(gridRef)

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef.current = jqx;
        }
    };

    const [rateType, setRateType] = useState([]);
    const [btnLoading,setBtnLoading]=useState(false);
    const [min, setMin] = useState([]);
    const [max, setMax] = useState([]);
    const [seller, setSeller] = useState([]);
    const [sellerId, setSellerId] = useState(null);
    const [sellerVisibility, setSellerVisibility] = useState(true);

    const shippingZoneState = useSelector(({seller}) => seller.shippingZone);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    let history = useHistory();

    useEffect(() => {

        dispatch(getCurrentSellerId(currentUser.id)).then(res => {

            if (!res.payload) {
                dispatch(getAllSellers()).then(result => {
                    setSeller(result.payload);
                    setSellerVisibility(true)

                });
            } else {
                setSeller(res.payload);
                setSellerId(res.payload);
                setSellerVisibility(false)
            }
        });
        if (id) {
            const idData = {
                seller_id: sellerId,
                id: id
            }
            dispatch(getSelectedShippingZoneData(idData)).then(res => {
                const set = {
                    countryList: res.payload.countryList,
                    zone_name: res.payload.shippingZoneData.name,
                }
                setDataList(res.payload.countryList);
                reset(set);
                setValue('type', String(res.payload.shippingZoneData.type));
                setValue('seller', res.payload.shippingZoneData.seller);
                if (res.payload.shippingZoneData.type == 2) {
                    setMin("Min (Kg)");
                    setMax("Max (Kg)");
                } else {
                    setMin("Min");
                    setMax("Max");
                }
                dispatch(setShippingRates(res.payload.shippingZoneRateData))
            })
        } else {
            if (sellerId) {
                dispatch(getCountryList(sellerId)).then(res => {
                    dispatch(addNewShipping())
                    setValue('countryList', res.payload);
                    setDataList(res.payload);
                    setValue('type', "1");
                    setRateType("1");
                    setMin("Min");
                    setMax("Max");
                });
            }
        }
    }, [dispatch, sellerId]);

    const onSubmitForm = (data) => {

        let formValidation = 0;
        const rowData = [];
        const gridInformation = gridRef.current.getdatainformation();

        for (let i = 0; i < gridInformation.rowscount; i++) {
            console.log(gridRef.current.getrowdata(i))
            if ((gridRef.current.getrowdata(i).rate_name &&
                gridRef.current.getrowdata(i).min &&
                gridRef.current.getrowdata(i).max &&
                gridRef.current.getrowdata(i).shipping_price) || (gridRef.current.getrowdata(i).rate_name==0||
                gridRef.current.getrowdata(i).min ==0 ||
                gridRef.current.getrowdata(i).max ==0 ||
                gridRef.current.getrowdata(i).shipping_price==0)) {

                rowData.push({
                    id: gridRef.current.getrowdata(i).id ? gridRef.current.getrowdata(i).id : null,
                    rate_name: gridRef.current.getrowdata(i).rate_name,
                    min: gridRef.current.getrowdata(i).min,
                    max: gridRef.current.getrowdata(i).max,
                    shipping_price: gridRef.current.getrowdata(i).shipping_price,
                });

            } else {
                formValidation++;
            }
        }
        if (formValidation != 0) {
            toast.error("Please fill all the fields in the rates table");
        } else {
            setBtnLoading(true);
            const finalData = {
                id: id ? id : null,
                seller_id: sellerId,
                rates: rowData,
                type: data.type,
                countryData: data.countryList,
                zone_name: data.zone_name
            }
            if (id) {
                dispatch(updateShippingZone(finalData)).then(res => {

                    if (!res.errors) history.push('/seller/shipping-zone');
                    setBtnLoading(false);
                })
            } else {
                dispatch(addShippingZone(finalData)).then(res => {

                    if (!res.error) history.push('/seller/shipping-zone');
                    setBtnLoading(false);
                })
            }
        }
    }


    const onChangeCountryValue = (index, value) => {
        for (let i = 0; i < dataList[index].children.length; i++) {
            setValue(`countryList.${index}.children[${i}].value`, value)
        }
    }
    let minValue = "Min";
    let maxValue = "Max";
    const baseTypeChange = (status, value) => {
        minValue = "Min";
        maxValue = "Max";
        if (status.target.value === "2") {
            minValue = "Min (KG)";
            maxValue = "Max (KG)";

        }
        gridRef.current.setcolumnproperty("min", "text", minValue);
        gridRef.current.setcolumnproperty("max", "text", maxValue);
        gridRef.current.clear();
        gridRef.current.addrow(null, {});
    }

    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title={id ? "Update Shipping Zone" : "Add Shipping Zone"}/>
            <Container fluid={true}>

                <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                    <Card>
                        <CardBody>
                            <Row className="m-b-25">
                                <Col sm={6}>

                                    <Label className="form-label">Zone Name</Label>
                                    <input {...register(`zone_name`)} type="text" name="zone_name"
                                           className="form-control"/>
                                </Col>
                                {sellerVisibility ? <Col sm={6}>
                                    <Label className="form-label">Seller Name</Label>
                                    <Select getOptionLabel={(seller) => `${seller.name} - ${seller.shop_name}`}
                                            value={getValues('seller')}
                                            defaultValue={getValues('seller')}
                                            getOptionValue={(seller) => seller.id} {...register("seller")}
                                            onChange={(e) => {
                                                setValue('seller', e);
                                                setSellerId(e.id)
                                                trigger('seller');
                                            }} options={seller}/>

                                </Col> : ''}
                            </Row>
                            <Row>
                                <Col sm="12" xl="12">
                                    <Accordion defaultActiveKey="0">
                                        {fields && fields.map((field, index) =>
                                            <Card key={index} style={{margin: 0}}>
                                                <Card.Header style={{padding: 10}}>
                                                    <Label className="form-label w-25">{field.name}</Label>
                                                    <input
                                                        type="checkbox"
                                                        className=""
                                                        key={field.id} // important to include key with field's id
                                                        {...register(`countryList.${index}.value`)}
                                                        onChange={(e) => onChangeCountryValue(index, e.target.checked)}
                                                    />
                                                    <CustomToggle eventKey={index}>Click me!</CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={index}>
                                                    <Card.Body>
                                                        <CountryList nestIndex={index} {...{control, register}}/>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )}
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row className="m-t-40 m-l-5">
                                <Col sm="4" xl="4">
                                    <Label>Shipping Type</Label>
                                </Col>
                                <Col sm="4" xl="4">
                                    <div className="btn-radio">
                                        <ButtonGroup>
                                            <div className="radio radio-primary">
                                                {gridRef && <input
                                                    {...register(`type`, {
                                                        onChange: (e) => {
                                                            baseTypeChange(e, 1)
                                                        }
                                                    })} id="radio7" type="radio" name="type" value="1"/>}
                                                <Label htmlFor="radio7">Based on order price</Label>
                                            </div>
                                            <div className="radio radio-primary m-l-20">
                                                <input
                                                    {...register(`type`, {
                                                        onChange: (e) => {
                                                            baseTypeChange(e, 2)
                                                        }
                                                    })} id="radio8" type="radio" name="type" value="2"/>
                                                <Label htmlFor="radio8">Based on item weight</Label>
                                            </div>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                                <Col sm="4" xl="4"></Col>
                            </Row>
                            <Row className="m-t-40 m-l-5">
                                <Col sm="12" xl="12">
                                    <RateJqx minValue={min} maxValue={max} data={shippingZoneState.rates}
                                             getJqxRef={getJqxRef} gridRef={gridRef}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardBody>
                                <ButtonLoader btntext={id ? "Update" : "Save"} color="primary" className="button-color btn  w-25 float-right" disabled={btnLoading} type="submit" />
                            </CardBody>
                        </CardBody>
                    </Card>
                </Form>
            </Container>
        </Fragment>
    );
}

export default (AddShippingZone)