import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Button
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useForm, useFieldArray, Controller} from "react-hook-form";
import {closeBinInfoModal, updateBinLocations, setList} from "../../store/orderSlice";

const schema = yup.object().shape({
    binLocations: yup.array().of(
        yup.object().shape({
            quantity: yup.number()
                .min(0, 'Number must be equal to or greater than 0.')
                .required('Quantity is required field.')
                .integer('Quantity must be an integer number.')
                .test('is-less-than-stock', 'Quantity cannot be greater than available quantity.', function(value) {
                    const stockQuantity = this.parent.stock_quantity; // Access the "stock_quantity" field
                    return value <= stockQuantity;
                })
                .typeError('Quantity is required field.'),
        })
    ),
});

const BinInfoModal = (props) => {
    // const {setValue, trigger, getValues, register, errors} = props;
    const dispatch = useDispatch();
    const orderState = useSelector(({order}) => order.order);
    const item = orderState.binInfoModal?.data ? orderState.binInfoModal.data : null;
    const binInfo = item && item.bin_numbers ? item.bin_numbers : [];
    const [totalQty, setTotalQty] = useState(0);
    const [stockQty, setStockQty] = useState(0);
    const [isLimitQty, setIsLimitQty] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);    

    const initialData = () => {
        return {
            id: item ? item.id : '',
            binLocations: binInfo
        }
    };

    const {
        control,
        handleSubmit: handleSubmitBinInfo,
        formState: formBinInfo,
        reset,
        register,
        setValue,
        getValues,
        trigger,
        watch
    } = useForm({
        mode: 'onChange',
        defaultValues: initialData(),
        resolver: yupResolver(schema)
    });

    const watchedFormData = watch(); // Watch the entire form
    const {errors: errors} = formBinInfo;
    const { fields, append, remove } = useFieldArray({ name: 'binLocations', control });

    useEffect(() => {   
        let formData = initialData();     
        reset(formData);
        setTotalQty(item && item.quantity ? item.quantity : 0);
    }, [item]);

    useEffect(() => {
        setQtyLimitFn();
    }, [watchedFormData]);

    const setQtyLimitFn = ()=>{
		let currentQtySum = watchedFormData?.binLocations?.reduce(function(prev, current) {
		    return prev + Number(current.quantity)
		}, 0);

        if(totalQty == currentQtySum){
            setIsLimitQty(true);
        }else{
            setIsLimitQty(false);
        }        
    };

    const onSubmitBinInfo = (dialog, data) => {
        if(!isLimitQty) return;

        const formData = {
			"item_id": data.id,
			'bin_locations': data.binLocations,
		};

        setButtonDisable(true);
		dispatch(updateBinLocations({...formData})).then(response => {
            const _item = response.payload.data;
            const lists = [...orderState.list];
            const index = lists.findIndex((data) => data.id === _item.id);

            if(index !== -1) {
                lists[index] = _item;
                dispatch(setList(lists));
            }
            setButtonDisable(false);
            clossBinInfoModal();
        });
    }

    const clossBinInfoModal = () => {
        dispatch(closeBinInfoModal())
    }

    const handleAddField = () => {
        append({ quantity: '', bin_number: '' });
    };

    const handleRemoveField = (index) => {
        remove(index);
    };

    return (
        <Fragment>            
            <ModalFormSubmit
                defaultValues={initialData()}
                onSubmitForm={onSubmitBinInfo}
                reset={reset}
                formState={formBinInfo}
                handleSubmit={handleSubmitBinInfo}
                title='Bin Number'
                selector='order'
                dialog={({order}) => order.order.binInfoModal}
                closeDialog={clossBinInfoModal}
                buttonDisable={buttonDisable}
                schema={schema}
            >
                {isLimitQty === false && <>
                <Row className='mb-3'>
                    <Col md="">
                        <p className="text-danger">The total quantity across all bin numbers must be {totalQty}</p>
                    </Col>
                </Row>
                </>}

                {fields.map((item, index) => (
                    <Row key={item.id}>
                        <Col md="" className={errors.binLocations?.[index]?.quantity ? '' : 'mb-3'}>
                            <input type="text" className="form-control" value={item.bin_number + " (Available: " + (item.available + item.quantity) + ")"} disabled />
                        </Col>
                        <Col md="5" className={errors.binLocations?.[index]?.quantity ? '' : 'mb-3'}>
                            <Controller
                                name={`binLocations.[${index}].quantity`}
                                control={control}
                                defaultValue={item.quantity}                                
                                render={({ field }) => (
                                    <>
                                    <input type="number" placeholder="Quantity" 
                                        className={`form-control ${errors.binLocations?.[index]?.quantity ? 'is-invalid' : ''}`} 
                                        {...register(`binLocations.[${index}].quantity`)}
                                        {...field} />
                                    </>
                                )}
                            />
                        </Col>
                        {errors.binLocations?.[index]?.quantity && <>
                        <Col md="12 mb-3">
                            <div className="invalid-feedback d-block text-right">{errors.binLocations?.[index]?.quantity?.message}</div>
                        </Col>
                        </>}
                    </Row>
                ))}

            </ModalFormSubmit>
        </Fragment>
    );
}

export default BinInfoModal;