import API from "../../utils/api";


export const getAgentsApi = async (filters) => {
    const response = await API.get('agent/agent', { params: filters });
    return { response };
};

export const addAgentsApi = async location => {
    const response = await API.post('agent/agent', location);
    return { response };
};

export const updateAgentsApi = async (location,id) => {
    const response = await API.put(`agent/agent/${id}`, location);
    return { response };
};

export const deleteAgentsApi = async (id) => {
    const response = await API.delete(`agent/agent/${id}`);
    return { response };
};

export const sellerAutoSuggestionApi = async (text) => {
    const response = await API.post(`agent/auto-search`, text);
    return { response };
};
export const getAllSellersApi = async () => {
    const response = await API.get(`agent/get-sellers-data`);
    return { response };
};

