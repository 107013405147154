import {Nav, NavItem, NavLink} from "reactstrap";
import React, {useState} from "react";
import '../navItems.scss'

export const UpdateBuyerLeadNavItems = (props) => {
    const {VerticleTab, setVerticleTab, id} = props;
    const [companyProfile, setCompanyProfile] = useState(false);


    return (
        <Nav className="nav flex-column nav-pills lead-nav">
            <NavItem>
                <NavLink  className={VerticleTab === '1' ? 'active' : ''}
                         onClick={() => setVerticleTab('1')}>Company Profile {companyProfile ?
                    <i className="icofont icofont-warning-alt"></i> : ""}
                </NavLink>
            </NavItem>
            {id &&
                <NavItem>
                    <NavLink  className={VerticleTab === '2' ? 'active' : ''}
                             onClick={() => setVerticleTab('2')}>Business Details</NavLink>
                </NavItem>}
            {id &&<NavItem>
                <NavLink  className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Contact Details</NavLink>
                </NavItem>}
            {id &&<NavItem>
                <NavLink className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Customize Details</NavLink>
                </NavItem>}
            {id &&<NavItem>
                <NavLink className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Additional Details</NavLink>
                </NavItem>}

        </Nav>
    );
}