import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapSettingResponseToTable} from "../../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import {
    addProductCommunity,
    closeEditDialog,
    deleteProductCommunity,
    getAllProductCommunities,
    openEditDialog,
    openNewDialog,
    selectProductCommunity,
    updateProductCommunity
} from "../../store/productCommunitySlice";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import SettingForm from "../settingForm";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    hs_code: '',
    publish_at: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Commodity',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
    hs_code: yup.string().required('You must enter a HS (Harmonized System) Code'),
    // tageName: yup.string().required('You must enter a tag name'),
});

const Community = () => {

    const [name, setName] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const productCommodity = useSelector(selectProductCommunity);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors} = formState;

    useEffect(() => {
        const data = {
            name
        };
        dispatch(getAllProductCommunities({...data})).then(res=> {
            if (!res.error)
            setLoading(false);
        });
    }, [dispatch, name]);

    const tableColumns = [
        {
            name: 'Product Commodity Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Hs Code',
            selector: row => row.hs_code,
            sortable: true,
            left: true,
        }
    ];

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteProductCommunity(row.id))
    }

    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addProductCommunity({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductCommunity({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        }
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }


    return (
        <Fragment>

            <Container fluid className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Commodity"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Commodity') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Commodity</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12" sm="12">
                                    <Form>
                                        <FormGroup>
                                            <input
                                                onChange={(e) => setName(e.target.value)}
                                                name="name"
                                                type="text" className="form-control border"
                                                placeholder="Search By Product Commodity Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                            <div className="table-responsive product-table mt-4">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={mapSettingResponseToTable(productCommodity)}
                                    pagination
                                    onEdit={(currentUser.permission).includes('Update Commodity') ? onEdit:''}
                                    onDelete={(currentUser.permission).includes('Delete Commodity') ? onDelete:''}
                                />
                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Commodity'
                selector='product'
                dialog={({product}) => product.productCommunity.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <SettingForm
                    control={control} formText="Commodity" showHs={true} errors={errors} register={register} setValue={setValue}
                    trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    )

}

export default (Community)