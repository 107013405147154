import API from "../../../utils/api";


export const getTagsSettingDataApi = async (filters) => {
    const response = await API.get('product-tag-setting/setting-data', { params: filters });
    return { response };
};

export const getAllTagsSettingDataApi = async (filters) => {
    const response = await API.get('product-tag-setting/index', { params: filters });
    return { response };
};

export const retrieveTagSettingApi = async id => {
	const response = await API.get(`product-tag-setting/${id}/edit`);
	return { response };
};
export const addTagSettingApi = async data => {
    const response = await API.post('product-tag-setting/store', data);    
    return { response };
};

export const updateTagSettingApi = async (item, id) => {
    const response = await API.put(`product-tag-setting/${id}`, item);
    return { response };
};

export const deleteTagSettingApi = async (id) => {
    const response = await API.delete(`product-tag-setting/${id}`);
    return { response };
};
