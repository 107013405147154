import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import CKEditors from "react-ckeditor-component";
import './ckeditorStyles.css';



const editorConfiguration = {
    toolbarGroups: [

        '/',
        '/'
    ],
    readOnly:true,
    removeButtons: 'Maximize,Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles'
};


export const ViewSellerStoreDescription = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-danger">Seller & Store Description</div>
                        <Row>
                            <Col md={6}>
                                <p className="sub-title">Store</p>
                                {
                                    sellerDetails.storeDescriptionHeading &&
                                    <FormGroup>
                                        <Label className="form-label font-weight-bold">Heading</Label>
                                        <p>{sellerDetails.storeDescriptionHeading}</p>
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Description</Label>
                                    <CKEditors
                                        config={editorConfiguration}
                                        disabled={true}
                                        activeclassName="p10"
                                        content={sellerDetails.storeDescription}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <p className="sub-title">Seller</p>
                                {
                                    sellerDetails.sellerDescriptionHeading &&
                                    <FormGroup>
                                        <Label className="form-label font-weight-bold">Heading</Label>
                                        <p>{sellerDetails.sellerDescriptionHeading}</p>
                                    </FormGroup>
                                }

                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Description</Label>
                                    <CKEditors
                                        config={editorConfiguration}
                                        disabled={true}
                                        activeclassName="p10"
                                        content={sellerDetails.sellerDescription}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <p className="sub-title">Other Information</p>
                                {
                                    sellerDetails.otherInformationHeading &&
                                    <FormGroup>
                                        <Label className="form-label font-weight-bold">Heading</Label>
                                        <p>{sellerDetails.otherInformationHeading}</p>
                                    </FormGroup>
                                }

                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Description</Label>
                                    <CKEditors
                                        config={editorConfiguration}
                                        disabled={true}
                                        activeclassName="p10"
                                        content={sellerDetails.otherInformation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <p className="sub-title">Other Policy Heading</p>
                                {
                                    sellerDetails.otherPolicyHeading &&
                                    <FormGroup>
                                        <Label className="form-label font-weight-bold">Heading</Label>
                                        <p>{sellerDetails.otherPolicyHeading}</p>
                                    </FormGroup>
                                }

                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Description</Label>
                                    <CKEditors
                                        config={editorConfiguration}
                                        disabled={true}
                                        activeclassName="p10"
                                        content={sellerDetails.otherPolicy}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Delivery Days</Label>
                                    <p>{sellerDetails.deliveryDays?sellerDetails.deliveryDays:"-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Delivery Method</Label>
                                    <p>{sellerDetails.deliveryMethodName?sellerDetails.deliveryMethodName:"-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Delivery Time</Label>
                                    <p>{sellerDetails.deliveryTime?sellerDetails.deliveryTime:"-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Return Days</Label>
                                    <p>{sellerDetails.returnDays?sellerDetails.returnDays:"-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Refund Days</Label>
                                    <p>{sellerDetails.refundDays?sellerDetails.refundDays:"-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Seller Since</Label>
                                    <p>{sellerDetails.sellerSince?sellerDetails.sellerSince:"-"}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};