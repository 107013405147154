import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
   createSellerProductListDataAPi,
   getSellerProductListDataAPi,
   getSellerProductSettingsApi
} from "../../../services/seller";


export const getSellerProductListData = createAsyncThunk('sellerProductListData/getAllProducts', async (data, {dispatch})=>{
   const response = await getSellerProductListDataAPi(data);
   dispatch(setTableRowCount(response.response.pagination.total));
   return response.response.data;
});

export const getSellerProductSettings = createAsyncThunk('sellerProductListData/getSettings', async (data,{dispatch})=> {
   const response = await getSellerProductSettingsApi();
   return response.response;
});

export const createSellerProductListData = createAsyncThunk('sellerProductListData/getAllProducts', async (data, {dispatch})=>{
   const response = await createSellerProductListDataAPi(data);
   return response.response;
});


export const sellerProductListDataAdapter = createEntityAdapter({});

export const {selectAll: geSellerProductListData, selectById: selectSellerProductDataById} = sellerProductListDataAdapter.getSelectors(
    state => state.seller.sellerProductListData
);

export const sellerProductListDataSlice = createSlice({
   name: 'sellerProductList',
   initialState: sellerProductListDataAdapter.getInitialState({
      sellerProductListDataTableRow:0,
      sellerProductSettings:[]
   }),
   reducers: {
      setTableRowCount: (state, action) => {
         state.sellerProductListDataTableRow = action.payload
      },
      sellerProductSettings: (state, action) => {
         state.sellerProductSettings = action.payload
      }
   },
   extraReducers: {
      [getSellerProductListData.fulfilled]: sellerProductListDataAdapter.setAll
   }
});

export const {setTableRowCount,sellerProductSettings} = sellerProductListDataSlice.actions;

export default sellerProductListDataSlice.reducer;