import React from 'react';
import CommonModal from "../../../../share-components/ui/commonModal";
import {setActionDialog} from "../../store/prodcutSlice";
import {useDispatch} from "react-redux";
import SaleOffer from "./action/saleOffer";


 function ProductAction(props){
    const {state,selectedProductId,setSelectedProductId,productFilter,selectAllRef,setSelectedPage} = props;
     const dispatch = useDispatch();
    const closeDialog=()=>{
        let obj = {props: {isOpen: false}};
        dispatch(setActionDialog(obj))
    }


    return(
      <>
          <CommonModal
              closeDialog={closeDialog}
              modalProps={state.actionDialogProps}
              // schema={schema}

          >
             <SaleOffer setSelectedPage={setSelectedPage} selectAllRef={selectAllRef} productFilter={productFilter}  selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} closeDialog={closeDialog} state={state}></SaleOffer>
          </CommonModal>
      </>
    )
}

export default ProductAction;