import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";


export const ViewExtraInformation = (props) => {
    const {sellerDetails}=props;
    return (
        <Fragment>
            <Col sm="8">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-success">Extra Information</div>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Minimum Order</Label>
                                    <p>{sellerDetails.minOrder}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Delivery Info</Label>
                                    <p>{sellerDetails.deliveryInfo}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Social Facebook Url</Label>
                                    {sellerDetails.socialFacebook?<a href={"https://"+sellerDetails.socialFacebook} target="_blank" style={{display:'block'}} rel="noopener noreferrer"><i className= "icon-link"></i> Facebook</a>:null}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Social Instagram Url</Label>
                                    {sellerDetails.socialInstagram?<a href={"https://"+sellerDetails.socialInstagram} target="_blank" style={{display:'block'}} rel="noopener noreferrer"><i className= "icon-link"></i> Instagram</a>:null}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Social Twitter Url</Label>
                                    {sellerDetails.socialTwitter?<a href={"https://"+sellerDetails.socialTwitter} target="_blank" style={{display:'block'}} rel="noopener noreferrer"><i className= "icon-link"></i> Twitter</a>:null}
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Snapppt Instagram Widget Code</Label>
                                    <p>{sellerDetails.instagramWidgetCode}</p>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Charge Taxes On Products</Label>
                                    <p>{sellerDetails.chargeTax?"Yes":"No"}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};