import {Price} from "../../../../../../constant";

export const singlePackCalculation = (value = null, datafield = null, dataSizeRatio, subGrid, gridRef, index, packSingle = null) => {
    if (dataSizeRatio.length > 0 && subGrid.current[index]) {
        const ratio = datafield ? dataSizeRatio.find(x => x.size == datafield).ratio : dataSizeRatio[0].ratio;
        const pack = value ? value / Number(ratio) : 0;
        let minimumPack = value ? Math.floor(pack) : "NAN";
        let totalAggregate = 0;
        let totalPack = 0;
        let totalSingle = 0;
        let totalPieceInPack = 0;
        ;

        packSingle = packSingle ? packSingle : gridRef.getcellvalue(index, 'pack_for_single');
        dataSizeRatio.map((rationItem, rationIndex) => {
            if (datafield != rationItem.size) {
                let otherSizeTotal = subGrid.current[index].getcellvalue(0, rationItem.size);
                let otherSizePack = ((otherSizeTotal ? otherSizeTotal : 0)) / Number(rationItem.ratio);
                minimumPack = Math.floor(minimumPack == "NAN" || minimumPack > otherSizePack ? otherSizePack : minimumPack);
            }
        });

        if (minimumPack > 0) {
            if (packSingle > minimumPack) {
                packSingle = minimumPack;
            }
            minimumPack = minimumPack - packSingle;
        }
        dataSizeRatio.map((rationItem, rationIndex) => {
            const totalValue = datafield == rationItem.size ? value : subGrid.current[index].getcellvalue(0, rationItem.size);

            const pack = minimumPack * Number(rationItem.ratio);
            const single = (totalValue ? totalValue : 0) - (minimumPack * Number(rationItem.ratio));
            totalAggregate = totalAggregate + Number((totalValue ? totalValue : 0));
            totalPack = totalPack + pack;
            totalSingle = totalSingle + single;
            totalPieceInPack = totalPieceInPack + Number(rationItem.ratio);

            subGrid.current[index].setcellvalue(1, rationItem.size, pack);
            subGrid.current[index].setcellvalue(2, rationItem.size, single);
        });
        subGrid.current[index].setcellvalue(0, 'total_qty', totalAggregate);
        subGrid.current[index].setcellvalue(1, 'total_qty', totalPack);
        subGrid.current[index].setcellvalue(2, 'total_qty', totalSingle);
        gridRef.setcellvalue(index, 'pack_qty', minimumPack);
        gridRef.setcellvalue(index, 'no_of_total_pack', Number(minimumPack) + Number(packSingle));
        gridRef.setcellvalue(index, 'no_piece_in_pack', totalPack);
        gridRef.setcellvalue(index, 'place_in_packs', totalPieceInPack);
        gridRef.setcellvalue(index, 'pack_for_single', packSingle);


    }


}
export const singleCalculation = (dataSizeRatio, value, datafield, index, subGrid) => {
    if (dataSizeRatio.length > 0) {
        let totalAggregate = 0;

        dataSizeRatio.map((rationItem, rationIndex) => {
            totalAggregate += Number(datafield == rationItem.size ? value : subGrid.current[index].getcellvalue(0, rationItem.size));
        });
        subGrid.current[index].setcellvalue(0, 'total_qty', totalAggregate);
    }

}

export const singlePriceCalculation = (price, isPercentage) => {
    let pricePercentage = isPercentage ? price * 0.1025 : 0;
    return isPercentage ? roundTwoDecimal(price + pricePercentage) : price;

}
export const packPriceCalculation = (price, noOfPack) => {

    return roundTwoDecimal(price * noOfPack);


}
const roundTwoDecimal = (num) => {
    return (+(Math.round(num + "e+1") + "e-1")).toFixed(2);
}
export const productShow = (currentPage, pagePerProduct, noOfProduct) => {
    const startPosition = currentPage * pagePerProduct + 1;
    const endPosition = (startPosition + noOfProduct) - 1;

    return startPosition + " to " + endPosition
}
export const pricePercentage = (price, percentage) => {
    const pricePercentage=price*(percentage/100);
    return price - pricePercentage;
}
