import {Badge, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment, useState} from "react";
import moment from "moment";

const textStyles = {
    marginLeft: '10px',
    marginTop: '3px',
    background: '-webkit-linear-gradient(#000000, #d2b2b2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

export const CardRecentOrders = (props) => {
    const {dashboardState,history, seller}=props;
    return (
        <Fragment>
            {dashboardState.recentOrders.length>0?
            <Col style={{height:"min-content"}}>
                <Card className="h-75">
                    <CardHeader>
                    <Row>
                        <h5>Recent Orders</h5>
                        <h6 style={textStyles}>{seller? seller.label:''} </h6>
                    </Row>
                        
                        <p className="font-roboto">Last updated {dashboardState.lastOrderUpdateTime?moment(dashboardState.lastOrderUpdateTime).fromNow():""}</p>
                    </CardHeader>
                    <CardBody className="overflow-auto">
                        <div className="table-responsive">
                            <Table className="table table-borderless table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Total (£)</th>
                                    <th scope="col">View</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dashboardState.recentOrders? dashboardState.recentOrders.map((item, i) =>
                                    <tr key={i}>
                                        <th scope="row">{item.orderName}</th>
                                        <td>{item.orderDate}</td>
                                        <td><Badge className="text-capitalize" color={item.fulfillmentStatus==="fulfilled"?"success":"dark"}>{item.fulfillmentStatus}</Badge></td>
                                        <td>{item.total}</td>
                                        <td><button onClick={()=>{ history.push('/order/' + item.id + '/view')}} className="btn btn-light"><i className= "icon-eye"></i></button></td>
                                    </tr>
                                ):null}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={()=>{history.push('/order')}}  type="button" className="btn btn-primary">View All Orders</button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Col>:
                null}
        </Fragment>
    );
};