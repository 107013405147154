import React, {useState, useEffect,useRef} from "react";
import {useDispatch} from "react-redux";
import {Col, Table, Modal, ModalBody, ModalHeader, Row, Badge, Label,Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    } from "reactstrap";

import {closeProductInfoDialog} from "../../../store/prodcutSlice";


function ViewProductInfo(props) {
    const dispatch = useDispatch();
   
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    
    
    const {
        size='xl',
        productStates
    } = props;

    const closeModalDialog = () => {
       dispatch(closeProductInfoDialog());
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === productStates.productInfoSet.images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? productStates.productInfoSet.images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = productStates.productInfoSet.images.map((item) => {
        return (
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <img src={item.src} alt={item.altText}  style={{height: '350px', width: '350px'}}/>
        </CarouselItem>
        );
    });


return (

    <div className="flex flex-col modal-lg">
        <Modal size={size} {...productStates.ProductDialogs.props} toggle={() => closeModalDialog()} >
            <ModalHeader toggle={() => {closeModalDialog()}}>
               <strong>Products Info</strong>
            </ModalHeader>

            <ModalBody>

            <Row  className="product-page-main">
                <Col sm="4">

                {productStates.productInfoSet.images.length === 0 ? (
                <img src="https://www.nbmchealth.com/wp-content/uploads/2018/04/default-placeholder.png" alt="Default Image" style={{height: '350px', width: '350px'}} />
                 ) : (
                 <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                >
                <CarouselIndicators
                items={productStates.productInfoSet.images}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel> 
            )}

                </Col>
                <Col sm="8">
                    <h3 style={{color: 'black', marginBottom: '15px'}}>{productStates.productInfoSet.title}</h3>

                    <p style={{color: 'black',}}>{productStates.productInfoSet.description}</p>

                    <Row>
                        <Col sm="5">
                        <Label style={{color: 'black',marginTop: '15px'}}><strong>Product Type :</strong> {productStates.productInfoSet.product_type}</Label>
                        </Col>
                        <Col sm="5">
                        <Label style={{color: 'black',marginTop: '15px',}}><strong>Vendor :</strong> {productStates.productInfoSet.vendor}</Label>
                        </Col>
                    </Row>

                    <hr style={{borderBlockColor: '#00cc66'}} />
               
                    {productStates.productInfoSet.options.map((option, index) => (
                        <Row key={index}>
                            <Col sm="1" key={index} style={{marginBottom: "10px"}}>
                                <Label >{option.name}</Label>  
                            </Col>
                            <Col>
                                {option.values.map((value, valueIndex) => (
                                <Badge color="success" key={`${index}-${valueIndex}`}>
                                    {value}
                                </Badge>
                                ))}
                            </Col>
                        </Row>
                    ))}
    
                </Col>
            </Row>
 
                <Table striped hover style={{marginTop: "30px", marginBottom: '30px'}}>
                    <thead>
                        <tr className="table-dark">
                        <th scope="col">SKU</th>
                        <th scope="col">Option 1</th>
                        <th scope="col">Option 2</th>
                        <th scope="col">Inventory Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Compare at Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productStates.productInfoSet.variants.map((item, i) =>
                        <tr key={i}>
                            <td>{item.sku}</td>
                            <td>{item.option1}</td>
                            <td>{item.option2}</td>
                            <td>{item.inventory_quantity}</td>
                            <td>{item.price}</td>
                            <td>{item.compare_at_price}</td>
                        </tr>
                        )}
                    </tbody>
                </Table>
                
            </ModalBody>      
        </Modal>
    </div>
)}

export default ViewProductInfo