import API from "../utils/api";


export const getSelectedSellerVatApi = async (id) => {
    const response = await API.get(`seller-vat/seller-vat/${id}`);
    return { response };
};

export const addSellerVatApi = async (data) => {
    const response = await API.post(`seller-vat/seller-vat`, data);
    return { response };
};
export const updateSellerVatApi = async (data, id) => {
    const response = await API.put(`seller-vat/seller-vat/${id}`,data);
    return { response };
};

export const getCountryListApi = async () => {
    const response = await API.get(`seller-vat/country-list`);
    return { response };
}

export const getAllSellersApi = async () => {
    const response = await API.get(`shipping-zone/get-all-sellers`);
    return { response };
}
