import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    getOrderSettingsApi,
    storeReturnReasonApi,
    deleteReturnReasonApi,
    updateReturnReasonApi
} from "../../../../services/returnReasonSetting";
import {toast} from "react-toastify";

export const getOrderSettings=createAsyncThunk('orderSettings/getOrderSettingData',async(_,{dispatch})=>{
    const response=await getOrderSettingsApi();
    return response.response.returnReasons;
});
export const storeReturnReason=createAsyncThunk('orderSettings/storeReturnReasonData',async(data,{dispatch})=>{
    const response=await storeReturnReasonApi(data);
    const res=response.response;
    dispatch(getOrderSettings());
    toast.success("Added return reason successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
})
export const updateReturnReason=createAsyncThunk('orderSettings/updateReturnReasonData',async(data,{dispatch})=>{
    const response= await updateReturnReasonApi(data.id,data);
    const res=response.response;
    dispatch(getOrderSettings());
    toast.success("Updated return reason successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
})
export const deleteReturnReason=createAsyncThunk('orderSettings/deleteReturnReasonData',async(id,{dispatch})=>{
    const response=await deleteReturnReasonApi(id);
    const res=response.response;
    dispatch(getOrderSettings());
    toast.success("Deleted return reason successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return res;
});

const returnReasonSettingAdapter=createEntityAdapter();
const returnReasonSettingSlice=createSlice({
    name:'returnReasonSetting',
    initialState:returnReasonSettingAdapter.getInitialState({
        addDialog: {
            component: 'returnReasonSetting',
            type: 'new',
            props: {
                open: false
            },
            data: null
        }
    }),
    reducers:{
        openNewDialog: (state,action)=>{
            state.addDialog = {
                component: 'returnReasonSetting',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getOrderSettings.fulfilled]:returnReasonSettingAdapter.setAll
    }
    
})
export  const {openNewDialog,openEditDialog,closeEditDialog}=returnReasonSettingSlice.actions
export default returnReasonSettingSlice.reducer;