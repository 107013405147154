import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addSizeMatrixConfigurationsApi, deleteSizeMatrixConfigurationsApi, getSelectedSizeMatrixOrderApi,
    getSizeMatrixConfigurationsApi, setSizeOrderApi, updateSizeMatrixConfigurationsApi
} from "../../../services/manage-product/setting/sizeMatrixConfigurationService";
import {toast} from "react-toastify";


export const getAllSizeMatrixConfiguration = createAsyncThunk('sizeMatrixConfiguration/getSizeMatrixConfiguration', async (filters, {dispatch}) =>{
    const response = await getSizeMatrixConfigurationsApi(filters);
    return response.response;
});

export const getSelectedSizeMatrixOrder = createAsyncThunk('sizeMatrixConfiguration/setMatrixOrder', async (id, {dispatch}) =>{
    const response = await getSelectedSizeMatrixOrderApi(id);
    return response.response;
});

export const addSizeMatrixConfiguration = createAsyncThunk('sizeMatrixConfiguration/addSizeMatrixConfiguration', async (sizeMatrix,{
    dispatch,
    getState
}) => {
    try {
        const response = await addSizeMatrixConfigurationsApi(sizeMatrix);
        const data = await response.response;
        dispatch(getAllSizeMatrixConfiguration());
        toast.success("Add size configuration successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateSizeMatrixConfiguration = createAsyncThunk('sizeMatrixConfiguration/updateSizeMatrixConfiguration', async (sizeMatrix, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateSizeMatrixConfigurationsApi(sizeMatrix, sizeMatrix.id);
        const data = await response.response;
        dispatch(getAllSizeMatrixConfiguration());
        toast.success("Update size configuration successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteSizeMatrixConfiguration = createAsyncThunk('sizeMatrixConfiguration/deleteSizeMatrixConfiguration', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteSizeMatrixConfigurationsApi(id);
        const data = await response.response;
        dispatch(getAllSizeMatrixConfiguration());
        toast.success("Delete size configuration successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const setSizeOrder = createAsyncThunk('sizeMatrixConfiguration/setSizeOrder', async (sizeOrder, {dispatch})=>{
    const response = await setSizeOrderApi(sizeOrder, sizeOrder.id);
    const data = await response.response;
    return data;

});

const sizeMatrixConfigurationAdapter = createEntityAdapter({});

export const {selectAll:selectSizeMatrixConfiguration, selectById: selectSizeMatrixConfigurationById} = sizeMatrixConfigurationAdapter.getSelectors(
    state => state.product.sizeMatrixConfiguration
);

const sizeMatrixConfigurationSlice = createSlice({
    name:'sizeMatrixConfiguration',
    initialState: sizeMatrixConfigurationAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'sizeMatrixConfiguration',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        sortDialogProps:{
            props: {
                isOpen: false
            },
        },
        matrixData: {
            sizeMetrics: []
        }
    }),
    reducers: {
        selectSizeMatrixConfigurationSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'sizeMatrixConfiguration',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setSortDialog: (state, action) => {
            state.sortDialogProps = action.payload
        },
        setMatrixData: (state, action) => {
            state.sizeMetrics = action.payload
        }


    },
    extraReducers: {
        [getAllSizeMatrixConfiguration.fulfilled]: sizeMatrixConfigurationAdapter.setAll
    }
});

export const {
    selectSizeMatrixConfigurationSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog,
    setSortDialog,
    setMatrixData,


} = sizeMatrixConfigurationSlice.actions;

export default sizeMatrixConfigurationSlice.reducer;