import React, { Fragment } from "react";
import CommonDataTable from "../../../../../share-components/table/commonDataTable";
import { numberWithCommasWithoutPounds } from "../../../../../utils/common";
import { Table } from "reactstrap";

const SaleOrderProductJqx = (props) => {
  const { getValues } = props;

  const tableColumns = [
    {
      name: "Product",
      selector: (row) => row.product_name,
      sortable: true,
      width: "30%",
      center: true,
      cellsalign: "left",
      align: "center",
      cell: (row) => {
        return (
          <div style={{ textAlign: "center" }}>
            <div className="row">
              <div className="col-md-4">
                <img
                  className="img-fluid"
                  src={row.product_image}
                  width="40"
                  alt=""
                />
              </div>
              <div className="col-md-8 pt-2" style={{ textAlign: "center" }}>
                <div className="row">
                  <p className={"font-primary"}>{row.product_name}</p>
                </div>
                <div className="row">
                  <p>{row.variant_name}</p>
                </div>
                <div className="row">
                  <p>{row.sku}</p>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Available",
      selector: (row) => row.stock,
      sortable: true,
      center: false,
    },
    {
      name: "Back Ordered",
      selector: (row) => row.back_order_quantity,
      sortable: true,
      center: false,
    },
    {
      name: "Order Qty",
      selector: (row) => row.quantity,
      sortable: true,
      center: false,
    },
    // {
    //   name: "Invoiced Qty",
    //   selector: (row) => row.date,
    //   sortable: true,
    //   center: false,
    // },
    // {
    //   name: "Fulfilled Qty",
    //   selector: (row) => row.date,
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Tax",
      selector: (row) => row.date,
      sortable: true,
      center: false,
      cell: (row) => {
        const isTaxGreaterThanZero = row.tax_amount > 0;

        return (
          <div>
            <input type="checkbox" checked={isTaxGreaterThanZero} disabled />
          </div>
        );
      },
    },
    {
      name: "Tax Amount",
      selector: (row) => row.tax_amount,
      sortable: true,
      width: "200px",
      center: true,
      cell: (row) => {
        return (
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <p className="font-primary text-left font-weight-bolder">
                      Sub
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="text-right">
                      {numberWithCommasWithoutPounds(row.total_price)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="font-primary text-left font-weight-bolder">
                      Tax
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="text-right">
                      {numberWithCommasWithoutPounds(row.tax_amount)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="font-primary text-left font-weight-bolder">
                      Gross
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="text-right">
                      {numberWithCommasWithoutPounds(row.gross_amount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const productsResponseToTable = (response) => {
    return response.map((res) => {
      return {
        product_id: res.product_id,
        product_image: res.image,
        variant: res.variant,
        total_price: res.total_price,
        tax_amount: res.tax_amount,
        product_name: res.product_name,
        stock: res.stock,
        back_order_quantity: res.back_order_quantity,
        quantity: res.quantity,
        location: res.location,
        gross_amount: res.gross_amount,
      };
    });
  };

  return (
    <Fragment>
      <div className="table-responsive product-table mt-4">
        <CommonDataTable
          headerColumns={tableColumns}
          gridData={productsResponseToTable(getValues("ordered_products"))}
          noAction={true}
          pagination
        />
      </div>
    </Fragment>
  );
};

export default SaleOrderProductJqx;
