import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Col, Container, Form} from "react-bootstrap";
import {Card, CardBody, FormGroup, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapSellerLeadResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import {getAllSellerLeads, selectSellerLead} from "../store/sellerLeadSlice";
import withReducer from "../../../store/withReducer";
import leadReducer from "../store";
import {useHistory} from "react-router-dom";
import PreLoader from "../../../share-components/ui/preLoader";

const dataMenu = [
    {
        type: 0,
        text: 'Leads',
        link: '/leads/seller-lead'
    },
    {
        type: 1,
        text: 'Seller Leads',
        link: ''
    },
];

const tableColumns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        left: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        left: true,
    },
    {
        name: 'Mobile Number',
        selector: row => row.mobile_number,
        sortable: true,
        left: true,
    },
];

const SellerLead = () => {

    const dispatch = useDispatch();
    let history = useHistory();

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [loading, setLoading] = useState(true);
    const sellerLead = useSelector(selectSellerLead);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);


    useEffect(() => {
        const data = {
            name,
            email,
            mobile
        }
        dispatch(getAllSellerLeads({...data})).then(res=> {
            if (!res.error)
                setLoading(false);
        });
    }, [dispatch, email, name, mobile])

    const onEdit = (row) => {
        history.push('/leads/seller-lead/' + row.id + '/edit');
    };

    const onDelete = (row) => {

    };

    return (
        <Fragment>

            <Container fluid={true}>
                {loading ? <><PreLoader></PreLoader></> : <>
                <Breadcrumb data={dataMenu} title="Seller Leads"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12" xl="4">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setName(e.target.value)}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Name"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="12" xl="4">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Email"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="12" xl="4">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setMobile(e.target.value)}
                                            name="name"
                                            type="number" className="form-control"
                                            placeholder="Search By Mobile"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <CommonDataTable
                                                headerColumns={tableColumns}
                                                gridData={mapSellerLeadResponseToTable(sellerLead)}
                                                pagination
                                                onEdit={(currentUser.permission).includes('Update Seller Lead') ? onEdit:''}
                                                onDelete={(currentUser.permission).includes('Delete Seller Lead') ? onDelete:''}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                </>}
            </Container>
        </Fragment>
    );
}

export default withReducer([{lead: leadReducer}])(SellerLead);