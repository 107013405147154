import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {defaultValues} from "../settings";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {addSellerLead} from "../store/sellerLeadSlice";
import {useDispatch} from "react-redux";
import {
    mapSellerLeadResponseToBusinessDetailsForm,
    mapSellerLeadResponseToCompanyProfileForm, mapSellerLeadResponseToWearhouseDetailsForm
} from "../../../utils/mapper";
import {useHistory} from "react-router-dom";
import {setSaveState} from "../store/buyerLeadSlice";

export const schema = yup.object().shape({
    address_line_2_1: yup.string().required("Address line 1 is required").typeError('Address line 1 is required'),
    town_2: yup.string().required("Town is required").typeError('Town is required'),
    state_2: yup.string().required('State is required field').typeError('State is required'),
    postcode_2: yup.string().required('Postcode is required field').typeError('Postcode is required'),
});

export const WearhouseDetails = (props) => {
    const {sellerLeadState,id,VerticleTab,setVerticleTab,formData}=props;
    const dispatch = useDispatch();

    const [actionBtn,setActionBtn]=useState(0);
    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const submitBtnRef = useRef();
    const {errors} = formState;
    let history = useHistory();
    const submitBtnSaveRef = useRef();

    const onSubmitForm = (data) => {
        submitBtnSaveRef.current.disabled=true;
        const finalData = {
            id: id,
            formData: data,
            type: 3
        }
        dispatch(addSellerLead({...finalData})).then(res=> {
            submitBtnSaveRef.current.disabled=false;
            if (!res.errors)
                if (actionBtn == 0) {
                    setVerticleTab((parseInt(VerticleTab) + 1).toString());
                    dispatch(setSaveState(true));
                } else {
                    history.push('/leads/seller-lead');
                }
        })
    }

    useEffect(()=> {
        if (formData.length != 0) {
            reset(mapSellerLeadResponseToWearhouseDetailsForm(formData))
        }
    }, [formData])


    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Address Line 1</Label>
                        <input name="address_line_2_1" type="text" className={`form-control ${errors.address_line_2_1 ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('address_line_2_1')}  />
                        <div className="invalid-feedback">{errors.address_line_2_1?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Address Line 2</Label>
                        <input name="address_line_2_2" type="text" className={`form-control ${errors.address_line_2_2 ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('address_line_2_2')}  />
                        <div className="invalid-feedback">{errors.address_line_2_2?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Town</Label>
                        <input name="town_2" type="text" className={`form-control ${errors.town_2 ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('town_2')}  />
                        <div className="invalid-feedback">{errors.town_2?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">State</Label>
                        <input name="state_2" type="text" className={`form-control ${errors.state_2 ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('state_2')}  />
                        <div className="invalid-feedback">{errors.state_2?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">PostCode/Zip</Label>
                        <input name="postcode_2" type="text" className={`form-control ${errors.postcode_2 ? 'is-invalid' : ''}`} placeholder="John Doe" {...register('postcode_2')}  />
                        <div className="invalid-feedback">{errors.postcode_2?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="form-label">Country</Label>
                        <Select placeholder="United Kingdom" getOptionLabel ={(option)=>option.name} value={getValues('address_country_2')}
                                getOptionValue ={(option)=>option.id} {...register("address_country_2")} onChange={(e)=>{
                            setValue('address_country_2',e);
                            trigger('address_country_2');
                        }} className={` ${errors.address_country_2 ? 'is-invalid' : ''}`} options={sellerLeadState.settings.countries} />
                        <div className="invalid-feedback">{errors.address_country_2?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
                <div className="mt-2 d-flex justify-content-end">
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={()=>{setActionBtn(1)}} className="btn btn-danger btn-block">Save & Exit</button>
                    </div>
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={()=>{setActionBtn(0)}}  className="btn btn-primary btn-block">Save & Next</button>
                    </div>
                </div>
            </Form>
        </Fragment>
    );
}