import moment from "moment";
import { Card, CardBody } from "reactstrap";
import React from "react";

export function editorConfiguration(data = 150) {
  return {
    toolbar: [
      { name: "clipboard", groups: ["clipboard", "undo"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },
      { name: "styles", groups: ["styles"] },
      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
      { name: "document", groups: ["mode", "document", "doctools"] },
      "/",
      "/",
    ],
    height: data,
    removeButtons:
      "Find,Replace,Scayt,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,About,Styles",
  };
}

export function getFileExtension(url) {
  const uri = url.split("?")[0];
  return uri.substring(uri.lastIndexOf("."));
}

export function getProductRibbonColor(status) {
  let statuscolor = [];
  statuscolor[1] = "ribbon-warning";
  statuscolor[2] = "ribbon-primary";
  statuscolor[3] = "ribbon-success";
  statuscolor[4] = "ribbon-info";
  statuscolor[5] = "ribbon-danger";
  return statuscolor[status];
}
export function numberWithCommas(x) {
  x = x ? x.toFixed(2) : "";
  return x
    ? "£ " + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "£ 0.00";
}
export function numberWithCommasWithoutPounds(x) {
  x = x ? x.toFixed(2) : "";
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
}
export function dateFormat(date) {
  return moment(date).format("YYYY-MM-DD");
}
export function convertDateFormat(date) {
  return date ? new Date(date) : "";
}
export function isObjectEqual(obj1, ob2) {
  if (obj1 && ob2) {
    delete obj1["type"];
    delete ob2["type"];
    return JSON.stringify(obj1) === JSON.stringify(ob2);
  } else {
    return false;
  }
}
export const logos = [
  {
    domain: "localhost",
    mainIcon: "logo-full-city-goddess.png",
    smallIcon: "small-tradegala.png",
  },
  {
    domain: "cgqa.mycitygoddess.com",
    mainIcon: "logo-full-city-goddess.png",
    smallIcon: "cg-logo.png",
  },
  {
    domain: "cg.mycitygoddess.com",
    mainIcon: "logo-full-city-goddess.png",
    smallIcon: "cg-logo.png",
  },
  {
    domain: "tg.tradegala.net",
    mainIcon: "main-tradegala.png",
    smallIcon: "small-tradegala.png",
  },
  {
    domain: "tgqa.tradegala.net",
    mainIcon: "main-tradegala.png",
    smallIcon: "small-tradegala.png",
  },
  {
    domain: "mpqa.mygoddiva.com",
    mainIcon: "goddiva-main.png",
    smallIcon: "goddiva-small.png",
  },
  {
    domain: "mp.mygoddiva.com",
    mainIcon: "goddiva-main.png",
    smallIcon: "goddiva-small.png",
  },
  {
    domain: "jtqa.mycitygoddess.com",
    mainIcon: "jerseytex-full.png",
    smallIcon: "jerseytex-small.png",
  },
  {
    domain: "mpqa.mycitygoddess.com",
    mainIcon: "logo-full-city-goddess.png",
    smallIcon: "cg-logo.png",
  },
];

export function getDefaultSeller(){
    return JSON.parse(localStorage.getItem('seller'));
    
}
export function optionDefaultValue(options, optionDefaultValue) {
  console.log(options);
  return options.find((option) => option.name === optionDefaultValue);
}
export function downloadBlobAsXLSX(blob, fileName) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([blob]));
  link.download = fileName;

  // Append the link to the document body
  document.body.appendChild(link);

  // Trigger the download by simulating a click event
  link.click();

  // Clean up resources
  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
}

export const salesOrderStatus = [
//   {
//     id: "0",
//     name: "Order Confirmed",
//   },
  {
    id: "1",
    name: "Pending Picking",
  },
  {
    id: "2",
    name: "Pending Billing",
  },
  {
    id: "3",
    name: "Pending Packing",
  },
  {
    id: "4",
    name: "Pending Shipping",
  },
  {
    id: "5",
    name: "Shipped",
  },
];

export function findIndexes(arr, condition) {
    return arr.map((element, index) => (condition(element) ? index : -1)).filter(index => index !== -1);
}

export  function  CarouseNavigation(props) {

    const {className, style, onClick,icon} = props;
    return (
        <div className="p-1">
            <Card className="pl-0 pr-0">
                <CardBody className="pl-0 pr-0">
                    <button className="btn btn-default" onClick={onClick}><i className={`fa ${icon} fa-3x`}></i>
                    </button>
                </CardBody>
            </Card>
        </div>
    );
};