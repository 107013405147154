import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Form,
} from "reactstrap";

import {Link, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {CarouseNavigation} from "../../../../../utils/common";
import withReducer from "../../../../../store/withReducer";
import salesOrderReturnReducer from "../../../store";
import SaleOrderReturnProductJqx from "./content/saleOrderReturnProductJqx";
import {useDispatch, useSelector} from "react-redux";
import {
    getInvoiceByOrder,
    getInvoicePendingReturn,
    setOrderInvoices,
} from "../../../store/saleOrderInvoiceSlice";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PreLoader from "../../../../../share-components/ui/preLoader";
import salesOrderReturn, {
    getAllReturnReasons,
    addSalesOrderReturn,
    getAllReturns,
    setReturnSettings,
} from "../../../store/saleOrderReturnSlice";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <CarouseNavigation icon="fa-arrow-right"/>,
    prevArrow: <CarouseNavigation icon="fa-arrow-left"/>,
};
const defaultValues = {
    id: "",
    ordered_products: [],
};
const schema = yup.object().shape({
    // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    // shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});
const ReturnInvoice = (props) => {
    const {id, order_id, slug} = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [isSlickArrow, setIsSlickArrow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [orderedProducts, setOrderedProducts] = useState(
        defaultValues.ordered_products
    );
    const draftOrderState = useSelector(({order}) => order.draftOrder);
    const salesOrderInvoiceState = useSelector(
        (state) => state.order.salesOrderInvoice
    );
    const returnState = useSelector(({order}) => order.salesOrderReturn);
    const {
        handleSubmit,
        formState,
        reset,
        control,
        register,
        setValue,
        trigger,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const {errors, isDirty} = formState;

    useEffect(() => {
        dispatch(getInvoiceByOrder(order_id)).then((res) => {
            if (!res.error) {
                dispatch(setOrderInvoices(res.payload));
            }
        });
    }, [dispatch, order_id]);

    useEffect(() => {
        dispatch(getAllReturnReasons()).then((res) => {
            if (!res.error) {
                dispatch(setReturnSettings(res.payload));
            }
        });
    }, []);

    useEffect(() => {
        getPendingInvoiceOrder();
    }, [id]);

    const getPendingInvoiceOrder = () => {
        setLoading(true);
        dispatch(getInvoicePendingReturn(id)).then((res) => {
            if (!res.error) {
                setValue("ordered_products", res.payload);
                setLoading(false);
            }
        });
    };

    // const returnProduct = () => {
    //   alert("Product");
    // const selectedRowsIndexes = gridRef.getselectedrowindexes();

    // const selectedProducts = getValues("order_products").filter(
    //   (product, index) => selectedRowsIndexes.includes(index)
    // );

    // if (selectedProducts.length > 0) {
    //   SweetAlert.fire({
    //     title: "Are you sure you want to return this product?",
    //     text: "Once confirmed, the return request will be sent to the system!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, Proceed",
    //     cancelButtonText: "No, Cancel",
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       console.log("Selected ProductsX :- ", selectedProducts);
    //       //  dispatch(returnProducts(selectedProducts));
    //     }
    //   });

    //   // Clear the selection after dispatching the action
    //   gridRef.clearselection();
    //   gridRef.clearselection();
    // } else {
    //   toast.warning("Please select at least one product to return.");
    // }
    // };

    const onSubmit = (data) => {
        console.log(data);

        const returnData = {
            'sales_order_id': order_id,
            'sales_invoice_id': id,
            'product': data.ordered_products
                .filter(function (v, i) {
                    return v.is_selected;
                })
                .map(function (item, index) {
                    return {
                        id: item.id,
                        sales_order_invoice_sales_order_line_item_id: item.invoice_line_item_id,
                        requested_quantity: item.return_quantity,
                       'reason_id':item.reasonID
                    };
                }),
    }
        console.log(returnData);
    dispatch(addSalesOrderReturn({...returnData})).then((res) => {
        if (!res.error) {
            getPendingInvoiceOrder();

        }
    });
};

return (
    <>
        <Row className="mt-3">
            <Col md={12} className={isSlickArrow ? "slick-arrow" : ""}>
                <Slider {...settings}>
                    {salesOrderInvoiceState.orderInvoices.map((invoice, index) => (
                        <div className="p-1" key={index}>
                            <Card className={id == invoice.id ? "active-invoice" : ""}>
                                <CardBody style={{padding: "30px", textAlign: "center"}}>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/return/${invoice.id}`}
                                    >
                                        <p className="font-weight-bolder f-14">
                                            Invoice ID : {invoice.invoice_no}
                                        </p>
                                    </Link>
                                    <p>
                                        Date: {moment(invoice.created_at).format("YYYY-MM-DD")}
                                    </p>
                                </CardBody>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Col>
        </Row>
        <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Col md={12}>
                                {loading ? (
                                    <>
                                        <PreLoader></PreLoader>
                                    </>
                                ) : (
                                    <SaleOrderReturnProductJqx
                                        getValues={getValues}
                                        setValue={setValue}
                                        trigger={trigger}
                                        returnState={returnState}
                                        orderedProducts={getValues('ordered_products')}
                                    />
                                )}
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {getValues("ordered_products").filter(function (v, i) {
                return v.is_selected;
            }).length > 0 && (<Row className="py-4">
                <Col md={12}>
                    {/* <Card> */}
                    <div className="float-right">
                        <ButtonLoader
                            color="secondary"
                            value="1"
                            btntext="Save"
                        ></ButtonLoader>
                    </div>
                    {/* </Card> */}
                </Col>
            </Row>)};
        </Form>
    </>
);
}

export default withReducer([{salesOrderReturn: salesOrderReturnReducer}])(
    ReturnInvoice
);
