import React, {Fragment, useEffect, useRef, useState,} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Button, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import {
    getAdditionalSettings,
    openDialogs,
    setAdditionalDetails,
    setAdditionalDetailsSettings
} from "../../../store/prodcutSlice";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

const options = [
    {
        name: "Group 1",
        options: [
            {name: "option 1", id: "value_1"},
            {name: "option 2", id: "value_2"}
        ]
    },
    {name: "A root option", id: "value_3"},
    {name: "Another root option", id: "value_4"}
];
const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.name}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
const Composition = (props) => {
    const {id} = useParams();
    const {errors, register, setValue, trigger, getValues, productState} = props;
    const dispatch = useDispatch();
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'composition_id', type: 'id'},
        {name: 'composition', type: 'string'},
        {name: 'percentage', type: 'string'},

    ];

    const columns = [
        {datafield: 'composition_id', hidden: true},
        {
            text: 'Composition',
            datafield: 'composition',
            width: '60%',
            editable: true,
            columntype: 'template',
            createeditor: (row, cellvalue, editor, cellText, width, height) => {
                // alert(JSON.stringify(composition));

                ReactDOM.render(
                    <div style={{zIndex: 1000}}>
                        <Select getOptionLabel={(option) => option.name}

                                ref={(ref) => {
                                    selectRef = ref;
                                }}

                                menuPortalTarget={document.getElementById('root')}
                                styles={{menuPortal: base => ({...base, zIndex: 100}), width: width}}
                            //  menuPortalTarget={document.body}
                                autoFocus={true}
                                classNamePrefix={"my-custom-react-select"}
                                menuShouldScrollIntoView={true}
                                menuPlacement={'auto'}
                            // menuPosition={'fixed'}
                                getOptionValue={(option) => option.id}
                                className={`test-el`}
                                formatGroupLabel={formatGroupLabel}
                                onChange={(e) => {
                                    //gridRef.setcellvalue(row, 'composition_id', e.id);
                                    let element = document.getElementById("select-menu");
                                    element.classList.add("menu-open")

                                }}

                                options={productState.compositions}
                        /></div>,
                    editor[0]
                );
                setTimeout(() => {
                    //  selectRef!.focus()
                    selectRef.focus();
                }, 500, false)

            },
            initeditor: function (row, cellvalue, editor, celltext, pressedkey) {
                const compositionName = gridRef.getcellvalue(row, 'composition');
                const compositionId = gridRef.getcellvalue(row, 'composition_id');
                selectRef.setValue({id: compositionId, name: compositionName})
                setTimeout(() => {

                    selectRef.focus();
                }, 500, false)
            },
            geteditorvalue: function (row, cellvalue, editor) {
                return selectRef.getValue().length > 0 ? selectRef.getValue()[0].name : "";
            },
            validation(cell, value) {
                let found = 0;
                if (value) {
                    const gridInformation = gridRef.getdatainformation();
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < gridInformation.rowscount; i++) {

                        const rowData = gridRef.getrowdata(i);
                        if ((i != cell.row) && (rowData.composition)) {
                            if (rowData.composition == value) {
                                toast.error("Composition already added", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                return {result: false, message: "Composition already added"}
                            }
                        }

                    }
                }
                gridRef.setcellvalue(cell.row, 'composition_id',  selectRef.getValue().length > 0 ? selectRef.getValue()[0].id : "");
                console.log("GAga");
                gridRef.setcellvalue(cell.row, 'percentage', selectRef.getValue().length > 0 ? selectRef.getValue()[0].fabric_percentage : "");
                return true;
            }
        },
        {
            text: 'Percentage',
            datafield: 'percentage',
            editable: true,
            columntype: 'text',
            // cellsalign: 'right',
            width: '35%',
            validation(cell, value) {
                let found = 0;
                if (value) {
                    gridRef.setcellvalue(cell.row, 'percentage', value);
                }
                return true;
            }

        }];
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if(gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if(myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };
    const renderAddActionHeader=(elemet)=>{
        setTimeout(function (){
            // if(myGrid) {
            ReactDOM.render(
                <a onClick={() => {
                    gridRef.addrow(0, {});
                }
                }><span className='fa fa-plus'></span></a>,
                elemet
            );
            // }
        }, 500, false)
    };

    // const additionalDetails = () => {
    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //     // useEffect(() => {
    //         // if(productStates.addDialogs.props.isOpen) {
    //             dispatch(getAdditionalSettings(id)).then(res => {
    //                 if (!res.error) {
    //                     dispatch(setAdditionalDetailsSettings( res.payload.settings));
    //                     console.log(res.payload);
    //                     dispatch(setAdditionalDetails(res.payload.additionalDetails));
    //                     dispatch(openDialogs())
    //                     // reset(res.payload.additionalDetails);

    //                 }

    //             });
    //         // }

    //     // },[dispatch]);
    // }


    const divRef = useRef(null);

    const seDataToHookForm=()=>{
        let compositionData=[];
        let element = document.getElementById("select-menu");
        element.classList.remove("menu-open");
        const gridInformation = gridRef.getdatainformation();
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            console.log(rowData);
            compositionData.push(rowData)

        }
        setValue('compositions',compositionData)
        console.log(getValues('compositions'))
    }
    const cellEdit = event => {
        seDataToHookForm();

    };
    const onDelete = () => {
        seDataToHookForm();

    }



    return (
        <Fragment>
            <Card>

                <CardBody>

                    <div className="form-row mb-3">
                        <Col md="8">
                            <Label className="col-form-label">Commodity</Label>
                            <Select getOptionLabel={(option) => option.name}
                                    defaultValue={getValues('commodity')}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    getOptionValue={(option) => option.id} {...register("commodity")}
                                    onChange={(e) => {
                                        setValue('commodity', e);
                                        setValue('hs_code', e.hs_code);
                                        trigger('commodity');
                                    }} className={` ${errors.commodity ? 'is-invalid' : ''}`}
                                    options={productState.commodities}/>

                            <div className="invalid-feedback">{errors.commodity?.message}</div>
                        </Col>
                        <Col md="4">
                            <Label className="col-form-label">Weight</Label>
                            <div className="form-row mb-3">
                                <Col md="6 pr-0">
                                    <input name="code" type="text" {...register('weight')}
                                           className={`form-control ${errors.weight ? 'is-invalid' : ''}`}/>
                                </Col>
                                <Col md="6 pl-0">
                                    <Input type="select" name="select" onChange={(e)=>{
                                        setValue('unit_type',e.target.value);
                                    }}  className="form-control digits" defaultValue={getValues('unit_type')} >
                                        <option value="1">Kg</option>
                                        <option value="2">Grams</option>
                                    </Input>
                                </Col>
                            </div>
                            <div className="invalid-feedback">{errors.unit_type?.message}</div>
                        </Col>
                    </div>
                    <div className="form-row mb-3">
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Country Origin</Label>
                                <Select getOptionLabel={(option) => option.name}
                                        defaultValue={getValues('country')}
                                        classNamePrefix={"my-custom-react-select"}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        getOptionValue={(option) => option.id} {...register("country")}
                                        onChange={(e) => {
                                            setValue('country', e);
                                            trigger('country');
                                            // combineTags();
                                        }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                        options={productState.countries}/>

                                <div className="invalid-feedback">{errors.country?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">HS(Harmonized System) Code</Label>
                                <input name="hs_code" type="number" {...register('hs_code')}
                                       className={`form-control ${errors.hs_code ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.hs_code?.message}</div>
                            </FormGroup>
                        </Col>
                    </div>
                    <div className="form-row mb-3" ref={divRef}>
                        <Col md="12">
                            <Label className="col-form-label " id="select-menu">Product Composition</Label>
                            <div style={{width: '100%'}}>
                                {(productState.compositions).length > 0 && <JqxTable
                                    datafields={datafields}
                                    columns={columns}
                                    data={getValues('compositions')}
                                    myGrid={gridRef}
                                    getJqxRef={getJqxRef}
                                    isDelete
                                    renderAction={renderAddActionHeader}
                                    onCellendedit={cellEdit}
                                    onDelete={onDelete}
                                />}
                            </div>
                            <div id="test-element"></div>
                        </Col>
                    </div>
                   <hr/>
                    <div className="form-row mb-3" ref={divRef}>
                        <Col md="12">
                    <Button style={{marginBottom:"30px"}} className="font-weight-bold float-right" color="success" type="button"
                            onClick={() => {dispatch(openDialogs())}}
                            // onClick={() => {additionalDetails()}}
                    >
                        Additional Details
                    </Button>
                        </Col>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default Composition;