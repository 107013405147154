import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getBinsApi,
  addBinApi,
  updateBinApi,
  deleteBinApi,
  typeAutoSuggestionApi,
} from "../../../services/manage-product/setting/binService";
import { toast } from "react-toastify";

export const getAllBins = createAsyncThunk(
  "bin/getBin",
  async (filters, { dispatch }) => {
    const response = await getBinsApi(filters);
    return response.response.data;
  }
);

export const addBin = createAsyncThunk(
  "bin/addBin",
  async (bin, { dispatch, getState }) => {
    try {
      const response = await addBinApi(bin);
      const data = await response.response;
      dispatch(getAllBins());
      toast.success("Add bin successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateBin = createAsyncThunk(
  "bin/updateBin",
  async (bin, { dispatch, getStatus }) => {
    try {
      const response = await updateBinApi(bin, bin.id);
      const data = await response.response;
      dispatch(getAllBins());
      toast.success("Updated bin successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteBin = createAsyncThunk(
  "bin/Bin",
  async (id, { dispatch, getStatus }) => {
    try {
      const response = await deleteBinApi(id);
      const data = await response.response;
      dispatch(getAllBins());
      toast.success("Deleted bin successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const binAdapter = createEntityAdapter({});

export const {
  selectAll: selectBin,
  selectById: selectBinById,
} = binAdapter.getSelectors((state) => state.product.bin);

console.log(binAdapter);

const binSlice = createSlice({
  name: "bin",
  initialState: binAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "Bin",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setBinSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "Bin",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAllBins.fulfilled]: binAdapter.setAll,
  },
});

export const {
  setBinSearchText,
  openNewDialog,
  closeNewDialog,
  openEditDialog,
  closeEditDialog,
} = binSlice.actions;

export default binSlice.reducer;