import API from "../../../utils/api";


export const getCompositionGroupsApi = async (filters) => {
    const response = await API.get('settings/composition-group', {params:filters});
    return { response };
};

export const addCompositionGroupApi = async (location) => {
    const response = await API.post('settings/composition-group', location);
    return { response };
};

export const updateCompositionGroupApi = async (location,id) => {
    const response = await API.put(`settings/composition-group/${id}`, location);
    return { response };
};

export const deleteCompositionGroupApi = async (id) => {
    const response = await API.delete(`settings/composition-group/${id}`);
    return { response };
};