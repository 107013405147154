import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductCategoryApi,
    getProductCategoryApi, updateProductCategoryApi
} from "../../../services/manage-product/setting/productCategoryService";
import {
    addProductCommunityApi, deleteProductCommunityApi,
    getAllProductCommunityApi,
    updateProductCommunityApi
} from "../../../services/manage-product/setting/productCommunityService";
import {toast} from "react-toastify";
import {getAllProductCategories} from "./productCategorySlice";


export const getAllProductCommunities = createAsyncThunk('productCommunity/getProductCommunity', async (filters, {dispatch}) => {
    const response = await getAllProductCommunityApi(filters);
    return response.response;
});

export const addProductCommunity = createAsyncThunk('productCommunity/addProductCommunity', async (community, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductCommunityApi(community);
        const data = await response.response;
        dispatch(getAllProductCommunities());
        toast.success("Add product community successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductCommunity = createAsyncThunk('productCommunity/updateProductCommunity', async (community, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductCommunityApi(community, community.id);
        const data = await response.response;
        dispatch(getAllProductCommunities());
        toast.success("Update product community successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductCommunity = createAsyncThunk('productCommunity/deleteProductCommunity', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductCommunityApi(id);
        const data = await response.response;
        dispatch(getAllProductCommunities());
        toast.success("Delete product community successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productCommunityAdapter = createEntityAdapter({});

export const {
    selectAll: selectProductCommunity,
    selectById: selectProductCommunityById
} = productCommunityAdapter.getSelectors(
    state=> state.product.productCommunity
);

const productCommunitySlice = createSlice({
    name: 'productCommunity',
    initialState: productCommunityAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'productCommunity',
            type: 'new',
            props: {
                open: false
            },
            data: null
        }
    }),
    reducers: {
        selectProductCommunitySearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload:event.target.value || ''})
        },
        openNewDialog: (state,action)=>{
            state.addDialog = {
                component: 'productCommunity',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductCommunities.fulfilled]: productCommunityAdapter.setAll
    }
});

export const {
    selectProductCommunitySearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog

} = productCommunitySlice.actions;

export default productCommunitySlice.reducer;