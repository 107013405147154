import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useRef} from "react";
import Select from "react-select";

const TrackingForm = (props) => {
    const {errors,register, orderState, setValue, trigger}=props;
    return (
        <Fragment>
                <Row>
                    {/* <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking Name<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingName" type="text" className={`form-control ${errors.trackingName ? 'is-invalid' : ''}`} placeholder="DHL" {...register('trackingName')}  />
                            <div className="invalid-feedback">{errors.trackingName?.message}</div>
                        </FormGroup>
                    </Col> */}
                    <Col md={6}>
                        
                        <Label for="selectOption">Select Delivery Partner</Label>
                            <Select 
                                placeholder="Select Partners"
                                type="select"
                                name="trackingName"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                options={orderState.deliverPartner}
                                onChange={(e) => {
                                    setValue('trackingName',e);
                                    trigger('trackingName');
                                }} 
                                className={` ${errors.trackingName ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors.trackingName?.message}</div>
                        
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Tracking ID<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingId" type="text" className={`form-control ${errors.trackingId ? 'is-invalid' : ''}`} placeholder="2164116410" {...register('trackingId')}  />
                            <div className="invalid-feedback">{errors.trackingId?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Tracking URL<span style={{color:"red"}}>*</span></Label>
                            <input name="trackingUrl" type="text" className={`form-control ${errors.trackingUrl ? 'is-invalid' : ''}`} placeholder="https://www.dhl.co.uk/url" {...register('trackingUrl')}  />
                            <div className="invalid-feedback">{errors.trackingUrl?.message}</div>
                        </FormGroup>
                    </Col>
                </Row>
        </Fragment>
    );
};

export default TrackingForm;