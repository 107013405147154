import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {useDispatch, useSelector} from "react-redux";
import {Card, Col, Container, FormGroup, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapSellerStaffUserToTable} from "../../../utils/mapper";
import {getAllSellerStaff, selectSellerStaff} from "../store/sellerStaffSlice";
import withReducer from "../../../store/withReducer";
import sellerReducer from "../store";
import {Form} from "react-bootstrap";

const dataMenu = [
    {
        type: 0,
        text: 'Seller',
    },
    {
        type: 1,
        text: 'Seller Staff',
    }
];

const SellerStaff = () => {

    let history = useHistory();
    const dispatch = useDispatch();

    const [name, setName] = useState([]);
    const [email, setEmail] = useState([]);
    const [mobile, setMobile] = useState([]);


    const data = {
        name,
        email,
        mobile
    };

    useEffect(() => {
        dispatch(getAllSellerStaff({...data}))
    }, [dispatch, name, email, mobile]);

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            left: true,
        },
    ];

    const onAdd = () => {
        history.push('staff/add')
    }
    const onEdit = (row) => {
        history.push('staff/' + row.id + '/edit')
    }

    const onDelete = (row) => {

    }

    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Seller Staff"/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col sm="12">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={onAdd}
                                                > <i className="fa fa-plus"></i>Add Seller Staff</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="4" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setName(e.target.value)}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Name"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xl="4" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Email"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xl="4" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input
                                            onChange={(e) => setMobile(e.target.value)}
                                            name="name"
                                            type="text" className="form-control"
                                            placeholder="Search By Mobile"/>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapSellerStaffUserToTable(useSelector(selectSellerStaff))}
                                pagination
                                onEdit={onEdit}
                                onDelete={onDelete}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </Fragment>
    );

}

export default withReducer([{sellerStaff: sellerReducer}])(SellerStaff)