import API from "../../../utils/api";


export const getAllProductCommunityApi = async (filters) => {
    const response = await API.get(`settings/commodity`,{ params: filters });
    return { response };
};

export const addProductCommunityApi = async (location) => {
    const response = await API.post(`settings/commodity`,location);
    return { response };
};

export const updateProductCommunityApi = async (location, id) => {
    const response = await API.put(`settings/commodity/${id}`,location);
    return { response };
};

export const deleteProductCommunityApi = async (id) => {
    const response = await API.delete(`settings/commodity/${id}`);
    return { response };
};