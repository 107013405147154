import React from 'react';
;



function PreLoader(props) {
    const {withoutText}=props;

    return (
        <div style={{     width: '100%',
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign:'center'}}>
            {!withoutText && <p>Loading ...</p>}
            <div className="loader-box">
                <div className="loader-18"></div>
            </div>
        </div>
    );
}

export default PreLoader;