import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";

import { Link, useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { CarouseNavigation } from "../../../../../utils/common";
import { BillingDetails } from "../../../../../constant";
import BillingOrderDetail from "../orderDetail";
import withReducer from "../../../../../store/withReducer";
import salesOrderFulfillmentReducer from "../../../store";
import LogActivity from "../../../../log";
import {
  getCountryList,
  getCountryListData,
  getDraftOrder,
} from "../../../store/draftOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalesOrderInvoice,
  getInvoiceByOrder,
  getPendingSaleOrderInvoiceItems,
  setOrderInvoices,
} from "../../../store/saleOrderInvoiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addSalesOrderFulfillment,
  editSaleOrderFulfilment,
  getCreateData,
  getFulfillmentByOrder,
  getPendingSaleOrderFulfillmentItems,
  setFromSettings,
  setOrderFulfillment,
  updateSalesOrderFulfillment,
} from "../../../store/saleOrderFulfillmentSlice";
import SaleOrderProductInvoiceJqx from "../invoice/content/saleOrderProductInvoicejqx";
import SalesOrderProductFulfillmentJqx from "./content/salesOrderProductFulfillmentJqx";
import Select from "react-select";
import { setOrderData } from "../../../store/salesOrderSlice";
import SalesOrderFulfillmentView from "./view";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};
const defaultValues = {
  id: "",
  ordered_products: [],
  tracking_url: "",
  tracking_id: "",
  delivery_partner: "",
  total_no_of_boxes: "",
  total_gross_weight: "",
  total_net_weight: "",
};
const schema = yup.object().shape({
  delivery_partner: yup
    .object()
    .shape({ id: yup.string() })
    .nullable()
    .required("You must select a delivery partner."),
  tracking_url: yup.string().required("You must enter tracking url"),
  tracking_id: yup.string().required("You must enter tracking id"),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.string().required("You must enter total net weight"),
  total_no_of_boxes: yup.string().required("You must enter total no of boxes"),
});
const BillIngFulfillment = (props) => {
  const { id, order_id, slug } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const salesOrderFulfillmentState = useSelector(
    ({ order }) => order.salesOrderFulfilment
  );
  const [isEditable, setIsEditable] = useState(!id);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;
  useEffect(() => {
    dispatch(getCreateData()).then((res) => {
      dispatch(setFromSettings(res.payload));
    });
  }, []);
  useEffect(() => {
    if (order_id) {
      dispatch(getFulfillmentByOrder(order_id)).then((res) => {
        dispatch(setOrderFulfillment(res.payload));
      });
    }

    if (order_id && id) {
      dispatch(editSaleOrderFulfilment(id)).then((res) => {
        if (!res.error) {
          dispatch(setOrderData(res.payload.order));
          reset(res.payload.fulfillment);
        }
      });
    } else {
      if (order_id) {
        dispatch(getPendingSaleOrderFulfillmentItems(order_id)).then((res) => {
          if (!res.error) {
            dispatch(setOrderData(res.payload.order));
            setValue("ordered_products", res.payload.order_products);
            trigger("ordered_products");
          }
        });
      }
    }
  }, [id, order_id]);

  const onSubmit = (data) => {
    const fulfillmentData = {
      sales_order_id: order_id,
      ...data,
      delivery_partner: data.delivery_partner?.id,
      ordered_products: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            sales_order_line_item_id: item.sales_order_line_item_id,
            quantity: item.fulfillment_quantity,
          };
        }),
    };
    if (id) {
      fulfillmentData["id"] = id;
      dispatch(updateSalesOrderFulfillment({ ...fulfillmentData })).then(
        (res) => {
          if (!res.error) {
          }
        }
      );
    } else {
      dispatch(addSalesOrderFulfillment({ ...fulfillmentData })).then((res) => {
        if (!res.error) {
          history.push(
            `/order/sale/${order_id}/billing/fulfillment/${res.payload.id}`
          );
        }
      });
    }
  };

  return (
    <>
      {id && (
        <Row>
          <Col md={12}>
            <Link
              className="btn btn-primary pull-right ml-2"
              to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/fulfillment`}
            >
              Generate New fulfilment{" "}
            </Link>
            <a
              className="btn btn-warning pull-right"
              onClick={() => {
                setIsEditable(!isEditable);
              }}
            >
              {isEditable ? "View" : "Edit"}
            </a>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col
          md={12}
          className={
            salesOrderFulfillmentState.orderFulfillment.length > 5
              ? "slick-arrow"
              : ""
          }
        >
          <Slider {...settings}>
            {salesOrderFulfillmentState.orderFulfillment.map((item, i) => (
              <div className="p-1 " key={i}>
                <Card className={id == item.id ? "active-invoice" : ""}>
                  <CardBody>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/fulfillment/${item.id}`}
                    >
                      <p className="font-weight-bolder f-14">
                        Fulfillment ID : {item.fulfillment_no}
                      </p>
                    </Link>
                    <p>Date : {item.created_at}</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={9}>
          {isEditable ? (
            <>
              {getValues("ordered_products").length > 0 ? (
                <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="selectOption">
                                  Select Delivery Partner
                                </Label>
                                <Select
                                  placeholder="Select Partners"
                                  type="select"
                                  name="trackingName"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={getValues("delivery_partner")}
                                  isClearable
                                  options={
                                    salesOrderFulfillmentState.formSettings
                                      .delivery_partners
                                  }
                                  onChange={(e) => {
                                    setValue("delivery_partner", e);
                                    trigger("delivery_partner");
                                  }}
                                  className={` ${
                                    errors.delivery_partner ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.delivery_partner?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Tracking Id
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("tracking_id")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.tracking_id?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Tracking Url
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("tracking_url")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.tracking_url?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <p>Products</p>

                          <SalesOrderProductFulfillmentJqx
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                            orderedProducts={getValues("ordered_products")}
                          ></SalesOrderProductFulfillmentJqx>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <h5>Pick Up Details</h5>
                          <Row className="mt-4">
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total No Of Boxes
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_no_of_boxes")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_no_of_boxes?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Gross Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_gross_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_gross_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Net Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_net_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_net_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {getValues("ordered_products").filter(function (v, i) {
                    return v.is_selected;
                  }).length > 0 && (
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <button className="btn btn-primary pull-right">
                              save
                            </button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Form>
              ) : (
                <div className="search-not-found text-center">
                  <div>
                    <img
                      className="img-fluid second-search"
                      src={require("../../../../../assets/images/search-not-found.png")}
                      alt=""
                    />
                    <p> All Ordered items are fulfilled</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <SalesOrderFulfillmentView fulFillmentData={getValues} />
          )}
        </Col>
        <Col md={3}>
          {isEditable ? (
            <LogActivity log="Sales Order Fulfillment" id={id}></LogActivity>
          ) : (
            ""
          )}
          <BillingOrderDetail></BillingOrderDetail>
        </Col>
      </Row>
      <Row className="mt-3"></Row>
    </>
  );
};
export default withReducer([
  { salesOrderFulfilment: salesOrderFulfillmentReducer },
])(BillIngFulfillment);
