import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import productReducer from "../../store";
import ProductTypeForm from "./productTypeForm";
import {
    addProductType,
    closeEditDialog,
    deleteProductType,
    getAllProductTypes,
    openEditDialog,
    openNewDialog,
    selectProductType,
    updateProductType
} from "../../store/productTypeSlice";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {productTypeResponseToTable} from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";


const defaultValues = {
    id: '',
    name: '',
    tageName: '',
    publish_at: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 2,
        text: 'Product Type',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
    // tageName: yup.string().required('You must enter a tag name'),
});

const ProductType = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [tag, setTag] = useState([]);
    const [loading, setLoading] = useState(true);
    const productTypes = useSelector(selectProductType);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        const data = {
            name,
            tag
        }
        dispatch(getAllProductTypes({...data}, 'season')).then(res=>{
            if (!res.error)
                setLoading(false);
        });

    }, [dispatch, name, tag]);


    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addProductType({...data})).then(res => {
                setButtonDisable(false);
                console.log(res)
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateProductType({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    const closeDialog = () => {
        dispatch(closeEditDialog());
    };

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteProductType(row.id))
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    const tableColumns = [
        {
            name: 'Type Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Tag Name',
            selector: row => row.tagName,
            sortable: true,
            left: true,
        }
    ]

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                    <Breadcrumb data={dataMenu} title="Product Type"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Product Types') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Product Type</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                                   type="text"
                                                   className="form-control border"
                                                   placeholder="Search By Type Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => setTag(e.target.value)}
                                                   type="text" className="form-control border"
                                                   placeholder="Search By Tag Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-4">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={productTypeResponseToTable(productTypes)}
                                                    pagination
                                                    onEdit={(currentUser.permission).includes('Update Product Types') ? onEdit:''}
                                                    onDelete={(currentUser.permission).includes('Delete Product Types') ? onDelete:''}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                         </>
                }

            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Product Type'
                selector='product'
                dialog={({product}) => product.productType.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <ProductTypeForm control={control} errors={errors} register={register} setValue={setValue}
                                 trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([
    {
        productType: productReducer
    }
])(ProductType);