import API from '../utils/api';

export const loginApi = async data => {
    const response = await API.post('user/login', data);
    return { response };
};
export const loginUserByToken = async () => {
    const response = await API.post('user/login-user-by-token');
    return { response };
};
export const getMenusApi = async () => {
    const response = await API.get('user/navigation-menu');
    return { response };
};

export const forgotPasswordApi = async data => {
    const response = await API.post('user/forgot-password', data);
    return { response };
}

export const resetPasswordApi = async data => {
    const response = await API.post('user/reset-password', data);
    return { response };
}

export const notificationMarkAsRead = async data => {
    const response = await API.get(`user/update-notification-status/${data}`);
    return {response}
}

