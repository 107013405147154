import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { CarouseNavigation } from "../../../../../utils/common";
import { BillingDetails, NotFoundData } from "../../../../../constant";
import BillingOrderDetail from "../orderDetail";
import withReducer from "../../../../../store/withReducer";
import salesOrderInvoiceReducer from "../../../store";
import {
  getCountryList,
  getCountryListData,
  getDraftOrder,
} from "../../../store/draftOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalesOrderInvoice,
  editSaleOrderInvoice,
  getCreateInvoiceData,
  getInvoiceByOrder,
  getPendingSaleOrderInvoiceItems,
  setInvoiceFromSettings,
  setOrderInvoices,
  updateSalesOrderInvoice,
} from "../../../store/saleOrderInvoiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SaleOrderProductInvoiceJqx from "./content/saleOrderProductInvoicejqx";
import SearchProductModal from "../../../draft-order/form/content/searchProductModal";
import OrderDiscount from "../../../draft-order/form/content/orderDiscount";
import OrderShipment from "../../../draft-order/form/content/orderShipment";
import OrderTax from "../../../draft-order/form/content/orderTax";
import SalesOrderDuty from "../../create/content/salesOrderDuty";
import SaleOrderPayment from "../../create/content/saleOrderPayment";
import {
  addSalesOrders,
  setOrderData,
  sendSalesOrderInvoiceToXero,
  downloadSalesOrderInvoicePdf,
} from "../../../store/salesOrderSlice";
import LogActivity from "../../../../log";
import {
  getCreateData,
  setFromSettings,
} from "../../../store/saleOrderFulfillmentSlice";
import Select from "react-select";
import SalesOrderInvoiceView from "./view";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};
const defaultValues = {
  id: "",
  ordered_products: [],
  due_date: "",
  tags: [],
  duty: "",
  terms: "",
  discount: "",
  shipping: "",
  tax: "",
  xero_id: "",
  total_net_weight: "",
  total_gross_weight: "",
  total_no_of_boxes: "",
  is_tax: false,
};
const schema = yup.object().shape({
  shipping: yup
    .object()
    .shape({ rate_name: yup.string() })
    .required("shipment is required")
    .typeError("shipment is required"),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.string().required("You must enter total net weight"),
  total_no_of_boxes: yup.string().required("You must enter total no of boxes"),
  terms: yup
    .object()
    .shape({ value: yup.string() })
    .nullable()
    .required("You must select a term."),
});
const BillIngInvoice = (props) => {
  const { id, order_id } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const draftOrderState = useSelector(({ order }) => order.draftOrder);
  const salesOrderInvoiceState = useSelector(
    ({ order }) => order.salesOrderInvoice
  );
  const [isEditable, setIsEditable] = useState(!id);
  const [isSentToXero, setIsSentToXero] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;
  useEffect(() => {
    if (order_id) {
      dispatch(getInvoiceByOrder(order_id)).then((res) => {
        dispatch(setOrderInvoices(res.payload));
      });
    }

    if (order_id && id) {
      dispatch(editSaleOrderInvoice(id)).then((res) => {
        if (!res.error) {
          dispatch(setOrderData(res.payload.order));
          reset(res.payload.invoice);
        }
      });
    } else {
      if (order_id) {
        dispatch(getPendingSaleOrderInvoiceItems(order_id)).then((res) => {
          if (!res.error) {
            dispatch(setOrderData(res.payload.order));
            setValue("ordered_products", res.payload.order_products);
            trigger("ordered_products");
          }
        });
      }
    }
  }, [id, order_id]);
  useEffect(() => {
    dispatch(getCreateInvoiceData()).then((res) => {
      dispatch(setInvoiceFromSettings(res.payload));
    });
    dispatch(getCountryList()).then((res) => {
      dispatch(getCountryListData(res.payload));
    });
  }, [dispatch]);

  const onSubmit = (data) => {
    const invoiceData = {
      sales_order_id: order_id,
      ...data,
      terms: data.terms.id,
      ordered_products: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            sales_order_line_item_id: item.sales_order_line_item_id,
            quantity: item.quantity,
            price: item.rate,
            sub_total: item.total_price,
            tax_amount: item.total_tax,
            total_amount: item.gross_price,
          };
        }),
    };

    if (id) {
      invoiceData.id = id;
      dispatch(updateSalesOrderInvoice({ ...invoiceData })).then((res) => {});
    } else {
      dispatch(addSalesOrderInvoice({ ...invoiceData })).then((res) => {
        if (!res.error) {
          history.push(
            `/order/sale/${order_id}/billing/invoice/${res.payload.id}`
          );
        }
      });
    }
  };

  const sendToXero = () => {
    const xeroData = {
      invoice_id: id,
    };
    dispatch(sendSalesOrderInvoiceToXero({ ...xeroData })).then((res) => {
      if (!res.error) {
        setIsSentToXero(true);
        console.error("Successfully sent to Xero");
      } else {
        console.error("Error updating status:", res.error);
      }
    });
  };

  const processPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${res.data ? res.payload.order_no : "-"}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const downloadInvoice = () => {
    processPDFDownload("Invoice");
  };

  return (
    <>
      <Row className="mt-3">
        <Col
          md={12}
          className={
            salesOrderInvoiceState.orderInvoices.length > 5 ? "slick-arrow" : ""
          }
        >
          <Slider {...settings}>
            {salesOrderInvoiceState.orderInvoices.map((item, i) => (
              <div className="p-1 " key={i}>
                <Card className={id == item.id ? "active-invoice" : ""}>
                  <CardBody>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice/${item.id}`}
                    >
                      <p className="font-weight-bolder f-14">
                        Invoice ID : {item.invoice_no}
                      </p>
                    </Link>
                    <p>Date : {item.created_at}</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>

      {id && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody style={{ padding: "24px" }}>
                <Link
                  // className="btn btn-primary pull-right ml-2"
                  className={`btn btn-primary pull-right ml-2 ${
                    getValues("xero_id") ? "" : "disabled"
                  }`}
                  to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/return/${id}`}
                >
                  Return Invoice{" "}
                </Link>

                <Link
                  className="btn btn-primary pull-right ml-2"
                  to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice`}
                >
                  Generate New Invoice{" "}
                </Link>

                <a
                  className="btn btn-warning pull-right ml-2"
                  onClick={downloadInvoice}
                >
                  Download
                </a>

                {isEditable ? (
                  <a
                    className="btn btn-primary pull-right ml-2"
                    onClick={sendToXero}
                  >
                    Send to Xero
                  </a>
                ) : (
                  ""
                )}
                {/* {!getValues("xero_id") && ( */}
                  <a
                    className="btn btn-warning pull-right"
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                  >
                    {isEditable ? "View" : "Edit"}
                  </a>
                {/* )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        <Col md={9}>
          {isEditable ? (
            <>
              {getValues("ordered_products").length > 0 ? (
                <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md={9}>
                              <FormGroup>
                                <Label className="col-form-label">Terms</Label>
                                <Select
                                  placeholder="Select Terms"
                                  type="select"
                                  name="term"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={getValues("terms")}
                                  isClearable
                                  options={
                                    salesOrderInvoiceState.formSettings.terms
                                  }
                                  onChange={(e) => {
                                    setValue("terms", e);
                                    const dueDate = moment()
                                      .add(e.no_of_days, "days")
                                      .format("YYYY-MM-DD");
                                    setValue("due_date", dueDate);
                                    trigger("terms");
                                    trigger("due_date");
                                  }}
                                  className={` ${
                                    errors.trackingName ? "is-invalid" : ""
                                  }`}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.terms?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Due Date
                                </Label>
                                <DatePicker
                                  name="dispatchDate"
                                  className="form-control"
                                  selected={
                                    getValues("due_date")
                                      ? new Date(getValues("due_date"))
                                      : null
                                  }
                                  onChange={(date) => {
                                    setValue("due_date", date);
                                    trigger("due_date");
                                  }}
                                  minDate={moment().toDate()}
                                  style={{ minHeight: "auto" }}
                                  dateFormat="dd-MM-yyyy"
                                />
                                <div style={{ color: "red" }}>
                                  {errors.due_date?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <p>Products</p>
                          <SaleOrderProductInvoiceJqx
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                            orderedProducts={getValues("ordered_products")}
                          ></SaleOrderProductInvoiceJqx>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardBody>
                          <h5>Pick Up Details</h5>
                          <Row className="mt-4">
                            <Col md={12}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total No Of Boxes
                                </Label>
                                <input
                                  type="number"
                                  className="form-control"
                                  {...register("total_no_of_boxes")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_no_of_boxes?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md={12}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Gross Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_gross_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_gross_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-1">
                            <Col md={12}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Net Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_net_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_net_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={8}>
                      {getValues("ordered_products").length > 0 && (
                        <>
                          <SaleOrderPayment
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                          ></SaleOrderPayment>
                          <div
                            style={{
                              color: "red",
                              float: "right",
                            }}
                          >
                            {errors.shipping?.message}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                  {getValues("ordered_products").filter(function (v, i) {
                    return v.is_selected;
                  }).length > 0 && (
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <button className="btn btn-primary pull-right">
                              save
                            </button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Form>
              ) : (
                <div className="search-not-found text-center">
                  <div>
                    <img
                      className="img-fluid second-search"
                      src={require("../../../../../assets/images/search-not-found.png")}
                      alt=""
                    />
                    <p> All Ordered items are invoiced</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <SalesOrderInvoiceView invoiceData={getValues} />
          )}
        </Col>
        <Col md={3}>
          {isEditable ? (
            <LogActivity log="Sales Order Invoice" id={id}></LogActivity>
          ) : (
            ""
          )}
          <BillingOrderDetail
            orderProducts={getValues("ordered_products").filter(function (
              v,
              i
            ) {
              return v.is_selected;
            })}
          ></BillingOrderDetail>
        </Col>
      </Row>
      <Row className="mt-3"></Row>
      <OrderDiscount
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderDiscount>
      <OrderShipment
        draftOrderState={draftOrderState}
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderShipment>
      <OrderTax
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderTax>
      <SalesOrderDuty
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></SalesOrderDuty>
    </>
  );
};
export default withReducer([{ salesOrderInvoice: salesOrderInvoiceReducer }])(
  BillIngInvoice
);
