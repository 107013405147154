import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import salesOrderReturnReducer from "../../store";

import { invoiceResponseToTable } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import {getAllSaleOrderInvoices, selectAllSaleInvoice} from "../../store/saleOrderInvoiceSlice";
import {getPendingReturnRequest, selectAllPendingReturns} from "../../store/saleOrderReturnSlice";

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Invoices",
    link: "",
  },
];

const SalesOrderPendingReturn = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  const [modal, setModal] = useState(false);
  let history = useHistory();
  const [loading, isSetLoading] = useState(false);

  const saleOrderReturnState = useSelector(
      ({ order }) => order.salesOrderReturn
  );

  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

  const pendingReturns = useSelector(selectAllPendingReturns);

  useEffect(() => {
    getData();
  }, [page, ]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,

    };
    dispatch(getPendingReturnRequest(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };


  const tableColumns = [
    {
      name: "Invoice #",
      selector: (row) => row.invoice_no,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },

    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      center: false,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      center: false,
    },

    {
      name: "Action",
      selector: (row) => (
          <div>

                    <a
                        onClick={() => {
                          onEdit(row);
                        }}
                    >
                      <i
                          className="fa fa-undo"
                          style={{
                            width: 25,
                            fontSize: 16,
                            padding: 11,
                            color: "rgb(255, 187, 51)",
                          }}
                      ></i>
                    </a>
          </div>
      ),
      sortable: false,
      center: true,
    },

  ];


  const onEdit = (row) => {
    history.push("/order/pending-return/" + row.id + "/edit");
  };
  const onView = (row) => {
    history.push(
        "order/sale/" + row.sales_order_id + "/billing/invoice/" + row.id
    );
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  return (
      <Fragment>
        <Container fluid={true} className="product-wrapper">
          {loading ? (
              <>
                <PreLoader></PreLoader>
              </>
          ) : (
              <>
                <Breadcrumb data={dataMenu} title="Pending Return" />
                <div className="product-grid">
                  <div className="feature-products">
                    <Row>
                      <Col sm="6">
                        <Form>
                          <FormGroup className="m-0">
                            <input
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                type="text"
                                className="form-control border"
                                placeholder="Search By Type Name"
                            />
                            <i className="fa fa-search"></i>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        {/* <Clock
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "fixed",
                        right: "40px",
                      }}
                      className="mr-1"
                      onClick={logHistory}
                    /> */}
                        <div
                            style={{
                              position: "fixed",
                              top: "50%",
                              zIndex: 10,
                              right: 0,
                            }}
                        >
                          {/* <LogActivity log="Sales Order"></LogActivity> */}
                        </div>
                        <div className="table-responsive product-table mt-4">
                          <CommonDataTable
                              headerColumns={tableColumns}
                              gridData={pendingReturns}
                              pagination
                              progressPending={tableLoading}
                              paginationServer
                              paginationTotalRows={
                                saleOrderReturnState.noOfTableRows
                              }
                              paginationPerPage={10}
                              noAction
                              paginationComponentOptions={{
                                noRowsPerPage: true,
                              }}
                              onChangePage={(page) => setPage(page)}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
          )}
        </Container>
      </Fragment>
  );
};
export default withReducer([
  {
    salesOrderReturn: salesOrderReturnReducer
  },
])(SalesOrderPendingReturn);
