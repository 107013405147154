import React, {Fragment, useEffect, useRef, useState} from "react";
import Slider from 'react-slick';
import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader,
    Col,
    FormGroup, Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label, Media,
    Modal,
    ModalBody,
    ModalHeader, Nav, NavItem, NavLink,
    Row, TabContent, TabPane
} from "reactstrap";
import radialimage from '../../../../assets/images/dashboard-2/radial-image.png';

import ApexCharts from 'react-apexcharts'

import {useDispatch, useSelector} from "react-redux";
import {
    getProduct, getProductView,
    setMediaDialog,
    setProductData,
    setProductDialog, setProductFilter,
    setProductSubCategory
} from "../../store/prodcutSlice";
import PreLoader from "../../../../share-components/ui/preLoader";
import {numberWithCommas} from "../../../../utils/common";


const options = {

        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -10,
        },

        labels: ['Return rate'],
        colors: ["#3e4a56"],

}


const ProductView = (props) => {

    const {} = props;
    const [state, setState] = useState({nav1: null, nav2: null});
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('1');
    const productState = useSelector(({product}) => product.product.productDialogProps);
    const productData = useSelector(({product}) => product.product.product);
    const productId = productState.data ? productState.data.id : "";
    const slider1 = useRef();
    const slider2 = useRef();
    const closDialog = () => {
        dispatch(setProductDialog({props: false}));
        setActiveTab('1');
        setLoading(true);
    }
    useEffect(() => {
        if (productId) {
            dispatch(getProductView(productId)).then(res => {
                if (!res.error) {
                    dispatch(setProductData(res.payload.product))
                    setLoading(false);
                    setState({
                        nav1: slider1.current,
                        nav2: slider2.current
                    });
                }
            });

        }
    }, [productId]);

    const {nav1, nav2} = state;


    return (
        <>
            <Modal className="modal-lg modal-dialog-centered product-modal" size="xl"   {...productState.props}>
                <ModalBody>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>

                    </ModalHeader>
                    {!loading ?
                        <div style={{width: '100%'}}>
                            <Row className="product-page-main">
                                <Col md="4">
                                    {productData.images.length > 0 ? <div>
                                        <Slider
                                            asNavFor={nav2}
                                            arrows={true}
                                            ref={slider => (slider1.current = slider)} className="product-slider">
                                            {productData.images.map((item, i) => {
                                                return (
                                                    <div className="item" key={i}>
                                                        <div className="text-center " style={{position:'relative',left:0,top:0}}>
                                                            {productData.is_best_seller &&  <img width={100} src={require('../../../../assets/images/best-seller.png')} style={{position:'absolute',left:0,top:0,zIndex:100}}/>}
                                                        <Media  style={{position:'relative',left:0,top:0}} src={item.image_path} alt="" className="img-fluid"/>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                        <Slider asNavFor={nav1}
                                                ref={slider => (slider2.current = slider)}
                                                slidesToShow={5}
                                                swipeToSlide={true}
                                                focusOnSelect={true}
                                                infinite={true}
                                                adaptiveHeight={true}
                                                variableWidth={true}
                                                className="small-slick">
                                            {productData.images.map((item, i) => {
                                                return (
                                                    <div className="item" key={i} style={{width: 60}}>
                                                        <Media src={item.image_path} alt="" className="img-fluid"/>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div> : <div>
                                        <img className="img-fluid"
                                             src={require('../../../../assets/images/product/default-product.jpeg')}
                                             alt=""/>
                                    </div>}
                                </Col>
                                <Col md="8">
                                    <Card>
                                        <CardBody className="pt-0">
                                            <div className="product-page-details" >
                                                <h3>{productData.name} - {productData.code} </h3>
                                            </div>

                                            <div className="product-price f-28">
                                                £ {productData.sale_price ? productData.sale_price : productData.price}
                                                {productData.sale_price && <del> £ {productData.price}</del>}
                                            </div>

                                            <Row>
                                                <Col md={6}>
                                                    <div className="product-price f-20">
                                                        <span
                                                            className="txt-success">  Availability : {productData.total_stock}</span>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="float-right">
                                                        {productData.selling_type.map((item, i) =>
                                                            <Badge color="warning"
                                                                   key={i}>{item.name} ({item.id == 1 ? productData.total_single_stock : productData.total_pack_stock})</Badge>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <hr/>
                                            <p dangerouslySetInnerHTML={{ __html: productData.description || '' }} />
                                            <div>
                                                <Nav tabs className="border-tab">
                                                    <NavItem id="myTab" role="tablist">
                                                        <NavLink href="#" className={activeTab === '1' ? 'active' : ''}
                                                                 onClick={() => setActiveTab('1')}>
                                                            Info
                                                        </NavLink>
                                                        <div className="material-border"></div>
                                                    </NavItem>
                                                    {/*<NavItem id="myTab" role="tablist">*/}
                                                    {/*    <NavLink href="#" className={activeTab === '2' ? 'active' : ''}*/}
                                                    {/*             onClick={() => setActiveTab('2')}>*/}
                                                    {/*        Retail*/}
                                                    {/*    </NavLink>*/}
                                                    {/*    <div className="material-border"></div>*/}
                                                    {/*</NavItem>*/}
                                                    <NavItem id="myTab" role="tablist">
                                                        <NavLink href="#" className={activeTab === '2' ? 'active' : ''}
                                                                 onClick={() => setActiveTab('2')}>
                                                            Order
                                                        </NavLink>
                                                        <div className="material-border"></div>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="1">
                                                        <div style={{minHeight:245}}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Brand</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <span
                                                                            className="f-w-100">{productData.brand}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Product Type</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <span
                                                                            className="f-w-100">{productData.product_type}</span>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Category</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{productData.category_name}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Sub Category</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{productData.sub_category_name}</span>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Country</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{productData.country_name}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Season</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{productData.season_name}</span>
                                                                    </Col>
                                                                </Row>

                                                            </Col>
                                                        </Row>
                                                            <hr/>
                                                        <Row className="mt-3">
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Cost Price</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{numberWithCommas(productData.cost_per_item_price)}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <span className="f-w-600">Price</span>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{numberWithCommas(productData.price)} </span>
                                                                    </Col>
                                                                </Row>

                                                            </Col>

                                                        </Row>
                                                            <Row className="mt-3">
                                                                <Col md={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <span className="f-w-600">Profit</span>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{numberWithCommas(productData.profit)}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <span className="f-w-600">Profit Margin</span>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{productData.profit_margin?(productData.profit_margin).toFixed(2)+" %" :""}</span>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>

                                                            </Row>
                                                            <hr/>
                                                            <Row className="mt-3">
                                                                <Col md={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <span className="f-w-600">WS Cost  </span>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{numberWithCommas(productData.retail_cost_value)}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <span className="f-w-600">WS Stock Value </span>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                <span
                                                                    className="f-w-100">{numberWithCommas(productData.retail_stock_value)}</span>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                    {/*<TabPane tabId="2">*/}
                                                    {/*    <div style={{minHeight:245}}>*/}

                                                    {/*    </div>*/}
                                                    {/*</TabPane>*/}
                                                    <TabPane tabId="2">
                                                        <div style={{minHeight:245}}>


                                                                <div
                                                                    className="apex-chart-container goal-status text-center row">
                                                                    <div className="rate-card col-xl-12">
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <div className="goal-chart">
                                                                                    <ApexCharts id="riskfactorchart"
                                                                                                options={options} series={[productData.return_percentage]}
                                                                                                type='radialBar' height={220}/>
                                                                                </div>
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <span className="f-w-600">Last Sold Date : {productData.last_sold_date}</span>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <div className="goal-end-point mt-3">

                                                                                    <ul>
                                                                                        <li className="mb-3">
                                                                                            <h6 className="mb-2 f-w-400">Total Sold ({productData.total_sold})</h6>
                                                                                            <h5 className="mb-0">{numberWithCommas(productData.total_sold_price)}</h5>
                                                                                        </li>
                                                                                        <li className="mb-3">
                                                                                            <h6 className="mb-2 f-w-400">Total Return  ({productData.total_return})</h6>
                                                                                            <h5 className="mb-0">{numberWithCommas(productData.total_return_price)}</h5>
                                                                                        </li>
                                                                                        <li className="mb-3">
                                                                                            <h6 className="mb-2 f-w-400">Total Sale  ({productData.total_sale})</h6>
                                                                                            <h5 className="mb-0">{numberWithCommas(productData.total_sale_price)}</h5>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </Col>
                                                                            {/*<Col md={4}>*/}
                                                                            {/*    <div className="goal-end-point mt-3">*/}

                                                                            {/*        <ul>*/}
                                                                            {/*            <li className="mb-3">*/}
                                                                            {/*                <h6 className="mb-2 f-w-400">Total Sold ({productData.total_sold})</h6>*/}
                                                                            {/*                <h5 className="mb-0">{numberWithCommas(productData.total_sold_price)}</h5>*/}
                                                                            {/*            </li>*/}
                                                                            {/*            <li className="mb-3">*/}
                                                                            {/*                <h6 className="mb-2 f-w-400">Total Return  ({productData.total_return})</h6>*/}
                                                                            {/*                <h5 className="mb-0">{numberWithCommas(productData.total_return_price)}</h5>*/}
                                                                            {/*            </li>*/}
                                                                            {/*            <li className="mb-3">*/}
                                                                            {/*                <h6 className="mb-2 f-w-400">Total Sale  ({productData.total_sale})</h6>*/}
                                                                            {/*                <h5 className="mb-0">{numberWithCommas(productData.total_sale_price)}</h5>*/}
                                                                            {/*            </li>*/}
                                                                            {/*        </ul>*/}
                                                                            {/*    </div>*/}
                                                                            {/*</Col>*/}
                                                                        </Row>



                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>


                                            </div>


                                            <div className="product-size m-t-15">
                                                {productData.colours.map((item, i) =>
                                                    <Badge color="info" key={i}>{item.name}</Badge>
                                                )}
                                            </div>
                                            <div className="product-size m-t-15">
                                                {productData.sizes.map((item, i) =>
                                                    <Badge color="success" key={i}>{item.name}</Badge>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>

                                </Col>

                            </Row>
                        </div>
                        :
                        <PreLoader withoutText></PreLoader>
                    }
                </ModalBody>
            </Modal>
        </>);

}
export default ProductView;
