import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getCheckoutApi, getCheckoutDataApi} from "../../../services/order";
import {getProductViewApi} from "../../../services/manage-product/productService";


export const getCheckouts = createAsyncThunk('orders/getCheckout', async (data,{dispatch,}) => {
    const response = await getCheckoutApi(data);
    dispatch(setCheckoutTableRowCount(response.response.count));
    return response.response.data;
});
export const getCheckoutData = createAsyncThunk('getCheckoutData', async (id, {dispatch, getState}) => {
    try {
        const response = await getCheckoutDataApi(id);
        const data = await response.response;
        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});



const checkoutAdapter = createEntityAdapter({});


export const { selectAll: selectCheckouts, selectById: selectCheckoutById } = checkoutAdapter.getSelectors(
    state => state.order.checkout
);

const checkoutSlice = createSlice({
    name: 'orders',
    initialState: checkoutAdapter.getInitialState({
        checkoutTableRow:0,
        checkOutData:{
            customer:"",
            variants:[],
            sub_total_price:0,
            total_price:0,
        },
        mediaDialogProps:{
            props: {
                isOpen: false
            },
        }
    }),
    reducers: {
        setCheckoutTableRowCount: (state, action) => {
            state.checkoutTableRow = action.payload;
        },
        setCheckoutData: (state, action) => {
            state.checkOutData = action.payload;
        },
        setCheckoutModal: (state, action) => {
            state.mediaDialogProps.props.isOpen = action.payload
        },
    },
    extraReducers: {
        [getCheckouts.fulfilled]: checkoutAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setCheckoutTableRowCount,setCheckoutData,setCheckoutModal} =
    checkoutSlice.actions;

export default checkoutSlice.reducer;