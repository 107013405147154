import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
    Button,
    Card,
    CardBody, CardHeader,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";

// import useNavigate from 'react-router-dom';

import salesOrderReducer from "./../../store";

import withReducer from "../../../../store/withReducer";

import PreLoader from "../../../../share-components/ui/preLoader";
import AsyncSelect from "react-select/async";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {mapSearchCustomerAutoSuggestion} from "../../../../utils/mapper";
import {customerAutoSuggestionApi} from "../../../../services/order";
import OrderCustomerAddress from "./content/orderCustomerAddress";
import {
    addDraftOrders,
    getCountryList,
    getCountryListData,
    setSearchProductDialog,
    updateDraftOrder
} from "../../store/draftOrderSlice";
import SearchProductModal from "../../draft-order/form/content/searchProductModal";
import SaleOrderProductJqx from "./content/saleOrderProductJqx";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import OrderDiscount from "../../draft-order/form/content/orderDiscount";
import OrderShipment from "../../draft-order/form/content/orderShipment";
import OrderTax from "../../draft-order/form/content/orderTax";
import SaleOrderPayment from "./content/saleOrderPayment";
import SalesOrderDuty from "./content/salesOrderDuty";
import {orderCommodities} from "../setting/calculation";
import {addSalesOrders, editSaleOrder, getExchangeRate, updateSalesOrder} from "../../store/salesOrderSlice";
import {useHistory, useParams} from "react-router-dom";
import {getCurrencyAutoSuggestionApi} from "../../../../services/manage-order/saleOrderService";


const defaultValues = {
    id: '',
    ordered_products: [],
    currency: {value: 50, label: "GBP"},
    customer_po_no: "",
    memo: "",
    discount: "",
    shipping: "",
    currency_rate:1,
    duty:"",
    tax: "",
    is_tax: false,
    customer: "",
    special_notes:"",
    shopify_draft_order_id: "",
    shippingAddress: '',
    billingAddress: '',
    total_price:0
};
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};
const dataMenu = [
    {
        type: 1,
        text: 'Sales Order',
        link: '/order/sale'
    },
    {
        type: 0,
        text: 'Form',
    }
];

export const schema = yup.object().shape({
    customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});
const SalesOrderForm = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, isSetLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);

    let searchProductRef = useRef()
    const draftOrderState = useSelector(({order})=> order.draftOrder);
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors, isDirty} = formState;
    const salesOrderState = useSelector(({order}) => order.salesOrder);

    useEffect(function (){
        if(id) {
            dispatch(editSaleOrder(id)).then(res => {
                 reset(res.payload);
                  isSetLoading(false);
            });
        }else{
            isSetLoading(false);
        }
    },[id])
    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data)))
            });
        });

    const currencyTypePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchCustomerAutoSuggestion(getCurrencyAutoSuggestionApi(data)))
            });
        });

    const openSearchProductDialog = () => {
        const obj = {props: {isOpen: true}, data: {searchValue: searchProductRef.current.value}}
        dispatch(setSearchProductDialog(obj));
    }
    const onSubmit = (data) => {
        setButtonDisable(false);
        let line_items=[];
        (data.ordered_products).map((item,index)=>{
            line_items.push({
                id:item.id,
                variant_id:item.variant,
                quantity:item.quantity,
                back_order_quantity:item.back_order_quantity,
                price:item.price,
                product_id:item.product_id,
                sub_total_price:item.total_price,
                tax_price:item.tax_amount,
                gross_price:item.gross_amount,
                is_tax:item.is_tax
            });
        });
        data.ordered_products=line_items;
        data.customer=data.customer.value;
        data.currency=data.currency.value;
        if(data.id){
            dispatch(updateSalesOrder({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/sale')
                }
            });
        }else {
            dispatch(addSalesOrders({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/sale')


                }
            });
        }
    }

    const handleCurrencyChange=(data)=>{
        const paramsData={currency:data.label};
        dispatch(getExchangeRate(paramsData)).then(res => {
            setValue('currency_rate',res.payload);
            trigger('currency_rate');
        });
    }
    useEffect(()=>{
        dispatch(getCountryList()).then(res => {
            dispatch(getCountryListData(res.payload));
        });
    },[dispatch])

    return (
        <Fragment>
            <Breadcrumb title="Sales Order" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                {loading ? <>
                        <PreLoader></PreLoader>
                    </> :
                    <>

                                <Row>

                                    <Col md={9}>
                                        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Customer</Label>

                                                            <AsyncSelect
                                                                className={` ${errors.customer ? 'is-invalid' : ''}`}
                                                                isClearable
                                                                cacheOptions
                                                                defaultOptions
                                                                defaultValue={getValues('customer')}
                                                                loadOptions={typePromiseOption}
                                                                onChange={(e) => {
                                                                    setValue('customer', e);
                                                                    trigger('customer');

                                                                    // getCustomerAddress(e);

                                                                }}

                                                            />
                                                            <div
                                                                className="invalid-feedback">{errors.customer?.message}</div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Customer PO</Label>
                                                            <input name="notes"
                                                                   type="text" {...register('customer_po_no')}
                                                                   className={`form-control`}/>
                                                            <div
                                                                className="invalid-feedback">{errors.customer_po_no?.message}</div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Currency</Label>
                                                            <AsyncSelect
                                                                className={` ${errors.customer ? 'is-invalid' : ''}`}
                                                                isClearable
                                                                cacheOptions
                                                                defaultOptions
                                                                defaultValue={getValues('currency')}
                                                                loadOptions={currencyTypePromiseOption}
                                                                onChange={(e) => {
                                                                    setValue('currency', e);
                                                                    handleCurrencyChange(e);


                                                                    // getCustomerAddress(e);

                                                                }}

                                                            />
                                                            <div
                                                                className="invalid-feedback">{errors.currency?.message}</div>
                                                        </FormGroup>
                                                    </Col>

                                                </div>




                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="10 input-with-icon pr-1">
                                                        <div>
                                                            <FormGroup className="m-0">
                                                                <i className="fa fa-search"></i>
                                                                <input name="searchProduct" type="text"
                                                                       placeholder="search Products"
                                                                       ref={ref => {
                                                                           searchProductRef.current = ref;
                                                                       }}
                                                                       onKeyUp={openSearchProductDialog}
                                                                       className={`form-control`}/>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                    <Col md="2 pl-0">
                                                        <Button color="primary" type="button"
                                                                className="btn btn-sm btn-primary btn-block"
                                                                onClick={openSearchProductDialog}
                                                                style={{height: 38}}>Browse</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                    {getValues('ordered_products').length >0 && <SaleOrderProductJqx setValue={setValue}
                                                                     getValues={getValues}
                                                                     trigger={trigger}
                                                                     id={id}
                                                                     orderedProducts={getValues('ordered_products')}
                                                    />}
                                                    </Col>
                                                </Row>



                                            </CardBody>
                                        </Card>
                                        {getValues('ordered_products').length > 0 &&
                                                <Row>
                                                    <Col sm="12">
                                                        <SaleOrderPayment setValue={setValue} getValues={getValues}
                                                                      trigger={trigger}></SaleOrderPayment>
                                                        <div style={{color:'red',float:"right"}}>{errors.shipping?.message}</div>
                                                    </Col>
                                                </Row>}

                                        {getValues('ordered_products').length >0 &&
                                                <Card>
                                                    <CardBody>
                                                        <div className="float-right" style={{display:"flex"}}>
                                                            {/*<div className="checkbox checkbox-dark mr-5">*/}
                                                            {/*    <input id="is_paid" type="checkbox"  onChange={(e)=>{*/}
                                                            {/*        setValue('is_paid',e.target.checked)*/}
                                                            {/*    }} defaultChecked={getValues('is_paid')}/>*/}
                                                            {/*    <Label for="is_paid">Mark As Paid</Label>*/}
                                                            {/*</div>*/}
                                                            <ButtonLoader disabled={buttonDisable}  color="primary"  value="1"	type="submit"  btntext="Save"></ButtonLoader>
                                                        </div>

                                                    </CardBody>
                                                </Card>
                                        }
                                        </Form>
                                    </Col>
                                    <Col md={3}>
                                        {getValues('customer') && <OrderCustomerAddress
                                            id={id}
                                            getValues={getValues}
                                            setValue={setValue}
                                            salesOrderState={salesOrderState}
                                            draftOrderState={draftOrderState}
                                            customer={getValues('customer')}
                                        />}
                                        {getValues('ordered_products').length >0 &&<Card>
                                            <CardBody>
                                                <h6 className="mb-3">Commodity Information</h6>
                                                    {/* eslint-disable-next-line no-undef */}
                                                    {orderCommodities(getValues('ordered_products')).map((item, i) =>
                                                       <p key={i}>{item.commodity} {item.quantity} cost {item.amount}</p>
                                                    )}
                                                </CardBody>
                                        </Card>}
                                        <Card>
                                        <CardBody>
                                            <h6 className="mb-3">Memo</h6>
                                            <textarea {...register('memo')} className='form-control' rows={3}></textarea>

                                        </CardBody>
                                        </Card>


                                    </Col>
                                </Row>



                    </>}
            </Container>
            <SearchProductModal setValue={setValue} getValues={getValues} trigger={trigger} isAllowOutOfStock></SearchProductModal>
            <OrderDiscount orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderDiscount>
            <OrderShipment draftOrderState={draftOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderShipment>
            <OrderTax orderSetValue={setValue} orderTrigger={trigger}

                      orderGetValues={getValues}></OrderTax>
            <SalesOrderDuty orderSetValue={setValue} orderTrigger={trigger}
                            orderGetValues={getValues}></SalesOrderDuty>
        </Fragment>
    );
}

export default withReducer([{salesOrder: salesOrderReducer}])(SalesOrderForm);