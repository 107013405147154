import API from "../../../utils/api";


export const getBoxTypesApi = async (filters) => {
    const response = await API.get('settings/box-type', { params: filters });
    return { response };
};

export const addBoxTypeApi = async location => {
    const response = await API.post('settings/box-type', location);
    return { response };
}

export const updateBoxTypeApi = async (location, id) => {
    const response = await API.put(`settings/box-type/${id}`, location);
    return { response };
};

export const deleteBoxTypeApi = async (id) => {
    const response = await API.delete(`settings/box-type/${id}`);
    return { response };
};
