import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {
    ButtonDropdown,Card,
    CardBody, CardHeader,Col,
    Container,DropdownItem,DropdownMenu,DropdownToggle, Form,
    FormGroup,Input,Label,Nav,NavItem,NavLink,
    Row,Popover,PopoverBody,PopoverHeader, Button
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import Select from "react-select";
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { Link } from 'react-router-dom';
import * as ReactDOMServer from "react-dom/server";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {  selectinventoryAdjustment, getInventoryAdjust, exportInventoryAdjustmentDetails
 } from "../store/inventoryAdjustmentsSlice";
import moment from "moment";
import adjustmentInventoryReducer from "../store";
import {  getInventorySettings, setSettings, inventorySku, inventoryBin, } from "../store/prodcutSlice";
import JqxTable from '../../../share-components/table/JqxTable';
import { mapInventoryAdjustmentResponseToTable } from '../../../utils/mapper';
import JqxGrid, {IGridProps, jqx} from '../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import * as ReactDOM from "react-dom";

const { afterToday } = DateRangePicker;

const dataMenu = [
    {
        type: 0,
        text: 'Inventory Adjustment',
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function AdjustmentInventory() {

    const inventory = useSelector(selectinventoryAdjustment);
    const [tableLoading, isSetTableLoading] = useState(false);
    const inventoryState = useSelector(({product}) => product.inventoryAdjustment);
    const productState = useSelector(({product}) => product.product);
    const dispatch = useDispatch();
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100000);
    const authData =  useSelector(({authReducer}) => authReducer);
    const [searchSeller, setSearchSeller] = useState('');
    const [isLoadingSku, setIsLoadingSku] = useState(false);
    const [selectedSkuData, setSelectedSkuData] = useState(null);
    const [skus, setSkus] = useState([]);
    const handleSearchForSku = (query) => {
        setIsLoadingSku(true);
        dispatch(inventorySku(query)).then((res) => {
          if (!res.error) {
            setSkus(res.payload);
            setIsLoadingSku(false);
          }
        });
      };
    let gridRef = useRef(null);
    let subGrid = useRef([]);
    let currentIndex="";
    let currentEditedCell="";
    const [subGridHeight, setSubGridHeight] = useState(200);

   useEffect(()=>{
          setSearchSeller(authData.defaultSeller)
    },[authData.defaultSeller]);

    console.log(searchSeller);
    const handleOnSkuChange = (skuData) => {
        setSelectedSkuData(skuData[0]);
    }
    const filterBy = () => true;

    const [selectedBinId, setSelectedBinId] = useState(null);
    const [isLoadingBin, setIsLoadingBin] = useState(false);
    const [bins, setBins] = useState([]);
    const handleSearchForBin = (query) => {
        setIsLoadingBin(true);
        const data = {
            value: query
        }
        dispatch(inventoryBin(data)).then((res) => {
          if (!res.error) {
            setBins(res.payload);
            setIsLoadingBin(false);
          }
        });
      };

    const handleOnBinChange = (binData) => {
        const selectedId = binData.length > 0 ? binData[0].name : null;
        setSelectedBinId(selectedId);
    }

    const [stock, setStock] = useState(null);
    const [reason, setReason] = useState(null);
    const [skuFilter, setSkuFilter] = useState(null);
    const [condition, setCondition] = useState(null);
    const [user, setUser] = useState(null);
    const [binFilter, setBinFilter] = useState(null);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, startDate, reason, endDate, stock, skuFilter, condition, 
        user, binFilter, selectedSkuData, selectedBinId, searchSeller]);


        const findBinDifferences = (oldData, newData) => {
            const differences = [];
        
            // Find modified bins and calculate changes
            oldData.forEach(oldItem => {
                const newItem = newData.find(newItem => newItem.bin_number === oldItem.bin_number);
        
                if (newItem) {
                    const change = newItem.available - oldItem.available;
                    if (change !== 0) {
                        differences.push({
                            bin_number: oldItem.bin_number,
                            old_available: oldItem.available,
                            new_available: newItem.available,
                            change: change
                        });
                    }else{
                        differences.push({
                            bin_number: oldItem.bin_number,
                            old_available: oldItem.available,
                            new_available: newItem.available,
                            change: 'No Change'
                        });
                    }
                } else {
                    differences.push({
                        bin_number: oldItem.bin_number,
                        old_available: oldItem.available,
                        new_available: 0,
                        change: 'Deleted'
                    });
                }
            });
        
            // Find added bins
            newData.forEach(newItem => {
                if (!oldData.find(oldItem => oldItem.bin_number === newItem.bin_number)) {
                    differences.push({
                        bin_number: newItem.bin_number,
                        old_available: 0,
                        new_available: newItem.available,
                        change: 'Added'
                    });
                }
            });
        
            return differences;
        }

    const getData = () =>{
        
        isSetTableLoading(true);
        const data = {
            page:page,
            pageSize:pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock:stock,
            reason:reason,
            skuFilter:selectedSkuData ? selectedSkuData.sku : '',
            condition:condition,
            user:user,
            binFilter:selectedBinId?selectedBinId:'',
            seller:searchSeller?.value,
        }
        
        dispatch(getInventoryAdjust(data)).then(res=>{
            if(!res.error){
                // isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    
    }


    useEffect(() => {
        dispatch(getInventorySettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    const dataFields = [
        {name: 'id', type: 'id'},
        {name: 'rowExpand', type: 'bool'},
        {name: 'sku', type: 'string'},
        {name: 'stock_type', type: 'string'},
        {name: 'reason_id', type: 'string' },
        {name: 'created_at', type: 'string' },
        {name: 'created_by', type: 'string'},
    ];

    const columns = [
        { datafield: 'id', hidden: true },
        {
            text: '#',
            width: '2%',
            datafield: 'rowExpand',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                let html="";
               if(value){
                   html = ReactDOMServer.renderToString(
                       <div className="jqx-delete-button-div">
                           <a>{value===1 ? <i className="fa fa fa-chevron-up"></i> :<i className="fa fa-chevron-down"></i>}</a>
                       </div>
                   );
               }
                return html;
            },
        },
        {
            text: 'SKU',
            datafield: 'sku',
            width: '28%',
            editable: false,
            align: 'center',
            cellsalign: 'left',
        },

        {
            text: 'Stock Type',
            datafield: 'stock_type',
            width: '30%',
            editable: false,
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Reason',
            datafield: 'reason_id',
            width: '15%',
            editable: false,
            columntype: 'numberinput',
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },
        {
            text: 'Created At',
            datafield: 'created_at',
            editable: true,
            width: '15%',
            align: 'center',
            cellsalign: 'center',
            cellsformat: 'n',

        },
        {
            text: 'Created By',
            datafield: 'created_by',
            width: '10%',
            editable: false,
            cellsformat: 'd',
            align: 'center',
            cellsalign: 'center',
            cellbeginedit(row, datafield, columntype) {},
        },       
    ];

    // const tableColumns = [
    
    //     {
    //         name: 'SKU',
    //         selector: row => row.sku,
    //         sortable: true,
    //         center: false,
    //         width: '25%'
    //     }, 
    //     // {
    //     //     name: 'Bin Number',
    //     //     selector: row => row.bin_number
    //     //     ,
    //     //     sortable: true,
    //     //     center: false,
    //     // }, 
    //     {
    //         name: 'Stock Type',
    //         selector: row => row.stock_type,
    //         sortable: true,
    //         center: false,
    //     },
    //     {
    //         name: 'Reason',
    //         selector: row => row.reason_id,
    //         sortable: true,
    //         center: false,
    //     }, 
    //     // {
    //     //     name: 'Quantity',
    //     //     selector: row => row.warehouse_input_qty,
    //     //     sortable: true,
    //     //     center: false,
    //     // }, 
       
    //     {
    //         name: 'Created At',
    //         selector: row => row.created_at,
    //         sortable: true,
    //         center: false,
    //     }, 
    //     {
    //         name: 'Created By',
    //         selector: row => {
    //             const createdBy = row.created_by;
    //             if (typeof createdBy === 'string' && createdBy.length > 0) {
    //                 return createdBy.charAt(0).toUpperCase() + createdBy.slice(1);
    //             }
    //             return createdBy;
    //         },
    //         sortable: true,
    //         center: false,
    //     }, 
    // ];

    const [btnDisable, setBtnDisable] = useState(false);
    const [importDdpFile, setImportDdpFile] = useState(false);
    const exportInventoryAdjustment = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            reason : reason ? reason : '',
        }
        const outputFilename = `inventory_adjustment_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportInventoryAdjustmentDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });

    }
    const cellEdit = event => {};
    const nestedGrids: any[] = [];
    const initrowdetails = (index: number, parentElement: any, gridElement: any, record: any) => {
        // if(gridRef.getcellvalue(record.boundindex,'isPack')){

            const nestedGridContainer = parentElement.children[0];
            nestedGrids[index] = nestedGridContainer;
            let differences = findBinDifferences(inventory[index].inventory_changes.old, inventory[index].inventory_changes.new);
            console.log(differences);
            const returnSubGridSource = {
                localdata: differences,
                datafields: [
                    { name: 'id', type: 'number' },
                    { name: 'bin_number', type: 'string' },
                    { name: 'old_available', type: 'number' },
                    { name: 'new_available', type: 'number' },
                    { name: 'change', type: 'string' },
                ],
            }

            let subGridColumns = [
                { datafield: 'id', hidden: true },
                { 
                    text: 'Bin Number', 
                    datafield: 'bin_number', 
                    width: '25%',
                    editable: false,
                    align: 'center',
                    cellsalign: 'center',
                },
                { 
                    text: 'Old Available', 
                    datafield: 'old_available', 
                    width: '25%',
                    editable: false,
                    align: 'center',
                    cellsalign: 'center',
                },
                { 
                    text: 'New Available', 
                    datafield: 'new_available', 
                    width: '25%',
                    editable: false, 
                    align: 'center',
                    cellsalign: 'center',
                },
                { 
                    text: 'Change', 
                    datafield: 'change', 
                    width: '25%',
                    editable: false,
                    align: 'center',
                    cellsalign: 'center',
                    cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                        let html="";
                       if(value == 'Deleted'){
                           html = ReactDOMServer.renderToString(
                               <div class="text-danger font-weight-bold text-center pt-2">{value}</div>
                           );
                       }else if(value == 'Added'){
                        html = ReactDOMServer.renderToString(
                            <div class="text-success font-weight-bold text-center pt-2">{value}</div>
                        );
                        }else if(value == 'No Change'){
                            html = ReactDOMServer.renderToString(
                                <div class="text-primary font-weight-bold text-center pt-2">{value}</div>
                            );
                        }else{
                            html = ReactDOMServer.renderToString(
                                <div class="text-secondary font-weight-bold text-center pt-2">{value}</div>
                            );
                        }
                        return html;
                    }
                },
            ];

            const returnNestedGridAdapter = new jqx.dataAdapter(returnSubGridSource);
            if (nestedGridContainer != null) {
                const columns: IGridProps['columns'] = subGridColumns;
                ReactDOM.render(
                    <JqxGrid id="jqx_sub" ref={jqxRef => {
                        subGrid.current[index] = jqxRef;
                    }} editable={true} theme='material'
                             onCellbeginedit={(event)=>{
                                 const rowCell=event.args;
                                 currentIndex=index;
                                 currentEditedCell=rowCell.datafield;
                             }}
                             height={'200px'} 
                             width={'100%'} 
                             source={returnNestedGridAdapter} 
                             showtoolbar={false}
                             selectionmode='singlecell' 
                             columnsheight={10} 
                             columns={subGridColumns}/>,
                    document.getElementById(nestedGridContainer.id)
                );

            }
        // }else{
        //     gridRef.hiderowdetails(record.boundindex);
        // }
    }
    
    const expandCollapseRowDetails = (row,qtySelect=false) =>{
        // if(gridRef.getcellvalue(row,'isPack')){
            let rowVal = gridRef.getcellvalue(row,'rowExpand');
            console.log(rowVal);
            if(!qtySelect || rowVal){
                if(rowVal===true)
                    rowVal=2;

                gridRef.setcellvalue(row,'rowExpand',rowVal===1?2:1);

                if(rowVal===2){
                    gridRef.showrowdetails(row);
                }else{
                    gridRef.hiderowdetails(row);
                }
            }
        // }
    }

    const onRowDetailsClick = args =>{
        expandCollapseRowDetails(args.rowindex);
    }

    // eslint-disable-next-line no-shadow
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
            // jqx.selectallrows();
        }
    };
    const onSelectRow = event => {
        expandCollapseRowDetails(event.args.rowindex,true);
    };


  return (
    <Fragment>
    <Breadcrumb title="Inventory Adjustment" data={dataMenu}/>

    <Container fluid={true} className="product-wrapper">
       <div className="product-grid">
           <div className="feature-products">
            <Row>
                <Col sm="12">
                    <Card>
                        <Row>
                            <Col md={3}>
                                <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                    <DatePicker 
                                        dateRange={dateRange} 
                                        dateRangeChange={(date)=>{
                                            setDateRange(date)
                                        }}
                                        defaultValue={[TodayDate, sevenDays]}
                                        disabledDate={afterToday()}
                                    />
                                </div>
                            </Col>
                            <Col md={3}></Col>
                            <Col md={6}>
                                <>
                                <div className='float-sm-right p-3'>
                                <Link to="/product/add-adjustment-Inventory" className="btn btn-primary">
                                    <i className="fa fa-plus"></i> New Inventory Adjustment
                                </Link>
                                </div> 

                                <div className='float-sm-right pt-3 pb-3 pr-3'>
                                <Button color="succuss" className="btn btn-success"
                                onClick={() => {exportInventoryAdjustment()}}
                                >Export Inventory Adjustment Report</Button>
                                </div>
                                </>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md='2' style={{ zIndex: '3',}}>
                    <FormGroup>
                        <Select
                            name='stock'
                            placeholder='Select Stock Type'
                            className="select2-filter"
                            defaultValue=''
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            options={productState.settings.stockType}
                            onChange={(e) => {
                                    setStock(e ? e.id : null)
                            }}
                            components={{
                                IndicatorSeparator: () => null
                            }}

                        />
                    </FormGroup>
                </Col>
               
                <Col md='2' style={{ zIndex: '2',}}>
                    <FormGroup>
                        <Select
                            name='reason'
                            placeholder='Select Reason'
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            className="select2-filter"
                            isClearable
                            cacheOptions
                            styles={customStyles}
                            options={productState.settings.reasonType}
                            onChange={(e) => {
                                setReason(e ? e.id : null)
                            }}
                            components={{
                                IndicatorSeparator: () => null
                            }}

                        />
                    </FormGroup>
                    </Col>
                <Col md='2' style={{ zIndex: '2',}}>
                    <FormGroup>
                        <Select
                            name='user'
                            placeholder='Select User'
                            className="select2-filter"
                            isClearable
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            cacheOptions
                            styles={customStyles}
                            // options={productState.settings.user}
                            options={productState.settings.mainUser.map(option => ({
                                ...option,
                                name: option.name.charAt(0).toUpperCase() + option.name.slice(1)
                            }))}
                            onChange={(e) => {
                                setUser(e ? e.id : null)
                            }}
                            components={{
                                IndicatorSeparator: () => null
                            }}

                        />
                    </FormGroup>
                </Col>
               
                <Col md="3">
                <AsyncTypeahead
                        name="binNumber"
                        // className={`${errors.binNumber ? 'is-invalid' : ''}`}
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoadingBin}
                        minLength={1}
                        labelKey="name"
                        onChange={(selected) => {
                            handleOnBinChange(selected);
                        }}
                        onSearch={handleSearchForBin}
                        options={bins}
                        placeholder="Search for Bin..."
                        renderMenuItemChildren={(option) => (
                        <>
                            <span>{option.name}</span>
                        </>
                        )}
                        inputProps={{
                            style: { height: '50px' }
                        }}
                    />
                </Col>

                <Col md="3">
                    <AsyncTypeahead
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoadingSku}
                        minLength={1}
                        labelKey="sku"
                        onChange={(selected) => {
                            handleOnSkuChange(selected)
                        }}
                        onSearch={handleSearchForSku}
                        options={skus}
                        placeholder="Search for SKU..."
                        renderMenuItemChildren={(option) => (
                        <>
                            <span>{option.sku}</span>
                        </>
                        )}
                        inputProps={{
                            style: { height: '50px' }
                        }}
                    />
                </Col>
                
            </Row>
 
            <Row>
                <Col sm="12" className="mt-3">
                    <Card>
                        <CardBody>
                            <div style={{width: '100%'}}>
                                <JqxTable
                                    pagermode={'simple'}
                                    pagerbuttonscount={5}
                                    pageable={true}
                                    datafields={dataFields}
                                    initrowdetails={initrowdetails}
                                    columns={columns}
                                    cellClickFunction={onRowDetailsClick}
                                    cellClickDataField={['rowExpand']}
                                    data={inventory ? mapInventoryAdjustmentResponseToTable(inventory) : null}
                                    myGrid={gridRef}
                                    isDelete={false}
                                    disableCreateRow
                                    rowsheight={50}
                                    getJqxRef={getJqxRef}
                                    onRowselect={onSelectRow}
                                    rowdetails={true}
                                    showrowdetailscolumn={false}
                                    rowdetailstemplate={{
                                        rowdetails: '<div id="nestedGrid" style="margin: 1px; border: 1px solid black; height:150; overflow-y: auto; overflow-x: hidden;"></div>',
                                        // rowdetailsheight: subGridHeight,
                                        rowdetailshidden: true
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
           </div>
       </div>
   </Container>
</Fragment>
  )
}

// export default AdjustmentInventory
export default withReducer([{product: adjustmentInventoryReducer}])(AdjustmentInventory);