import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Breadcrumb from '../../../layout/breadcrumb'
import JqxTable from "../../../share-components/table/JqxTable";
import withReducer from "../../../store/withReducer";
import permissionReducer from "./store";
import {getNavigation, setNavigationList, setUserPermission} from "./store/permissionSlice";
import {getUserMenus} from "../../../redux/auth/navigationSlice";
import JqxGrid, {IGridProps, jqx} from './../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import * as ReactDOM from "react-dom";

const Permission = () => {
    const dispatch = useDispatch();

    let subGrid = useRef([]);
    const [subGridHeight, setSubGridHeight] = useState(320);

    useEffect(() => {
        dispatch(getNavigation()).then(
            res => {
                if (!res.error) dispatch(setNavigationList(res.payload))
            }
        )
    }, [dispatch]);

    let gridRef = useRef(null);
    const permissionState = useSelector(({permission}) => permission.permission);


    const navigationMenu = permissionState.navigations

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;
        }
    };
    const cellEdit = event => {
        // navigationMenu[event.args.row.boundindex].permission.map((value,index) => {
        //     console.log(value[event.args.datafield])
        // });

        // console.log(event.args.datafield)
        const data = {
            id: event.args.datafield,
            title: event.args.row.sidemenu_title,
            status: event.args.value,
            type: event.args.row.type,
        };
        dispatch(setUserPermission(data)).then(res => {
            if (!res.error) dispatch(getUserMenus());
        }, dispatch);
    }

    const nestedGrids: any[] = [];
    const initrowdetails = (index: number, parentElement: any, gridElement: any, record: any) => {

        const nestedGridContainer = parentElement.children[0];
        console.log(parentElement)
        nestedGrids[index] = nestedGridContainer;
        console.log(record);

        let subDataProps = JSON.parse(JSON.stringify(navigationMenu[index].permission));
        subDataProps.legendPosition = 'right';
        Object.preventExtensions(subDataProps)
        const ordersSource = {
            localdata: subDataProps
        }

        let subGridColumns = [];
        (permissionState.userList).map((item, index) => {
            const cellbeginedit = (row: number, datafield: string, columntype: any, value: any): boolean => {
                return record[datafield];
            }
            subGridColumns.push({cellbeginedit:cellbeginedit,...item});
        });


        const nestedGridAdapter = new jqx.dataAdapter(ordersSource);
        if (nestedGridContainer != null) {
            const columns: IGridProps['columns'] = subGridColumns;
            ReactDOM.render(
                <JqxGrid onCellendedit={cellEdit} columns={subGridColumns} id="jqx_sub" ref={jqxRef => {
                    subGrid.current[index] = jqxRef;
                }} editable={true} theme='material'
                         onCellbeginedit={() => {
                             return false;
                         }}
                         height={'100%'}
                         width={'100%'} source={nestedGridAdapter} showtoolbar={false}
                         selectionmode='singlecell' columnsheight={5}/>,
                document.getElementById(nestedGridContainer.id)
            );

        }
    }

    let tempProps = JSON.parse(JSON.stringify(permissionState.navigations));
    tempProps.legendPosition = 'right';
    Object.preventExtensions(tempProps);
    return (
        <Fragment>
            <Breadcrumb parent="Manage Permission" title="Permissions"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                {permissionState.navigations.length > 0 && <JqxTable
                                    initrowdetails={initrowdetails}
                                    columns={permissionState.userList}
                                    data={tempProps}
                                    myGrid={gridRef}
                                    getJqxRef={getJqxRef}
                                    onCellendedit={cellEdit}
                                    rowdetails={true}
                                    rowdetailstemplate={{
                                        rowdetails: '<div id="nestedGrid" style="margin: 1px;border: 1px solid black"></div>',
                                        rowdetailsheight: subGridHeight,
                                        rowdetailshidden: true
                                    }}
                                />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default withReducer([{permission: permissionReducer}])(Permission)