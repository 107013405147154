import React, {Fragment} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/async";

const ProductStyleForm = (props) => {
    const { errors, control, register,setValue,trigger,getValues,setOptions,categoryPromiseOption } = props;

    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">Product category</Label>
                            <AsyncSelect
                                isMulti
                                isClearable
                                cacheOptions
                                defaultOptions
                                defaultValue={getValues('category')}
                                loadOptions={categoryPromiseOption}
                                onChange={(e) => {
                                    setValue('category', e)
                                }}

                            />
                            <div className="invalid-feedback">{errors.type?.message}</div>
                        </FormGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Product Style Name</Label>
                        <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Tag Name</Label>
                        <input name="tag_name" type="text" {...register('tag_name')} className={`form-control ${errors.tag_name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.tag_name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
}

export default ProductStyleForm;