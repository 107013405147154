import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductCompositionsApi, deleteProductCompositionsApi,
    getProductCompositionsApi, updateProductCompositionsApi
} from "../../../services/manage-product/setting/productCompositionService";
import {toast} from "react-toastify";


export const getAllProductCompositions = createAsyncThunk('productComposition/getProductComposition', async (filters, {dispatch})=>{
    const response = await getProductCompositionsApi(filters);
    return response.response;

});

export const addProductComposition = createAsyncThunk('productComposition/addProductComposition', async (composition,{
    dispatch,
    getState
}) => {
    try {
        const response = await addProductCompositionsApi(composition)
        const data = await response.response;
        dispatch(getAllProductCompositions());
        toast.success("Add composition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductComposition = createAsyncThunk('productComposition/updateProductComposition', async (composition, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductCompositionsApi(composition, composition.id);
        const data = await response.response;
        dispatch(getAllProductCompositions());
        toast.success("Update composition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductComposition = createAsyncThunk('productComposition/deleteProductComposition', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductCompositionsApi(id);
        const data = await response.response;
        dispatch(getAllProductCompositions());
        toast.success("Delete composition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const compositionAdapter = createEntityAdapter({});

export const {selectAll:selectComposition, selectById: selectCompositionById} = compositionAdapter.getSelectors(
    state => state.product.composition
);

const compositionSlice = createSlice({
    name: 'composition',
    initialState: compositionAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'composition',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectCompositionSearchText:{
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'composition',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductCompositions.fulfilled]: compositionAdapter.setAll
    }
});

export const {
    selectCompositionSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = compositionSlice.actions;

export default compositionSlice.reducer;