import React, {Fragment, useEffect, useState} from 'react';
import {
    Card, CardBody,
    Col, Container,
    Form,
    FormGroup, Label, Row
} from 'reactstrap'


import {useDispatch, useSelector} from "react-redux";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import {
    closeEditDialog,
    getActivityLogNames, getActivityLogs,
    getUserSettings, openEditDialog, setActivityLogData, setActivityLogNames, setSettings,
    updateProfile
} from "../../store/userSlice";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import DatePicker from "react-datepicker";
import withReducer from "../../../../store/withReducer";
import userReducer from "../../store";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import moment from "moment";
import CustomModal from "../../../../share-components/modal";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../../services/manage-product/productService";
import {userAutoSuggestionApi} from "../../../../services/manage-user/user";
import {ArrowRight} from "react-feather";
import ProductView from "../../../manage-product/product/content/productView";
import {setProductDialog} from "../../../manage-product/store/prodcutSlice";
import {useHistory} from "react-router-dom";


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};


const UserLog = (props) => {
    const {modal_view=true}=props;
    const [loading,setloading]=useState(false);
    const [log,setLog]=useState(null);
    const dispatch = useDispatch();
    let history = useHistory();

    const userState = useSelector(({user}) => user.user);
    const [page,setPage]=useState(1);
    const [selectAction,setSelectAction]=useState(null);
    const [user,setUser]=useState(null);

    const TodayDate=new Date();
    const sevenDays=new Date().setDate(TodayDate.getDate()-7);
    const [dateRange, setDateRange] = useState([ sevenDays,TodayDate]);
    const [startDate, endDate] = dateRange;

    const tableColumns = [
        {
            name: 'Section Name',
            selector: row => row.section_name,
            sortable: true,
            center: false,
            width:"15%"
        }, {
            name: 'Action Description',
            selector: row => row.description.length>25?<abbr title={row.description}>{row.description.substring(0, 25)}</abbr>:row.description,
            sortable: true,
            center: false
        }, {
            name: 'User',
            selector: row => row.user,
            sortable: true,
            center: false,
            omit:modal_view
        }, {
            name: 'Roles',
            selector: row => row.roles,
            sortable: true,
            center: false,
            omit:modal_view
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },{
            name: 'View',
            selector: row => row.properties.length>0?(<div className={"px-3"}>
                    {loading?"-":(
                    <>
                        <a onClick={() => {viewDialog(row);}}>
                            <i className="fa fa-eye"
                               style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)'}}></i></a>
                    </>)}
                </div>):"-",
            sortable: false,
            center: true,
        }
    ];

    useEffect(() => {
        dispatch(getActivityLogNames()).then(res => {
            if (!res.error) dispatch(setActivityLogNames(res.payload.message));
        });
    }, [dispatch]);

    useEffect(() => {
        if(startDate && endDate){
            const set={
                page,
                pageSize:10,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                log,
                user
            };
            setloading(true);
            dispatch(getActivityLogs(set)).then(res => {
                if (!res.error) {dispatch(setActivityLogData(res.payload))};
                setloading(false);
            });
        }

    }, [startDate,endDate,log,page,user]);

    const viewDialog= (row)=>{
        setSelectAction(row.title);
        dispatch(openEditDialog(row));
    }

    const closeDialog = () =>{
        dispatch(closeEditDialog());
    }

    const viewOrder = (id)=>{
        history.push('/order/' + id + '/view');
    }

    const userPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data ={
                value: inputValue
            }
            setTimeout(() =>{
                resolve(mapSearchAutoSuggestion(userAutoSuggestionApi(data)))
            });
        });


    return (
        <Fragment>
            <Row>
                <Col sm={modal_view?"6":"4"}>
                    <FormGroup>
                        {modal_view?<Label className="col-form-label">Date Range</Label>:""}
                        <div className="dashboard-datepicker">
                            <DatePicker
                                selectsRange={true}
                                className={modal_view?'form-control':'form-control hide-border-control'}
                                dateFormat="dd/MM/yyyy"
                                startDate={startDate}
                                maxDate={TodayDate}
                                endDate={endDate}
                                styles={customStyles}
                                onChange={(update) => {
                                    setDateRange(update);
                                }}
                                placeholderText="Search by Date Range"
                                isClearable={false}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col sm={modal_view?"6":"4"}>
                    <FormGroup>
                        {modal_view?<Label className="col-form-label">Log Name</Label>:""}
                    {userState.activityLogNames &&
                    <Select className="select2-filter"
                            styles={modal_view?"":customStyles}
                            isClearable={true}
                            onChange={(e) => setLog(e.value)}
                            options={userState.activityLogNames}/>}
                    </FormGroup>
                </Col>
                {!modal_view &&
                <Col sm={modal_view?"6":"4"}>
                    <AsyncSelect
                        className="select2-filter"
                        isClearable={true}
                        defaultOptions
                        styles={modal_view?"":customStyles}
                        placeholder="Select User"
                        loadOptions={userPromiseOption}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onClear={(e) => {

                        }}
                        onChange={(e) => {
                            setUser(e?e.value:null);
                        }}

                    />
                </Col>}

            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <div className="table-responsive product-table">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    gridData={userState.activityLogData?userState.activityLogData:[]}
                                    noAction
                                    progressPending={loading}
                                    paginationServer
                                    paginationTotalRows={userState.activityLogTableRow}
                                    paginationPerPage={10}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                />

                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CustomModal
                title={selectAction}
                subTitle={userState.addDialog.data && userState.addDialog.data.description}
                dialog={({user}) => user.user.addDialog}
                closeDialog={closeDialog}
            >

                {
                    userState.addDialog.data && userState.addDialog.data.properties.map((item, index) =>
                        item.type===0? <div><span className="d-inline-flex"><b>{item.key.replace(/\*/g, "")}</b>: <p className="ml-1 text-danger">{item.old}</p> <ArrowRight size={20} /> <p className="text-success">{item.new}</p></span></div>
                            :  <div><h6 className="text-capitalize"><u>{item.key.replace(/\*/g, "")}</u></h6> {item.data.map((subItem, index) =>   <div><span className="d-inline-flex"><b>{subItem.key.replace(/\*/g, "")}</b>: <p className="ml-1 text-danger">{subItem.old}</p> <ArrowRight size={20} /> <p className="text-success">{subItem.new}</p></span></div>)}<hr/></div>

                )
                }
                {
                    userState.addDialog.data && userState.addDialog.data.section_name==="Product" && <button onClick={()=>{ let obj = {props: {isOpen: true}, data: {id:userState.addDialog.data.id }};
                        dispatch(setProductDialog(obj))}} className="btn btn-light"><i className= "icon-eye"></i></button>
                }
                {
                    userState.addDialog.data && ((userState.addDialog.data.section_name==="Order Tracking" || userState.addDialog.data.section_name==="Order Refund") && userState.addDialog.data.id!=="") && <button onClick={()=>{viewOrder(userState.addDialog.data.id)}} className="btn btn-light"><i className= "icon-eye"></i></button>
                }
            </CustomModal>
            <ProductView></ProductView>
        </Fragment>
    );

};
export default withReducer([{user: userReducer}])(UserLog)


