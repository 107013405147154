import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import {
  getCurrencyRateApi,
  addSalesOrderApi,
  getSalesOrderApi,
  updateSalesOrderApi,
  showSalesOrderApi,
  editSaleOrderApi,
  getSalesOrderCommentApi,
  addSalesOrderCommentApi,
  showSalesOrderCommentApi,
  updateSalesOrderCommentApi,
  deleteSalesOrderCommentApi,
  updateSalesOrderStatusApi,
  downloadSalesOrderPickingTicketPdfApi,
  sendSalesOrderInvoiceToXeroApi,
} from "../../../services/manage-order/saleOrderService";
import {downloadSalesOrderInvoicePdfApi} from "../../../services/manage-order/saleOrderInvoiceService";

export const getSalesOrders = createAsyncThunk(
  "salesOrder/getSalesOrder",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderApi(filters);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
  }
);

export const getExchangeRate = createAsyncThunk(
  "sales-order/get-exchange-rate",
  async (data, { dispatch }) => {
    const response = await getCurrencyRateApi(data);
    return response.response;
  }
);

export const addSalesOrders = createAsyncThunk(
  "salesOrder/addSalesOrder",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderApi(orderData);
      const data = await response.response;
      toast.success("Sales Order created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const sendSalesOrderInvoiceToXero = createAsyncThunk(
  "salesOrder/sendSalesOrderInvoiceToXero",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await sendSalesOrderInvoiceToXeroApi(orderData);
      const data = await response.response;
      toast.success("Sales Order Invoice sent to Xero successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const editSaleOrder = createAsyncThunk(
  "orders/draft/show",
  async (id, { dispatch }) => {
    const response = await editSaleOrderApi(id);
    return response.response;
  }
);

export const getSalesOrder = createAsyncThunk(
  "salesOrder/showSalesOrder",
  async (id, { dispatch }) => {
    const response = await showSalesOrderApi(id);
    return response.response;
  }
);

export const updateSalesOrder = createAsyncThunk(
  "salesOrder/updateSalesOrder",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderApi(orderData, orderData.id);
    toast.success(`Sales Order updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const updateSalesOrderStatus = createAsyncThunk(
  "salesOrder/updateSalesOrderStatus",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderStatusApi(orderData, orderData.id);
    toast.success(`Sales Order Status updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const showSalesOrder = createAsyncThunk(
  "salesOrder/showSalesOrder",
  async (id, { dispatch }) => {
    const response = await showSalesOrderApi(id);
    return response.response;
  }
);

// COMMENT
export const addSalesOrderComment = createAsyncThunk(
  "salesOrder/addSalesOrderComment",
  async (formData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderCommentApi(formData);
      const data = await response.response;
      toast.success("Sales Order comment created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getSalesOrderComment = createAsyncThunk(
  "salesOrder/getSalesOrderComment",
  async (salesOrderId) => {
    const response = await getSalesOrderCommentApi({
      sales_order_id: salesOrderId,
    });
    console.log("Response on Get " + response.response.data);
    return response.response.data;
  }
);

export const showSalesOrderComment = createAsyncThunk(
  "salesOrder/showSalesOrderComment",
  async (id, { dispatch }) => {
    const response = await showSalesOrderCommentApi(id);
    console.log("Response on Show " + response.response);
    return response.response;
  }
);

export const updateSalesOrderComment = createAsyncThunk(
  "salesOrder/updateSalesOrderComment",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderCommentApi(orderData, orderData.id);
    toast.success(`Sales Order updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const deleteSalesOrderComment = createAsyncThunk(
  "salesOrder/deleteSalesOrderComment",
  async (id, { dispatch }) => {
    try {
      const response = await deleteSalesOrderCommentApi(id);
      const data = await response.response;
      toast.success("Comment deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const downloadSalesOrderPickingTicketPdf = createAsyncThunk(
  "salesOrder/downloadPdfTicket",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderPickingTicketPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const downloadSalesOrderInvoicePdf = createAsyncThunk(
  "salesOrder/downloadInvoicePdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderInvoicePdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

const salesOrderAdapter = createEntityAdapter({});

export const { selectAll: selectSalesOrder, selectById: selectSalesOrderById } =
  salesOrderAdapter.getSelectors((state) => state.order.salesOrder);

console.log("Select S : " + selectSalesOrder);

const saleOrderSlice = createSlice({
  name: "salesOrder",
  initialState: salesOrderAdapter.getInitialState({
    id: null,
    sellerTableRow: 0,
    searchText: "",
    data: null,
    orderData: null,
    settings: {
      countries: [],
      shippings: [],
      types: [],
      statuses: [],
      customers: [],
    },
    addressDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    dutyDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    products: {
      data: [],
      pagination: {
        count: 0,
        next_page: 1,
        total: 0,
        has_more: true,
      },
    },
    comments: [],
  }),
  reducers: {
    setSalesOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.sellerTableRow = action.payload;
    },
    setAddressDialog: (state, action) => {
      state.addressDialogProps = action.payload;
    },
    setDutyDialog: (state, action) => {
      state.dutyDialogProps = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setSettings: (state, action) => {
      state.settings.types = action.payload.returnReasons.admin;
      state.settings.statuses = action.payload.returnReasons.customer;
      state.settings.customers = action.payload.countries;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getSalesOrders.fulfilled, salesOrderAdapter.setAll)
  //     .addCase(getSalesOrderComment.fulfilled, (state, action) => {
  //       // Update comments using the comments field in the state
  //       state.comments = action.payload;
  //     });
  // },
  extraReducers: {
    [getSalesOrders.fulfilled]: salesOrderAdapter.setAll,
    [getSalesOrderComment.fulfilled]: (state, action) => {
      state.comments = action.payload;
    },
  },
});

// export const selectAllSalesOrderComments = (state) => state.order.salesOrder.comments;
// console.log(selectAllSalesOrderComments);
export const {
  setAddressDialog,
  setTableRowCount,
  setDutyDialog,
  setOrderData,
  setSettings,
} = saleOrderSlice.actions;

export default saleOrderSlice.reducer;
