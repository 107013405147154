import React, {useState} from 'react';
import TagsInput from "react-tagsinput";

;


function CustomTagsInput(props) {
    const {value, tagChangeEvent} = props;

    const handelBlur = (e) => {
        let tagArray = [];
        value.map((item, i) => {
            tagArray.push(item);
        });
        if (e.target.value) {
            const splitValues = (e.target.value).split(",");
            splitValues.map((item, i) => {
                tagArray.push(item);
            });
            setTagValue("")
        }
        tagChangeEvent(tagArray)
    }
    const [tagValue, setTagValue] = useState();

    return (
                <>
                    <TagsInput className="form-control tag-input-custom"
                                     onlyUnique={true}
                                     inputProps={{onBlur: handelBlur}}
                                     inputValue={tagValue} onChangeInput={(e) => {
                        setTagValue(e);
                    }}
                                     value={value}
                                     onChange={(value) => {
                                         tagChangeEvent(value);
                                     }}
                    />
                </>
    );
}

export default CustomTagsInput;