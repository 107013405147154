import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useSelector} from "react-redux";
import ButtonLoader from "../ui/buttonLoader";


export default function ModalFormSubmit(props) {
    const {
        dialog,
        defaultValues,
        reset,
        handleSubmit,
        title,
        closeDialog,
        onSubmitForm,
        size = "md",
        isUseSelector,
        publish,
        setValue,
        getValues,
        btnText,
        btnDisabled=false,
        publishValue,
        saveValue,
        loadingBtn,buttonDisable,
        hide
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const addEditDialog = isUseSelector ? dialog : useSelector(dialog);

    const initDialog = useCallback(() => {
        /**
         * Dialog type: 'edit'
         */
        if (addEditDialog.type === 'edit' && addEditDialog.data) {
            reset({...addEditDialog.data});
        }

        /**
         * Dialog type: 'new'
         */
        if (addEditDialog.type === 'new') {
            reset({
                ...defaultValues,
                ...addEditDialog.data
            });
        }
    }, [addEditDialog.data, addEditDialog.type, reset]);
    useEffect(() => {
        if (addEditDialog.props.isOpen) {
            initDialog();
        }
    }, [addEditDialog.props.isOpen, initDialog]);
    const closeModalDialog = () => {
        closeDialog()
    }

    function onSubmit(data) {
        return onSubmitForm(addEditDialog, data);
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...addEditDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    {addEditDialog.type === 'new' ? `Add ${title}` : `Update ${title}`}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>

                        {props.children}

                    </ModalBody>

                    {!publish? <ModalFooter>
                    <Col>
                    <Button disabled={buttonDisable} style={{float: "right"}} color="secondary" onClick={() => {
                            closeModalDialog()
                    }}>Cancel</Button>
                       </Col>
                            {loadingBtn?
                                <ButtonLoader disabled={buttonDisable || btnDisabled} onClick={() => {
                                    {publish && setValue('publish_at', saveValue)}
                                }} color="primary" type="submit" btntext="Save" />
                            :
                                <ButtonLoader onClick={() => {
                                    {publish && setValue('publish_at', saveValue)}}} color="success" value="0" className="mr-2"
                                              type="submit" disabled={buttonDisable || btnDisabled}
                                              btntext="Save"></ButtonLoader>
                                // <Button disabled={btnDisabled} onClick={() => {
                                //     {publish && setValue('publish_at', saveValue)}
                                // }} color="primary" type="submit">Save</Button>
                            }


                    </ModalFooter>:
                        <Row style={{marginBottom: 20}}>
                            <Col>
                                <Button className="ml-3" color="secondary"  onClick={() => {
                                    closeModalDialog()
                                }}>Cancel</Button>
                            </Col>

                            <ButtonLoader onClick={() => {
                                {publish && setValue('publish_at', saveValue)}}} color="success" value="0" className="mr-2"
                                          type="submit" disabled={buttonDisable}
                                          btntext="Save"></ButtonLoader>

                            {/*<Button style={{marginRight: 10}} onClick={() => {*/}
                            {/*    {publish && setValue('publish_at', saveValue)}*/}
                            {/*}} color="primary" type="submit">Save</Button>*/}

                            {publish && <ButtonLoader onClick={() => {
                                setValue('publish_at', publishValue)
                            }} style={{marginRight: 30, width:"auto"}} disabled={buttonDisable} color="primary" value="1"
                                                      type="submit"
                                                      btntext={btnText}></ButtonLoader>}

                        </Row>}
                </Form>
            </Modal>

        </div>
    );
}
