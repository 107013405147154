import React, {Fragment} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";


const SettingForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions,
        showHs=false,
        formText
    } = props;

    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Product {formText} Name</Label>
                        <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            {showHs &&
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">HS (Harmonized System) Code</Label>
                        <input name="hs_code" type="text" {...register('hs_code')} className={`form-control ${errors.hs_code ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.hs_code?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            }
        </Fragment>
    );
};

export default SettingForm;