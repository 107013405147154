import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import {
    closeNewAddressDialog, createBuyerLead,
    getBuyerLeadSettings,
    getSelectedBuyerLeadData,
    openEditAddressDialog, openNewAddressDialog,

} from "../store/buyerLeadSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";

import BuyerLeadAddressForm from "../form/buyerLeadAddressForm";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import {getSellerLeadSettings,setSettings} from "../store/sellerLeadSlice";
import {getCountryList, getCountryListData} from "../../manage-order/store/draftOrderSlice";
import {toast} from "react-toastify";

export const defaultAddressValues = {
    company:'',
    type:'',
    country: '',
    first_name: '',
    last_name:'',
    address1: '',
    address2: '',
    city:"",
    zip:'',
    phone:'',
    province:'',


};

const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});
export const addressSchema = yup.object().shape({
    address_1: yup.string().required("Address line 1 is required").typeError('Address line 1 is required'),
    city: yup.string().required("City is required").typeError('City is required'),
    // province: yup.string().required("Province is required").typeError('Province is required'),
    zip: yup.string().required("Zip is required").typeError('Zip is required'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    phone_number: yup.string().min(9, 'Invalid Phone number')
        .max(10, 'Invalid Phone number').required("Phone number is required field").typeError('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    province: yup.object().when(['country'],(country)=>{
        if (country) {
            if (country.id!==242) {
                return yup.string().nullable()
            } else {
                return yup.string().required("State is required")

            }
        } else {
            return yup.string().nullable()
        }
    })
});


const dataMenu = [
    {
        type: 1,
        text: 'Buyer Leads',
        link: '/leads/buyer-lead'
    },
    {
        type: 0,
        text: 'Create Buyer Update',
        link: ''
    },
];

export const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address_line_1: '',
    address_line_2: '',
    town: '',
    state: '',
    zip: '',
    country: '',
    company_name: '',
};

export const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required field"),
    last_name: yup.string().required("Last Name is required field"),
    email: yup.string().required("Email is required field"),
    phone: yup.string().min(9, 'Invalid Phone number')
        .max(10, 'Invalid Phone number').required("Phone number is required field").typeError('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    company_name: yup.string().required("Company Name is required field").typeError('Company Name is required field'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    state: yup.object().when(['country'],(country)=>{
        if (country) {
            if (country.id!=242) {
                return yup.string().nullable()
            } else {
                return yup.string().required("State is required")

            }
        }
        return yup.string().nullable()
    })
});

const UpdatePrimaryBuyerLead = () =>{
    const {id} = useParams();
    const formRef = useRef();
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const dispatch = useDispatch();
    const sellerLeadState = useSelector(({lead}) => lead.buyerLead);
    const [addressArray, setAddressArray] = useState([]);
    const [checkedValues, setCheckedValues] = useState([]);
    const [active, setActive] = useState(true);
    let history = useHistory();

    const draftOrderState = useSelector(({order})=> order.draftOrder);

    const onSubmitForm = (data) => {
        const formData = {
            "id" : id,
            "first_name": data.first_name,
            "last_name": data.last_name,
            "email": data.email,
            "phone": data.phone,
            "address_line_1": data.address_line_1,
            "address_line_2": data.address_line_2,
            "town": data.town,
            "state": data.state,
            "zip": data.zip,
            "company_name": data.company_name,
            "country": data.country ? data.country.id : 241,
            "address_array" : data.address_array
        };

        submitBtnSaveRef.current.disabled=true;
        dispatch(createBuyerLead({...formData})).then(res=> {
            if(!res.error){
                // if (res.payload.id) {
                    toast.success("Buyer Lead Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                    history.push({
                        pathname: '/leads/buyer-lead',
                        state: {from: "submit"}
                    });
                // }
            }
            submitBtnSaveRef.current.disabled=false;
        });
    }

    const {
        handleSubmit:addressHandleSubmit,
        formState:addressFormState,
        control: addressControl,
        reset: addressReset,
        register: addressRegister,
        trigger: addressTrigger,
        setValue: addressSetValue,
        getValues: addressGetValues} = useForm({
        mode: 'onChange',
        defaultAddressValues,
        resolver: yupResolver(addressSchema)
    });


    const {handleSubmit, formState, reset, register, setValue, getValues, trigger, control} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors:addressErrors} = addressFormState;
    const {errors} = formState;

    useEffect(() => {
        dispatch(getSelectedBuyerLeadData(id)).then(res => {
            reset(res.payload);
            setAddressArray(res.payload.address_array)
            // setFormData(res.payload);
        });
    },[dispatch]);

    useEffect(()=>{
        dispatch(getCountryList()).then(res => {
            dispatch(getCountryListData(res.payload));
        });
    },[dispatch]);

    const onEdit = editId => {
        const addArray = [...addressArray];
        const dataObj = {...addArray[editId], id: editId};
        const obj = {
            type: 'edit',
            isOpen: true,
            data: dataObj
        };
        dispatch(openEditAddressDialog(obj))
    }

    const closeDialog = () => {
        dispatch(closeNewAddressDialog());
    }

    const onSubmitFormData = (dialog, data) => {
        let addressArr;
        addressArr = [...addressArray];
        let addressStatus = true;
        // eslint-disable-next-line array-callback-return

        if (addressStatus) {
            if (dialog.type === 'edit') {

                addressArray[data.id] = {...addressArray[data.id], ...data};
            } else {
                // eslint-disable-next-line array-callback-return,consistent-return
                addressArray.map((item, i) => {});
                addressArr = [
                    ...addressArray,
                    {
                        ...data,
                        isPrimary: !(addressArray.length > 0)
                    }
                ];
                setAddressArray(
                    addressArr
                )
            }
            closeDialog();
        }
    }

    const deleteAddress = editId => {
        const addressArr = [...addressArray];
        addressArr.splice(editId, 1);
        setAddressArray(addressArr)
    };

    const changeDefault = (value, editId) => {
        let vals = [...checkedValues];
        vals = [];
        vals.push(editId);
        setCheckedValues(vals)
        setActive(true);

        const array = [...addressArray];
        // eslint-disable-next-line array-callback-return
        array.map((data, index) => {
            array[index] = {...array[index], is_default: 0};
        });
        if (value.target.checked) {
            array[editId] = {...array[editId], is_default: 1};
        } else {
            array[0] = {...array[0], is_default: 1};
        }
        setAddressArray(array);
    };

    const itemsPerPage = 6;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = addressArray.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(addressArray.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % addressArray.length;
        setItemOffset(newOffset);
    };


        return (
            <Fragment>
                <Breadcrumb data={dataMenu} title="Buyer Leads Update"/>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                    <CardBody>
                                        <Row>
                                            <Col className="mb-3" md={12}>
                                                <p className="sub-title font-weight-bold">Primary Details</p>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">First Name<span
                                                        style={{color: "red"}}>*</span></Label>
                                                    <input name="first_name" type="text"
                                                           className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                                           placeholder="Enter First Name" {...register('first_name')}  />
                                                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">Last Name<span
                                                        style={{color: "red"}}>*</span></Label>
                                                    <input name="last_name" type="text"
                                                           className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                                           placeholder="Enter Last Name" {...register('last_name')}  />
                                                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">Email<span style={{color: "red"}}>*</span></Label>
                                                    <input name="email" type="text"
                                                           className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                           placeholder="Enter Email" {...register('email')}  />
                                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">Country<span
                                                        style={{color: "red"}}>*</span></Label>
                                                    <Select placeholder="Country"
                                                            getOptionLabel={(option) => option.name}
                                                            value={getValues('country')}
                                                            getOptionValue={(option) => option.id} {...register("country")}
                                                            onChange={(e) => {
                                                                setValue('country', e);
                                                                trigger('country');
                                                                setDialCode(e.dial_code)
                                                            }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                                            options={draftOrderState.countryList}/>
                                                    <div className="invalid-feedback">{errors.country?.message}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">Phone Number<span
                                                        style={{color: "red"}}>*</span></Label>
                                                    <FormGroup className="input-group">
                                                        <span className="input-group-text">{dialCode}</span>
                                                        <input name="phone" type="text"
                                                               className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                                               placeholder="Phone Number" {...register('phone')}  />
                                                        <div className="invalid-feedback">{errors.phone?.message}</div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label className="form-label">Company Name<span
                                                        style={{color: "red"}}>*</span></Label>
                                                    <input name="company_name" type="text"
                                                           className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                                                           placeholder="Enter Company Name" {...register('company_name')}  />
                                                    <div className="invalid-feedback">{errors.company_name?.message}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col className="mb-3 mt-3" md={12}>
                                                <p className="sub-title font-weight-bold">Address</p>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col sm="6">
                                                        {/*<div className=" mr-2 mt-3">*/}
                                                        {/*    <span style={{backgroundColor: '#d8f2e5'}} className="mr-2 badge rounded-pill"> </span>*/}
                                                        {/*    /!*<Badge style={{backgroundColor: '#939db8 !important'}} className="mr-2 badge-red" pill>{' '}</Badge>*!/*/}
                                                        {/*    <span>Default Address</span>*/}
                                                        {/*</div>*/}
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className='float-sm-right p-3'>
                                                            <a className="btn btn-sm btn-primary"
                                                               onClick={() => {
                                                                   dispatch(openNewAddressDialog())
                                                               }}
                                                            > <i className="fa fa-plus"></i> Add Address</a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {currentItems?.map((item, index) => (
                                                        <Col key={index} md={4} sm={12}>
                                                            <Card style={{backgroundColor: item.is_default===1 ? '#d8f2e5' : ''}}>
                                                                <CardBody>
                                                                    <Row className="">
                                                                        <Col md={6}>
                                                                            <input onChange={(e) => {
                                                                                changeDefault(e, index)
                                                                            }} id="inline-3" name="group1" type="checkbox"
                                                                                   checked={checkedValues.includes(index) || item.is_default}/>
                                                                            &nbsp;&nbsp;
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                                <a onClick={() => {
                                                                                    onEdit(index);
                                                                                }}><i className="fa fa-pencil"
                                                                                      style={{
                                                                                          width: 25,
                                                                                          fontSize: 16,
                                                                                          color: 'rgb(40, 167, 69)'
                                                                                      }}></i></a>
                                                                                <a onClick={() => {
                                                                                    deleteAddress(index);
                                                                                }}><i className="fa fa-trash" style={{
                                                                                    width: 25,
                                                                                    fontSize: 16,
                                                                                    color: '#e4566e'
                                                                                }}></i></a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <p className="mb-0 text-center" style={{
                                                                        fontSize: 'large',
                                                                        fontWeight: 'bold'
                                                                    }}>{item.first_name + ' ' + item.last_name}</p>
                                                                    <p className="mb-0 text-center f-2">{item.address_1}</p>
                                                                    <p className="mb-0 text-center f-2">{item.city}</p>
                                                                    <p className="mb-0 text-center f-2">{item.zip}</p>
                                                                    <p className="mb-0 text-center f-2">{item.country && item.country.name?item.country.name:''}</p>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <ReactPaginate
                                                    className="pagination"
                                                    activeClassName="pagination-active"
                                                    breakLabel="..."
                                                    nextLabel="next >"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="< previous"
                                                    renderOnZeroPageCount={null}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="mt-2 d-flex justify-content-end">
                                            <div className="p-2">
                                                <button ref={submitBtnSaveRef} onClick={() => {
                                                    formRef.current.submit();
                                                    // setActionBtn(0)
                                                }} className="btn btn-primary btn-block">Save
                                                </button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalFormSubmit
                    defaultValues={defaultAddressValues}
                    schema={addressSchema}
                    onSubmitForm={onSubmitFormData}
                    reset={addressReset}
                    formState={addressFormState}
                    handleSubmit={addressHandleSubmit}
                    title='Buyer Lead Address'
                    selector='BuyerLead'
                    dialog={sellerLeadState.addressAddDialog}
                    closeDialog={closeDialog}
                    setValue={addressSetValue}
                    getValues={addressGetValues}
                    isUseSelector
                    size="xl"
                    publish={false}
                >
                    <BuyerLeadAddressForm countries={draftOrderState.countryList} control={addressControl}
                                          formText="Buyer Lead Address" errors={addressErrors} register={addressRegister}
                                          setValue={addressSetValue}
                                          trigger={addressTrigger} getValues={addressGetValues}/>
                </ModalFormSubmit>
            </Fragment>
        );
}

export default UpdatePrimaryBuyerLead;