import React, {Fragment, useState} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Switch from "react-switch";
import { useSelector } from "react-redux";


const ReturnReasonsSettingFrom=(props)=>{
    const currentEditableData=useSelector((store)=>store.returnReasonSetting.returnReasonSetting.addDialog.data);
    const [isRestock,setIsRestock]=useState(currentEditableData&&currentEditableData.is_restock==='Yes'?true:false);
    const [isSeller,setIsSeller]=useState(currentEditableData&&currentEditableData.is_seller==='Yes'?true:false);
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        returnReasonType

    } = props;
    const handleChange=()=>{
        setIsRestock(!isRestock)
    }
    const handleChangeSeller=()=>{
        setIsSeller(!isSeller)
    }
    setValue('isRestock',isRestock);
    setValue('isSeller',isSeller);
    returnReasonType==='admin'?setValue('type',1):setValue('type',2);
    if(returnReasonType==='admin'){
        return(
            
            <Fragment>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label className="col-form-label">Return Reason Name</Label>
                            <input type="text" {...register('name')}
                                   className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </FormGroup>
                    </Col>
                
                </Row>
                <Row>
                <Col md="6">
                    <label style={{marginTop: 8, marginLeft: 4}}>Is Restock</label>
                    <FormGroup style={{marginTop: 1, marginLeft: 5}}>
                        <Switch
                            checked={isRestock}
                            onChange={handleChange}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            
                        />
    
                    </FormGroup>
                    </Col>
                    <Col md="6">
                    <label style={{marginTop: 8, marginLeft: 4}}>Is Seller</label>
                    <FormGroup style={{marginTop: 1, marginLeft: 5}}>
                        <Switch
                            checked={isSeller}
                            onChange={handleChangeSeller}
                            className="react-switch"
                            uncheckedIcon={false}
                            checkedIcon={false}

                        />

                    </FormGroup>
                    </Col>
                </Row>
                            <input type="hidden" {...register('type')}/>
                            <input type="hidden" {...register('id')}/>
            </Fragment>
        )
        }
        else{
            return(<Fragment>
                 <Row>
                    <Col>
                        <FormGroup>
                            <Label className="col-form-label">Return Reason Name</Label>
                            <input type="text" {...register('name')}
                                   className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.name?.message}</div>
                            <input type="hidden" {...register('type')}/>
                            <input type="hidden" {...register('id')}/>
                        </FormGroup>
                    </Col>
                
                </Row>
            </Fragment>)
        }
    }
   

export default ReturnReasonsSettingFrom;