import API from "../utils/api";


export const getSellerStaffsApi = async (data) => {
    const response = await API.get('seller-staff/seller-staff/1',{params:data});
    return { response };
};

export const addSellerStaffApi = async location => {
    const response = await API.post('seller-staff/seller-staff', location);
    return { response };
};

export const updateSellerStaffApi = async (data, id) => {
    const response = await API.post(`seller-staff/seller-staff/${id}`, data);
    return { response };
};

export const getSellerStaffNavigationApi = async () => {
    const response = await API.get('permission/get-user-permission');
    return { response };
}

export const getSelectedSellerStaffDataApi = async (id) => {
    const response = await API.get(`seller-staff/get-selected-seller-staff/${id}`)
    return { response };
}