import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {ButtonGroup} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {useForm} from "react-hook-form";
import {defaultValues} from "../settings";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {addSellerLead} from "../store/sellerLeadSlice";
import {mapSellerLeadResponseToCompanyProfileForm} from "../../../utils/mapper";
import {useHistory} from "react-router-dom";
import {addBuyerLead, setDialCodeValue, setSaveState} from "../store/buyerLeadSlice";
import {toast} from "react-toastify";
import moment from "moment";
import PreLoader from "../../../share-components/ui/preLoader";

const phoneRegExp =/^[0-9]{9,10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});

export const schema = yup.object().shape({
    trading_name: yup.string('').required("Trading name is required").typeError('Trading name is required'),
    company_name: yup.string("should be text").required("Company name is required").typeError('Company name is required'),
    registration_number:yup.object().when(['is_registered'],(is_registered) =>{
        //check business registered or not
        if (is_registered==="1") {
            return yup.string().required("Business registration number is required").typeError("Business registration number is required");
        } else {
            return yup.string().nullable();
        }
    }),
    phone_number: yup.string().min(9, 'Invalid phone number')
        .max(10, 'Invalid phone number').required("Phone Number is required").typeError('Phone Number is required').customPhoneValidation('You must enter valid phone number'),
    country_id: yup.object().required('Country is required field').typeError('Please select Country'),
});

export const CompanyProfile = (props) => {
    const {sellerLeadState, id, VerticleTab, setVerticleTab, formData, buyer} = props;
    const dispatch = useDispatch();
    const [actionBtn, setActionBtn] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const submitBtnSaveRef = useRef();
    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    let history = useHistory();
    const [dialCode, setDialCode] = useState('');

    useEffect(() => {
        dispatch(setDialCodeValue(dialCode))
    },[dialCode]);


    useEffect(() => {
        if (id){
            if (formData.length != 0) {
                reset(mapSellerLeadResponseToCompanyProfileForm(formData));
                setDialCode(formData.dial_code??'')
                setLoading(false);
                if (formData.is_registered) {
                    setIsRegister(true);
                } else {
                    setIsRegister(false);
                }
            }
        } else {
            setLoading(false);
        }

    }, [formData]);

    const lineOfBusiness = [];

    const {errors} = formState;



    console.log(dialCode)

    const handleSellerSinceChange = date => {

    }

    const submitBtnRef = useRef();

    const isRegistered = (status, value) => {
        if (status.target.value === "1") {
            setIsRegister(true);
        } else {
            setIsRegister(false);
        }
    }

    const setLineOfBusiness = (e, id) => {
        if (e.target.checked) {
            lineOfBusiness.push(id)
        } else {
            const index = lineOfBusiness.indexOf(id);
            if (index > -1) {
                lineOfBusiness.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setValue("line_of_business", lineOfBusiness);
    }

    const onSubmitForm = (data) => {
        console.log(data.country_id)
        // submitBtnSaveRef.current.disabled=true;
        data.country_id = data.country_id ? data.country_id.id : '';
        data.customer_representative = data.customer_representative ? data.customer_representative.id : '';
        data.incorporated_date = data.incorporated_date ? moment(data.incorporated_date).format('YYYY-MM-DD') : null;
        // data.phone_number = data.phone_number ? data.country_id.dial_code+data.phone_number : null;
        const finalData = {
            id: id,
            formData: data,
            type: 1
        }
        if (buyer) {
            dispatch(addBuyerLead({...finalData})).then(res => {
                if (!res.errors)
                    if (!res.payload.error) {
                        if (actionBtn == 0) {

                            toast.success("Company Profile Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                            submitBtnSaveRef.current.disabled=false;
                            setVerticleTab((parseInt(VerticleTab) + 1).toString());
                            dispatch(setSaveState(true));
                            if (res.payload.id) {
                                history.push('/leads/buyer-lead/'+res.payload.id+ '/edit');
                            }
                        } else {
                            submitBtnSaveRef.current.disabled=false;
                            history.push({
                               pathname: '/leads/buyer-lead',
                               state: { from: "submit" }
                            });
                        }
                    } else {
                        submitBtnSaveRef.current.disabled=false;
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }

            });
        } else {
            dispatch(addSellerLead({...finalData})).then(res => {

                if (!res.errors)
                    if (!res.payload.error) {
                        if (actionBtn == 0) {
                            toast.success("Company Profile Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                            setVerticleTab((parseInt(VerticleTab) + 1).toString());
                        } else {
                            history.push('/leads/seller-lead');
                        }
                    } else {
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }
            });
        }
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                {loading ?<><PreLoader></PreLoader></> : <>
                <Row>
                    <Col md={6}>
                        {buyer ? <FormGroup>
                            <Label className="form-label">Buyer Type</Label>
                            <br/>
                            <ButtonGroup className="m-l-5">
                                <div className="radio radio-primary">
                                    <input
                                        {...register(`buyer_type`)} id="radio7" type="radio" name="buyer_type"
                                        value="1"/>
                                    <Label htmlFor="radio7">Customer</Label>
                                </div>
                                <div className="radio radio-primary m-l-20">
                                    <input
                                        {...register(`buyer_type`)} id="radio8" type="radio" name="buyer_type"
                                        value="2"/>
                                    <Label htmlFor="radio8">Lead</Label>
                                </div>
                            </ButtonGroup>
                        </FormGroup> : ''}
                    </Col>
                    <Col md={6}>
                        {buyer ?
                            <FormGroup>
                                <Label className="form-label">Customer Representative / Agent</Label>
                                <Select placeholder="Customer Representative / Agent" getOptionLabel={(option) => option.name}
                                        value={getValues('customer_representative')}
                                        getOptionValue={(option) => option.id} {...register("customer_representative")}
                                        onChange={(e) => {
                                            setValue('customer_representative', e);
                                            trigger('customer_representative');
                                        }} className={` ${errors.customer_representative ? 'is-invalid' : ''}`}
                                        options={sellerLeadState.settings.customerRepresentative}/>
                                <div className="invalid-feedback">{errors.customer_representative?.message}</div>
                            </FormGroup>
                            : ''}
                    </Col>
                    {/*{buyer ?*/}
                    {/*    <Col md={12}>*/}
                    {/*        <FormGroup>*/}
                    {/*            <Label className="form-label">Name</Label>*/}
                    {/*            <input name="name" type="text"*/}
                    {/*                   className={`form-control ${errors.name ? 'is-invalid' : ''}`}*/}
                    {/*                   placeholder="Name" {...register('name')}  />*/}
                    {/*            <div className="invalid-feedback">{errors.name?.message}</div>*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col> : ''}*/}
                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Trading Name</Label>
                            <input name="trading_name" type="text"
                                   className={`form-control ${errors.trading_name ? 'is-invalid' : ''}`}
                                   placeholder="Trading Name" {...register('trading_name')}  />
                            <div className="invalid-feedback">{errors.trading_name?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <Label className="form-label">Is your business registered?</Label>
                        <br/>
                        <ButtonGroup className="m-l-5">
                            <div className="radio radio-primary">
                                <input
                                    {...register(`is_registered`, {
                                        onChange: (e) => {
                                            isRegistered(e, 1)
                                        }
                                    })} id="radio9" type="radio" name="is_registered" value="1"/>
                                <Label htmlFor="radio9">Yes</Label>
                            </div>
                            <div className="radio radio-primary m-l-20">
                                <input
                                    {...register(`is_registered`, {
                                        onChange: (e) => {
                                            isRegistered(e, 0)
                                        }
                                    })} id="radio10" type="radio" name="is_registered" value="0"/>
                                <Label htmlFor="radio10">No</Label>
                            </div>
                        </ButtonGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Country of Business Registration</Label>
                            <Select placeholder="Country Name" getOptionLabel={(option) => option.name}
                                    value={getValues('country_id')}
                                    getOptionValue={(option) => option.id} {...register("country_id")}
                                    onChange={(e) => {
                                        console.log(e)
                                        setValue('country_id', e);
                                        setDialCode(e.dial_code);
                                        trigger('country_id');
                                    }} className={` ${errors.country_id ? 'is-invalid' : ''}`}
                                    options={sellerLeadState.settings.countries}/>
                            <div className="invalid-feedback">{errors.country_id?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Company Name</Label>
                            <input name="company_name" type="text"
                                   className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                                   placeholder="Company Name" {...register('company_name')}  />
                            <div className="invalid-feedback">{errors.company_name?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Business Registration Number</Label>
                            <input name="registration_number" type="text"
                                   className={`form-control ${errors.registration_number ? 'is-invalid' : ''}`}
                                   placeholder="Business Registration Number" {...register('registration_number')}  />
                            <div className="invalid-feedback">{errors.registration_number?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">VAT Number</Label>
                            <input name="vat_number" type="text"
                                   className={`form-control ${errors.vat_number ? 'is-invalid' : ''}`}
                                   placeholder="VAT Number" {...register('vat_number')}  />
                            <div className="invalid-feedback">{errors.vat_number?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">EORI Number</Label>
                            <input name="eori_number" type="text"
                                   className={`form-control ${errors.eori_number ? 'is-invalid' : ''}`}
                                   placeholder="EORI Number" {...register('eori_number')}  />
                            <div className="invalid-feedback">{errors.eori_number?.message}</div>
                        </FormGroup>
                    </Col>

                    {buyer ?
                        <Col md={12}>
                            <FormGroup>
                                <Label className="form-label">Is valid VAT number/Is Customer is Vattable?</Label>
                                <br/>
                                <ButtonGroup className="m-l-5">
                                    <div className="radio radio-primary">
                                        <input
                                            {...register(`is_vattable`, {
                                                onChange: (e) => {
                                                    isRegistered(e, 1)
                                                }
                                            })} id="radio5" type="radio" name="is_vattable" value="1"/>
                                        <Label htmlFor="radio5">Yes</Label>
                                    </div>
                                    <div className="radio radio-primary m-l-20">
                                        <input
                                            {...register(`is_vattable`, {
                                                onChange: (e) => {
                                                    isRegistered(e, 2)
                                                }
                                            })} id="radio6" type="radio" name="is_vattable" value="2"/>
                                        <Label htmlFor="radio6">No</Label>
                                    </div>
                                </ButtonGroup>
                            </FormGroup></Col> : ''}


                    <Col md={6}  className="">
                        <Label className="form-label">Phone Number</Label>
                        <FormGroup className="input-group">
                            <span className="input-group-text">{dialCode}</span>
                            <input name="phone_number" type="text"
                                   className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                   placeholder="Phone Number" {...register('phone_number')}  />
                            <div className="invalid-feedback">{errors.phone_number?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Company Email</Label>
                            <input name="email" type="text"
                                   className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                   placeholder="Company Email" {...register('email')}  />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Company Website</Label>
                            <input name="website" type="text"
                                   className={`form-control ${errors.website ? 'is-invalid' : ''}`}
                                   placeholder="Company Website" {...register('website')}  />
                            <div className="invalid-feedback">{errors.website?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <Label className="form-label">Line Of Business</Label>
                        <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
                            <Row>
                                {sellerLeadState.settings.lineOfBusiness?.map((item, index) =>
                                    <Col md={6} key={'line-of-' + index}>
                                        <div className="checkbox checkbox-dark">
                                            <input defaultChecked={getValues("line_of_business")?.includes(item.id)}
                                                   onChange={(e) => {
                                                       setLineOfBusiness(e, item.id)
                                                   }} name={item.value} id={"inline-line-of-" + item.id} type="checkbox"/>
                                            <Label for={"inline-line-of-" + item.id}><span
                                                className="digits"> {item.name}</span></Label>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label className="form-label">Date Business Incorporated</Label>
                            <DatePicker name="incorporated_date"
                                        selected={getValues('incorporated_date') ? new Date(getValues('incorporated_date')) : null}
                                        className={`form-control digits ${errors.incorporated_date ? 'is-invalid' : ''}`}
                                        dateFormat="dd-MM-yyyy" onChange={(date) => {
                                console.log(date)
                                setValue('incorporated_date', date);
                                trigger('incorporated_date')
                            }}/>
                            <div className="invalid-feedback">{errors.incorporated_date?.message}</div>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="mt-2 d-flex justify-content-end">
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(1)
                        }} className="btn btn-danger btn-block">Save & Exit
                        </button>
                    </div>
                    <div className="p-2">
                         <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(0)
                        }} className="btn btn-primary btn-block">Save & Next
                        </button>
                    </div>
                </div>
                    </>}
            </Form>
        </Fragment>
    );
}