import Customizer from './customizer/reducer'
import authReducer from './auth/userSlice';


import { combineReducers } from '@reduxjs/toolkit';
import themeSlice from "./customizer/themeSlice";

const reducers = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
        authReducer,
        ...asyncReducers
    });

    /*
    Reset the redux store when user logged out
     */
    if (action.type === 'auth/user/userLoggedOut') {
        state = undefined;
    }

    return combinedReducer(state, action);
};

export default reducers;
