import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import PreLoader from "../../../share-components/ui/preLoader";
import { Clock } from "react-feather";
import LogActivity from "../../log";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import salesOrderInvoiceReducer from "../store";
import {
  getAllSaleOrderInvoices,
  selectAllSaleInvoice,
  selectDraftOrder,
  selectInvoices,
} from "../store/saleOrderInvoiceSlice";
import { invoiceResponseToTable } from "../../../utils/mapper";
import withReducer from "../../../store/withReducer";

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Invoices",
    link: "",
  },
];

const SalesOrderInvoice = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  const [modal, setModal] = useState(false);
  let history = useHistory();
  const [loading, isSetLoading] = useState(true);
  const saleOrderInvoiceState = useSelector(
    ({ order }) => order.salesOrderInvoice
  );
  const invoices = useSelector(selectAllSaleInvoice);
  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const toggle = () => setModal(!modal);

  const tableColumns = [
    {
      name: "Invoice ID",
      selector: (row) => row.invoice_no,
      sortable: true,
      center: false,
    },
    // {
    //   name: "Xero ID",
    //   selector: (row) => row.xero_id,
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Due Date",
      selector: (row) => row.due_date,
      sortable: true,
      center: false,
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Net Total",
      selector: (row) => row.total_amount,
      sortable: true,
      center: false,
    },
    {
      name: "Country",
      selector: (row) => row.buyer_lead_country,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
              <a
                onClick={() => {
                  onView(row);
                }}
              >
                <i
                  className="fa fa-eye"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(51, 153, 255)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
          {userState.user &&
            (userState.user.permission.indexOf("Update Seller") !== -1 ? (
              <a
                onClick={() => {
                  onEdit(row);
                }}
              >
                <i
                  className="fa fa-undo"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(255, 187, 51)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [page, brand, search]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
    };
    dispatch(getAllSaleOrderInvoices(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const onEdit = (row) => {
    history.push("sale/" + row.sales_order_id + "/return/" + row.id);
  };
  const onView = (row) => {
    history.push("sale/" + row.sales_order_id + "/billing/invoice/" + row.id);
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Invoices" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="6">
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Type Name"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    {/* <Clock
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "fixed",
                        right: "40px",
                      }}
                      className="mr-1"
                      onClick={logHistory}
                    /> */}
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        zIndex: 10,
                        right: 0,
                      }}
                    >
                      {/* <LogActivity log="Sales Order"></LogActivity> */}
                    </div>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={invoiceResponseToTable(invoices)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderInvoiceState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                <div className="customizer-contain">
                  <div className="customizer-header">
                    <i
                      className="icon-close"
                      onClick={() => {
                        document
                          .querySelector(".customizer-contain")
                          .classList.remove("open");
                      }}
                    ></i>
                    <h5 className="filter-header">Log History</h5>
                  </div>
                </div>
              </Row>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    salesOrderInvoice: salesOrderInvoiceReducer,
  },
])(SalesOrderInvoice);
