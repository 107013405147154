import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {UpdateBuyerLeadNavItems} from "./updateBuyerLeadNavItems";
import {UpdateBuyerLeadForm} from "../form/updateBuyerLeadForm";
import {getBuyerLeadSettings, getSelectedBuyerLeadData, setSettings} from "../store/buyerLeadSlice";

const dataMenu = [
    {
        type: 1,
        text: 'Buyer Leads',
        link: '/leads/buyer-lead'
    },
    {
        type: 0,
        text: 'Update Buyer Lead',
        link: ''
    },
];

const UpdateBuyerLead = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [VerticleTab, setVerticleTab] = useState('1');
    const [formData, setFormData] = useState([]);
    const [buyer, setBuyer] = useState(true);

    const buyerLeadState = useSelector(({lead})=> lead.buyerLead);

    useEffect(() => {

        dispatch(getSelectedBuyerLeadData(id)).then(res=>{
            // console.log(res.payload)
            setFormData(res.payload);
        } );

        dispatch(getBuyerLeadSettings()).then(res => {
            dispatch(setSettings(res.payload));
        });
    },[dispatch ]);

    return (
        <Fragment>
            <Breadcrumb data={dataMenu} title="Update Buyer Lead"/>
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="3" xs="12" className="border-right">
                                        <UpdateBuyerLeadNavItems VerticleTab={VerticleTab}
                                                                 setVerticleTab={setVerticleTab} id={id}/>
                                    </Col>
                                    <Col sm="9" xs="12">
                                        <UpdateBuyerLeadForm buyer={buyer}
                                            formData={formData} VerticleTab={VerticleTab} id={id} setVerticleTab={setVerticleTab}
                                            sellerLeadState={buyerLeadState}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

}

export default (UpdateBuyerLead);