import React, {Fragment, useEffect, useState} from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup, Label, Input, ModalFooter, Button, Media
} from 'reactstrap'
import {ProductListTitle, ProductListDesc} from '../../../../constant';
import withReducer from "../../../../store/withReducer";
import userReducer from "../../store";

import {connect, useDispatch, useSelector} from "react-redux";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import {
    addUser,
    closeEditDialog,
    closeNewDialog, deleteUser,
    getUsers,
    getUserSettings, openEditDialog,
    openNewDialog,
    selectUser,
    setSettings, updatePassword, updateProfile, updateUser
} from "../../store/userSlice";
import {mapUserRoleResponseToTable} from "../../../../utils/mapper";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import reducers from "../../../../redux";


const defaultValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    currentPassword: yup.string().required('You must enter old password'),
    password: yup.string().required('You must enter new password').matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
    confirmPassword: yup.string() .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const UserChangePassword = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Manage Users',
            link: '/'
        },
        {
            type: 0,
            text: "Password Reset"
        }
    ];
    const { handleSubmit, formState, reset, register} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors } = formState

    const dispatch = useDispatch();

    const onSubmitForm = (data) => {
        const set={
            current_password:data.currentPassword,
            new_password:data.password
        };
        dispatch(updatePassword({ ...set })).then(res => {
            reset({...defaultValues});
                    // if (!res.error) closeDialog(dialog.type);
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Fragment>
                <FormGroup>
                    <Label className="form-label">Current Password</Label>
                    <input name="currentPassword" type="password" className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`} {...register('currentPassword')}  />
                    <div className="invalid-feedback">{errors.currentPassword?.message}</div>
                </FormGroup>
                <FormGroup>
                    <Label className="form-label">Password</Label>
                    <input name="password" type="password" className={`form-control ${errors.password ? 'is-invalid' : ''}`} {...register('password')}  />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                </FormGroup>
                <FormGroup>
                    <Label className="form-label">Confirm Password</Label>
                    <input name="confirmPassword" type="password" className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} {...register('confirmPassword')}  />
                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                </FormGroup>
                <div className="form-footer">
                    <button type="submit" className="btn btn-primary btn-block">Update</button>
                </div>
            </Fragment>
        </Form>
    );

};
export default (UserChangePassword)


