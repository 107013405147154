import API from "../../../utils/api";


export const getAllProductFitTypeApi = async (filters) => {
    const response = await API.get(`settings/fit-type`, {params: filters});
    return {response};
}

export const addProductFitTypeApi = async (fitType) => {
    const response = await API.post(`settings/fit-type`, fitType);
    return {response};
}

export const updateProductFitTypeApi = async (id,fitType) => {
    const response = await API.put(`settings/fit-type/${id}`, fitType);
    return {response};
}

export const deleteProductFitTypeApi = async (id) => {
    const response = await API.delete(`settings/fit-type/${id}`);
    return {response};
}