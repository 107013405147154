import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import {singleCalculation, singlePackCalculation} from "./settings/calculation";


const ColorJqx = (props) => {
    const {setValue, trigger, getValues, productState} = props;
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'id', type: 'id'},
        {name: 'name', type: 'string'},
        {name: 'tag_name', type: 'string'},
        {name: 'product_image_id', type: 'id'},
        {name:'pack_for_single',type:'number'}

    ];

    const columns = [
        {datafield: 'id', hidden: true},
        {datafield: 'product_image_id', hidden: true},
        {datafield: 'pack_for_single', hidden: true},
        {
            text: 'Colour',
            datafield: 'name',
            width: '50%',
            editable: true,
            columntype: 'template',
            createeditor: (row, cellvalue, editor, cellText, width, height) => {
                // alert(JSON.stringify(composition));

                ReactDOM.render(
                    <div style={{zIndex: 1000}}>
                        <Select getOptionLabel={(option) => option.name}

                                ref={(ref) => {
                                    selectRef = ref;
                                }}

                                menuPortalTarget={document.getElementById('root')}
                                styles={{menuPortal: base => ({...base, zIndex: 100}), width: width}}
                            //  menuPortalTarget={document.body}
                                autoFocus={true}
                                classNamePrefix={"my-custom-react-select"}
                                menuShouldScrollIntoView={true}
                                menuPlacement={'auto'}
                                getOptionValue={(option) => option.id}
                                className={`test-el`}
                                onChange={(e) => {
                                    //gridRef.setcellvalue(row, 'composition_id', e.id);
                                    let element = document.getElementById("select-menu");
                                    element.classList.add("menu-open")
                                }}

                                options={productState.colors}
                        /></div>,
                    editor[0]
                );
                setTimeout(() => {
                    //  selectRef!.focus()
                    selectRef.focus();
                }, 500, false)

            },
            initeditor: function (row, cellvalue, editor, celltext, pressedkey) {
                const name = gridRef.getcellvalue(row, 'name');
                const id = gridRef.getcellvalue(row, 'id');
                selectRef.setValue({id: id, name: name})
                setTimeout(() => {

                    selectRef.focus();
                }, 500, false)
            },
            geteditorvalue: function (row, cellvalue, editor) {
                return selectRef.getValue().length > 0 ? selectRef.getValue()[0].name : "";
            },
            validation(cell, value) {
                let found = 0;
                if (value) {
                    const gridInformation = gridRef.getdatainformation();
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < gridInformation.rowscount; i++) {

                        const rowData = gridRef.getrowdata(i);
                        if ((i != cell.row) && (rowData.name)) {
                            if (rowData.name == value) {
                                toast.error("color already added", {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                return {result: false, message: "Colour already added"}
                            }
                        }

                    }
                }

                gridRef.setcellvalue(cell.row, 'id', selectRef.getValue().length > 0 ? selectRef.getValue()[0].id : "");
                setDataToHookForm();
                return true;
            },
        },
        {
            text: 'Custom Colour',
            datafield: 'tag_name',
            editable: true,
            columntype: 'text',
            width: '45%',
            cellendedit: function (row, datafield, columntype, oldvalue, value) {
                if (value) {
                    gridRef.setcellvalue(row, 'tag_name', value);
                    setDataToHookForm();
                }
            },


        }];
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };


    const cellEdit = event => {
        setDataToHookForm();

    };
    const onDelete = () => {
        setDataToHookForm();

    }
    const renderAddActionHeader=(elemet)=>{
        setTimeout(function (){
            // if(myGrid) {
            ReactDOM.render(
                <a onClick={() => {
                    gridRef.addrow(0, {});
                }
                }><span className='fa fa-plus'></span></a>,
                elemet
            );
            // }
        },500,false)
    }

    const setDataToHookForm = () => {
        let colourData = [];
        let element = document.getElementById("select-menu");
        element.classList.remove("menu-open");
        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            if (rowData.id) {
                colourData.push(rowData);
            }

        }
        setValue('color', colourData);
        trigger('color');

    }


    return (
        <Fragment>
            <div className="form-row mb-3">
                <Col md="12">
                    <Label className="col-form-label ">Colour</Label>
                    <div style={{width: '100%'}}>
                        {(productState.colors).length > 0 && <JqxTable
                            datafields={datafields}
                            columns={columns}
                            data={getValues('color')}
                            myGrid={gridRef}
                            getJqxRef={getJqxRef}
                            isDelete
                            onCellendedit={cellEdit}
                            onDelete={onDelete}
                            renderAction={renderAddActionHeader}
                        />}
                    </div>
                </Col>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.memoData) == JSON.stringify(nextProps.memoData);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(ColorJqx, areEqual);