import API from "../../utils/api";


export const getRolesApi  = async () => {
    const response = await API.get('role/role');
    return { response };
};

export const addRoleApi = async location => {
    const response = await API.post('role/role', location);
    return { response };
};

export const updateRoleApi = async (location,id) => {
    const response = await API.put(`role/role/${id}`, location);
    return { response };
}
export const deleteRoleApi = async (id) => {
    const response = await API.delete(`role/role/${id}`);
    return { response };
}


