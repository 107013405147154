import API from "../../../utils/api";


export const getProductTypesApi = async (filters) => {
    const response = await API.get('product-type/product-type', { params: filters });
    return { response };
};

export const addProductTypeApi = async location => {
    const response = await API.post('product-type/product-type', location);
    return { response };
}

export const updateProductTypeApi = async (location, id) => {
    const response = await API.put(`product-type/product-type/${id}`, location);
    return { response };
};

export const deleteProductTypeApi = async (id) => {
    const response = await API.delete(`product-type/product-type/${id}`);
    return { response };
};
