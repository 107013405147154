import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import offerItemReport, {getVerifyOfferItemList, selectOfferItemReport} from "./store/verifyOfferItemsSlice";
import moment from "moment/moment";
import {getExchangeOrderReportData} from "../exchangeOrder/store/exchangeOrderSlice";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import Select from "react-select";
import {getCustomerList, setCustomers} from "../../backInStock/store/backStockSlice";
import {useHistory} from "react-router-dom";

const dataMenu = [
    {
        type: 0,
        text: 'Returns Offer List',
    }
];



const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const VerifyOfferItems = () => {

    const dispatch = useDispatch();
    const verifyOfferItemReport = useSelector(selectOfferItemReport);
    const verifyOfferItemState = useSelector(({report}) => report.offerItemReport);
    const [pageSize, setPageSize] = useState(10);
    const [page,setPage]=useState(0);
    const TodayDate=new Date();
    const sevenDays=new Date().setDate(TodayDate.getDate()-30);
    const [name, setName] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [dateRange, setDateRange] = useState([ sevenDays,TodayDate]);
    const [seller, setSeller] = useState(null);
    const [startDate, endDate] = dateRange;
    const [loading, isSetLoading] = useState(false);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const backInRequestState = useSelector(({backStore}) => backStore.backStoreRequest);
    let history = useHistory();
    const authData =  useSelector(({authReducer}) => authReducer);
    useEffect(()=>{
        setSeller(authData.defaultSeller)
    },[authData.defaultSeller])

    const tableColumns = [
        {
            name: 'Order ID',
            selector: row => row.order_id,
            sortable: true,
            cell: (row) => <a className="text-primary" style={{cursor: "pointer"}} onClick={onView}
                              id={row.order_target_id}>{row.order_id}</a>,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
            center: false,
        },
        {
            name: 'Seller',
            selector: row => row.seller,
            sortable: true,
            center: false,
        },
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            center: false,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            center: false,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
    ];


    useEffect(() => {
        getData();
    },[startDate,endDate,page,pageSize,dispatch, name, customer,seller]);

    const getData= () => {
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                name,
                page: page,
                customer,
                seller: seller? seller.value:'',
                pageSize: 10,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            dispatch(getVerifyOfferItemList(data)).then(res => {
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    };

    useEffect(()=>{
        dispatch(getCustomerList()).then(res => {
            dispatch(setCustomers(res.payload))
        });
    },[dispatch]);

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const onView = (state) => {
        console.log(state.target.id)
        history.push('/order/' + state.target.id + '/view');
    }

    return (
        <Fragment>
            <Breadcrumb title="Returns Offer List" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm={4}>
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{
                                                clearTimeout(timer);
                                                const newTimer = setTimeout(()=>{
                                                    setName(e.target.value);

                                                },500);
                                                setTimer(newTimer)
                                                }}
                                            placeholder="Search Order"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm={4}>
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    menuPortalTarget={document.body}
                                    defaultOptions
                                    value={seller}
                                    placeholder="select Seller"
                                    loadOptions={sellerPromiseOption}
                                    styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    onChange={(e) => {
                                        setSeller(e);

                                    }}

                                />
                            </Col>
                            <Col sm={4}>
                                <Select
                                    placeholder={<div>Customer</div>}
                                    className="select2-filter"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.uid}
                                    isClearable
                                    // cacheOptions
                                    styles={customStyles}
                                    // defaultValue={backInRequestState.filter.country}
                                    options={backInRequestState.customers}
                                    onChange={(e) => {
                                        setCustomer(e ? e.uid : null);
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-4" sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <CommonDataTable
                                                noAction
                                                headerColumns={tableColumns}
                                                gridData={verifyOfferItemReport}
                                                paginationTotalRows={verifyOfferItemState.offerItemReportTableRow}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: true
                                                }}
                                                paginationPerPage={10}
                                                paginationServer
                                                onChangePage={page => setPage(page)}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </Fragment>
    )
}

export default VerifyOfferItems;