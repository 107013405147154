import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import {useDispatch, useSelector} from "react-redux";
import {addSeller, getSellerSettings, setSettings} from "../store/sellerSlice";
import withReducer from "../../../store/withReducer";
import sellerReducer from "../store";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Switch from "react-switch";
import {editorConfiguration} from "../../../utils/common";


export const defaultValues = {
    name: '',
    email: '',
    number: '',
    shopName: '',
    pdCity: '',
    pdCountry: '',
    storeDescription: '',
    sellerDescription: '',
    sellerSince: new Date(),
    otherPolicy: '',
    minOrder: '',
    chargeTax: false,
    shippingOption: '',
    isPremium: false,
    commission: '',
};

/**
 * Form Validation Schema
 */
const phoneRegExp =/^[0-9]{10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    email: yup.string().required('Email is required field'),
    number: yup.string().required('Phone number is required field').customPhoneValidation('You must enter valid phone number'),
    // .min(9, 'Invalid Phone number')
    // .max(10, 'Invalid Phone number'),
    shopName: yup.string().required('Shop Name is required field'),
    pdCity: yup.string().required('City is required field'),
    pdCountry: yup.object().required('Country is required field').typeError('Please select Country'),
    storeDescription: yup.string().required('Store Description is required field'),
    sellerDescription: yup.string().required('Seller Description is required field'),
    sellerSince: yup.date().required('Seller Since is required field'),
    otherPolicy: yup.string().required('Seller Description is required field'),
    minOrder: yup.number().required('Minimum order is required field').typeError('Please enter valid input for minimum order'),
    chargeTax: yup.bool().nullable(),
    shippingOption: yup.string().required('Shipping Option Description is required field'),
    isPremium: yup.bool().nullable(),
    commission: yup.number().required('Commission is a required field').typeError('Please enter valid input for Commission'),
});


const AddSeller = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Sellers',
            link: '/seller'
        },
        {
            type: 0,
            text: "Add Seller"
        }
    ];
    const { handleSubmit, formState, reset, register, setValue , getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const [storeDescription,setStoreDescription] = useState('');
    const [sellerDescription,setSellerDescription] = useState('');
    const [otherPolicy,setOtherPolicy] = useState('');
    const [sellerSinceDate, setSellerSinceDate] = useState(new Date());
    const [actionBtn,setActionBtn]=useState(0);
    const [isPremium,setIsPremium]=useState(false);
    const [isTaxChargeable,setIsTaxChargeable]=useState(false);
    const [basictooltip, setbasictooltip] = useState(false);
    const { errors } = formState;
    const formRef = useRef();
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const submitBtnSaveExitRef = useRef();
    const dispatch = useDispatch();
    let history = useHistory();
    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    const sellerState = useSelector(({ seller }) => seller.seller);


    const onSubmitForm = (data) => {
        const set={
            "name":data.name,
            "email":data.email,
            "number":data.number,
            "shopName":data.shopName,
            "pdCity":data.pdCity,
            "pdCountry":data.pdCountry.id,
            "storeDescription":data.storeDescription,
            "role":1,
            "sellerDescription":data.sellerDescription,
            "otherPolicy":data.otherPolicy,
            "minOrder":data.minOrder,
            "chargeTax":isTaxChargeable,
            "shippingOption":data.shippingOption,
            "isPremium":isPremium,
            "sellerId":data.sellerId,
            "commission":data.commission,
            "sellerSince":`${data.sellerSince.getFullYear()}-${(data.sellerSince.getMonth()+1)<2?'0'+(data.sellerSince.getMonth()+1):(data.sellerSince.getMonth()+1)}-${data.sellerSince.getDate()<2?'0'+data.sellerSince.getDate():data.sellerSince.getDate()}`
        };
        // console.log(set);
        submitBtnSaveRef.current.disabled=true;
        submitBtnSaveExitRef.current.disabled=true;
        dispatch(addSeller({ ...set })).then(res => {
            if(!res.error){
                reset(defaultValues);
                setStoreDescription("");
                setSellerDescription("");
                setOtherPolicy("");
                if(actionBtn===0){
                    history.push(res.payload.id+'/edit');
                }else{
                    history.push('/seller');
                }
            }else{
                // toast.error(res.error.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
            }
            submitBtnSaveRef.current.disabled=false;
            submitBtnSaveExitRef.current.disabled=false;
        });
    };

    const onChangeStoreDescription = (evt) => {
        const newContent = evt.editor.getData();
        setStoreDescription(newContent);
        setValue('storeDescription',newContent);
        trigger('storeDescription');
    }

    const onChangeSellerDescription = (evt) => {
        const newContent = evt.editor.getData();
        setSellerDescription(newContent);
        setValue('sellerDescription',newContent);
        trigger('sellerDescription');

    }

    const onChangeOtherPolicy = (evt) => {
        const newContent = evt.editor.getData();
        setOtherPolicy(newContent);
        setValue('otherPolicy',newContent);
        trigger('otherPolicy');
    }

    const handleSellerSinceChange = date => {
        setValue('sellerSince',date);
        trigger('sellerSince');
        setSellerSinceDate(date);
    };

    const toggle = () => setbasictooltip(!basictooltip);
    return (
        <Fragment>
            <Breadcrumb title="Add Seller" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Primary Details</p>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Legal Name<span style={{color:"red"}}>*</span></Label>
                                                <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="City Goddess Ltd" {...register('name')}  />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Email<span style={{color:"red"}}>*</span></Label>
                                                <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.shopName?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Seller Since</Label>
                                                <DatePicker showMonthDropdown showYearDropdown name="sellerSince" className={`form-control digits ${errors.sellerSince ? 'is-invalid' : ''}`} dateFormat="dd-MM-yyyy" selected={sellerSinceDate} maxDate={new Date()} onChange={handleSellerSinceChange} />
                                                <div className="invalid-feedback">{errors.sellerSince?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Shop Name<span style={{color:"red"}}>*</span></Label>
                                                <input name="shopName" type="text" className={`form-control ${errors.shopName ? 'is-invalid' : ''}`} placeholder="City Goddess" {...register('shopName')}  />
                                                <div className="invalid-feedback">{errors.shopName?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">City<span style={{color:"red"}}>*</span></Label>
                                                <input name="pdCity" type="text" className={`form-control ${errors.pdCity ? 'is-invalid' : ''}`} placeholder="Liverpool" {...register('pdCity')}  />
                                                <div className="invalid-feedback">{errors.pdCity?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Country<span style={{color:"red"}}>*</span></Label>
                                                <Select placeholder="United Kingdom" getOptionLabel ={(option)=>option.name} defaultValue={getValues('pdCountry')}
                                                        getOptionValue ={(option)=>option.id} {...register("pdCountry")} onChange={(e)=>{
                                                    setValue('pdCountry',e);
                                                    trigger('pdCountry');
                                                    setDialCode(e.dial_code)
                                                }} className={` ${errors.pdCountry ? 'is-invalid' : ''}`} options={sellerState.settings?sellerState.settings.countries:null} />
                                                <div className="invalid-feedback">{errors.pdCountry?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Phone Number<span style={{color:"red"}}>*</span></Label>
                                                <FormGroup className="input-group">
                                                    <span className="input-group-text">{dialCode}</span>
                                                    <input name="phone" type="number" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                                           className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                                           placeholder="Phone Number" {...register('number')}  />
                                                    <div className="invalid-feedback">{errors.number?.message}</div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col className="mt-3 mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Seller & Store Description</p>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Store Description<span style={{color:"red"}}>*</span></Label>

                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={storeDescription}
                                                    config={editorConfiguration("100")}
                                                    {...register('storeDescription')}
                                                    events={{
                                                        "change": onChangeStoreDescription
                                                    }}
                                                />
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.storeDescription?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Seller Description<span style={{color:"red"}}>*</span></Label>

                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={sellerDescription}
                                                    config={editorConfiguration("100")}
                                                    {...register('sellerDescription')}
                                                    events={{
                                                        "change": onChangeSellerDescription
                                                    }}
                                                />
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.sellerDescription?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Other Policy<span style={{color:"red"}}>*</span></Label>

                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={otherPolicy}
                                                    config={editorConfiguration("100")}
                                                    {...register('otherPolicy')}
                                                    events={{
                                                        "change": onChangeOtherPolicy
                                                    }}
                                                />
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.otherPolicy?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col className="mt-3 mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Extra Information</p>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Minimum Order<span style={{color:"red"}}>*</span></Label>
                                                <input name="minOrder" type="number" className={`form-control ${errors.minOrder ? 'is-invalid' : ''}`} placeholder="10" {...register('minOrder')}  />
                                                <div className="invalid-feedback">{errors.minOrder?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Commission<span style={{color:"red"}}>*</span></Label>
                                                <InputGroup>
                                                    <input name="commission" type="text" className={`form-control ${errors.commission ? 'is-invalid' : ''}`} placeholder="10" {...register('commission')}  />
                                                    <InputGroupAddon addonType="append"><InputGroupText>{"%"}</InputGroupText></InputGroupAddon>
                                                    <div className="invalid-feedback">{errors.commission?.message}</div>
                                                </InputGroup>

                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Charge Taxes On Products</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={setIsTaxChargeable} checked={isTaxChargeable} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Seller ID</Label>
                                                <input name="sellerId" type="text" className={`form-control ${errors.sellerId ? 'is-invalid' : ''}`} placeholder="1234" {...register('sellerId')}  />
                                                <div className="invalid-feedback">{errors.sellerId?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col className="d-flex justify-content-start w-50">
                                            
                                            <FormGroup className="ml-3">
                                                <Label className="form-label">Is Premium</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={setIsPremium} checked={isPremium} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                            <div className="ml-5">
                                                <Label className="form-label">Shipping Option<span style={{color:"red"}}>*</span></Label>
                                                <FormGroup className="m-t-15 m-checkbox-inline mb-0 ml-2">
                                                    <div className="radio radio-primary">
                                                        <input id="radioinline3" type="radio" name="shippingOption" value="1" {...register('shippingOption')} />
                                                        <Label className="mb-0" for="radioinline3">Shopify Default Shipping</Label>
                                                    </div>
                                                    <div className="radio radio-primary">
                                                        <input id="radioinline4" type="radio" name="shippingOption" value="2" {...register('shippingOption')}  />
                                                        <Label className="mb-0" for="radioinline4">Country Wise Shipping</Label>
                                                    </div>
                                                    <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.shippingOption?.message}</div>
                                                </FormGroup>
                                            </div>
                                        </Col>

                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div   div className="p-2">
                                            <button ref={submitBtnSaveExitRef} onClick={()=>{formRef.current.submit(); setActionBtn(1)}} className="btn btn-danger btn-block">Save & Exit</button>
                                        </div>
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={()=>{formRef.current.submit(); setActionBtn(0)}}  className="btn btn-primary btn-block">Save</button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{seller: sellerReducer}])(AddSeller);