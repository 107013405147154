import React, {Fragment, useEffect, useState} from "react";
import { Card, CardBody, Col, Container, Row} from "reactstrap";
import {closeEditDialog, deleteRole, openEditDialog, openNewDialog, updateRole,} from "../store/roleSlice";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapRoleResponseToTable, mapUserRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from '../../../layout/breadcrumb'
import {addRole, getRoles, selectRole} from "../store/roleSlice";
import withReducer from "../../../store/withReducer";
import userReducer from "../store";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import RoleForm from "./roleForm";
import PreLoader from "../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
};

const schema = yup.object().shape({
    name: yup.string().required('You must enter a role name'),
});

const Role = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const roles = useSelector(selectRole);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    useEffect(() => {
        dispatch(getRoles()).then(res=> {
            setLoading(false);
        });
    }, [dispatch]);

    const { handleSubmit, formState, reset, control, register,setValue,handleChange ,trigger,getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            setButtonDisable(false);
            dispatch(addRole({...data})).then(res => {
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateRole({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    }


    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteRole(row.id))
    }
    const closeDialog = () =>{
        dispatch(closeEditDialog());
    }

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            center: true,
        }
    ];

    const { errors } = formState

    return (
        <Fragment>
            <Container fluid={true}>
                {loading ? <><PreLoader></PreLoader></>:<>
                <Breadcrumb parent="Manage Roles" title="Roles"/>
                <Row>
                    <Col sm="12">

                        <Card>
                            <CardBody>
                                {(currentUser.permission).includes('Create User Roles') ?
                                <div className='float-sm-right m-b-30'>
                                    <a className="btn btn-sm btn-primary" onClick={()=>{dispatch(openNewDialog())}}> <i className="fa fa-plus"></i> Add Role</a>
                                </div>
                                    :''}

                                <div className="table-responsive product-table">
                                    <CommonDataTable
                                        headerColumns={tableColumns}
                                        gridData={mapRoleResponseToTable(roles)}
                                        pagination
                                        onEdit={(currentUser.permission).includes('Update User Roles') ? onEdit:''}
                                        onDelete={(currentUser.permission).includes('Delete User Roles') ? onDelete:''}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </>}
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Role'
                selector='staff'
                dialog={({ user }) => user.role.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
            <RoleForm control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}/>
            </ModalFormSubmit>
        </Fragment>
    );
};

export default withReducer([{role: userReducer}])(Role)