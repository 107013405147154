export const saleOrderPaymentCalculation = (getValues)=>{
    let subTotalPrice=0;
    let discountAmount=0;
    let shippingAmount=0;
    let taxAmount=0;
    let dutyAmount=0;
    const discount=  getValues('discount');
    const orders=  getValues('ordered_products');
    const shipping=getValues('shipping');
    const tax=getValues('tax');
    const duty=getValues('duty');
    const billingAddress=getValues('billingAddress');
    // commidities.map(function (item, index) {
    //     console.log(item);
    // });


    if(orders.length >0) {
        console.log(orders, "orde lenght");
        orders.map(function (item, index) {
            subTotalPrice += item.total_price?item.total_price:0;
        });
    }
    if(discount) {
        if (discount.discount_type == 1) {
            discountAmount = Number(discount.value);
        } else {
            discountAmount = subTotalPrice * (Number(discount.value) / 100);
        }
    }
    if(duty){
        if(duty.type==1){
            dutyAmount=Number(duty.value);
        }else{
            dutyAmount=subTotalPrice* (Number(duty.value) / 100);
        }
    }

    if(tax){
        if(tax.is_charge){
            const totalTaxablePrice=subTotalPrice-discountAmount;
            taxAmount=(totalTaxablePrice-(totalTaxablePrice/(1+(Number(tax.percentage)/100))));
        }
    }
    if(shipping){
        if(shipping.shipping_type ==2){
            shippingAmount=Number(shipping.price);
        }
    }


    let total=(subTotalPrice+shippingAmount+dutyAmount+taxAmount)-discountAmount;
    let subTotalWithDiscount=subTotalPrice -discountAmount;
    return {
        subTotal: subTotalPrice.toFixed(2),
        total: total.toFixed(2),
        discountPrice: discountAmount.toFixed(2),
        shippingPrice: shippingAmount.toFixed(2),
        taxPrice: taxAmount.toFixed(2),
        dutyPrice:dutyAmount.toFixed(2),
        subTotalWithDiscount: subTotalWithDiscount.toFixed(2)
    }

}

const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const orderCommodities=function (orders){
    const data=[];
    const commodities=groupBy(orders,'commodity');
    let totalQuantity=0;
    let totalPrice=0;
    for (const [key, value] of Object.entries(commodities)) {
        console.log(key);
        if(key) {
            let price = value.map(o => o.total_price).reduce((a, c) => {
                return a + c
            });

            let quantity = value.map(o => o.quantity).reduce((a, c) => {
                return a + c
            });
            totalPrice+=price;
            totalQuantity+=quantity;

            data.push({commodity: key, amount: totalPrice,quantity:quantity,});
        }

    }
    data.push({commodity: 'Total', amount: totalPrice,quantity:totalQuantity});
   return data;
}