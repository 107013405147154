import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Form, FormGroup,
} from "reactstrap";

import {Link, useHistory, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {CarouseNavigation} from "../../../../../utils/common";
import withReducer from "../../../../../store/withReducer";
import salesOrderReturnReducer from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {
    getInvoiceByOrder,
    getInvoicePendingReturn,
    setOrderInvoices,
} from "../../../store/saleOrderInvoiceSlice";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import PreLoader from "../../../../../share-components/ui/preLoader";
import salesOrderReturn, {
    getAllReturnReasons,
    addSalesOrderReturn,
    getAllReturns,
    setReturnSettings, editPendingReturnRequest, updateSalesOrderReturn,
} from "../../../store/saleOrderReturnSlice";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import SalesOrderPendingReturn from "../index";
import Breadcrumb from "../../../../../layout/breadcrumb";
import SaleOrderReturnProductJqx from "../../return/invoice/content/saleOrderReturnProductJqx";
import SalesOrderPendingReturnJqx from "./content/salesOrderPendingReturnJqx";

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <CarouseNavigation icon="fa-arrow-right"/>,
    prevArrow: <CarouseNavigation icon="fa-arrow-left"/>,
};
const defaultValues = {
    id: "",
    ordered_products: [],
};
const schema = yup.object().shape({
    // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    // shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});

const dataMenu = [
    {
        type: 0,
        text: "Pending Return",
    },
    {
        type: 1,
        text: "Form",
        link: "",
    },
];
const SalesOrderPendingReturnForm = (props) => {
    const {id, order_id, slug} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [isSlickArrow, setIsSlickArrow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [orderedProducts, setOrderedProducts] = useState(
        defaultValues.ordered_products
    );
    const draftOrderState = useSelector(({order}) => order.draftOrder);
    const salesOrderInvoiceState = useSelector(
        (state) => state.order.salesOrderInvoice
    );
    const returnState = useSelector(({order}) => order.salesOrderReturn);
    const {
        handleSubmit,
        formState,
        reset,
        control,
        register,
        setValue,
        trigger,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });
    const {errors, isDirty} = formState;



    useEffect(() => {
        dispatch(getAllReturnReasons()).then((res) => {
            if (!res.error) {
                dispatch(setReturnSettings(res.payload));
            }
        });
    }, []);

    useEffect(() => {
        getPendingInvoiceOrder();
    }, [id]);

    const getPendingInvoiceOrder = () => {
        setLoading(true);
        dispatch(editPendingReturnRequest(id)).then((res) => {
            if (!res.error) {
                setValue("ordered_products", res.payload);
                setLoading(false);
            }
        });
    };


    const onSubmit = (data) => {
        console.log(data);

        const returnData = {
            'id': id,
            'product': data.ordered_products
                .filter(function (v, i) {
                    return v.is_selected;
                })
                .map(function (item, index) {
                    return {
                        id: item.id,
                        approved_quantity:item.approve_quantity,
                        is_restocked:item.is_restock,
                        is_warehouse_declined:item.is_declined
                    };
                }),
        }

        dispatch(updateSalesOrderReturn({...returnData})).then((res) => {
            if (!res.error) {
                history.push('/order/pending-return');
            }
        });
    };

    return (
        <>
            <Breadcrumb data={dataMenu} title="Pending Return"/>
            <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>

                                        {loading ? (
                                            <>
                                                <PreLoader></PreLoader>
                                            </>
                                        ) : (
                                            <SalesOrderPendingReturnJqx
                                                getValues={getValues}
                                                setValue={setValue}
                                                trigger={trigger}
                                                returnState={returnState}
                                                orderedProducts={getValues('ordered_products')}
                                            />
                                        )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {getValues("ordered_products").filter(function (v, i) {
                        return v.is_selected;
                    }).length > 0 && (<Row className="py-4">
                        <Col md={12}>
                            {/* <Card> */}
                            <div className="float-right">
                                <ButtonLoader
                                    color="secondary"
                                    value="1"
                                    btntext="Save"
                                ></ButtonLoader>
                            </div>
                            {/* </Card> */}
                        </Col>
                    </Row>)};
                </Form>
        </>
    );
}

export default withReducer([{salesOrderReturn: salesOrderReturnReducer}])(
    SalesOrderPendingReturnForm
);
