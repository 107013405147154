import API from "../../../utils/api";


export const getAllProductFitSizeApi = async (filters) => {
    const response = await API.get(`settings/fit-size-condition`, {params: filters});
    return {response};
}

export const addProductFitSizeApi = async (fitSizeCondition) => {
    const response = await API.post(`settings/fit-size-condition`, fitSizeCondition);
    return {response};
}

export const updateProductFitSizeApi = async (id,fitSizeCondition) => {
    const response = await API.put(`settings/fit-size-condition/${id}`, fitSizeCondition);
    return {response};
}

export const deleteProductFitSizeApi = async (id) => {
    const response = await API.delete(`settings/fit-size-condition/${id}`);
    return {response};
}