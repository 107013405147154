import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import {numberWithCommas, numberWithCommasWithoutPounds} from "../../../../../utils/common";


const SaleOrderProductJqx = (props) => {
    const {setValue, trigger, getValues, orderedProducts,id} = props;
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    const [orderData, setOrderData] = useState([]);
    const [isCreate,setIsCreate]=useState(!id);


    // useEffect(() => {
    //     setOrderData(getValues('ordered_products'));
    // }, [getValues('ordered_products')])
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'id', type: 'number'},
        {name: 'variant', type: 'number'},
        {name: 'product_id', type: 'number'},
        {name: 'product_name', type: 'string'},
        {name: 'image', type: 'string'},
        {name: 'location', type: 'string'},
        {name: 'sku', type: 'string'},
        {name: 'variant_name', type: 'string'},
        {name: 'quantity', type: 'number'},
        {name: 'back_order_quantity', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'total_price', type: 'number'},
        {name: 'stock', type: 'number'},
        {name: 'original_price', type: 'number'},
        {name: 'amount', type: 'number'},
        {name: 'is_tax', type: 'boolean'},
        {name: 'tax_amount', type: 'number'},
        {name: 'gross_amount', type: 'number'},

    ];

    const columns = [
        {datafield: 'id', hidden: true},
        {datafield: 'variant', hidden: true},
        {datafield: 'total_price', hidden: true},
        {datafield: 'tax_amount', hidden: true},
        {
            text: 'Product',
            datafield: 'product_name',
            editable: false,
            columntype: 'text',
            width: '38%',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.variant_name}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
        {
            text: 'Available',
            datafield: 'stock',
            editable: false,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
            // cellsformat: 'c2',

        },
        {
            text: 'Back Qty',
            datafield: 'back_order_quantity',
            editable: true,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',


        },
        {
            text: 'Quantity',
            datafield: 'quantity',
            editable: true,
            columntype: 'text',
            width: '7%',
            cellsalign: 'right',
            align: 'right',
            cellclassname: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                return 'editable-column';
            },
            validation: function (cell, value) {
                const stock = gridRef.getcellvalue(cell.row, 'stock');

                if (Number(stock) < Number(value)) {
                    return {result: false, message: "only " + stock + " is available"}
                } else {
                    return true;

                }
            },

        },
        {
            text: 'Location',
            datafield: 'location',
            editable: false,
            columntype: 'text',
            width: '14%',
            cellsalign: 'left',
            align: 'left',
            // cellsformat: 'c2',

        },
        {
            text: 'Rate',
            datafield: 'price',
            editable: false,
            columntype: 'text',
            width: '6%',
            cellsalign: 'right',
            cellsformat: 'c2',
            align: 'right',

        },
        // {
        //     text: 'Amount',
        //     datafield: 'total_price',
        //     editable: false,
        //     columntype: 'text',
        //     width: '7%',
        //     cellsalign: 'right',
        //     cellsformat: 'c2',
        //     align: 'right',
        //
        // },
        {
            text: 'Tax',
            datafield: 'is_tax',
            editable: true,
            columntype: 'checkbox',
            width: '4%',
            cellsalign: 'center',
            cellsformat: 'c2',
            align: 'center',

        },
        // {
        //     text: 'Tax Amount',
        //     datafield: 'tax_amount',
        //     editable: false,
        //     columntype: 'text',
        //     width: '8%',
        //     cellsalign: 'right',
        //     cellsformat: 'c2',
        //     align: 'right',
        //
        // },
        {
            text: 'Amount',
            datafield: 'gross_amount',
            editable: false,
            columntype: 'text',
            width: '12%',
            cellsalign: 'right',
            // cellsformat: 'c2',
            align: 'right',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                // console.log(row,columnfield,);
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-12">
                                <div className="col-md-12 p-1">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>Sub</p>
                                        </div>
                                        <div className="col-8">
                                            <p  className="text-right" >{numberWithCommasWithoutPounds(rowdata.total_price)}</p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>Tax</p>
                                        </div>
                                        <div className="col-8">
                                            <p  className="text-right" >{numberWithCommasWithoutPounds(rowdata.tax_amount)}</p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>Gross</p>
                                        </div>
                                        <div className="col-8">
                                            <p  className="text-right" >{numberWithCommasWithoutPounds(rowdata.gross_amount)}</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
    ];
    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };
    const onDelete = (data) => {
        setDataToHookForm();
    }
    useEffect(function (){
        if(isCreate) {
            const gridInformation = gridRef.getdatainformation();
            for (let i = 0; i < gridInformation.rowscount; i++) {
                const rowData = gridRef.getrowdata(i);
                const originalPrice = rowData.original_price;
                const quantity = rowData.quantity;
                const singlePrice = originalPrice * Number(getValues('currency_rate'));
                const isTax = rowData.is_tax;
                gridRef.setcellvalue(i, "price", singlePrice);
                setTotalAmount(i, quantity, singlePrice, isTax)
            }
            setDataToHookForm();
        }
        setIsCreate(true);
    },[getValues('currency_rate')])

    const cellEdit = event => {
        const rowArgs = event.args;
        if (rowArgs.datafield == "quantity") {
            const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, 'price');
            const isTax = gridRef.getcellvalue(rowArgs.rowindex, 'is_tax');
            setTotalAmount(rowArgs.rowindex,rowArgs.value,singlePrice,isTax)
        }
        if(rowArgs.datafield=="is_tax"){
            const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, 'price');
            const quantity = gridRef.getcellvalue(rowArgs.rowindex, 'quantity');
            setTotalAmount(rowArgs.rowindex,quantity,singlePrice,rowArgs.value)
        }
        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };

    const setTotalAmount=(index,quantity,singlePrice,isVat)=>{
        const totalPrice=quantity*singlePrice;
        const taxAmount=isVat?totalPrice*0.2:0;
        gridRef.setcellvalue(index, "total_price", totalPrice);
        gridRef.setcellvalue(index, "tax_amount", taxAmount);
        gridRef.setcellvalue(index, "gross_amount", taxAmount+totalPrice);
    }
    const setDataToHookForm = () => {
        let orderData = [];

        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);

        }
        setValue('ordered_products', orderData);
        trigger('ordered_products');

    }


    return (
        <Fragment>

            <div style={{width: '100%'}}>
                {getValues('ordered_products').length > 0 && <JqxTable
                    deleteAll
                    rowsheight={75}
                    datafields={datafields}
                    columns={columns}
                    data={getValues('ordered_products')}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    autoheight={getValues('ordered_products').length<7}
                    isDelete
                    scrollerbar
                    height="500"
                    onCellendedit={cellEdit}
                    disableCreateRow
                    onDelete={onDelete}
                />}
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderedProducts) == JSON.stringify(nextProps.orderedProducts);
}

export default SaleOrderProductJqx;