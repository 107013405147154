import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Row, Form, Input} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {DateRangePicker} from "rsuite";
import moment from "moment";
import orderReducer from "../store";
import {selectPickingTicket, getPickingTicket} from "../store/pickingTicketSlice";
import {Link, useHistory} from "react-router-dom";
const {afterToday} = DateRangePicker;

function PickingTicket() {

    const dataMenu = [
        {
            type: 0,
            text: 'Picking Ticket',
        }
    ];

    const dispatch = useDispatch();
    const pickingTicketState = useSelector(({order}) => order.pickingTicket);
    console.log("picking tikets", pickingTicketState);
    const pickingTicketSelect = useSelector(selectPickingTicket);

    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [order, setOrder] = useState(null);
    const [timer, setTimer] = useState(null);
    const [startDate, endDate] = dateRange;

    const [page, setPage] = useState(1);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [loading, isSetLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);

    // let history = useHistory()

    let tableColumns = [
       
        {
            name: 'Picking Ticket Id',
            selector: row => row.id,
            sortable: true,
            cell: (row) =><a href={row.path} target="_blank">{row.id}</a>,
            center: false,
        }, 
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Name',
            selector: row => row.created_by,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.created_time,
            sortable: true,
            center: false,
        },
        
        
    ];

    useEffect(()=> {
        getData();
    },[dispatch, startDate, endDate,page, pageSize,order]);

    const getData = () =>{
        console.log(pageSize);
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                page:page,
                pageSize:pageSize,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                order:order
            }
            console.log("picking ticket data",data);
            dispatch(getPickingTicket(data)).then(res=>{
                console.log("data555", data);
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

  return (
    <Fragment>

        <Breadcrumb title="Picking Ticket" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
                <div className="feature-products">
                <Row>
                        <Col sm="6">
                            <div className="dashboard-datepicker">
                                <DatePicker
                                    dateRange={dateRange}
                                    dateRangeChange={(date) => {
                                        setDateRange(date)
                                    }}
                                    defaultValue={[TodayDate, sevenDays]}
                                    disabledDate={afterToday()}
                                />
                            </div>
                        </Col>

                        <Col sm={6}>
                            <Form>
                                <FormGroup className="m-0">
                                    <Input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setOrder(e.target.value);

                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" className="mt-3">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={pickingTicketSelect}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={pickingTicketState.pickingTicketTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>

    </Fragment>
  )
}

// export default PickingTicket
export default withReducer([{order: orderReducer}])(PickingTicket);