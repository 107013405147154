import API from "../../utils/api";


export const getPendingOrderFulfillmentAPi = async (orderId) => {
    const response = await API.get(`order/fulfillment/${orderId}/pending-fulfillment`);
    return { response };
};

export const getFulfillmentByOrderApi = async (id) => {
    const response = await API.get(`order/fulfillment/${id}/order`);
    return { response };
};

export const addSalesOrderFulfillmentApi = async (data) => {
    const response = await API.post('order/fulfillment', data);
    return { response };
};

export const getFulfillmentCreateDataApi = async (id) => {
    const response = await API.get(`order/fulfillment/get-create-data`);
    return { response };
};

export const editSaleOrderFulfillmentApi = async (id) => {
    const response = await API.get(`order/fulfillment/${id}/edit`);
    return { response };
};

export const updateSalesOrderFulfillmentApi = async (location, id) => {
    const response = await API.put(`order/fulfillment/${id}/update`, location);
    return { response };
};

export const getFulfillmentApi = async (filters) => {
    const response = await API.get("order/fulfillment", { params: filters });
    console.log("F Service: " + response);
    return { response };
  };