import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Nav, Row} from "reactstrap";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import sellerReducer from "../store";
import withReducer from "../../../store/withReducer";
import {
    approveRequest,
    geSellerProductList,
    getSellerProductList, getSellers,
    retrieveProducts,setSellers
} from "../store/sellerProductListSlice";
import {
    getCurrentDeliveryPartner,
    setCurrentDeliveryPartner,
    setCurrentDeliveryPartners,
} from "../../manage-order/store/orderSlice";
import moment from "moment/moment";
import PreLoader from "../../../share-components/ui/preLoader";
import {toast} from "react-toastify";
import AsyncSelect from "react-select/async";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import Select from "react-select";



const dataMenu = [
    {
        type: 0,
        text: 'Product',
        link: '/product'
    },
    {
        type: 1,
        text: 'Sync Products',
        link: ''
    },
];

const tableColumns = [
    {
        name: 'Product',
        sortable: true,
        center: false,
        selector: params => (
            <div className="p-1">
                <img
                    width={25}
                    className="img-fluid"
                    src={params.image}
                    alt={params.id}
                />
                <p className="ml-2" style={{ display: "inline-block" }}>{params.title}</p>
            </div>
        )
    },
    {
        name: 'Product Type',
        selector: row => row.product_type,
        sortable: true,
        right: false,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        right: true,
    },

];

const isRowDisabled = (row) => {
    // Define your condition to disable the row
    return row.status === "Publish";
};


const ProductList = () => {
    const dispatch = useDispatch();
    const [loading, isSetLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [page,setPage]=useState(0);
    const sellerProductListState = useSelector(({seller}) => seller.sellerProductList);
    const sellerProductList = useSelector(geSellerProductList);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [isChecked, setIsChecked] = useState(null);
    const [seller, setSeller] = useState(null);
    const [itemIdArray, setItemIdArray] = useState([]);
    const [removed, setRemoved] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 30);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [timer, setTimer] = useState(null);
    const [name, setName] = useState(null);
    const userState =  useSelector(({authReducer}) => authReducer.data);

    console.log(sellerProductListState)



    let idArray = [];
    const handleChange = (state) => {
        // idArr = [...itemIdArray]
        if (state.selectedCount>0) {
            setIsChecked(true);
            state.selectedRows.map((item, i) => {
                idArray.push(item.id);
                setItemIdArray(idArray);
                setOrderCheckBoxData(prevState => ({
                    idArray:[...prevState.idArray, ...idArray],

                }));
            });
            const dat  = {
                order_id: idArray
            }
        } else {
            setIsChecked(false)
        }
    };

    const getData =()=> {
        const data = {
            page: page,
            seller,
            name,
            pageSize: pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        dispatch(getSellerProductList(data)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
        });
    };

    useEffect(() =>{
        getData()
    },[dispatch,page,pageSize,seller,name]);

    useEffect(() => {
        dispatch(getSellers()).then(res=>{
            dispatch(setSellers(res.payload.response))
        });
    }, [dispatch])



    const pageChange = (e) => {

        setPage(e)
    }
    const onPageSizeChange = (e) => {
        setPageSize(e)
    }
    const toggleRowSelected = (id) => {
        // console.log(id)
    }

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
        })
    };

    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb data={dataMenu} title="Sync Products"/>
                    <Container fluid={true}>
                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <Row>

                                                {userState.user.role.name!=="Seller"?<Col sm={4} className="pt-3 pl-4">
                                                    <FormGroup>
                                                        <Select
                                                            placeholder={<div>Seller</div>}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            styles={customStyles}
                                                            options={sellerProductListState.sellerList}
                                                            onChange={(e) => {
                                                                setSeller(e.id)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>:'' }


                                                <Col  sm={userState.user.role.name!=="Seller" ? 4: 8} className="pt-2">
                                                    <div className='float-sm-left p-3'>
                                                        <ButtonLoader onClick={() => {
                                                            if (userState.user.role.name==="Seller") {
                                                                setButtonDisable(true);
                                                                dispatch(retrieveProducts()).then(res=>{
                                                                    if (!res.error) {
                                                                        setButtonDisable(false);
                                                                        getData();
                                                                        toast.success("List updated successfully", {
                                                                            position: toast.POSITION.TOP_RIGHT,
                                                                        });
                                                                    }


                                                                });
                                                            } else {
                                                                if (seller) {
                                                                    setButtonDisable(true);
                                                                     const formData={seller_id:seller}
                                                                    dispatch(retrieveProducts(formData)).then(res=>{
                                                                        if (!res.error) {
                                                                            setButtonDisable(false);
                                                                            getData();
                                                                            toast.success("List updated successfully", {
                                                                                position: toast.POSITION.TOP_RIGHT,
                                                                            });
                                                                        }


                                                                    });
                                                                } else {
                                                                    toast.error("Add Seller First", {
                                                                        position: toast.POSITION.TOP_RIGHT,
                                                                    });
                                                                }
                                                            }
                                                        }} color="success" value="0" className="mr-2"
                                                                      type="submit" disabled={buttonDisable}
                                                                      btntext="Retrieve Products"></ButtonLoader>
                                                    </div>

                                                </Col>
                                                <Col sm="4" className="pt-2">
                                                    <div className='float-sm-right p-3'>
                                                        {isChecked && <a className="btn btn-sm btn-secondary"
                                                                         onClick={() => {
                                                                             const finalArr = [...new Set(itemIdArray)]
                                                                             dispatch(approveRequest({id:finalArr})).then(res=>{
                                                                                if (!res.error) {
                                                                                    toast.success("Send Request successfully", {
                                                                                        position: toast.POSITION.TOP_RIGHT,
                                                                                    });
                                                                                }
                                                                             })
                                                                         }}
                                                        > Send Checked Products to Admin</a>}
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} >
                                        <Form>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                        const newTimer = setTimeout(()=>{
                                                            setName(e.target.value);

                                                        },500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search Product"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4" sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive product-table">
                                                    <BackInStockTable
                                                        noAction
                                                        selectableRowDisabled={isRowDisabled}
                                                        tableColumns={tableColumns}
                                                        idArray={orderCheckBoxData}
                                                        remove={removed}
                                                        backStockData={sellerProductList}
                                                        backInRequestState={sellerProductListState}
                                                        paginationTotalRows={sellerProductListState.sellerProductListTableRow}
                                                        pageChange={pageChange}
                                                        onPageSizeChange={onPageSizeChange}
                                                        toggleRowSelected={toggleRowSelected}
                                                        handleChange={handleChange}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </>
            }
        </Fragment>
    );
};

export default withReducer([{seller: sellerReducer}]) (ProductList);