export const paymentCalculation = (getValues)=>{
    let subTotalPrice=0;
    let discountAmount=0;
    let shippingAmount=0;
    let taxAmount=0;
    const discount=  getValues('discount');
    const orders=  getValues('ordered_products');
    const shipping=getValues('shipping');
    const tax=getValues('tax');
    const billingAddress=getValues('billingAddress');
    console.log(billingAddress);
    if(orders.length >0) {

        orders.map(function (item, index) {
            subTotalPrice += item.total_price;
        });
    }
    if(discount) {
        if (discount.discount_type == 1) {
            discountAmount = Number(discount.value);
        } else {
            discountAmount = subTotalPrice * (Number(discount.value) / 100);
        }
    }

    if(tax){
        if(tax.is_charge){
            const totalTaxablePrice=subTotalPrice-discountAmount;
            taxAmount=(totalTaxablePrice-(totalTaxablePrice/(1+(Number(tax.percentage)/100))));
        }
    }
    if(shipping){
        if(shipping.shipping_type ==2){
            shippingAmount=Number(shipping.price);
        }
    }


    let total=(subTotalPrice+shippingAmount)-discountAmount;
    return {subTotal:subTotalPrice.toFixed(2),total:total.toFixed(2),discountPrice:discountAmount.toFixed(2),shippingPrice:shippingAmount.toFixed(2),taxPrice:taxAmount.toFixed(2)}

}