import React, {Fragment, useEffect, useRef, useState} from "react";

import {Button, Card, CardBody, Col, Row} from "reactstrap";
import JqxTable from "../../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import {jqx} from "../../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";
import {useDispatch, useSelector} from "react-redux";
import ButtonLoader from "../../../../../../share-components/ui/buttonLoader";
import {getOrderSettings, setSettings} from "../../../../store/orderSlice";
import SweetAlert from "sweetalert2";
import {findIndexes} from "../../../../../../utils/common";
// import {
//   getAllReturnReasons,
//   addSalesOrderReturn,
//   getAllReturns,
// } from "../../../../store/saleOrderReturnSlice";

const SalesOrderPendingReturnJqx = (props) => {
    const {setValue, trigger, getValues, returnState, orderedProducts} = props;
    const dispatch = useDispatch();
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    const [orderData, setOrderData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const orderState = useSelector(({order}) => order.order);
    const [selectedRowsData, setSelectedRowsData] = useState([]);


    const reasonSources = (reasonData) => {
        console.log("Reason Data:", reasonData);
        return {
            datatype: "array",
            datafields: [
                {name: "id", type: "string"},
                {name: "name", type: "string"},
                {name: "is_restock", type: "string"},
            ],
            localdata: reasonData,
        };
    };

    const reasonAdapter: any = new jqx.dataAdapter(
        reasonSources(returnState.settings.return_reasons),
        {autoBind: true}
    );

    // useEffect(() => {
    //     setOrderData(getValues('ordered_products'));
    // }, [getValues('ordered_products')])
    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: "id", type: "number"},
        {name: "order_quantity", type: "number"},
        {name: "product", type: "array"},
        {name: "return_quantity", type: "number"},
        {name: "invoice_quantity", type: "number"},
        {name: "request_quantity", type: "number"},
        {name: "reason", type: "text"},
        {name: "approve_quantity", type: "number"},
        {name: 'is_selected', type: 'boolean'},
        {name: 'is_restock', type: 'boolean'},
        {name: 'is_declined', type: 'boolean'}

    ];

    const columns = [
        {datafield: "invoice_line_item_id", hidden: true},
        {datafield: "tax_amount", hidden: true},
        {
            text: "Product",
            datafield: "product",
            editable: false,
            columntype: "text",
            align: "center",
            width: "40%",
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues("ordered_products")[row].product;
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: "center", padding: 5}}>
                        {productDetails && (
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="img-fluid"
                                        src={productDetails.image}
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <p className={"font-primary"}>{productDetails.name}</p>
                                    </div>
                                    <div className="row">
                                        <p>{productDetails.option}</p>
                                    </div>
                                    <div className="row">
                                        <p>{productDetails.sku}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
                return html;
            },
        },
        {
            text: "Quantity",
            datafield: "order_quantity",
            editable: false,
            columntype: "text",
            width: "15%",
            cellsalign: "right",
            align: "center",
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                // console.log(row,columnfield,);
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="col-md-12 p-1">
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>order Qty</p>
                                        </div>
                                        <div className="col-8">
                                            <p className="text-right">{rowdata.order_quantity}</p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <p className='font-primary text-left font-weight-bolder'>Invoice Qty</p>
                                        </div>
                                        <div className="col-8">
                                            <p className="text-right">{rowdata.invoice_quantity}</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                );
                return html;
            },
        },
        {
            text: "Request Quantity",
            datafield: "request_quantity",
            editable: false,
            columntype: "text",
            width: "8%",
            cellsalign: "right",
            align: "center",

        },
        {
            text: "Reason",
            datafield: "reason",
            editable: false,
            columntype: "text",
            width: "8%",
            cellsalign: "right",
            align: "center",

        },
        {
            text: "Approve Qty",
            datafield: "approve_quantity",
            editable: true,
            columntype: "text",
            width: "8%",
            cellsalign: "right",
            align: "center",
            validation: function (cell, value) {
                const stock = gridRef.getcellvalue(cell.row, "request_quantity");
                if (Number(stock) < Number(value)) {
                    return {
                        result: false,
                        message: "should be less the order Quantity",
                    };
                } else {
                    return true;
                }
            },
        },
        {
            text: "Re Stock",
            datafield: "is_restock",
            editable: true,
            columntype: "checkbox",
            width: "11%",
            cellsalign: "right",
            align: "center",

        },
        {
            text: "Decline",
            datafield: "is_decline",
            editable: true,
            columntype: "checkbox",
            width: "8%",
            cellsalign: "right",
            align: "center",

        },
    ];
    const getJqxRef = (jqx) => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(() => {
                if (gridRef) {
                    const myEle = document.getElementById(gridRef._id);
                    if (myEle) {
                        gridRef.updatebounddata();
                    }
                }

                // myGrid.updatebounddata();
            }, 1000);
        }
    };
    const onDelete = (data) => {
        setDataToHookForm();
    };

    const cellEdit = (event) => {
        const rowArgs = event.args;
        // if (rowArgs.datafield == "return_quantity") {
        //     const singlePrice = gridRef.getcellvalue(
        //         rowArgs.rowindex,
        //         "original_price"
        //     );
        //     setTotalAmount(rowArgs.rowindex, rowArgs.value, singlePrice);
        // }
        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };

    const setTotalAmount = (index, quantity, singlePrice) => {
        const totalPrice = quantity * singlePrice;
        gridRef.setcellvalue(index, "total_price", totalPrice);
    };
    const setDataToHookForm = () => {
        let orderData = [];

        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);
        }
        setValue("ordered_products", orderData);
        // trigger("ordered_products");
    };

    const handelRowSelect = (event) => {
        const orderProducts = getValues("ordered_products");
        const rowIndexes = gridRef.getselectedrowindexes();

        const orderData = orderProducts.map((item, index) => {
            const taxAmount = item.is_tax ? item.rate * 0.2 : 0;
            const isSelected = rowIndexes.includes(index);
            const quantity = isSelected ?(item.approve_quantity?item.approve_quantity: item.request_quantity) : 0;

            return {
                ...item,
                approve_quantity: quantity,
                is_selected: isSelected,
            };
        });

        // const selectedData = orderData
        //     .filter((item, index) => rowIndexes.includes(index))
        //     .map((item) => ({...item, is_selected: true}));


        setValue("ordered_products", orderData);
        trigger("ordered_products");
    };

    return (
        <Fragment>
            <div style={{width: "100%"}}>
                {getValues("ordered_products").length > 0 && (
                    <JqxTable
                        // deleteAll
                        rowsheight={75}
                        datafields={datafields}
                        columns={columns}
                        data={getValues("ordered_products")}
                        myGrid={gridRef}
                        getJqxRef={getJqxRef}
                        autoheight={getValues("ordered_products").length < 7}
                        // isDelete
                        scrollerbar
                        height="500"
                        onCellendedit={cellEdit}
                        disableCreateRow
                        // onDelete={onDelete}
                        onRowselect={handelRowSelect}
                        onRowunselect={handelRowSelect}
                        selectionmode={"checkbox"}
                        selectedrowindexes={findIndexes(
                            getValues("ordered_products"),
                            (item) => item.is_selected
                        )}
                    />
                )}
            </div>
        </Fragment>
    );
};

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderedProducts) != JSON.stringify(nextProps.orderedProducts);
}

export default SalesOrderPendingReturnJqx;

