import API from "../utils/api";


export const getPartnersApi = async (data) => {
    const response = await API.get('partner/get-all',{params:data});
    return { response };
};

export const addPartnerApi = async data => {
    const response = await API.post('partner/add-partner', data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const getPartnerSettingsApi = async () => {
    const response = await API.get('partner/get-settings');
    return { response };
};

export const updatePartnerOrderApi = async data => {
	const response = await API.post('partner/update-partner-order', data);
	return { response };
};


export const getPartnerApi = async id => {
    const response = await API.get(`partner/${id}/edit`);
    return { response };
};

export const updatePartnerApi = async (data, id)  => {
    const response = await API.post(`partner/${id}/update`, data,{'content-type': 'multipart/form-data'});
    return { response };
};

export const updatePartnerStatusApi = async data => {
	const response = await API.post('partner/update-partner-status', data);
	return { response };
};

export const showPartnerApi = async id => {
    const response = await API.get(`partner/${id}/show`);
    return { response };
};

export const partnerSelectAutoSuggestionApi = async (text) => {
    const response = await API.get('partner/get-customer-details', {
    params: text,
});
return { response };
};
