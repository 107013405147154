import API from "../utils/api";

export const getData = async (data) => {
    const response = await API.get('dashboard/index',{params:data});
    return { response };
};

export const getGoogleData = async (data) => {
    const response = await API.get('dashboard/get-google-analytic-data',{params:data});
    return { response };
};