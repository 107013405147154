import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductSizeApi, deleteProductSizeApi,
    getProductSizesApi,
    updateProductSizeApi
} from "../../../services/manage-product/setting/productSizeService";
import {toast} from "react-toastify";


export const getAllProductSizes = createAsyncThunk('productSize/getProductSize', async (filters,{dispatch}) => {
    const response = await getProductSizesApi(filters);
    return response.response;
});

export const addProductSize = createAsyncThunk('productSize/addProductSize', async (productSize, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductSizeApi(productSize);
        const data = await response.response;
        dispatch(getAllProductSizes());
        toast.success("Add product size successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductSize = createAsyncThunk('productSize/updateProductSize', async (productSize, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductSizeApi(productSize, productSize.id);
        const data = await response.response;
        dispatch(getAllProductSizes());
        toast.success("Update product size successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductSize = createAsyncThunk('productSize/deleteProductSize', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductSizeApi(id);
        const data = await response.response;
        dispatch(getAllProductSizes());
        toast.success("Deleted product size successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productSizeAdapter = createEntityAdapter({});

export const {selectAll: selectProductSize, selectById: selectProductSizeById} = productSizeAdapter.getSelectors(
    state => state.product.productSize
);

const productSizeSlice = createSlice({
    name: 'productSize',
    initialState: productSizeAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'ProductSize',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectProductSizeSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'ProductSize',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductSizes.fulfilled]: productSizeAdapter.setAll
    }
});

export const {
    selectProductSizeSearchText, openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog
} = productSizeSlice.actions;


export default productSizeSlice.reducer;