import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addProductFitSizeApi, deleteProductFitSizeApi,
    getAllProductFitSizeApi, updateProductFitSizeApi
} from "../../../services/manage-product/setting/productFitSizeConditionService";
import {toast} from "react-toastify";


export const getAllProductFitSizeConditions = createAsyncThunk('productFitSizeConditions/getProductFitSizeConditions', async (filters, {dispatch}) => {
    const response = await getAllProductFitSizeApi(filters);
    return response.response;

});

export const addProductFitSizeCondition = createAsyncThunk('productFitSizeConditions/addProductFitSizeCondition', async (productFitSizeCondition, {
    dispatch,
    getState
}) => {
    try {
        const response = await addProductFitSizeApi(productFitSizeCondition);
        const data = await response.response;
        dispatch(getAllProductFitSizeConditions());
        toast.success("Add product fit size condition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductFitSizeCondition = createAsyncThunk('productFitSizeConditions/updateProductFitSizeCondition', async (productFitSizeCondition, {
    dispatch,
    getState
}) => {
    try {
        const response = await updateProductFitSizeApi(productFitSizeCondition.id,productFitSizeCondition);
        const data = await response.response;
        dispatch(getAllProductFitSizeConditions());
        toast.success("Updated product fit size condition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductFitSizeCondition = createAsyncThunk('productFitSizeConditions/deleteProductFitSizeCondition', async (id, {
    dispatch,
    getState
}) => {
    try {
        const response = await deleteProductFitSizeApi(id);
        const data = await response.response;
        dispatch(getAllProductFitSizeConditions());
        toast.success("Delete product fit size condition successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productFitSizeConditionAdapter = createEntityAdapter({});

export const {selectAll: selectProductFitSizeCondition, selectById: selectProductFitSizeConditionById} = productFitSizeConditionAdapter.getSelectors(
    state=> state.product.productFitSizeCondition
);

const productFitSizeConditionSlice = createSlice({
    name: 'fitSizeCondition',
    initialState: productFitSizeConditionAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'fitSizeCondition',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
    }),
    reducers: {
        selectFitSizeConditionSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state,action) => {
            state.addDialog = {
                component: 'fitSizeCondition',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllProductFitSizeConditions.fulfilled]: productFitSizeConditionAdapter.setAll
    }
});

export const {
    selectFitSizeConditionSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog

} = productFitSizeConditionSlice.actions;

export default productFitSizeConditionSlice.reducer;