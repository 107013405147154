import {Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment, useState} from "react";
import Select from "react-select";
import ImageUpload from 'image-upload-react';
import {updateSellerImg} from "../../store/sellerSlice";
import {useDispatch} from "react-redux";

export const SellerImageUploader = (props) => {
    const {imgSrc=null,imgGcp=null,sellerId,typeId,typeName,setLoaderIcon}=props;
    const [selectedImage,setSelectedImage]=useState(imgSrc);
    const dispatch = useDispatch();
    const onImageChange = (data) => {
        setLoaderIcon(true);
        const imageLoader=require("../../../../assets/images/uploading-text.jpg");
        setSelectedImage(imageLoader);
        if(data){
            const formData = new FormData();
            formData.append('id', sellerId);
            formData.append('type_id', typeId);
            formData.append('type_name', typeName);
            formData.append('gcp', imgGcp);
            formData.append('image', data ? data : '');
            dispatch(updateSellerImg(formData)).then(res => {
                if (!res.error) {
                    setLoaderIcon(false);
                    setSelectedImage(URL.createObjectURL(data));
                }
            });
        }
    };
    return (

        <ImageUpload
            handleImageSelect={e => {
                onImageChange(e.target.files[0]);
            }}

            imageSrc={selectedImage}
            setImageSrc={setSelectedImage}
            style={{
                width: 200,
                height: 180,
                marginTop:0,
                background: '#262f3e'
            }}
            deleteIcon={
                <span
                    style={{ color: 'white',background: '#ef0808',
                    padding: '3px',
                    borderRadius: '3px' }}
                    className="material-icons mt-20 mr-15 border-white border-3"
                >
																	<i className="fa fa-trash-o"></i>
																</span>
            }
        />
    );
};