import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import {useDispatch, useSelector} from "react-redux";
import {addPartner, getPartnerSettings, setSettings} from "../store/partnerSlice";
import withReducer from "../../../store/withReducer";
import partnerReducer from "../store";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Switch from "react-switch";
import {editorConfiguration} from "../../../utils/common";
import AsyncSelect from "react-select/async";
import {partnerSelectAutoSuggestion} from "../../../utils/mapper";
import {partnerSelectAutoSuggestionApi} from "../../../services/partner";
import ImageUpload from "image-upload-react";

export const defaultValues = {
    name: '',
    email: '',
    number: '',
    pdCountry: '',
    formats: '',
    isActive: false,
    isFormReturn: false,
    channelId:'',
    channelName:'',
    returnUrl:'',
    logoImage:'',
    imageFileLogo:'',
    logoImageDeleted:'',
    orderedVia:'',
    howMakeReturnUrl:'',
    returnContent:'',
    isPartner:false
};

/**
 * Form Validation Schema
 */
const phoneRegExp =/^[0-9]{9,10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  true;
    });
});
yup.addMethod(yup.string, "returnUrlValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        if(value){
              return  value.includes('$return_url');
        }
        return true;
    });
});
yup.addMethod(yup.string, "howToReturnUrlValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        if(value && this.parent.howMakeReturnUrl ){
            return  value.includes('$how_to_return_url');
        }
        return true;
    });
});

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    returnContent:yup.string().returnUrlValidation('You must include $return_url').howToReturnUrlValidation('You must include $how_to_return_url'),
    formats: yup
    .mixed()
    .when(['channelId'], {
      is: (channelId) => channelId?.id == '1',
      then: yup.object().required('You must select a format.').typeError('Please select Format'),
      otherwise: yup.object().nullable(),
    }),
    number: yup.string().nullable().min(9, 'Invalid Phone number').max(10, 'Invalid Phone number').when({
        is: (val) => val,
        then: yup.string().customPhoneValidation('You must enter valid phone number'),
      }),
    email: yup.string().nullable().email(),
    // pdCountry: yup.object().required('Country is required field').typeError('Please select Country'),
    // customer:yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    // isActive: yup.bool().nullable(),
    // isFormReturn: yup.bool().nullable(),
});


const AddPartner = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Partners',
            link: '/partner'
        },
        {
            type: 0,
            text: "Add Partner"
        }
    ];
    const { handleSubmit, formState, reset, register, setValue , getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const [storeDescription,setStoreDescription] = useState('');
    const [sellerDescription,setSellerDescription] = useState('');
    const [otherPolicy,setOtherPolicy] = useState('');
    const [sellerSinceDate, setSellerSinceDate] = useState(new Date());
    const [actionBtn,setActionBtn]=useState(0);
    const [isFormReturn,setIsFormReturn]=useState(false);
    const [isActive,setIsActive] = useState(false);
    const [isPartner,setIsPartner] = useState(false);
    const [basictooltip, setbasictooltip] = useState(false);
    const { errors } = formState;
    const formRef = useRef();
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const submitBtnSaveExitRef = useRef();
    const dispatch = useDispatch();
    let history = useHistory();
    const [partnerFormat, setPartnerFormat] = useState(null);


    const [selectedImageLogo,setSelectedImageLogo]=useState(getValues('imageFileLogo'));
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const handleDeleteLogoImage = () => {
        setValue('logoImage', null);
        setValue('imageFileLogo', null);
        setSelectedImageLogo(null);
        console.log('Logo Image deleted');
        const input = document.getElementById("hiddenFileInput2");
        input.value = "true";
    }

    useEffect(() => {
        dispatch(getPartnerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                console.log("result",results);
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    const partnerState = useSelector(({ partner }) => partner.partner);

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    const onSubmitForm = (data) => {
        if(data){
           
            const formData = new FormData();
            
            formData.append('name', data.name? data.name:"");
            formData.append('email', data.email? data.email:"");
            formData.append('number', data.number? data.number:"");
            formData.append('pdCountry', data.pdCountry? data.pdCountry.id:"");
            formData.append('format', data.formats? data.formats.id:"");
            formData.append('status', isActive ? 1 : 0);
            formData.append('is_return_form', isFormReturn ? 1 : 0);
            formData.append('channelId', data.channelId? data.channelId.id:"");
            formData.append('channelName', data.channelName? data.channelName:"");
            formData.append('returnUrl', data.returnUrl? data.returnUrl:"");
            formData.append('orderedVia', data.orderedVia? data.orderedVia:"");
            formData.append('howMakeReturnUrl', data.howMakeReturnUrl? data.howMakeReturnUrl:"");
            formData.append('returnContent', data.returnContent? data.returnContent:"");
            formData.append('isPartner', isPartner ? 1 : 0);
            formData.append('logoImage', data.logoImage ? data.logoImage : "");
            if (document.getElementById("hiddenFileInput2").value === "true") {
                formData.append('logoImageDeleted', true);
            } else {
                formData.append('logoImageDeleted', false);
            }

            console.log("Form Submit",data);
        submitBtnSaveRef.current.disabled=true;
        dispatch(addPartner(formData)).then(res => {
            if(!res.error){
                reset(defaultValues);               
                history.push('/partner');
            }else{
                // toast.error(res.error.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
            }
            submitBtnSaveRef.current.disabled=false;
        });
        }
    };

    const handleChange = (nextChecked) => {
        console.log(nextChecked)
        setIsActive(nextChecked);
        // setValue('status',nextChecked)
    };

    const handleChangeReturnForm = (nextChecked) => {
        console.log(nextChecked)
        setIsFormReturn(nextChecked);
        // setValue('is_return_form',nextChecked)
    };

    const handleChangeBarcode = (nextChecked) => {
        console.log(nextChecked)
        setIsPartner(nextChecked);
    };

    
    const toggle = () => setbasictooltip(!basictooltip);
    return (
        <Fragment>
            <Breadcrumb title="Add Partner" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                                
                                <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Partner Details</p>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Name<span style={{color:"red"}}>*</span></Label>
                                                <input name="name" type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="City Goddess Ltd" {...register('name')}  />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="email" type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Country</Label>
                                                <Select placeholder="United  Kingdom" getOptionLabel ={(option)=>option.name} defaultValue={getValues('pdCountry')}
                                                        getOptionValue ={(option)=>option.id} {...register("pdCountry")} onChange={(e)=>{
                                                    setValue('pdCountry',e);
                                                    trigger('pdCountry');
                                                    setDialCode(e.dial_code)
                                                }} className={` ${errors.pdCountry ? 'is-invalid' : ''}`} options={partnerState.settings?partnerState.settings.countries:null} />
                                                <div className="invalid-feedback">{errors.pdCountry?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Phone Number</Label>
                                                <FormGroup className="input-group">
                                                    <span className="input-group-text">{dialCode}</span>
                                                    <input name="number" type="number" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                                           className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                                           placeholder="Phone Number" {...register('number')}  />
                                                    <div className="invalid-feedback">{errors.number?.message}</div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md={3}>
                                        <FormGroup>
                                        <Label className="form-label">Format</Label>
                                            <Select
                                                placeholder="Select Format"
                                                name="formats"
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                isClearable
                                                options={partnerState.settings.formats}
                                                onChange={(e) => {
                                                    setPartnerFormat({
                                                        formats:e,
                                                        });
                                                    setValue('formats',e);
                                                    trigger('formats');
                                                }}
                                                className={` ${errors.formats ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.formats?.message}</div>
                                        </FormGroup>
                                        </Col>
                                       
                                        <Col md={1}>
                                            <FormGroup>
                                                <Label className="form-label">Is Active</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChange} checked={isActive} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Return Form</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChangeReturnForm} checked={isFormReturn} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    <Col md={3}>
                                    <Label for="selectOption">Select Channel name</Label>
                                    <InputGroup>
                                    <Select 
                                        placeholder="Select Channel"
                                        type="select"
                                        name="channelId"
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isClearable
                                        options={partnerState.settings.channelId}
                                        onChange={(e) => {
                                            setValue('channelId',e);
                                            trigger('channelId');
                                        }} 
                                        className={` ${errors.channelId ? 'is-invalid' : ''}`}
                                    />
                                    <div className="invalid-feedback">{errors.channelId?.message}</div>
                                    <input name="channelName" type="text" className='form-control' placeholder="channel Name" {...register('channelName')}  />
                                    </InputGroup>
                                       
                                    </Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label className="form-label">Ordered Via</Label>
                                            <input name="orderedVia" type="text" className='form-control' placeholder="Ordered Via" {...register('orderedVia')}  />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <input name="logoImageDeleted" type="hidden" id="hiddenFileInput2" />
                                            <FormGroup>
                                                <Label className="form-label">Partner shop Logo</Label>
                                                <span className="mt-3 mr-2 ml-1" id="TooltipExample2">
                                                    <i aria-hidden="true" className="fa fa-exclamation-circle"></i>
                                                </span>
                                                <Tooltip
                                                    placement= 'right'
                                                    isOpen={tooltipOpen1}
                                                    target="TooltipExample2"
                                                    toggle={toggle1}
                                                    style={{ fontSize: '12px',maxWidth: '900px' }}
                                                >
                                                    please upload logo image with width:260px, height:68px
                                                </Tooltip>
                                                <div className="text-black-50">{errors.logoImage?.message}</div>
                                                <div className="text-black-50">{errors.imageFileLogo?.message}</div>
                                                <ImageUpload
                                                    handleImageSelect={e => {
                                                        setValue('logoImage',e.target.files[0]);
                                                        setValue('imageFileLogo',URL.createObjectURL(e.target.files[0]));
                                                        setSelectedImageLogo(URL.createObjectURL(e.target.files[0]));
                                                    }}
                                                    imageSrc={selectedImageLogo}
                                                    setImageSrc={setSelectedImageLogo}
                                                    style={{
                                                        width: 'auto',
                                                        height: '150px',
                                                        marginTop:0,
                                                        marginLeft:0,
                                                        backgroundColor:'#3E4A56',
                                                        zIndex:0
                                                    }}
                                                    deleteIcon={
                                                        <span className="mt-3 mr-2"  onClick={handleDeleteLogoImage}>
                                                            <i aria-hidden="true" className="fa fa-times-circle fa-2x"></i>
                                                        </span>
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Partner barcode generate</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false} onChange={handleChangeBarcode} checked={isPartner} className="react-switch" />
                                                </div>
                                                <div style={{ width:'100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545'}}>{errors.is_parent?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                </CardBody>
                                </Card>

                                <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Return Details</p>
                                        </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="form-label">Return Url</Label>
                                            <input  name="returnUrl" type="text" className='form-control' placeholder="Return URL" {...register('returnUrl')}  />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="form-label">How to make return Url</Label>
                                            <input onBlur={()=>{
                                                if(getValues('returnContent')) {
                                                    trigger('returnContent')
                                                }
                                            }} name="howMakeReturnUrl" type="text" className='form-control' placeholder="How to make return Url" {...register('howMakeReturnUrl')}  />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="form-label">Return Content</Label>
                                            <textarea {...register('returnContent')}

                                                      className={`form-control`} rows={5}></textarea>
                                        </FormGroup>
                                        <div className="invalid-feedback" style={{display:"block"}}>{errors.returnContent?.message}</div>
                                    </Col>

                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={()=>{formRef.current.submit(); setActionBtn(0)}}  className="btn btn-primary btn-block">Save</button>
                                        </div>
                                    </div>
                                </CardBody>
                                </Card>
                            </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{partner: partnerReducer}])(AddPartner);