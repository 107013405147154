import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getNavigationMenuApi, setPermissionApi} from "../../../../services/manage-user/permission";
import {mapDataFieldListToTable, mapUserRolesToTable} from "../../../../utils/mapper";


export const getNavigation = createAsyncThunk('permission/getNavigation', async () => {
    const response = await getNavigationMenuApi();
    return response.response;
});

export const setUserPermission = createAsyncThunk('permission/setPermission', async (permission, { dispatch, getState }) => {
    try {
        const response = await setPermissionApi(permission);
        return response.response;
    } catch (e) {
        // dispatch(showMessage({ message: e, variant: 'error' }));
        // return Promise.reject(e);
    }
});

const permissionAdapter = createEntityAdapter({});

const permissionSlice = createSlice({
    name: 'permission',
    initialState: permissionAdapter.getInitialState({
        navigations: [],
        userList: [],
        dataFieldList: []
    }),
    reducers: {
        setNavigationList: (state, action) => {
            state.navigations = action.payload.navigation;
            state.userList = mapUserRolesToTable(action.payload.roles);
        },
        setDataFiledListList: (state, action) => {
            state.dataFieldList = mapDataFieldListToTable(action.payload);
        }
    },

    extraReducers: {
        [getNavigation.fulfilled]: permissionAdapter.setAll
    }
});

export const { setNavigationList, setDataFiledListList } = permissionSlice.actions;

export default permissionSlice.reducer;