import React, { useState, useRef, useEffect } from "react";
import { Form, FormGroup, Input, Button, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalesOrderComment,
  showSalesOrderComment,
  selectSalesOrder,
} from "../../../store/salesOrderSlice";

function Discussion({ salesOrderId, salesOrderComments }) {
  const dispatch = useDispatch();
  const messages = useSelector(selectSalesOrder) || [];
  const [newMessages, setNewMessages] = useState([]);
  const messageContainerRef = useRef(null);

  const fetchMessages = () => {
    dispatch(showSalesOrderComment(salesOrderId));
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fileInput = e.target.elements.file_path;
    const file = fileInput.files[0];

    try {
      const formData = new FormData();

      if (file) {
        formData.append("file_path", file);
      }

      formData.append("comment", e.target.elements.comment.value);
      formData.append("sales_order_id", salesOrderId);

      // await dispatch(addSalesOrderComment(formData));
      const addedMessage = await dispatch(addSalesOrderComment(formData));
      setNewMessages((prevMessages) => [...prevMessages, addedMessage]);
      // setNewMessage(addedMessage);

      if (file) {
        fileInput.value = "";
      }
      e.target.elements.comment.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  // console.log("New Message : " + addedMessage);

  useEffect(() => {
    fetchMessages();
  }, [salesOrderComments]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    console.log("New Messages:", newMessages);
  }, [newMessages]);

  return (
    <div>
      <div
        style={{ flex: "1", overflowY: "auto", padding: "10" }}
        ref={messageContainerRef}
      >
        {salesOrderComments.length > 0 ? (
          salesOrderComments.map((message) => (
            <div
              key={message.id}
              style={{ marginBottom: "8px" }}
              className="bg-white rounded box-shadow"
            >
              <div className="media text-muted pt-3">
                <div
                  className="avatar-initial"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    borderRadius: "50%",
                    border: "solid 20px",
                    borderColor: "#fff",
                    width: "80px",
                    height: "80px",
                    backgroundSize: "cover",
                    zIndex: "2",
                    cursor: "pointer",
                    background: "grey",
                    color: "black",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    {message.causer?.charAt(0).toUpperCase()}
                  </p>
                </div>
                <div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                  <strong
                    className="d-block text-gray-dark pb-2"
                    style={{ textTransform: "capitalize", fontSize: "14px" }}
                  >
                    {message.causer}
                  </strong>
                  {message.comment && (
                    <>
                      <div
                        className="p-2 my-1"
                        style={{
                          background: "rgba(0,0,0,0.1)",
                          width: "90%",
                          borderRadius: "8px",
                        }}
                      >
                        <span>{message.comment}</span>
                      </div>
                    </>
                  )}
                  {message.file && (
                    <div
                      style={{
                        border: "1px solid #ddd",
                        width: "400px",
                        padding: "10px",
                        marginTop: "10px",
                        display: "flex",
                      }}
                    >
                      {message.file.toLowerCase().endsWith(".pdf") ? (
                        <a
                          href={message.file}
                          download={message.fileName}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fa fa-file-pdf-o fa-5x"
                            aria-hidden="true"
                          ></i>
                        </a>
                      ) : (
                        <img
                          className="img-fluid"
                          src={message.file}
                          width="100"
                          alt=""
                        />
                      )}
                      <p className="pl-2 pt-3">File {message.fileName}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No messages available</p>
        )}

        {newMessages.map((newMessage) => (
          <div
            key={newMessage.payload.id}
            style={{ marginBottom: "8px" }}
            className="bg-white rounded box-shadow"
          >
            <div className="media text-muted pt-3">
              <div
                className="avatar-initial"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  borderRadius: "50%",
                  border: "solid 20px",
                  borderColor: "#fff",
                  width: "80px",
                  height: "80px",
                  backgroundSize: "cover",
                  zIndex: "2",
                  cursor: "pointer",
                  background: "grey",
                  color: "black",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  {newMessage.payload.causer?.charAt(0).toUpperCase()}
                </p>
              </div>
              <div className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                <strong
                  className="d-block text-gray-dark pb-2"
                  style={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  {newMessage.payload.causer}
                </strong>
                {newMessage.payload.comment && (
                  <>
                    <div
                      className="p-2 my-1"
                      style={{
                        background: "rgba(0,0,0,0.1)",
                        width: "90%",
                        borderRadius: "8px",
                      }}
                    >
                      <span>{newMessage.payload.comment}</span>
                    </div>
                  </>
                )}
                {newMessage.payload.file && (
                  <div
                    style={{
                      border: "1px solid #ddd",
                      width: "400px",
                      padding: "10px",
                      marginTop: "10px",
                      display: "flex",
                    }}
                  >
                    {newMessage.payload.file.toLowerCase().endsWith(".pdf") ? (
                      <a
                        href={newMessage.payload.file}
                        download={newMessage.payload.fileName}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-file-pdf-o fa-5x"
                          aria-hidden="true"
                        ></i>
                      </a>
                    ) : (
                      <img
                        className="img-fluid"
                        src={newMessage.payload.file}
                        width="100"
                        alt=""
                      />
                    )}
                    <p className="pl-2 pt-3">
                      File {newMessage.payload.fileName}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Col sm="12">
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <Col xs={12}>
              <div className="input-group">
                <label className="input-group-btn btn-secondary my-0 rounded-left">
                  <span
                    className="btn btn-large text-white rounded-0"
                    id="browse"
                  >
                    <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <input type="file" hidden name="file_path" />
                  </span>
                </label>
                <Input name="comment" placeholder="Enter Comment" />
                <div className="input-group-append">
                  <Button type="submit" color="primary">
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </div>
  );
}

export default Discussion;
