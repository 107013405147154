import {Badge, Card, CardBody, CardHeader, Col, FormGroup, Label, Row} from "reactstrap";
import React, {Fragment} from "react";


export const ViewDetails = (props) => {
    const {partnerDetails}=props;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary">Primary Details</div>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Name</Label>
                                    <p>{partnerDetails.name}</p>
                                </FormGroup>
                            </Col>
                            
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Email</Label>
                                    <p>{partnerDetails.email}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Country</Label>
                                    <p>{partnerDetails.pdCountry.name}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Mobile/Telephone</Label>
                                    <p>{partnerDetails.phone_number}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Customer</Label>
                                    <p>{partnerDetails.customer.label}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Format</Label>
                                    <p>{partnerDetails.format.name}</p>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Is Active</Label>
                                    <p><Badge color={partnerDetails.status == 1 ? 'success' : 'warning'} key={1}> {partnerDetails.status == 1 ? 'Active' : 'In-Active'} </Badge></p>
                                    
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Is Return Form</Label>
                                    <p>{partnerDetails.is_return_form == true? 'Yes' :'No'}</p>
                                </FormGroup>
                            </Col>
                            {/* <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label font-weight-bold">Is Return From</Label>
                                    <p>{partnerDetails.pdCountry.code && <i className={"mr-1 flag-icon flag-icon-"+partnerDetails.pdCountry.code.toLowerCase()}></i>}{partnerDetails.pdCountry.name}</p>
                                </FormGroup>
                            </Col> */}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};