import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import salesOrderReturnItemReducer from "../../store";
import RefundModal from "./content/refundModal";
import { invoiceResponseToTable } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";

import {
  getReturnItems,
  selectAllReturnItems,
} from "../../store/saleOrderReturnItemSlice";

import { sendReturnItemShopify } from "../../store/orderSlice";
import {
  setRefundModal,
  sendReturnItemUpdate,
} from "../../store/saleOrderReturnItemSlice";

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Return List",
  },
];

const SalesOrderReturnItem = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  const [modal, setModal] = useState(false);
  let history = useHistory();
  const [loading, isSetLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const orderState = useSelector(({ order }) => order.saleOrderReturnItem);

  console.log("orderState ", orderState);

  const saleOrderReturnItemState = useSelector(
    ({ order }) => order.saleOrderReturnItem
  );

  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

  const returnItems = useSelector(selectAllReturnItems);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
    };
    dispatch(getReturnItems(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const onAction = (row) => {
    dispatch(setRefundModal({ props: { isOpen: true }, data: row }));
  };
  const onRefund = (data) => {
    setBtnDisable(true);
    const postData = {
      id: data.data.id,
      is_penalty: data.isPenalty,
      is_declined: data.isDecline,
    };
    dispatch(sendReturnItemUpdate(postData)).then((res) => {
      if (!res.error) {
        getData();
        dispatch(setRefundModal({ props: { isOpen: false }, data: null }));
      }
      setBtnDisable(false);
    });
  };

  const tableColumns = [
    {
      name: "Order #",
      selector: (row) => row.order_id,
      sortable: true,
      center: false,
    },
    {
      name: "Invoice ID",
      selector: (row) => row.invoice_id,
      sortable: true,
      center: false,
    },
    {
      name: "Xero ID",
      selector: (row) => row.xero_id,
      sortable: true,
      center: false,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_at,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Return Date",
      selector: (row) => row.return_date,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: false,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            onClick={() => {
              onAction(row);
            }}
          >
            <i
              className="fa fa-undo"
              style={{
                width: 25,
                fontSize: 16,
                padding: 11,
                color: "rgb(255, 187, 51)",
              }}
            ></i>
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const onEdit = (row) => {
    history.push("/order/sale/" + row.id + "/return/invoice");
  };
  const onView = (row) => {
    history.push("order/sale/" + row.id + "/billing/invoice/" + row.id);
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Returns" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="6">
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Type Name"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={returnItems}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderReturnItemState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <RefundModal
          orderState={orderState}
          onRefund={onRefund}
          btnDisable={btnDisable}
        ></RefundModal>
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    SalesOrderReturnItem: salesOrderReturnItemReducer,
  },
])(SalesOrderReturnItem);
