import React, {Fragment} from "react";
import {FormGroup, Label} from "reactstrap";


const RoleForm = (props) => {
    const { errors, control, userRoles, register,setValue,trigger,roles,getValues } = props;

    return (
        <Fragment>
            <FormGroup>
                <Label className="col-form-label">Role Name</Label>
                <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.name?.message}</div>
            </FormGroup>
        </Fragment>
    );
};

export default RoleForm;