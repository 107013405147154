import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {getInventoryAdjustApi, exportProductConditionDetailsApi, exportInventoryDetailsApi, exportRibbonDetailsApi, 
    exportBarcodeDetailsApi, exportPlasticDetailsApi
} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";

export const getInventoryAdjust=createAsyncThunk('product/getInventoryAdjust',async(data, {dispatch})=>{
    const response=await getInventoryAdjustApi(data);
    dispatch(setInventoryData(response.response.count))
    return response.response.data;
});

export const exportProductConditionDetails = createAsyncThunk('product/exportProductConditionDetails', async (data, {dispatch}) => {
    const response = await exportProductConditionDetailsApi(data);
    return response.response;
});

export const exportInventoryDetails = createAsyncThunk('product/exportInventoryDetails', async (data, {dispatch}) => {
    const response = await exportInventoryDetailsApi(data);
    return response.response;
});

export const exportRibbonDetails = createAsyncThunk('product/exportRibbonDetails', async (data, {dispatch}) => {
    const response = await exportRibbonDetailsApi(data);
    return response.response;
});

export const exportBarcodeDetails = createAsyncThunk('product/exportBarcodeDetails', async (data, {dispatch}) => {
    const response = await exportBarcodeDetailsApi(data);
    return response.response;
});

export const exportPlasticDetails = createAsyncThunk('product/exportPlasticDetails', async (data, {dispatch}) => {
    const response = await exportPlasticDetailsApi(data);
    return response.response;
});


const inventoryAdjustmentAdapter = createEntityAdapter({});
export const {selectAll: selectinventoryAdjustment, selectById: selectinventoryAdjustmentById} = inventoryAdjustmentAdapter.getSelectors(
    state => state.product.inventoryAdjustment
);

const inventoryAdjustmentSlice = createSlice({
    name: 'inventoryAdjustment',
    initialState: inventoryAdjustmentAdapter.getInitialState({
        inventoryAdjustmentTableRow: 0, 
       
    }),
    reducers: {
        setInventoryData: (state, action) => {
            state.inventoryAdjustmentTableRow = action.payload;
        },  
    },
    extraReducers: {
        [getInventoryAdjust.fulfilled]: inventoryAdjustmentAdapter.setAll,
    }
});

export const {setInventoryData, } = inventoryAdjustmentSlice.actions;

export default inventoryAdjustmentSlice.reducer;