import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Form, Button,

} from 'reactstrap'
import {optionDefaultValue} from "../../../../utils/common";
import withReducer from "../../../../store/withReducer";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {useHistory, useParams} from "react-router-dom";
import PreLoader from "../../../../share-components/ui/preLoader";
import draftOrderReducer from "../../store";
import {
    addDraftOrders, closeNewAddressDialog, getCountryList, getCountryListData,
    getCustomerAddressData,
    getDraftOrder, openEditAddressDialog,
    setSearchProductDialog,
    updateDraftOrder
} from "../../store/draftOrderSlice";
import SearchProductModal from "./content/searchProductModal";
import OrderProductJqx from "./content/orderProductJqx";
import {mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {customerAutoSuggestionApi} from "../../../../services/order";
import AsyncSelect from "react-select/async";
import TagsInput from "react-tagsinput";
import OrderPayment from "./content/orderPayment";

import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import OrderDiscount from "./content/orderDiscount";
import OrderShipment from "./content/orderShipment";
import OrderTax from "./content/orderTax";
import BuyerLeadAddressForm from "../../../leads/form/buyerLeadAddressForm";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import Select from "react-select";
import DraftOrderAddressForm from "./buyerLeadAddressForm";
import {getBuyerLeadSettings, setSettings} from "../../../leads/store/buyerLeadSlice";


const defaultValues = {
    id: '',
    ordered_products: [],
    tags: [],
    notes: "",
    discount: "",
    shipping: "",
    tax:"",
    is_tax: false,
    is_paid:false,
    customer:"",
    shopify_draft_order_id:"",
    shippingAddress:'',
    billingAddress:'',
};

export const defaultAddressValues = {
    company:'',
    type:'',
    country: '',
    first_name: '',
    last_name:'',
    address1: '',
    address2: '',
    city:"",
    zip:'',
    phone:'',
    province:'',
    order_no:''


};



/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});
const dataMenu = [
    {
        type: 1,
        text: 'Draft Order',
        link: '/order/draft'
    },
    {
        type: 0,
        text: "Draft Order Form"
    }
];


export const addresSchema = yup.object().shape({
    address1: yup.string().required("Address line 1 is required").typeError('Address line 1 is required'),
    city: yup.string().required("City is required").typeError('City is required'),

    zip: yup.string().required("Zip is required").typeError('Zip is required'),
    country: yup.string().required('Country is required field').typeError('Please select Country'),
    phone: yup.string().nullable().matches(/^.{0}$|^.{9,10}$/, 'Phone must be empty or have 9 or 10 characters'),
});

const DraftOrderForm = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);

    let searchProductRef = useRef();
    const [shippingAddress, setShippingAddress] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    const [type, setType] = useState(1);
    const [addressStatus, setAddressStatus] = useState(false);
    const [billingAddressStatus, setBillingAddressStatus] = useState(false);
    const buyerLeadState = useSelector(({lead}) => lead.buyerLead);

    const draftOrderState = useSelector(({order})=> order.draftOrder);

    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {handleSubmit:addressHandleSubmit,
        formState:addressFormState,
        control:addressControl,
        reset:addressReset,
        register:addressRegister,
        trigger:addressTrigger,
        setValue:addressSetValue,
        getValues:addressRGetValues

    } = useForm({
        mode: 'onChange',
        defaultValues:defaultAddressValues,
        resolver: yupResolver(addresSchema)
    });

    const {errors:addressErrors, addressIsDirty} = addressFormState;


    const {errors, isDirty} = formState;
    useEffect(() => {
         if(id) {
             dispatch(getDraftOrder(id)).then(res => {
                 if (!res.error) {
                     reset(res.payload);

                     // setValue('shippingAddress', res.payload);
                     // setValue('billingAddress', res.payload);
                     setLoading(false);
                 }
             });
         }else{
             setLoading(false);
         }
    }, [dispatch]);

    useEffect(()=>{
        dispatch(getCountryList()).then(res => {
            dispatch(getCountryListData(res.payload));
        });
    },[dispatch])

    const onSubmit = (data) => {
        setButtonDisable(true);
        let line_items=[];
        (data.ordered_products).map((item,index)=>{
           line_items.push({variant_id:item.variant_id,quantity:item.quantity,price:item.price});
        });
        data.ordered_products=line_items;
        data.customer=data.customer.value;
        if(data.id){
            dispatch(updateDraftOrder({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/draft')
                }
            });
        }else {
            dispatch(addDraftOrders({...data})).then(res => {
                if (!res.error) {
                    history.push('/order/draft')
                }
                ;
            });
        }

    }
    const openSearchProductDialog = () => {
        const obj = {props: {isOpen: true}, data: {searchValue: searchProductRef.current.value}}
        dispatch(setSearchProductDialog(obj));
    }

    const [addressArray, setAddressArray] = useState([]);
    const [fullAddressArray, setFullAddressArray] = useState([]);
    const [add, setAdd] = useState([]);
    const getCustomerAddress = (e) => {
       if (e) {
           dispatch(getCustomerAddressData(e.value)).then(res=>{
               if (res.payload) {
                   setFullAddressArray(res.payload)
                   let addressArr = [];
                   if(res.payload.length >0) {
                       res.payload.map(function (value) {
                           if (value.is_default) {
                               setAdd([{id: value.id, name: value.address1}]);
                               // setShippingAddress(value);
                               setValue('shippingAddress', value);
                               setValue('billingAddress', value);
                               addressTrigger();
                           }

                           if (res.payload.length > 1) {
                               addressArr.push({'id': value.id, 'name': value.address1})
                           }

                       });
                       setAddressArray(addressArr)
                   }else{
                       setValue('shippingAddress', {});
                       setValue('billingAddress', {});
                       addressTrigger();
                   }
                   setVatForUk();
               };
           });
       }
    }

    const setAddressData = (e) => {
        fullAddressArray.map(function (value){
            if (value.id===e.id) {
                addressReset(value);
            }
        });
    }


    const onSubmitForm = (dialog, data) => {
        if (data.type==="1") {
            // setShippingAddress(data)
            setValue('shippingAddress', data);
            addressTrigger()
            // setShippingAddress(data);
        } else {
            // setBillingAddressStatus(data)
            setValue('billingAddress', data);
            addressTrigger()
            // setBillingAddress(data)
        }
        setVatForUk();
        closeDialog();
    }


    // useEffect(() => {
    //     addressSetValue('shippingAddress', shippingAddress)
    //     addressSetValue('billingAddress', billingAddress)
    // }, [shippingAddress, billingAddress]);

    const closeDialog = () => {
        dispatch(closeNewAddressDialog())
    }

    const onEdit = (editId,type) => {
        setType(type)
        const addArray = [...addressArray];
        const dataObj = {...addArray[editId], id: editId};
        const obj = {
            type: 'edit',
            isOpen: true,
            data: type===1 ? getValues('shippingAddress'): getValues('billingAddress')
        };
        // setValue('address', address)
        if (getValues('shippingAddress').first_name!== undefined || getValues('shippingAddress').first_name!== '') {
            dispatch(openEditAddressDialog(obj))
        }

    }
    const isOjectEqual=(obj1,ob2)=>{
        if(obj1 && ob2) {

            delete obj1['type'];
            delete ob2['type'];
            return JSON.stringify(obj1) === JSON.stringify(ob2);
        }else{
            return false;
        }
    }

    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(customerAutoSuggestionApi(data)))
            });
        });
    const sameAsShipping=()=>{
        setValue('billingAddress', getValues('shippingAddress'));
        setVatForUk();
        addressTrigger()
    }

    const setVatForUk=()=>{
        const country=optionDefaultValue(draftOrderState.settings.countries,getValues('billingAddress').country);
        if(country?.code == "GB"){
            setValue('is_tax',true);
            setValue('tax',{"is_charge":true,"rate_name":"GB VAT 20%","percentage":20});
            trigger();
        }else{
            setValue('is_tax',false);
            setValue('tax',"");
            trigger();
        }
    }
    return (
        <Fragment>
            <Breadcrumb title={`Draft Order`+(getValues('order_no')?" - "+getValues('order_no'):"")} data={dataMenu}/>
            <Container fluid={true}>
                {loading ? <>
                        <PreLoader></PreLoader>
                    </> :

                    <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm="8">
                                <Row>
                                    <Col sm="12">
                                        <Card>

                                            <CardBody>
                                                <div className="form-row mb-3 ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Products</Label>
                                                            <Row>
                                                                <Col md="10 input-with-icon pr-1">
                                                                    <div>
                                                                        <FormGroup className="m-0">
                                                                            <i className="fa fa-search"></i>
                                                                            <input name="searchProduct" type="text"
                                                                                   placeholder="search Products"
                                                                                   ref={ref => {
                                                                                       searchProductRef.current = ref;
                                                                                   }}
                                                                                   onKeyUp={openSearchProductDialog}
                                                                                   className={`form-control`}/>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col md="2 pl-0">
                                                                    <Button color="primary" type="button"
                                                                            className="btn btn-sm btn-primary btn-block"
                                                                            onClick={openSearchProductDialog}
                                                                            style={{height: 38}}>Browse</Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <OrderProductJqx setValue={setValue}
                                                                                     getValues={getValues}
                                                                                     trigger={trigger}
                                                                                     orderedProducts={getValues('ordered_products')}
                                                                    ></OrderProductJqx>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {getValues('ordered_products').length > 0 && <><Row>
                                    <Col sm="12">
                                        <OrderPayment setValue={setValue} getValues={getValues}
                                                      trigger={trigger}></OrderPayment>
                                        <div style={{color:'red',float:"right"}}>{errors.shipping?.message}</div>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <div className="float-right" style={{display:"flex"}}>
                                                        <div className="checkbox checkbox-dark mr-5">
                                                            <input id="is_paid" type="checkbox"  onChange={(e)=>{
                                                                      setValue('is_paid',e.target.checked)
                                                            }} defaultChecked={getValues('is_paid')}/>
                                                            <Label for="is_paid">Mark As Paid</Label>
                                                        </div>
                                                        <ButtonLoader disabled={buttonDisable}  color="primary"  value="1"	type="submit"  btntext="Create Draft Order"></ButtonLoader>
                                                    </div>

                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </>
                                }

                            </Col>
                            <Col sm="4">
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Customer</Label>

                                                            <AsyncSelect
                                                                className={` ${errors.customer ? 'is-invalid' : ''}`}
                                                                isClearable
                                                                cacheOptions
                                                                defaultOptions
                                                                defaultValue={getValues('customer')}
                                                                loadOptions={typePromiseOption}
                                                                onChange={(e) => {
                                                                    setValue('customer', e);
                                                                    trigger();

                                                                    getCustomerAddress(e);

                                                                }}

                                                            />
                                                            <div className="invalid-feedback">{errors.customer?.message}</div>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {getValues('customer')&&<Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="6">
                                                        <Label style={{fontWeight: 'bold'}}>Shipping Address</Label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                            <a onClick={() => {
                                                                onEdit(getValues("shippingAddress").id,1);
                                                            }}><i className="fa fa-pencil"
                                                                  style={{
                                                                      width: 25,
                                                                      fontSize: 16,
                                                                      color: 'rgb(40, 167, 69)'
                                                                  }}></i></a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>First Name :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.first_name}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Last Name :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.last_name}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Address 1 :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.address1}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Address 2 :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.address2}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>City :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.city}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Province :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.province}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Country :</label>
                                                        <label style={{marginLeft: '5px'}}>{ getValues("shippingAddress")?.country}</label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <label style={{fontWeight: "bolder"}}>Zip :</label>
                                                        <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.zip}</label>
                                                    </Col>
                                                </Row>


                                                <Row style={{marginTop: '20px'}}>
                                                    <Col sm="6">
                                                        <Label style={{fontWeight: 'bold'}}>Billing Address</Label>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                            {! (isOjectEqual(getValues("shippingAddress"),getValues("billingAddress"))) && <a className="mr-3 btn btn-success btn-xs" onClick={sameAsShipping}>Same As Shipping</a>}
                                                            <a onClick={() => {
                                                                onEdit(getValues("billingAddress") ? getValues("billingAddress").id : 0,2);
                                                            }}><i className="fa fa-pencil"
                                                                  style={{
                                                                      width: 25,
                                                                      fontSize: 16,
                                                                      color: 'rgb(40, 167, 69)'
                                                                  }}></i></a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>

                                                        {isOjectEqual(getValues("shippingAddress"),getValues("billingAddress")) && <Label>Same As Shipping Address</Label>}

                                                    </Col>
                                                </Row>
                                                {!isOjectEqual(getValues("shippingAddress"),getValues("billingAddress"))  && <Row>



                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>First Name :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.first_name}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Last Name :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.last_name}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Address 1 :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.address1}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Address 2 :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.address2}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>City :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.city}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Province :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.province}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Country :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.country}</label>
                                                            </Col>
                                                            <Col sm="6">
                                                                <label style={{fontWeight: "bolder"}}>Zip :</label>
                                                                <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.zip}</label>
                                                            </Col>
                                                </Row>}
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>}
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Notes</Label>
                                                            <input name="notes" type="text" {...register('notes')}
                                                                   className={`form-control`}/>
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="form-row ">
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <Label className="col-form-label">Tags</Label>
                                                            <TagsInput className="form-control tag-input-custom"
                                                                       onlyUnique={true} value={getValues('tags')}
                                                                       onChange={(value) => {
                                                                           // alert(JSON.stringify(value));
                                                                           setValue('tags', value)
                                                                           trigger('tags');
                                                                           // combineTags();
                                                                       }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Form>


                }
            </Container>
            <SearchProductModal setValue={setValue} getValues={getValues} trigger={trigger}></SearchProductModal>
            <OrderDiscount orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderDiscount>
            <OrderShipment draftOrderState={draftOrderState} orderSetValue={setValue} orderTrigger={trigger}
                           orderGetValues={getValues}></OrderShipment>
            <OrderTax orderSetValue={setValue} orderTrigger={trigger}
                      orderGetValues={getValues}></OrderTax>

            <ModalFormSubmit
                defaultValues={defaultAddressValues}
                schema={addresSchema}
                onSubmitForm={onSubmitForm}
                reset={addressReset}
                formState={addressFormState}
                handleSubmit={addressHandleSubmit}
                title='Draft Order Address'
                selector='BuyerLead'
                dialog={draftOrderState.addressAddDialog}
                closeDialog={closeDialog}
                setValue={addressSetValue}
                getValues={addressRGetValues}
                isUseSelector
                size="xl"
                publish={false}
            >
                <DraftOrderAddressForm countries={draftOrderState.settings.countries} control={addressControl}
                                      formText="Buyer Lead Address" errors={addressErrors} register={addressRegister}
                                      setValue={addressSetValue}
                                      trigger={addressTrigger} getValues={addressRGetValues} addressArray={addressArray} add={add} setAddressData={setAddressData} type={type}/>
            </ModalFormSubmit>



        </Fragment>
    );

}

export default withReducer([
    {
        order: draftOrderReducer
    }
])(DraftOrderForm);