import {createSelector, createEntityAdapter, createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { getMenusApi } from '../../services/auth';

export const getUserMenus = createAsyncThunk('settings/navigation/getUserMenus', async () => {
    const response = await getMenusApi();
    return response.response;
});

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(emptyInitialState, []);




export const {
    selectAll: selectNavigationAll,
    selectIds: selectNavigationIds,
    selectById: selectNavigationItemById
} = navigationAdapter.getSelectors(state => state.theme.navigation);

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setNavigation: navigationAdapter.setAll,
        resetNavigation: (state, action) => initialState
    }, extraReducers: {
        [getUserMenus.fulfilled]: navigationAdapter.setAll,
    }
});

export const {setNavigation, resetNavigation} = navigationSlice.actions;
export default navigationSlice.reducer;