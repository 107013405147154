import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import React, {Fragment, useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {schema} from "../../manage-order/dispatch-order";
import Select from "react-select";
import {getSelectedSettingData, storeSettingsData} from "../../report/backInStockReport/store/backInReportSlice";


const defaultValues = {
    name:'',
    is_percentage: '',
    value: '',
}

const GeneralSetting = () =>{
    const {slug} = useParams();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [dataList, setDataList] = useState([]);

    const {handleSubmit, formState, reset, register, setValue, getValues, trigger, control} = useForm({});




    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "settingList", // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });



    useEffect(()=>{
        dispatch(getSelectedSettingData()).then(res=>{
           setValue('settingList', res.payload);
            setDataList(res.payload);

        })
    }, [dispatch]);


    const percent = [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
    ];


    // console.log(getValues(''))


    const {errors} = formState;

    const onSubmitForm = (data) => {
        console.log(data)
        const finalData = {
            data:data.settingList
        }

        dispatch(storeSettingsData(finalData)).then(res=>{
            console.log(res)
        })

    }

    const dataMenu = [
        {
            type: 0,
            text: "Settings",
        }
    ];

    return (
        <Fragment>
            <Breadcrumb title="Settings" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                {/*<div className="product-grid">*/}
                {/*    <div className="feature-products">*/}
                        <Col className="mt-4" sm="12">
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(onSubmitForm)}>
                                        {fields && fields.map((field, index) =>
                                        <Row key={field.index}>
                                            <Col md={4}>
                                                <Label className="col-form-label">Name</Label>
                                                <input
                                                    disabled={true}
                                                    name="name"
                                                    type="text"
                                                    value={field.name}
                                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    style={{ border: '1px solid gray' }}
                                                />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </Col>
                                            <Col md={4} className="mt-2">
                                                <Label className="form-label">Is Percentage</Label>
                                                <Select
                                                    getOptionLabel={(option) => option.label}
                                                    placeholder="Is Percentage"
                                                    getOptionValue={(option) => option.value}
                                                    {...register(`settingList.${index}.is_percentage`)}
                                                    defaultValue={field.is_percentage===1 ? {label:'Yes',value:1} : {label:'No',value:0}}
                                                    onChange={(e) => {
                                                        setValue(`settingList.${index}.is_percentage`, e.value);
                                                        trigger(`settingList.${index}.is_percentage`);
                                                    }}
                                                    className={` ${errors.is_percentage ? 'is-invalid' : ''}`}
                                                    options={percent}
                                                    style={{ border: '1px solid gray' }}
                                                />
                                                <div className="invalid-feedback">{errors.is_percentage?.message}</div>
                                            </Col>
                                            <Col md={4}>
                                                <Label className="col-form-label">Value</Label>
                                                <input
                                                    // name={`value-${field.item_id}`}
                                                    // onChange={()=>{
                                                    //     trigger(`value-[${field.item_id}]`)
                                                    // }}
                                                    defaultValue={field.value}
                                                    type="text"
                                                    {...register(`settingList.${index}.value`)}
                                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    style={{ border: '1px solid gray' }}
                                                />
                                                <div className="invalid-feedback">{errors.value?.message}</div>
                                            </Col>

                                            <Col md={7}>
                                                {/*<input type={"hidden"} value={field.item_id} {...register(`id-${field.id}`)}/>*/}
                                            </Col>
                                        </Row>
                                            )}
                                        <Row>
                                            <Col md={10}></Col>
                                            <Col md={2} style={{marginTop: 36}}>
                                                <button
                                                    style={{ border: '1px solid black' }}
                                                    onClick={() => {
                                                        // setActionBtn(1)
                                                    }}
                                                    className="btn btn-success btn-block"
                                                >
                                                    Save
                                                </button>
                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                {/*    </div>*/}
                {/*</div>*/}
            </Container>
        </Fragment>
    );
}

export default GeneralSetting;