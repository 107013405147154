import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Card, CardBody, Col, Container, FormGroup, Nav, Row} from "reactstrap";
import * as yup from "yup";
import Breadcrumb from "../../../../layout/breadcrumb";
import ColoredLine from "../../../../share-components/ui/coloredLine";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {mapCompositionResponseToTable, mapSearchAutoSuggestion} from "../../../../utils/mapper";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import SizeMatrixForm from "./sizeMatrixForm";
import {sizeMatrixAutoSuggestionApi} from "../../../../services/manage-product/setting/sizeMatrixConfigurationService";
import {
    addSizeMatrixConfiguration,
    closeEditDialog,
    deleteSizeMatrixConfiguration,
    getAllSizeMatrixConfiguration,
    getSelectedSizeMatrixOrder,
    openEditDialog,
    openNewDialog,
    selectSizeMatrixConfiguration,
    setSortDialog,
    updateSizeMatrixConfiguration
} from "../../store/sizeMatrixConfigurationSlice";
import SizeMetrixSortModal from "./sizeMetrixSortModal";
import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import {Form} from "react-bootstrap";
import PreLoader from "../../../../share-components/ui/preLoader";

const schema = yup.object().shape({
    name: yup.string().required('You must enter a product type name'),
});

const defaultValues = {
    id: '',
    name: '',
    composition_group: ''
};
const dataMenu = [
    {
        type: 0,
        text: 'Products',
        link: '/product'
    },
    {
        type: 1,
        text: 'Product Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Product Size Format',
        link: ''
    }
];


const SizeMatrixConfiguration = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [val, setVal] = useState(false);
    const [loading, setLoading] = useState(true);
    const matrixConfiguration = useSelector(selectSizeMatrixConfiguration);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    const productOrderState = useSelector(({product}) => product.sizeMatrixConfiguration.sizeMatrix);


    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const tableColumns = [
        {
            name: 'Size Format Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
    ];

    useEffect(() => {
        const data = {
            name
        }
        dispatch(getAllSizeMatrixConfiguration({...data})).then(res=>{
            setLoading(false);
        });
    }, [dispatch, name]);

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addSizeMatrixConfiguration({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateSizeMatrixConfiguration({...data})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        }
    }


    const closeDialog = () => {
        dispatch(closeEditDialog());
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    }

    const onDelete = (row) => {
        dispatch(deleteSizeMatrixConfiguration(row.id))
    }


    const onSort = (row) => {
        dispatch(getSelectedSizeMatrixOrder(row.id)).then(res => {
            let data = {props: {isOpen: true}, row: row, sortData: res.payload}
            dispatch(setSortDialog(data));
        });
    }

    const sizeMatrix = (inputValue: string) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sizeMatrixAutoSuggestionApi(data)))
            });
        });

    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                <Breadcrumb data={dataMenu} title="Size Format"/>
                <div className="product-grid">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12">
                                {(currentUser.permission).includes('Create Size Format') ?
                                <Card>
                                    <Row>
                                        <Col sm="6">
                                            <Nav tabs className="border-tab">

                                            </Nav>
                                        </Col>
                                        <Col sm="6">
                                            <div className='float-sm-right p-3'>
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={() => {
                                                       dispatch(openNewDialog())
                                                   }}
                                                > <i className="fa fa-plus"></i>Add Size Format</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                    :''}
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12" sm="12">
                                <Form>
                                    <FormGroup>
                                        <input onChange={(e) => setName(e.target.value)} name="name"
                                               type="text" className="form-control border"
                                               placeholder="Search By Product Size Format Name"/>
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                        <div className="table-responsive product-table mt-4">
                            <CommonDataTable
                                headerColumns={tableColumns}
                                gridData={mapCompositionResponseToTable(matrixConfiguration)}
                                pagination
                                onEdit={(currentUser.permission).includes('Update Size Format') ? onEdit:''}
                                onDelete={(currentUser.permission).includes('Delete Size Format') ? onDelete:''}
                                onSort={(currentUser.permission).includes('Sort Order') ? onSort:''}
                            />
                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                </>}
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Size Format'
                selector='product'
                dialog={({product}) => product.sizeMatrixConfiguration.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <SizeMatrixForm
                    control={control} errors={errors} register={register} setValue={setValue} trigger={trigger}
                    getValues={getValues} sizeMatrix={sizeMatrix}
                />
            </ModalFormSubmit>

            <SizeMetrixSortModal val={val} setValue={setValue} getValues={getValues}
                                 productOrderState={productOrderState}/>

        </Fragment>

    )

}

export default withReducer([{sizeMatrixConfiguration: productReducer}])(SizeMatrixConfiguration)