import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addSellerStaffApi,
    getSelectedSellerStaffDataApi,
    getSellerStaffNavigationApi,
    getSellerStaffsApi, updateSellerStaffApi
} from "../../../services/sellerStaff";
import {toast} from "react-toastify";
import seller from "./sellerSlice";


export const getSellerNavigationMenu = createAsyncThunk('sellerStaff/getSellerStaff', async (id,{dispatch})=>{
    const response = await getSellerStaffNavigationApi(id);
    return response.response;
});

export const getAllSellerStaff = createAsyncThunk('sellerStaff/getAllSellerStaff', async (data,{dispatch}) =>{
    const response = await getSellerStaffsApi(data);
    return response.response;
});

export const getSelectedSellerStaffData = createAsyncThunk('sellerStaff/getSelectedSellerStaffData', async (data, {dispatch})=> {
   const response = await getSelectedSellerStaffDataApi(data);
    return response.response;
});

export const addSellerStaff = createAsyncThunk('sellerStaff/addSellerStaff',async (sellerStaff, {dispatch,getState})=>{
    try {
        const response = await addSellerStaffApi(sellerStaff);
        const data = await response.response;
        toast.success("Seller Staff added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }

});

export const updateSellerStaff = createAsyncThunk('sellerStaff/updateSellerStaff',async (sellerStaff, {dispatch,getState})=>{
    try {
        const response = await updateSellerStaffApi(sellerStaff,sellerStaff.id);
        const data = await response.response;
        toast.success("Seller Staff updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {

        return Promise.reject(e);
    }

});

const sellerStaffAdapter = createEntityAdapter({});

export const {selectAll: selectSellerStaff,selectById: selectSellerStaffById} = sellerStaffAdapter.getSelectors(
    state => state.seller.sellerStaff
);

const sellerStaffDefaultInfo = {
    id:'',
    name: '',
    email:'',
    mobile: '',
    permission: []
}

const sellerStaffSlice = createSlice({
    name: 'sellerStaff',
    initialState: sellerStaffAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'sellerStaff',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        defaultValues: sellerStaffDefaultInfo,
        // sellerPermissions: ''
    }),
    reducers: {
        selectSellerStaffSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        setSellerStaffInfo: (state, action) => {
            state.defaultValues = action.payload
        },
        setSellerStaffPermission: (state, action) => {
            state.sellerPermissions = action.payload
        }

    },
    extraReducers: {
        [getAllSellerStaff.fulfilled]: sellerStaffAdapter.setAll
    }
});

export const {
    selectSellerStaffSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog,
    setSellerStaffInfo,
    setSellerStaffPermission
} = sellerStaffSlice.actions;

export default sellerStaffSlice.reducer;