import React, {Fragment} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/async";

const ProductCompositionForm = (props) => {
    const {errors, control, register, setValue, trigger, getValues, setOptions, compositionGroupPromiseOption} = props;

    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Product composition group</Label>
                        <AsyncSelect
                            isClearable
                            cacheOptions
                            defaultOptions
                            defaultValue={getValues('composition_group')}
                            loadOptions={compositionGroupPromiseOption}
                            onChange={(e) => {
                                setValue('composition_group', e.value)
                            }}

                        />
                        <div className="invalid-feedback">{errors.composition_group?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Composition Name </Label>
                        <input name="name" type="text" {...register('name')}
                               className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Fabric Percentage (%)</Label>
                        <input name="fabric_percentage" type="text" {...register('fabric_percentage')}
                               className={`form-control ${errors.fabric_percentage ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.fabric_percentage?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ProductCompositionForm;