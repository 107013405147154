import React from 'react';
import CommonDataTable from "../../../share-components/table/commonDataTable";
;



function BackInStockTable(props) {
    const {handleChange,tableColumns,backStockData,backInRequestState,paginationTotalRows, onView,pageChange,onPageSizeChange,toggleRowSelected, idArray,selectableRowDisabled= null, paginationRowsPerPageOptions}=props;

    return (
        <CommonDataTable
            headerColumns={tableColumns}
            gridData={backStockData}
            paginationServer
            paginationTotalRows={paginationTotalRows}
            noAction
            selectableRows
            onSelectedRowsChange={handleChange}
            onView={onView}
            onChangePage={pageChange}
            onChangeRowsPerPage={onPageSizeChange}
            toggleRowSelected={toggleRowSelected}
            selectableRowSelected={(row)=>{
                return (idArray.idArray).includes(row.id)
            }}
            selectableRowDisabled={selectableRowDisabled}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            {...props}
        />
    );
}
function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.backStockData) == JSON.stringify(nextProps.backStockData) && prevProps.remove == nextProps.remove && prevProps.paginationTotalRows == nextProps.paginationTotalRows;
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(BackInStockTable, areEqual);