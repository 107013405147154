import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Row, Form, Input} from "reactstrap";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {getPendingCancel, selectcancelOrder, getPendingCancelDetails, setPendingCancelModal, openPendingCancelDialogs, setPendingCancelViewData, getPendingCancelStatus} from "../../store/cancelOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import PendingCancelModal from "./create/content/pendingCancelModal";
import {toast} from "react-toastify";
import {DateRangePicker} from "rsuite";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../../services/manage-product/productService";
import DatePicker from "../../../../share-components/ui/DateRangePicker";
import Select from "react-select";
const {afterToday} = DateRangePicker;


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function PendingCancel() {

    const dispatch = useDispatch();
    const cancelOrderState = useSelector(({order}) => order.cancelOrder);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const pendingCancel = useSelector(selectcancelOrder);
    let history = useHistory();
    const dataMenu = [
        {
            type: 0,
            text: 'Cancel Order',
        }
    ];
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    // const [name, setName] = useState(null);
    // const [timer, setTimer] = useState(null);
    const [startDate, endDate] = dateRange;
    const [order, setOrder] = useState(null);
    const [timer, setTimer] = useState(null);


    let tableColumns = [
       
        {
            name: 'Order Id',
            selector: row => row.order_id,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: false,
        },
        {
            name: 'Requested Date',
            selector: row => row.requested_date,
            sortable: true,
            center: false,
        },
        {
            name: 'Customer',
            selector: row => row.customer,
            sortable: true,
            center: false,
        },
        {
            name: 'No of items',
            selector: row => row.no_of_items,
            sortable: true,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: row => 
            <div>
                <a>
                    <i className="fa fa-eye" style={{cursor: 'pointer'}} onClick={()=>{
                        viewCheckoutData(row.id);
                    }}>
                    </i>
                </a>
            </div>,

            visible: true,
            sortable: true,
            center: true,
        }
        
    ];

    const viewCheckoutData=(id)=>{
        dispatch(getPendingCancelDetails(id)).then(res=>{
            if(!res.error){
                 dispatch(setPendingCancelModal(true));
                 dispatch(setPendingCancelViewData(res.payload))
            }
        });
    }
    const [page, setPage] = useState(1);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [loading, isSetLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [btnDisable, setBtnDisable] = useState(false);
    const [status, setStatus] = useState(null);

    useEffect(()=> {
        getData();
    },[dispatch, page, pageSize, startDate, endDate, order, status]);

    const submitdata = (id,status) =>{
        const data={
             id,
             status
         }
         dispatch(getPendingCancelStatus(data)).then(res=>{
             if(!res.error){
                 toast.success("Action Updated successfully",);
                 getData();
             }  
         }); 
     }

    const getData = () =>{
        
            isSetTableLoading(true);
            const data = {
                page:page,
                pageSize:pageSize,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                order:order,
                status:status
            }
            
            dispatch(getPendingCancel(data)).then(res=>{
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        
    }

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

        
        const fulfillmentOptions = [
            {value: '1 ', label: 'Pending'},
            {value: '2', label: 'Cancel'},
            {value: '3', label: 'Decline'},
        ]

        
  return (
    <Fragment>
         <Breadcrumb title="Cancel Order" data={dataMenu}/>

         <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
                <div className="feature-products">
                <Row>
                        <Col sm="4">
                            <div className="dashboard-datepicker">
                                <DatePicker
                                    dateRange={dateRange}
                                    dateRangeChange={(date) => {
                                        setDateRange(date)
                                    }}
                                    defaultValue={[TodayDate, sevenDays]}
                                    disabledDate={afterToday()}
                                />
                            </div>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Select
                                    placeholder={<div>Status</div>}
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={fulfillmentOptions}
                                    onChange={(e) => {
                                        // console.log(e)
                                        setStatus(e ? e.value : null)
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        name="order"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setOrder(e.target.value);

                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="mt-3">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={pendingCancel}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={cancelOrderState.cancelOrderTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <PendingCancelModal submitdata={submitdata} />
                </div>
            </div>
        </Container>
    </Fragment>
  )
}

export default PendingCancel