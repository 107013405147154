import React, {Fragment, useEffect, useRef, useState} from "react";
import Slider from 'react-slick';
import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader,
    Col,
    FormGroup, Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label, Media,
    Modal,
    ModalBody,
    ModalHeader, Nav, NavItem, NavLink,
    Row, TabContent, Table, TabPane
} from "reactstrap";


import {useDispatch, useSelector} from "react-redux";
import PreLoader from "../../../share-components/ui/preLoader";
import {
    generateAllPickingTicket,
    getLatestPickingTicket,
    openPickingTicketDialogs,
    setLatestPickingTickets
} from "../store/orderSlice";
import InitialsAvatar from "react-initials-avatar";
import {Link} from "react-router-dom";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import {toast} from "react-toastify";


const OrderPickingTicket = (props) => {

    const {orderState} = props;
    const [state, setState] = useState({nav1: null, nav2: null});
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();


    const closDialog = () => {
        dispatch(openPickingTicketDialogs(false))
        setLoading(false);
    }
    useEffect(() => {
        if (orderState.pickingTicketDialogs.props.isOpen) {
            dispatch(getLatestPickingTicket()).then(res => {
                dispatch(setLatestPickingTickets(res.payload));
                setLoading(false)

            })
        }
    }, [orderState.pickingTicketDialogs.props.isOpen]);

    const generatePickingTicket=(data)=>{
        setLoading(true);
        dispatch(generateAllPickingTicket(data)).then(res => {
            if (!res.error) {
                const url = window.URL.createObjectURL(new Blob([res.payload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `picking_tickets.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                dispatch(openPickingTicketDialogs(false))
                setLoading(false);
            } else {
                toast.error("No orders to be Picked", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
            }

        });
    }

    const {nav1, nav2} = state;


    return (
        <>
            <Modal className="modal-lg modal-dialog-centered product-modal"
                   size="lg"   {...orderState.pickingTicketDialogs.props}>
                {/*<ModalBody>*/}
                <ModalHeader toggle={() => {
                    // alert("Test");
                    closDialog()
                }}>
                    Picking Ticket
                </ModalHeader>
                {!loading ?
                    <div>
                        <div className="row">

                            {orderState.latestPickingTickets.map(item => (
                                <div className="col-md-4" key={item.id}>
                                    <Card style={{boxShadow: '0 0 20px rgba(8, 21, 66, 0.05) !important'}}>
                                        <CardBody style={{padding: 20}}>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <p>Created By {item.created_by}</p>
                                                    <p>at {item.created_time}</p>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="mt-3">
                                                        <a  href={item.url} target="_blank" className="float-right"><i
                                                            className="fa fa-download"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))
                            }
                        </div>
                        <Row>
                          <Col md={12}>
                              <div className="float-right p-2" >
                             <Button type="button" color="primary"  onClick={()=>{
                                 generatePickingTicket({});
                             }}>Generate New Picking Ticket</Button>
                              </div>
                          </Col>
                        </Row>
                    </div>


                    :
                    <PreLoader withoutText></PreLoader>
                }
                {/*</ModalBody>*/}
            </Modal>
        </>);

}
export default OrderPickingTicket;
