import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import { getPickingTicketApi } from "../../../services/order";
import {toast} from "react-toastify";

export const getPickingTicket=createAsyncThunk('order/getPickingTicket',async(data, {dispatch})=>{
    const response=await getPickingTicketApi(data);
    console.log("slice",data);
    dispatch(setPickingTicketData(response.response.count))
    return response.response.data;
});


const pickingTicketAdapter = createEntityAdapter({});
export const {selectAll: selectPickingTicket, selectById: selectPickingTicketById} = pickingTicketAdapter.getSelectors(
    state => state.order.pickingTicket
);

const pickingTicketSlice = createSlice({
    name: 'pickingTicket',
    initialState: pickingTicketAdapter.getInitialState({
        pickingTicketTableRow: 0,
    }),
    reducers: {
        setPickingTicketData: (state, action) => {
            state.pickingTicketTableRow = action.payload;
        },
    },
    extraReducers: {
        [getPickingTicket.fulfilled]: pickingTicketAdapter.setAll,
    }
});

export const {setPickingTicketData} = pickingTicketSlice.actions;

export default pickingTicketSlice.reducer;