import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { numberWithCommasWithoutPounds } from "../../../../../utils/common";
import { saleOrderPaymentCalculation } from "../../setting/calculation";

const SalesOrderInvoiceView = (props) => {
  const { invoiceData } = props;
  const dispatch = useDispatch();
  const payment = saleOrderPaymentCalculation(invoiceData);

  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <span className="f-w-600">Invoice ID : </span>
                    <span className="f-w-100 ">
                      {invoiceData("invoice_no")}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Invoice Date : </span>
                    <span className="f-w-100 ">{invoiceData("date")}</span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Due Date : </span>
                    <span className="f-w-100 ">{invoiceData("due_date")}</span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Terms : </span>
                    <span className="f-w-100 ">
                      {invoiceData("terms")?.name}
                    </span>
                  </Col>
                </Row>
                {/*<Row className="mt-3">*/}
                {/*    <Col md={4}>*/}
                {/*        <span className="f-w-600">Total Boxes</span>*/}
                {/*        <span  className="f-w-100 pull-right">11</span>*/}
                {/*    </Col>*/}
                {/*    <Col md={4}>*/}
                {/*        <span className="f-w-600">Total Gross Weight</span>*/}
                {/*        <span className="f-w-100 pull-right">11</span>*/}
                {/*    </Col>*/}
                {/*    <Col md={4}>*/}
                {/*        <span className="f-w-600">Total Net Weight</span>*/}
                {/*        <span className="f-w-100 pull-right">11</span>*/}
                {/*    </Col>*/}

                {/*</Row>*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="table-responsive w-100">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "40%" }}>Product</th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Order Qty
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Invoice Qty
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Rate
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Sub Total
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Tax Amount
                            </th>
                            <th style={{ textAlign: "right", width: "10%" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData("ordered_products")
                            .filter(function (value, index) {
                              return value.is_selected;
                            })
                            .map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <img
                                        className="img-fluid"
                                        src={item.product.image}
                                        width="40"
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        <p className={"font-primary"}>
                                          {item.product.name}
                                        </p>
                                      </div>
                                      <div className="row">
                                        <p>{item.product.option}</p>
                                      </div>
                                      <div className="row">
                                        <p>{item.product.sku}</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.order_quantity}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {item.quantity}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCommasWithoutPounds(item.rate)}{" "}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCommasWithoutPounds(
                                      item.total_price
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCommasWithoutPounds(
                                      item.total_tax
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <div style={{ marginTop: "1rem" }}>
                                    {numberWithCommasWithoutPounds(
                                      item.gross_price
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card>
              <CardBody>
                <Row className="mb-3 mt-5">
                  <Col md="12">
                    <h6>Pickup Details</h6>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <span className="f-w-600">Total No Of Boxes</span>
                    <span className="f-w-100 pull-right">
                      {invoiceData("total_no_of_boxes")}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <span className="f-w-600">Total Gross Weight</span>
                    <span className="f-w-100 pull-right">
                      {invoiceData("total_gross_weight")}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3 mb-5">
                  <Col md={12}>
                    <span className="f-w-600">Total Net Weight</span>
                    <span className="f-w-100 pull-right">
                      {invoiceData("total_net_weight")}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md="12">
                    <h6>Payment</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Total Amount</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.subTotal}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Discount</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.discountPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Sub Total</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.subTotalWithDiscount}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Duty</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.dutyPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Shipment</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.shippingPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Tax</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">
                      {payment.taxPrice}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    {" "}
                    <span className="f-w-600">Net Total</span>
                  </Col>
                  <Col md={6} className="text-center">
                    <span className="text-center">
                      ..............................................
                    </span>
                  </Col>
                  <Col md={3}>
                    {" "}
                    <span className="f-w-100 pull-right">{payment.total}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SalesOrderInvoiceView;
